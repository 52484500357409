import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Alert, Box, CircularProgress, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const UserTableLayout = ({ clientList, setSingleUser, loading }) => {
  return (
    <>
      {loading ? (
        <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
          <CircularProgress sx={{ color: "#C4C4C4" }} />
        </Box>
      ) : clientList.length > 0 ? (
        <table className="leading-normal w-full">
          <thead className=" text- font-semibold tracking-wider text-left px-5 py-3 hover:cursor-pointer ">
            <tr className="">
              <th
                scope="col"
                className="py-8 pr-3 pl-7 text-left font-semibold text-black  tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="py-8 text-left font-semibold text-black  tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="pb-y text-left font-semibold text-black  tracking-wider"
              >
                Phone Number
              </th>
              <th
                scope="col"
                className="pb-y  px-3 text-left font-semibold text-black  tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="pb-y pl-3 pr-7 text-left font-semibold text-black  tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody className="font-semibold px-5 cursor-pointer">
            {clientList.map((user, index) => {
              return (
                <tr
                  className={index % 2 === 0 && "bg-[#C4C4C480]"}
                  key={index}
                  onClick={() => setSingleUser(user)}
                >
                  <td className="py-3 pr-3 pl-7 text-gray-900 ">
                    <p className="text-black">
                      {user.first_name} {user.last_name}
                    </p>
                  </td>
                  <td className="py-3 text-gray-900 ">
                    <p className="text-black">{user.email}</p>
                  </td>
                  <td className="py-4 pr-3 text-gray-900 ">
                    <p className="text-black">{user.phone}</p>
                  </td>
                  <td className="font-normal py-3 px-3 text-gray-900 ">
                    <p
                      className={`${
                        user.status === "active"
                          ? "text-green-500"
                          : user.status === "inactive"
                          ? "text-red-500"
                          : "text-yellow-500"
                      }`}
                    >
                      {user.status}
                    </p>
                  </td>
                  <td className="py-3 pl-3 pr-7 text-gray-900 ">
                    <IconButton>
                      <ArrowForwardIosIcon className="text-green-700" />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No Clients Found
          </Alert>
        </center>
      )}
    </>
  );
};

export default UserTableLayout;
