import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendingLoading: false,
  sent: null,
  failed: null,
};

const SendEmailSlice = createSlice({
  name: "Send SMS",
  initialState,
  reducers: {
    SendingEmail: (state) => {
      state.sendingLoading = true;
      state.sent = null;
      state.failed = null;
    },
    EmailSent: (state, { payload }) => {
      state.sent = payload;
      state.sendingLoading = false;
      state.failed = null;
    },
    EmailFailed: (state, { payload }) => {
      state.sent = null;
      state.failed = payload;
      state.sendingLoading = false;
    },
  },
});

export const { SendingEmail, EmailFailed, EmailSent } = SendEmailSlice.actions;

export default SendEmailSlice.reducer;
