import React, { useState } from "react";
import { COLORS, FONTSIZE } from "../../../constants/theme";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import bin from "../../../assets/bin.svg";
import SearchIcon from "../../../assets/search.svg";
import ViewModal from "./ViewModal";
import "./viewModal.css";
import movefile from "../../../assets/movefiles.svg";
import { ReactComponent as GroupIcon } from "../../../assets/group.svg";
import styles from "./Users.module.css";
const { white, darkBlue, grey, primary, black } = COLORS;
const { px20, px14, px18, px36 } = FONTSIZE;

const Teams = () => {
  const tableData = [
    {
      name: "Financial team",
      users: "2 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Statistics team",
      users: "10 Users",
      roles: "1 role",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Research team",
      users: "4 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Development team",
      users: "3 Users",
      roles: "3 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Security team",
      users: "5 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Pipeline team",
      users: "6 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Management team",
      users: "5 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Experiment team",
      users: "3 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
    {
      name: "Seal team",
      users: "9 Users",
      roles: "2 roles",
      desc: "Financial team handling the basic...",
      amount: "",
      totalMessages: "",
    },
  ];

  const [viewModal, setViewModal] = useState(false);
  const openModal = () => {
    setViewModal(true);
  };

  const navigate = useNavigate();
  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={styles.tableDetails}>
          <GroupIcon />
          <div className={styles.tableActions}>
            <button
              className={styles.importBtn}
              onClick={() => navigate("/people/add-team")}
            >
              ADD NEW TEAM
            </button>
          </div>
        </div>
        <div className={styles.searchSection}>
          <div className={styles.searchBox}>
            <img src={SearchIcon} />
            <input
              type="text"
              className={styles.search}
              placeholder="Search by first name, last name, number"
            />
          </div>
        </div>
        <div className={styles.sortSection}>
          <div>
            <p> Showing 1 - 8 of 1000</p>
          </div>
          <button className={styles.sortBtn}>
            <Filter />
            Sort by
          </button>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table className={styles.customers}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Users</th>
                <th>Roles</th>
                <th>Description</th>
                <th>Expenditure </th>
                {/* <th>Total Messages</th> */}

              </tr>
            </thead>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td onClick={openModal}>{item.name}</td>
                <td>{item.users}</td>
                <td>{item.roles}</td>
                <td>{item.desc}</td>
                <td>{item.amount}</td>
                {/* <td>{item.totalMessages}</td> */}
              </tr>
            ))}
          </table>
        </div>
        {viewModal && (
          <ViewModal
            close={() => {
              setViewModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Teams;
