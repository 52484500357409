import React from "react";
import styles from "./ChangePassword.module.css";
import Alert from "@mui/material/Alert";
import { COLORS, FONTSIZE } from "../../../../constants/theme";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { useState, useEffect } from "react";
import { ReactComponent as EyeHide } from "../../../../assets/eyehide.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "../../../../utils/axios";
import ConfirmationBox from "../../../../components/confirmationBox/ConfirmationBox";
import SAlert from "../../../../utils/Alert";
const { white, darkBlue, grey, primary, black } = COLORS;
const { px20, px14, px18, px36 } = FONTSIZE;

const ChangePasswordModal = ({ close }) => {
  const [buttonName, setButtonName] = useState("Sign In");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [passwordUnmatch, setPasswordUnmatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });
  const [passwordInputs, setPasswordInputs] = useState({
    password: {
      type: "password",
      text: "",
    },
    confirm_password: {
      type: "password",
      text: "",
    },
    new_password: {
      type: "password",
      text: "",
    },
  });

  const afterTransaction = () => {
    setConfirmation(false);
    setOpenDialogBox(false);
    close(false);
    setLoading(false);
  };

  useEffect(() => {
    if (confirmation) {
      const { new_password, password, confirm_password } = passwordInputs;

      const data = {
        password: password.text,
        new_password: new_password.text,
        confirm_password: confirm_password.text,
      };

      axios
        .post("/admin/change-password", data)
        .then((response) => {
          setAlertStatus({
            alertMessage: response.data.message,
            alertType: "success",
            openAlert: true,
          });
          afterTransaction();
        })
        .catch((error) => {
          setAlertStatus({
            alertMessage: error.response.data.message,
            alertType: "error",
            openAlert: true,
          });
          afterTransaction();
        });
    }
  }, [confirmation]);

  const submit = (e) => {
    e.preventDefault();
    const { new_password, password, confirm_password } = passwordInputs;

    if (
      new_password.text === "" ||
      password.text === "" ||
      confirm_password.text === ""
    ) {
      return;
    } else if (new_password.text !== confirm_password.text) {
      setPasswordUnmatch(true);
    } else {
      setLoading(true);
      setButtonName("UPDATING...");
      setOpenDialogBox(true);
      setDialogText("Are you sure you want to change password?");
    }
  };

  const handleTextOnchange = (e, value) => {
    setPasswordInputs({
      ...passwordInputs,
      [value]: {
        type: passwordInputs[value].type,
        text: e.target.value,
      },
    });
  };

  const handleIconOnChange = (value) => {
    setPasswordInputs({
      ...passwordInputs,
      [value]: {
        ...value,
        type: passwordInputs[value].type === "password" ? "text" : "password",
      },
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  return (
    <>
      <form
        onSubmit={submit}
        className={styles.container}
        style={{
          borderBottom: "5px solid #4B1A7C",
          margin: "auto",
          borderRadius: "5px",
        }}
      >
        <h1>Change Password</h1>

        <div
          style={{
            width: "100%",
          }}
        >
          <div className={styles.inputBox}>
            <Input
              label="Current Password"
              type={passwordInputs.password.type}
              id="currentPassword"
              name="currentPassword"
              placeholder="Current Password"
              value={passwordInputs.password.text}
              mb="2rem"
              onChange={(e) => handleTextOnchange(e, "password")}
            />
            <div onClick={() => handleIconOnChange("password")}>
              {passwordInputs.password.type === "password" ? (
                <VisibilityIcon fontSize="medium" />
              ) : (
                <EyeHide />
              )}
            </div>
          </div>
          <div className={styles.inputBox}>
            <Input
              label="New Password"
              type={passwordInputs.new_password.type}
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              value={passwordInputs.new_password.text}
              mb="2rem"
              onChange={(e) => handleTextOnchange(e, "new_password")}
            />
            <div onClick={() => handleIconOnChange("new_password")}>
              {passwordInputs.new_password.type === "password" ? (
                <VisibilityIcon fontSize="medium" />
              ) : (
                <EyeHide />
              )}
            </div>
          </div>
          <div className={styles.inputBox}>
            <Input
              label="Confirm Password"
              type={passwordInputs.confirm_password.type}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={passwordInputs.confirm_password.text}
              mb="2rem"
              onChange={(e) => handleTextOnchange(e, "confirm_password")}
            />
            <div onClick={() => handleIconOnChange("confirm_password")}>
              {passwordInputs.confirm_password.type === "password" ? (
                <VisibilityIcon fontSize="medium" />
              ) : (
                <EyeHide />
              )}
            </div>
          </div>
          {passwordUnmatch && (
            <Alert severity="error">Passwords do not match</Alert>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "4rem",
            }}
            className={styles.buttonSection}
          >
            <Button
              name="CANCEL"
              width="150px"
              height="max-content"
              marginTop="1rem"
              bc="transparent"
              tc="#000000"
              mr="1rem"
              b="1px solid #000000"
              style={{ borderRadius: "0", padding: "10px", width: "150px" }}
              onClick={() => close(false)}
            />
            <Button
              name={loading ? setButtonName("UPDATING...") : buttonName}
              style={{
                borderRadius: 2,
                width: "150px",
                height: "50px",
                padding: "5px",
                opacity:
                  passwordInputs.confirm_password.text === "" ||
                  passwordInputs.new_password.text === "" ||
                  passwordInputs.password.text === ""
                    ? 0.5
                    : 1,
                marginTop: "1rem",
              }}
              disabled={
                (passwordInputs.confirm_password.text === "" ||
                  passwordInputs.new_password.text === "" ||
                  passwordInputs.password.text === "") &&
                true
              }
              // onClick={() => signInLoading && setButtonName("UPDATING...")}
            />
          </div>
        </div>
      </form>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={dialogText}
        />
      )}
      <SAlert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
};

export default ChangePasswordModal;
