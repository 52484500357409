import { COLORS, FONTSIZE } from "../../../constants/theme";

export default function Button({
  name,
  textCase,
  bc,
  p,
  tc,
  width,
  margin,
  height,
  marginTop,
  onClick,
  b,
  mr,
  style,
  customClass,
  ...rest
}) {
  const { primary } = COLORS;
  const { px18 } = FONTSIZE;

  return (
    <button
      style={{
        backgroundColor: bc ?? primary,
        outline: 0,
        border: b ?? `1px solid ${primary}`,
        borderRadius: "5px",
        padding: p ?? "1rem",
        color: tc ?? "#ffffff",
        fontSize: px18,
        //  width: width ?? "100%",
        margin: margin ?? 0,
        cursor: "pointer",
        height: height,
        marginTop: marginTop,
        marginRight: mr,
        textTransform: textCase,
        ...style,
      }}
      onClick={onClick}
      className={customClass}
      {...rest}
    >
      {name}
    </button>
  );
}
