import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "../../../../../utils/Alert";
import { fetchDeposits } from "../../../../../utils/apiCalls/admin/fetchDeposits";
import axios from "../../../../../utils/axios";
import Close from "../../../../assets/closeCircle.svg";

const ViewDetails = ({
  setViewDetails,
  viewDetails,
  typeStatus,
  setAlertStatus,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");

  const verifyAccount = (status) => {
    setLoading(true);
    axios
      .post("/admin/payment/update", { pay_id: `${viewDetails.id}`, status })
      .then((response) => {
        setLoading(false);
        setViewDetails(null);
        dispatch(fetchDeposits(typeStatus));
        setAlertStatus({
          alertMessage:
            status === "approved"
              ? "Transaction approved successful"
              : "Transaction declined successful",
          alertType: "success",
          openAlert: true,
        });
        // setViewDetails(null);
      })
      .catch((error) => {
        setLoading(false);
        setViewDetails(null);
        setAlertStatus({
          alertMessage: "Transaction failed",
          alertType: "error",
          openAlert: true,
        });
      });
  };

  useEffect(() => {
    if (loading) {
    }
  }, [loading]);

  const localData =
    localStorage.userDetails && JSON.parse(localStorage.userDetails);

  return (
    <>
      <Modal
        open={viewDetails !== null}
        onClose={() => setViewDetails(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div className="bg-white w-[570px] rounded p-[31px]">
            <div className="flex justify-between items-center mb-12">
              <h3 className="font-medium text-2xl">Verify Deposit</h3>
              <img
                src={Close}
                alt="close the side panel"
                onClick={() => setViewDetails(null)}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center mb-10">
              <p className="w-40 mr-24  font-semibold">Name</p>
              <p>{viewDetails.sender}</p>
            </div>
            <div className="flex items-center mb-10">
              <p className="w-40 mr-24 font-semibold">Bank</p>
              <p>{viewDetails.bank}</p>
            </div>
            <div className="flex items-center mb-10">
              <p className="w-40 mr-24 font-semibold">Amount Deposited</p>
              <div>
                <p className="">{viewDetails.amount}</p>
              </div>
            </div>
            <div className="flex items-center mb-10">
              <p className="w-40 mr-24 font-semibold">Payment Method</p>
              <p className="">{viewDetails.channel}</p>
            </div>
            <div className="flex items-center mb-10">
              <p className="w-40 mr-24 font-semibold">Payment Date</p>
              <p>{viewDetails.payment_date}</p>
            </div>
            <div className="flex items-center">
              <p className="w-40  mr-24 font-semibold">Reference</p>
              <p>{viewDetails.reference}</p>
            </div>

            {localData.user.role !== "Admin" && typeStatus === "pending" && (
              <div className="flex justify-end px-3 mt-20 w-full">
                <button
                  onClick={() => {
                    verifyAccount("declined");
                    setType("declined");
                  }}
                  className="w-[180px] text-[#D11A2A] font-bold py-2 px-3 rounded-xl"
                >
                  {loading && type === "declined" ? (
                    <CircularProgress />
                  ) : (
                    "Decline"
                  )}
                </button>
                <button
                  onClick={() => {
                    verifyAccount("approved");
                    setType("approved");
                  }}
                  className="w-[180px] text-[#3A974C] bg-green-100 font-bold py-2 px-3 ml-2 rounded-2xl"
                  type="submit"
                >
                  {loading && type === "approved" ? (
                    <CircularProgress />
                  ) : (
                    "Verify"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewDetails;
