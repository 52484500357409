import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  transferUnitSuccess: false,
  transferDetails: {},
  transferUnitError: false,
  transferError: "",
};

export const TransferUnitSlice = createSlice({
  name: "TransferUnit",
  initialState,
  reducers: {
    transferUnitStart: (state) => {
      state.loading = true;
    },
    transferUnitSucessful: (state, { payload }) => {
      state.loading = false;
      state.transferDetails = payload;
      state.transferUnitSuccess = true;
      state.transferUnitError = false;
    },
    transferUnitFailed: (state, { payload }) => {
      state.loading = false;
      state.transferUnitError = true;
      state.transferError = payload;
    },
  },
});

export const { transferUnitSucessful, transferUnitStart, transferUnitFailed } =
  TransferUnitSlice.actions;

export default TransferUnitSlice.reducer;
