import React from 'react'


const AddNewUserPage = () => {
  return (
    <div>

    </div>
  )
}

export default AddNewUserPage