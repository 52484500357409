import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  getTransactionData: [],
  showCalendar: false,
};

export const transactionsSlice = createSlice({
  name: "get-transactions",
  initialState,
  reducers: {
    getTransactionStart: (state) => {
      state.loading = true;
    },
    getTransactionSuccess: (state, { payload }) => {
      state.loading = false;
      state.getTransactionData = payload;
    },
    setShowCalendar: (state, { payload }) => {
      state.showCalendar = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getTransactionStart, getTransactionSuccess, setShowCalendar } =
  transactionsSlice.actions;

export default transactionsSlice.reducer;
