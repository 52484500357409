import React from "react";
import "./acctModal.css";
import closeCircle from "../../../assets/closeCircle.svg";
const ViewAcctModal = ({closeModal}) => {
  return (
    <>
      {/* <div id="overlayyy"> */}
      <div className="modalll">
        <div className="modalContents">
          <div id="btnBox">
            <img
              src={closeCircle}
              alt=""
              id="closeBtnn"
              onClick={() => closeModal(false)}
            />
          </div>
          <div className="bankDetails">
            <h3> Bank: Guarantee Trust Bank</h3>
            <h1>NUBAN: 0572004368</h1>
            <h2>
              Account name: Exturi Integrated Solutions Limited
            </h2>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ViewAcctModal;
