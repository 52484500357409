import React from 'react'
import styles from './QuickLinkCard.module.css'
function QuickLinkCard({item}) {
  return (
    <div className={styles.quickLinkCard}>
    {item.icon}
    
         <h4>{item.title}</h4>
 </div>
  )
}

export default QuickLinkCard