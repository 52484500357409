import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  volume: null,
  type: null,
  rate: null
};

export const calculateSlice = createSlice({
  name: "get-transactions",
  initialState,
  reducers: {
    SetType: (state, actions) => {
        state.type = actions.payload
    },
    SetVolume : (state, actions) => {
        state.volume = actions.payload
    },
    SetRate: (state, actions) => {
        state.rate = actions.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { SetType, SetVolume, SetRate } =
  calculateSlice.actions;

export default calculateSlice.reducer;
