import React, { useState } from "react";
import styles from "./UserType.module.css";
import { ReactComponent as IndividualIcon } from "../../assets/individualicon.svg";
import { ReactComponent as TickIcon } from "../../assets/tick.svg";
import TeamsIcon from "../../assets/teamsicon.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

function UserType() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate()
  const Plans = [
    "2500 Units",
    "Access to send sms",
    "2500 Units",
    "Access to send sms",
    "2500 Units",
    "Access to send sms",
  ];
  function handleSelect(plan) {
    setSelectedPlan(plan)
  }
  return (
    <div className={styles.userTypeContainer}>
      <div className={styles.boxes}>
        <div className={styles.choiceBox} onClick={()=>handleSelect("individual")}>
         {selectedPlan==='individual' &&  <div className={styles.selected}>
            <i className={`ri-check-line ${styles.selectIcon}`}></i>
          </div>}
          <IndividualIcon />
          <h4>individual</h4>
          <div>
            {Plans.map((elem, index) => (
              <div className={styles.itemBox} key={index}>
                <TickIcon />
                {elem}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.choiceBox} onClick={()=>handleSelect("team")}>
          {/* <TeamsIcon /> */}
          {selectedPlan==='team' &&  <div className={styles.selected}>
            <i className={`ri-check-line ${styles.selectIcon}`}></i>
          </div>}
          <img src={TeamsIcon} />
          <h4>Team</h4>
          <div>
            {Plans.map((elem, index) => (
              <div className={styles.itemBox} key={index}>
                <TickIcon />
                {elem}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button name="next" height={50} textCase="uppercase" onClick={()=>navigate('/dashboard')} />
    </div>
  );
}

export default UserType;
