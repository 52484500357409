import {
  EmailFailed,
  EmailSent,
  SendingEmail,
} from "../../../../redux/admin/Send/sendEmail";
import axios from "../../../axios";

export const SendEmail = (data) => (dispatch) => {
  dispatch(SendingEmail());

  axios
    .post("/admin/notification/mail-user", data)
    .then((response) => {
      dispatch(EmailSent(response.data.message));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(EmailFailed(message));
    });
};
