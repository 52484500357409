import React, { useEffect, useState } from "react";
import downArrow from "../../assets/arrowdown.svg";
import styles from "./download.module.css";
// import Download from "react-csv/components/Download";
// import { DownloadForOfflineOutlined } from "@mui/icons-material";

const DownloadDropdown = ({ items, selectedFilter, onFilterChange }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(selectedFilter);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (item) => {
    setCurrentFilter(item);
    onFilterChange(item);
    closeDropdown();
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (
      isDropdownOpen &&
      !event.target.closest(`.${styles.dropdownContainer}`)
    ) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };
  }, []);

  return (
    <div className={styles.dropdownContainer} onClick={toggleDropdown}>
      <div className={styles.selectStyle}>
        <p className={styles.downloadBtn}> Download</p>
        {isDropdownOpen && (
          <ul className={styles.dropdownList}>
            {items.map((item, index) => (
              <li
                className={styles.list}
                key={index}
                onClick={() => handleOptionClick(item.label)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <DownloadForOfflineOutlined /> */}
      <img src={downArrow} alt="Filter Icon" style={iconStyle} />
    </div>
  );
};

const iconStyle = {};

export default DownloadDropdown;
