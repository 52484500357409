import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendingLoading: false,
  sent: null,
  failed: null,
};

const SendSmsSlice = createSlice({
  name: "Send SMS",
  initialState,
  reducers: {
    SendingSMS: (state) => {
      state.sendingLoading = true;
    },
    MessageSent: (state, { payload }) => {
      state.sent = payload;
      state.sendingLoading = false;
      state.failed = null;
    },
    MessageFailed: (state, { payload }) => {
      state.sent = null;
      state.failed = payload;
      state.sendingLoading = false;
    },
  },
});

export const { SendingSMS, MessageFailed, MessageSent } = SendSmsSlice.actions;

export default SendSmsSlice.reducer;
