import React, { useState } from "react";
import styles from "./Api.module.css";
import userSettings from "../../../../assets/userSettings.svg";
import infoCircle from "../../../../assets/infoCircle.svg";
import Modal from "../../../../components/modal/Modal";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

const Permissions = ({
  setPermissionType,
  cancel,
  permissionType,
  generate,
  apiName,
  setApiName,
  header,
}) => {
  const generateError = useSelector((state) => state.APIs.generateError);
  const loading = useSelector((state) => state.APIs.loading);

  const cards = [
    {
      Title: "Simple Access",
      Pic: userSettings,
      Details:
        "This API will allow you to connect your application to XMS in order to send single or bulk sms.",
      checked: permissionType === "simple" ? true : false,
    },
    {
      Title: "Super Access",
      Pic: userSettings,
      Details:
        "This gives the API a super access to allow your application to perform various activities.",
      checked: permissionType === "super" ? true : false,
    },

    // {
    //   Title:"Full Access",
    //   Pic : userSettings,
    //   Details: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi sunt sed ullam sint omnis possimus dolorem, ipsa illo",
    // },
    // {
    //   Title:"Full Access",
    //   Pic : userSettings,
    //   Details: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi sunt sed ullam sint omnis possimus dolorem, ipsa illo",
    // },
  ];
  return (
    <>
      <Modal
        // close={()=>dispatch(openCreateModal(false))}
        customStyle={{ backdropFilter: "blur(20px)" }}
      >
        <div className={`${styles.apiBox}, ${styles.slideInRight}`}>
          <div className={styles.dataBox}>
            <h3>{header}</h3>
            <div id={styles.keyNameApi}>
              <h6>
                API Key Name{" "}
                <span style={{ color: "var(--color-red)", fontWeight: "700" }}>
                  *
                </span>
              </h6>
              {generateError !== "" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "small",
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  Error: {generateError}
                </p>
              )}
              <div style={{ marginTop: "0px" }}>
                <div id={styles.flex}>
                  <img src={infoCircle} alt="" id={styles.infoCircle} />
                </div>
                <input
                  type="text"
                  placeholder="Api name"
                  value={apiName}
                  onChange={(e) => setApiName && setApiName(e.target.value)}
                  id={styles.keyNameInput}
                />
              </div>

              <div className={styles.options}>
                <span className={styles.flexRow}>
                  <h3>
                    API Key Permissions{" "}
                    <span
                      style={{ color: "var(--color-red)", fontWeight: "700" }}
                    >
                      *
                    </span>
                  </h3>
                  <span>
                    <img
                      id={styles.infoCircle}
                      src={infoCircle}
                      alt=""
                      style={{ marginLeft: "10px" }}
                    />
                  </span>
                </span>
                <div
                  id="child"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {cards.map((card, index) => {
                    const { Title, Details, Pic, checked } = card;
                    return (
                      <div className={styles.miniCon} key={index}>
                        <div className={styles.topIcons}>
                          <input
                            type="radio"
                            name="select_type"
                            checked={checked}
                            onChange={() =>
                              setPermissionType(
                                Title === "Simple Access" ? "simple" : "super"
                              )
                            }
                            // value="contacts"
                            // onClick={}
                            // onClick={(e) => setDisabled("")}
                          />
                          <span>
                            <img src={Pic} alt="img" />
                          </span>
                        </div>
                        <span id={styles.con1}>
                          <big>{Title}</big>
                          <p>{Details}</p>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.btnCon}>
                  <button id={styles.cancelBtn} onClick={() => cancel()}>
                    Cancel
                  </button>
                  <button
                    id={styles.createBtn}
                    style={{
                      opacity: apiName === "" ? 0.5 : loading ? 0.5 : 1,
                    }}
                    disabled={apiName === "" ? true : false}
                    onClick={() => generate()}
                  >
                    {loading ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress
                          size={15}
                          sx={{ color: "#C4C4C4", width: 5 }}
                        />
                        &nbsp;Please wait
                      </Box>
                    ) : (
                      "Create & View"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Permissions;
