export const formatPhoneNumber = (number) => {
    
    let formattedNumber
    if (number.substr(0, 1) == 0) {
      formattedNumber = 234 + number.substr(1);
    } else if (
      number.substr(0, 1) == 7 ||
      number.substr(0, 1) == 8 ||
      number.substr(0, 1) == 9
    ) {
        formattedNumber = 234 + number;
    } else {
      formattedNumber = number;
    }
    
  return formattedNumber;
};

