import React from "react";
import styles from "./companys.module.css";
import fobca2 from "../../assets/Fobca2.svg";
// import exturi1 from "../../assets/exturi1.svg";
import kounty from "../../assets/kounty2.svg";
import jejeBW from "../../assets/jeje2.svg";
import peacemass from "../../assets/pmt.svg";
import alphaBeta from "../../assets/alphaBeta.svg";
import lagos from "../../assets/lagos.svg";
import hardams from "../../assets/hardams.svg";
import Slide from "react-reveal/Slide";
const Companys = () => {
  return (
    <Slide bottom>
      <p id={styles.quote}>Our top partners</p>
      <marquee>

      <div className={`${styles.companys}`}>
        <span>
          <img
            src={lagos}
            alt=""
            id={styles.img}
            style={{ width: "150px", height: "auto" }}
          />
        </span>
        <span>
          <img
            src={alphaBeta}
            alt=""
            id={styles.img}
            style={{ width: "auto", height: "75px" }}
          />
        </span>
        <span>
          <img
            src={hardams}
            alt=""
            id={styles.img}
            style={{ width: "auto", height: "75px" }}
          />
        </span>
        <span>
          <img src={fobca2} alt="" id={styles.img} />
        </span>
        <span>
          <img
            src={peacemass}
            alt="img"
            id={styles.img}
            // style={{ width: "auto", height: "75px" }}
          />
        </span>
        <span>
          <img
            src={jejeBW}
            alt="img"
            id={styles.img}
            style={{ width: "auto", height: "65px" }}
          />
        </span>

        {/* <span>
          <img src={exturi1} alt="" id={styles.img} />
        </span> */}
        <span>
          <img src={kounty} alt="img" id={styles.img}  style={{ width: "auto", height: "75px" }} />
        </span>
      </div>
      </marquee>

      {/* <div className={styles.feature}>
        <h3>FEATURES</h3>
        <div id={styles.dottedLines}></div>
      </div> */}
    </Slide>
  );
};

export default Companys;
