import axios from "../../../axios";
import {
  sendMailError,
  sendMailStart,
  sendMailSuccess,
} from "../../../../redux/admin/messages/sendMailSlice";
import {
  MessageFailed,
  MessageSent,
  SendingSMS,
} from "../../../../redux/admin/Send/sendSmsSlice";

export const SendSMS = (data) => (dispatch) => {
  dispatch(SendingSMS());

  axios
    .post("/admin/notification/sms-user", data)
    .then((response) => {
      dispatch(MessageSent(response.data.message));
    })
    .catch((error) => {
      const message = error.response.data.error;
      dispatch(MessageFailed(message));
    });
};

export const SendEmail = (data) => async (dispatch) => {
  dispatch(sendMailStart());
  axios
    .post("/admin/notification/mail", data)
    .then((response) => {
      const { message } = response.data;
      dispatch(sendMailSuccess(message));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(sendMailError(message));
    });
};
