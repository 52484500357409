import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  topUpStatus: "",
  failed: "",
};

const adminTopUpSlice = createSlice({
  name: "Admin Top Up",
  initialState,
  reducers: {
    TopUpLoading: (state) => {
      state.loading = true;
      state.topUpStatus = "";
      state.failed = "";
    },
    TopUpWallet: (state, { payload }) => {
      state.loading = false;
      state.topUpStatus = payload;
      state.failed = "";
    },
    TopUpFailed: (state, { payload }) => {
      state.loading = false;
      state.topUpStatus = "";
      state.failed = payload;
    },
    TopUpReset: (state) => {
      state.topUpStatus = "";
      state.failed = "";
    },
  },
});

export const { TopUpFailed, TopUpLoading, TopUpWallet, TopUpReset } =
  adminTopUpSlice.actions;

export default adminTopUpSlice.reducer;
