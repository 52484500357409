import React, { useEffect, useState } from 'react'
import styles from './ConfirmCard.module.css'
import {ReactComponent as Warning} from '../../assets/warning.svg'
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { sendSms } from '../../utils/apiCalls/sendSms';
import Alert from '../../utils/Alert';
import { resetStatus } from '../../redux/sms/messageSummarySlice';
import { useNavigate, } from 'react-router-dom';
import {useHistory } from 'react-router'
import axios from '../../utils/axios';

function ResendFailedMsgs({ close, message, reference }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  // const loading = useSelector((state) => state.summary.sendSmsLoad);
  // const msgPayload = useSelector((state) => state.summary.msgSummary);
  // const openAlert = useSelector((state) => state.summary.sendSmsSuccess);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
  
      return;
    }
  };

  const action = async () => {
    setLoading(true)
    // file deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
    await axios.post("user/sms/resend", {reference: reference})
    .then((response)=>{
      const {data} = response
      const ref = data.reference
      setOpenAlert(true)
      setTimeout(() => {
        navigate(`/delivery-report/${ref}`);
      }, 2000);
    })
    .error((error)=>{

    })
  };

  return (
    <>
      <div className={styles.confirmContainer}>
        <Warning />
        <h3>{message ?? "Are you sure you want to send the message?"}</h3>
        <div className={styles.buttonSection}>
          <button onClick={close}>cancel</button>
          <button onClick={() => action()}>continue</button>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert
        handleClose={handleClose}
        openAlert={openAlert}
        Text="Message sent successfully"
        Type="success"
      />
    </>
  );
}

export default ResendFailedMsgs;