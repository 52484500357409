import styles from "../styles/userStyles.module.css";
import { DateRangePicker } from "react-date-range";
import { Alert, Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as Download } from "../../../../../assets/download.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import MaterialUIPickers from "../../../../../components/dateAndTime/MaterialUIPickers";
import Paginationn from "../../../../../components/Pagination/Pagination";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fil
import moment from "moment";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDown from "../../../../../assets/chevrondown.svg";
import Modal from "../../../../../components/modal/Modal";
import { getUsermessageHistoryByDate } from "../../../../../utils/apiCalls/admin/user/fetchUserMessageHistory";

const MessageHistory = () => {
  let PageSize = 15;

  const dispatch = useDispatch();
  const { state } = useLocation();

  const navigate = useNavigate();

  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDay, setStartDay] = useState(
    moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [stopDay, setStopDay] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  );

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // const showCalendar = useSelector((state)=>state.transactions.showCalendar)

  const { historyData, loading, failed } = useSelector(
    (state) => state.userMessageHistory
  );

  useEffect(() => {
    dispatch(getUsermessageHistoryByDate(state, startDay, stopDay));
  }, [startDay, stopDay]);

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  useEffect(() => {
    if (failed === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [failed]);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return historyData && historyData.slice(firstPageIndex, lastPageIndex);
  };

  const apply = () => {
    setStartDay(moment(date[0].startDate).format("YYYY-MM-DD"));
    setStopDay(moment(date[0].endDate).format("YYYY-MM-DD"));
    setShowCalendar(false);
  };

  const getData = () => {
    let dd = [];
    historyData.map((data, index) => {
      dd.push({
        SenderID: data.tag,
        Amount: data.cost,
        Receiver: data?.total_reciever,
        "Time Spent": moment(data.created_at).format("H:mm:ss, YYYY-MM-DD"),
        Route: data.route,
      });
    });
    return dd;
  };

  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={`${styles.tableDetails}`}>
          <div className="flex items-center">
            <p className="text-lg font-bold ml-2">Message History</p>
          </div>
          <div className={styles.tableActions}>
            {historyData?.length > 0 && (
              <CSVLink
                data={getData()}
                filename={`XMS_transaction_report [${startDay} to ${stopDay}].csv`}
                style={{ textDecoration: "none" }}
              >
                <button className={styles.downloadBtn}>
                  <Download />
                  <b>Download</b>
                </button>
              </CSVLink>
            )}
            <button
              className={styles.carlendarBtn}
              onClick={() => setShowCalendar(true)}
            >
              <div>
                <CalendarIcon />
                <p>
                  <div className={styles.desktopTimePicker}>
                    {moment(startDay).format("DD-MM-YYYY")} to{" "}
                    {moment(stopDay).format("DD-MM-YYYY")}
                  </div>
                </p>
              </div>
              <img src={ArrowDown} alt="arrowDown" />
            </button>
            <div className={styles.mobileTimePicker}>
              <MaterialUIPickers />
            </div>
            {showCalendar && (
              <Modal>
                <div className={styles.calendar}>
                  <DateRangePicker
                    showDateDisplay={false}
                    color="#A382C5"
                    rangeColors={"#4B1A7C"}
                    ranges={date}
                    onChange={(item) => setDate([item.selection])}
                  />
                  <div className={styles.buttonSection}>
                    <button onClick={() => setShowCalendar(false)}>
                      Cancel
                    </button>
                    <button onClick={() => apply()}>Apply</button>
                  </div>
                </div>
              </Modal>
            )}

            {/* <button className={styles.sortBtn}>
              <Filter />
              Sort by
            </button> */}
          </div>
        </div>
        {loading ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress sx={{ color: "#C4C4C4" }} />
          </Box>
        ) : historyData?.length === 0 ? (
          <center>
            <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
              You have not made any transaction
            </Alert>
          </center>
        ) : (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className={styles.customers}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Sender ID
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Amount
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Receiver
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Time sent
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>Route</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData()?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {item.sender_id}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontSize: 13,
                        }}
                      >
                        {formatPriceByCountrySymbol("Nigeria", item.cost)}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {formatPriceByCountrySymbol("", item.total_reciever)}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {moment(item.created_at).format("H:mm:ss, YYYY-MM-DD")}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {item.route}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginationn
              currentPage={currentPage}
              totalCount={historyData?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MessageHistory;
