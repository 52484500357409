import React from "react";
import "./viewModal.css";
import closeCircle from "../../../assets/closeCircle.svg";
import { useNavigate } from "react-router-dom";
import styles from "./Teams.module.css";
const ViewModal = (props) => {
  const tableData = [
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "few seconds ago",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "Never logged in",
    },
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "2 months ago",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "Few seconds ago",
    },
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "Never logged in",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "5 minutes ago",
    },
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "2 months ago",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "2 months ago",
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.overlayy}>
        <div className={styles.modall}>
          <div className={styles.modalContents}>
            <img
              src={closeCircle}
              alt=""
              id={styles.close}
              onClick={props.close}
            />
            <div className={styles.transactionBox}>
              <div className={styles.tableSection}>
                <div className={styles.tableDetails}>
                  {/* <GroupIcon /> */}
                  <h3 style={{fontSize:"24px", fontWeight:"700"}}>Financial Team</h3>
                  <div className={styles.tableActions}>
                    <button
                      className={styles.importBtn}
                      onClick={() => navigate("/people/add-team")}
                    >
                      ADD NEW MEMBER
                    </button>
                  </div>
                </div>
                <div className={styles.searchSection}>
                  {/* <div className={styles.searchBox}>
                    <img src={SearchIcon} />
                    <input
                      type="text"
                      className={styles.search}
                      placeholder="Search by first name, last name, number"
                    />
                  </div> */}
                </div>
                {/* <div className={styles.sortSection}>
                  <div>
                    <p> Showing 1 - 8 of 1000</p>
                  </div>
                  <button className={styles.sortBtn}>
                    <Filter />
                    Sort by
                  </button>
                </div> */}
                <div style={{ overflowX: "auto" }}>
                  <table className={styles.customers}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Activity</th>
                      </tr>
                    </thead>
                    {tableData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.status}</td>
                        <td>{item.activity}</td>
                      </tr>
                    ))}
                  </table>
                </div>
                {/* {viewModal && (
                  <ViewModal
                    close={() => {
                      setViewModal(false);
                    }}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewModal;
