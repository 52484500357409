import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";
// import { AxisOptions, Chart } from "react-charts";

const PieChart = () => {
  const [pieChartData, setPieChartData] = useState();

  const {
    delivered,
    pending,
    failed,
    success_rate,
    pending_rate,
    failed_rate,
    total,
  } = useSelector((state) => state.dashboardData.analysisData.message);

  const { dataError, analysisData, loading } = useSelector(
    (state) => state.dashboardData
  );

  useEffect(() => {
    if (analysisData?.message) {
      setPieChartData({
        datasets: [
          {
            label: "Messages data",
            data: [delivered, pending, failed],
            backgroundColor: ["#4CAF50", "#9D9E9D", "#FF6B80"],
            hoverOffset: 2,
          },
        ],
      });
    }
  }, [analysisData, delivered, failed, pending]);

  const DataExist = () => {
    if (+delivered === 0 && +pending === 0 && +failed === 0) {
      return false;
    } else {
      return true;
    }
  };

  const status = [
    {
      type: "Successful",
      percentage: success_rate?.toFixed(2),
    },
    {
      type: "Failed",
      percentage: failed_rate?.toFixed(2),
    },
    {
      type: "Pending",
      percentage: pending_rate?.toFixed(2),
    },
  ];

  return (
    <div className="h-4/6 bg-white px-3 py-5 rounded-xl shadow-md">
      <p className="text-sm font-semibold">Messages</p>
      <div className="h-full">
        {!DataExist() ? (
          <span
            style={{
              flex: 1,
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <p className="grid place-content-center h-full text-gray-600">
              No Data found
            </p>
          </span>
        ) : (
          <div className="h-full">
            {pieChartData && (
              <div className="h-3/5">
                <Doughnut
                  data={pieChartData}
                  options={{
                    cutout: 80,
                    title: {
                      display: false,
                      text: "Messsage Status",
                      fontSize: 20,
                    },
                    legend: {
                      display: true,
                      position: "left",
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                  }}
                />
                <div className="">
                  {status &&
                    status.map((type) => {
                      return (
                        <div
                          key={type.type}
                          className="flex py-2 border-b items-center"
                        >
                          <div
                            className={`h-5 w-5 rounded-md mr-4 ${
                              type.type === "Successful" && "bg-[#4CAF50]"
                            } ${type.type === "Failed" && "bg-[#FF6B80]"} ${
                              type.type === "Pending" && "bg-[#9D9E9D]"
                            }`}
                          ></div>
                          <p>
                            {type.type} {`(${type.percentage}%)`}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChart;
