import {
  getAllClientFailed,
  getAllClientStart,
  getAllClientSuccess,
} from "../../../redux/admin/clients/getAllClientSlice";
import axios from "../../axios";

export const getAllClients = () => async (dispatch) => {
  dispatch(getAllClientStart());
  axios
    .get("/admin/user/all")
    .then((response) => {
      const { data } = response.data;
      dispatch(getAllClientSuccess(data));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(getAllClientFailed(message));
    });
};
