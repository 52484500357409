import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  changePasswordLoad: false,
  changePasswordError: null,
  changePasswordData: null,
};

export const changePasswordSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    changePasswordStart: (state) => {
      state.changePasswordLoad = true;
    },
    changePasswordSuccess: (state, { payload }) => {
      state.changePasswordLoad = false;
      state.changePasswordData = payload;
      state.changePasswordError = null;
    },
    changePasswordFail: (state, { payload }) => {
      state.changePasswordLoad = false;
      state.changePasswordData = null;
      state.changePasswordError = payload;
    },
    clearchangePasswordErrorMessage: (state) => {
      state.changePasswordError = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
  clearchangePasswordErrorMessage,
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
