import React, { useEffect, useState } from "react";
import Input from "./../../../components/Input/Input";
import Button from "./../../../components/Button/Button";
import Logo from "../../../components/Logo/Logo";

import { FONTSIZE, COLORS } from "../../../../constants/theme";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";

import validator from "validator";

import styles from "./ForgotPassword.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForgotPasswordErrorMessage,
  forgotPasswordStart,
} from "../../../../redux/admin/auth/adminForgotPasswordSlice";
import { ExturiLogo } from "../../../assets";
import { runForgotPassword } from "../../../../utils/apiCalls/admin/auth/runForgotPassword";

const { white, primary, lightGrey } = COLORS;
const { px40, px18, px14 } = FONTSIZE;

export default function AdminForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.forgotPassword.sendEmailLoad);
  const error = useSelector(
    (state) => state.forgotPassword.forgotPasswordError
  );
  const successMessage = useSelector(
    (state) => state.forgotPassword.forgotPasswordData
  );

  useEffect(() => {
    email === "" || validator.isEmail(email) === false
      ? setValidated(false)
      : setValidated(true);
  }, [email]);

  useEffect(() => {
    successMessage && navigate("/adminResetPwd");
  }, [successMessage]);

  const performAction = () => {
    dispatch(runForgotPassword(email));
  };

  return (
    <div className={styles.container}>
      <Logo />

      <div
        className={styles.wrapper}
        style={{
          backgroundColor: white,
          width: "700px",
          margin: "3rem auto",
          display: "flex",
          justifyContent: "center",
          padding: "2rem 2rem 6rem",
          borderRadius: "10px",
        }}
      >
        <div
          className={styles.form}
          style={{ width: "500px", padding: "0 3rem" }}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{ fontSize: px40, color: primary, marginBottom: ".5rem" }}
            >
              Forgot password?
            </h1>
            <p
              style={{
                fontSize: px14,
                marginBottom: "4rem",
                color: lightGrey,
                maxWidth: "333px",
                margin: "0 auto 4rem",
              }}
            >
              Enter your email and you will receive an email with an OTP
            </p>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <div style={{ marginBottom: "2rem" }}>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  dispatch(clearForgotPasswordErrorMessage());
                }}
                required
              />
            </div>
            {error && <Alert severity="error">{error}</Alert>}
          </div>

          <Button
            name={loading ? "Validating..." : "Reset"}
            margin="0 0 4.3rem"
            style={{ opacity: validated ? 1 : 0.5, width: "100%" }}
            disabled={validated ? false : true}
            onClick={() => performAction()}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: px18 }}>
              {" "}
              <Link to="/office" style={{ textDecoration: "none" }}>
                Return to Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ color: white, fontSize: px14, marginBottom: ".4rem" }}>
          Powered by
        </div>
        <div>
          <img src={ExturiLogo} width="" height="" alt="exturi" />
        </div>
      </div>
    </div>
  );
}
