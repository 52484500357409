import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Verified } from "@mui/icons-material";
import CallIcon from "@mui/icons-material/Call";
import avatar from "../../assets/avatar.svg";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../utils/axios";
import { getAdminUsers } from "../../../utils/AdminApiCalls/registerAdmin";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../../utils/Alert";
import Modal from "../../../components/modal/Modal";
import SuspendAdminConfimation from "../../../components/confirmCard/SuspendAdminConfimation";
import { useEffect } from "react";
import { suspendReset } from "../../../redux/admin/users/suspendUserSlice";

const SideCardAdmin = ({ singleUserData, setSingleUserData }) => {
  const adminRole = JSON.parse(localStorage.getItem("userDetails")).user.role;
  const dispatch = useDispatch();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [availableAction, setAvailableAction] = useState();
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const { loading, status, message } = useSelector(
    (state) => state.SuspendUser
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const onClickSuspend = () => {
    setShowWarning(true);
  };

  useEffect(() => {
    if (adminRole === "superadmin") {
      setAvailableAction(true);
    }
  }, []);

  const Timeout = (x) => {
    setTimeout(() => {
      dispatch(suspendReset());
      setShowWarning(false);
      x === "success" && dispatch(getAdminUsers());
    }, 2000);
  };

  useEffect(() => {
    if (status === "failed") {
      setAlertStatus({
        alertMessage: message,
        alertType: "error",
        openAlert: true,
      });
      Timeout(status);
    } else if (status === "success") {
      setAlertStatus({
        alertMessage: message,
        alertType: "success",
        openAlert: true,
      });
      Timeout(status);
    }
  }, [status]);

  useEffect(() => {
    if (singleUserData.status === "suspended") {
      setCurrentStatus("Un-Suspend");
    }
  }, []);

  return (
    <>
      {showWarning && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <SuspendAdminConfimation
            close={() => setShowWarning(false)}
            status={singleUserData.status}
            email={singleUserData.email}
          />
        </Modal>
      )}

      <div
        style={{ minWidth: "25%", background: "#fff", borderRadius: "10px" }}
      >
        <div>
          <span
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <button
              className="bg-[#fef3f5] w-10 h-10 rounded-full"
              onClick={() => setSingleUserData({})}
            >
              <CloseIcon fontSize="10" />
            </button>
          </span>
          <div className="mt-[4rem] w-full flex flex-col items-center p-[1rem] ">
            <img src={avatar} className="w-[4rem] h-[4rem]" />
            <p className="text-[#030229] font-semibold mt-2">
              {singleUserData.name}
            </p>
            <br />
            <br />
            <hr className="w-full" />
            <p className="text-[#030229] font-semibold self-start mt-10">
              Contact Info
            </p>
            <p className="text-[#030229] self-start mt-10 text-opacity-70 flex flex-row items-center">
              <EmailIcon fontSize="small" /> {singleUserData.email}
            </p>
            <hr className="w-full mt-4" />
            <p className="text-[#030229] self-start mt-4 text-opacity-70">
              <CallIcon fontSize="small" /> {singleUserData.phone}
            </p>
            <hr className="w-full mt-4" />
            <p className="text-[#030229] self-start mt-4 text-opacity-70">
              <Verified fontSize="small" /> {singleUserData.role}
            </p>
          </div>
          <div style={{ paddingLeft: "1.5rem" }}>
            <p className="font-bold mb-3 mt-7">Actions</p>

            <p
              className="text-blue-800 mb-1 text-sm font-semibold cursor-pointer"
              onClick={onClickSuspend}
            >
              {availableAction && singleUserData.status === "suspended"
                ? "Un-Suspend Admin"
                : "suspend admin"}

              {/* {adminRole === "superadmin" && } */}
            </p>
          </div>
        </div>
      </div>
      {/* <Alert
        openAlert={openSuccess}
        Text={
          singleUserData.status === "suspended"
            ? "Admin un-Suspended"
            : "Admin Suspended"
        }
        Type={"success"}
        handleClose={handleClose}
      /> */}
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
};

export default SideCardAdmin;
