import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  paymentData: [],
  showCalendar: false,
  failed: "",
};

export const userPaymentSlice = createSlice({
  name: "get-user-transactions",
  initialState,
  reducers: {
    getPaymentStart: (state) => {
      state.loading = true;
    },
    getPaymentSuccess: (state, { payload }) => {
      state.loading = false;
      state.paymentData = payload;
      state.failed = "";
    },
    getPaymentFailed: (state, { payload }) => {
      state.loading = false;
      state.paymentData = [];
      state.failed = payload;
    },
    setShowCalendar: (state, { payload }) => {
      state.showCalendar = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPaymentStart,
  getPaymentSuccess,
  setShowCalendar,
  getPaymentFailed,
} = userPaymentSlice.actions;

export default userPaymentSlice.reducer;
