import React from "react";
import styles from "./topContents.module.css";
import newWoman from "../../assets/newWoman.png";
import x from "../../assets/x.svg";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import InfoButtons from "./InfoButtons";
import message from "../../assets/message.svg";
import peopleInfo from "../../assets/peopleInfo.svg";
import userTick from "../../assets/userTick.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTotalUsers } from "../../../utils/apiCalls/getStatistics";

const TopContents = () => {
  const dispatch = useDispatch();

  const statistics = useSelector((state) => state.statistics);

  const business_served = statistics?.business_served;
  const message_sent = statistics?.message_sent;
  const number_reached = statistics?.number_reached;

  useEffect(() => {
    dispatch(fetchTotalUsers());
  }, [dispatch]);
  
  function formatNumber(number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k';
    } else {
      return number;
    }
  }

  return (
    <div>
      <div className={styles.wrap}>
        <div className={styles.leftContents}>
          <div>
            <Fade>
              <p id={styles.pTag}>UNLEASH THE POWER OF COMMUNICATION</p>
              <p id={styles.quickWay}>
                Quick way to get bulk sms messages to your customers
              </p>
            </Fade>

            <Slide bottom>
              <Link to="/signup">
                <button id={styles.getBtn}>Get started</button>
              </Link>
            </Slide>
          </div>
        </div>

        <Fade>
          <div className={styles.rightContents}>
            <span className={styles.leftButtonPosition}>
              <InfoButtons
                icon={<img src={message} alt="Message Icon" />}
                texts={"Message sent"}
                numbers={formatNumber(message_sent) }
              />
            </span>
            <div>
              <img src={newWoman} alt="" id={styles.imagge} />
            </div>
            <span className={styles.rightButtonPosition}>
              <InfoButtons
                icon={<img src={peopleInfo} alt="Message Icon" />}
                texts={"Numbers reached"}
                numbers={formatNumber(number_reached)}
              />
            </span>
            <span className={styles.buttonButtonPosition}>
              <InfoButtons
                icon={<img src={userTick} alt="Message Icon" />}
                texts={"Businesses served"}
                numbers={business_served}
                // numbers={totalUsers}
              />
            </span>
          </div>
        </Fade>
      </div>
      <div className={styles.x}>
        <img src={x} alt="x" />
      </div>
    </div>
  );
};

export default TopContents;
