import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";

const AnalysisGraph = () => {
  const { analysisData } = useSelector((state) => state.dashboardData);

  const [analysisChartData, setAnalysisChartData] = useState();

  useEffect(() => {
    if (analysisData?.message?.chart.length > 0) {
      setAnalysisChartData({
        labels:
          analysisData.period === "LM" ||
          analysisData.period === "TM" ||
          analysisData.period === "TW" ||
          analysisData.period === "LW"
            ? analysisData.message.chart.map((day) => day.date)
            : analysisData.period === "All Time"
            ? analysisData.message.chart.map((day) => day.day)
            : analysisData.period === "T" || analysisData.period === "Y"
            ? analysisData.message.chart.map((day) => day.time)
            : analysisData.message.chart.map((day) => day.month_year),
        datasets: [
          {
            fill: false,
            lineTension: 0,
            backgroundColor: "#4B1A7C",
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 2,
            data: analysisData.message.chart.map((num) => num.total),
            pointRadius: 0,
            label: "Total",
          },
        ],
      });
    }
  }, [analysisData]);

  return (
    <div className="h-[580px] w-[74%] bg-white rounded border-[#E5E5E5] pr-12 pl-5 rounded-xl shadow-md">
      <p className="my-8 text-2xl font-bold pl-5">Messages</p>
      {analysisChartData ? (
        <div className="h-3/5">
          <Line
            data={analysisChartData}
            options={{
              title: {
                display: false,
                text: "Messages",
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "right",
              },
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
          <div className="flex justify-end mt-5">
            <div>
              <p className="text-sm">Total Messages</p>
              <p className="text-2xl font-bold">
                {formatPriceByCountrySymbol("", analysisData?.message?.total)}
              </p>
              <p
                className={`${
                  analysisData?.message?.percentage_dif > 0
                    ? "text-green-700 "
                    : "text-red-600"
                } text-[0.625rem]`}
              >
                {analysisData?.message?.percentage_dif &&
                  `${analysisData?.message?.percentage_dif > 0 ? "+" : ""}${
                    analysisData?.message?.percentage_dif
                  }%`}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p className="grid place-content-center h-1/2">No data available</p>
      )}
    </div>
  );
};

export default AnalysisGraph;
