import React from 'react'
import { useDispatch } from 'react-redux';
import { canManageAccount, canManageComm, canManageFinance, canManageRoles } from '../../../redux/people/createTeamSlice';
import styles from "./Users.module.css";

const BarBox = ({Title, Details, Value}) => {
  const dispatch = useDispatch()


  const onCheck = (value, check) => {
    switch (value) {
    case "manage_roles":
      dispatch(canManageRoles(check))
      break;
      case "manage_finance":
      dispatch(canManageFinance(check))
      break;
      case "manage_account":
      dispatch(canManageAccount(check))
      break;
      case "manage_comm":
      dispatch(canManageComm(check))
      break;
  
    default:
      break;
  }
  }
  
  return (
    <div>
      <div id={styles.barBox}>
        <small>{Title}</small>
        <span id="flexrow">
          <p>
            {Details}
          </p>
          <label className="switch">
            <input type="checkbox" id="myToggle" value={Value} onChange={(e)=>{onCheck(e.target.value, e.target.checked)}} />
            <span className="slider round"></span>
          </label>
        </span>
      </div>
    </div>
  );
}

export default BarBox