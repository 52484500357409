import React from "react";
import styles from "./lastSection.module.css";
import Shake from "react-reveal/Shake";
import { Link } from "react-router-dom";



const LastSection = () => {
  return (
    <main className={styles.mainFrame}>
      <div className={styles.frame}>
        <big className={styles.headerText}>Stop Calling & Start Texting 99.9% Delivery Rates</big>
        <Shake>
        <Link to="/signup">
        <button className={styles.btn}>Get started</button>
        </Link>
        </Shake>
      </div>
    </main>
  );
};

export default LastSection;
