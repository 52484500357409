import React, { useEffect, useState } from "react";
import downArrow from "../../assets/arrowdown.svg";
import styles from "./download.module.css";
import { useSelector } from "react-redux";
// import Download from "react-csv/components/Download";
// import { DownloadForOfflineOutlined } from "@mui/icons-material";

const PerpageDropdown = ({ selectedFilter, onFilterChange, data }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(selectedFilter);

  const report = useSelector((state) => state.history.HistoryData);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (item) => {
    setCurrentFilter(item);
    onFilterChange(item);
    closeDropdown();
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (
      isDropdownOpen &&
      !event.target.closest(`.${styles.dropdownContainer}`)
    ) {
      closeDropdown();
    }
  };

  const RowsPerpage = [
    { label: report?.to, value: report?.to },
    // { label: 5, value: 5 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };
  }, []);

  return (
    <div className="flex flex-row gap-1 items-center">
      <p className={styles.textStyle}>Rows per page</p>
      <div className={styles.dropdownContainer} onClick={toggleDropdown}>
        <div className={styles.selectStyle}>
          <p className={styles.downloadBtn}> {currentFilter}</p>
          {isDropdownOpen && (
            <ul className={styles.dropdownList}>
              {RowsPerpage.map((item, index) => (
                <li
                  className={styles.list}
                  key={index}
                  onClick={() => handleOptionClick(item.label)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </div>

        <img src={downArrow} alt="Filter Icon" style={iconStyle} />
      </div>
      {data?.to && data?.total && (
        <p className={`ml-3 font-bold ${styles.textStyle}`}>
          {data?.to} of {data?.total} records
        </p>
      )}
    </div>
  );
};

const iconStyle = {};

export default PerpageDropdown;
