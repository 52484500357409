import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  analysisData: {},
  dataError: "",
};

const DashboardSlice = createSlice({
  name: "Dashboard Reducer",
  initialState,
  reducers: {
    getAllDashboardInfoStart: (state) => {
      state.loading = true;
    },
    getAllDashboardInfoSuccess: (state, { payload }) => {

      state.analysisData = payload;
      state.loading = false;
      state.dataError = "";
    },
    getAllDashboardInfoFailure: (state, { payload }) => {
      state.loading = false;
      state.analysisData = {};
      state.dataError = payload;
    },
  },
});

export const {
  getAllDashboardInfoStart,
  getAllDashboardInfoSuccess,
  getAllDashboardInfoFailure,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
