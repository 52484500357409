import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registerLoad: false,
  registerError: null,
  registerData: null,
  registerSuccessState: false,
  // emailVerified: false,
  // emailVerificationLoading: false,
  // emailVerificationMessage: null,
};

export const registerSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    registerStart: (state) => {
      state.registerLoad = true;
    },
    registerSuccess: (state, { payload }) => {
      localStorage.setItem("userDetails", JSON.stringify(payload.data));
      state.registerLoad = false;
      state.registerData = payload;
      state.registerError = null;
      state.registerSuccessState = true;
    },
    registerFail: (state, { payload }) => {
      state.registerLoad = false;
      state.registerData = null;
      state.registerError = payload;
    },
    clearregisterErrorMessage: (state) => {
      state.registerError = null;
    },
    // emailVerificationStart: (state) => {
    //   state.emailVerificationLoading = true;

    // },
    // emailVerificationSuccess: (state, { payload }) => {
    //   state.emailVerificationMessage = payload;
    //   state.emailVerified = true;
    // },
    // emailVerificationFailed: (state, { payload }) => {
    //   state.emailVerificationMessage = payload;
    //   state.emailVerified = false;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  registerStart,
  registerSuccess,
  registerFail,
  clearregisterErrorMessage,
  // emailVerificationStart,
  // emailVerificationSuccess,
  // emailVerificationFailed,
} = registerSlice.actions;

export default registerSlice.reducer;
