import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  getTransactionData: [],
  showCalendar: false,
  failed: "",
};

export const userTransactionsSlice = createSlice({
  name: "get-user-transactions",
  initialState,
  reducers: {
    getTransactionStart: (state) => {
      state.loading = true;
    },
    getTransactionSuccess: (state, { payload }) => {
      state.loading = false;
      state.getTransactionData = payload;
      state.failed = "";
    },
    getTransactionFailed: (state, { payload }) => {
      state.loading = false;
      state.getTransactionData = [];
      state.failed = payload;
    },
    setShowCalendar: (state, { payload }) => {
      state.showCalendar = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getTransactionStart,
  getTransactionSuccess,
  getTransactionFailed,
  setShowCalendar,
} = userTransactionsSlice.actions;

export default userTransactionsSlice.reducer;
