import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendEmailLoad: false,
  forgotPasswordData: null,
  forgotPasswordError: null,
  resetPasswordLoad: false,
  resetPasswordData: null,
  resetPasswordError: null,
};

export const forgotPasswordSlice = createSlice({
  name: "forgot-password",
  initialState,
  reducers: {
    forgotPasswordStart: (state) => {
      state.sendEmailLoad = true;
    },
    forgotPasswordSuccess: (state, { payload }) => {
      state.sendEmailLoad = false;
      state.forgotPasswordData = payload;
      state.forgotPasswordError = null;
    },
    forgotPasswordFail: (state, { payload }) => {
      state.sendEmailLoad = false;
      state.forgotPasswordData = null;
      state.forgotPasswordError = payload;
    },
    clearForgotPasswordErrorMessage: (state) => {
      state.forgotPasswordError = null;
    },
    resetPasswordStart: (state) => {
      state.resetPasswordLoad = true;
    },
    resetPasswordSuccess: (state, { payload }) => {
      state.resetPasswordLoad = false;
      state.resetPasswordData = payload;
      state.resetPasswordError = null;
    },
    resetPasswordFail: (state, { payload }) => {
      state.resetPasswordLoad = false;
      state.resetPasswordData = null;
      state.resetPasswordError = payload;
    },
    clearResetPasswordErrorMessage: (state) => {
      state.resetPasswordError = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFail,
  clearForgotPasswordErrorMessage,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  clearResetPasswordErrorMessage,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
