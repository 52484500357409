import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendMailLoading: false,
  sendingFailed: "",
  mailSuccess: {},
  mailSentSuccessfully: false,
};

const SendMail = createSlice({
  name: "MailManager",
  initialState,
  reducers: {
    sendMailStart: (state) => {
      state.sendMailLoading = true;
      state.mailSentSuccessfully = false;
      state.sendingFailed = "";
      state.mailSuccess = {};
    },
    sendMailSuccess: (state, { payload }) => {
      state.sendMailLoading = false;
      state.mailSuccess = payload;
      state.mailSentSuccessfully = true;
      state.sendingFailed = "";
    },
    sendMailError: (state, { payload }) => {
      state.sendMailLoading = false;
      state.mailSuccess = {};
      state.mailSentSuccessfully = false;
      state.sendingFailed = payload;
    },
  },
});

export const { sendMailStart, sendMailSuccess, sendMailError } =
  SendMail.actions;

export default SendMail.reducer;
