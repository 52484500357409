import React, { useEffect, useState } from "react";
import axios from "../../../../utils/axios";
import styles from "./Api.module.css";
import { useNavigate } from "react-router-dom";
import infoCircle from "../../../../assets/infoCircle.svg";
import { ReactComponent as Menu } from "../../../../assets/menu.svg";
import { ReactComponent as Filter } from "../../../../assets/filtericon.svg";
import { ReactComponent as API } from "../../../../assets/apiwheel.svg";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/modal/Modal";
import userSettings from "../../../../assets/userSettings.svg";
import { upload } from "@testing-library/user-event/dist/upload";
import { Alert, Link } from "@mui/material";
import Permissions from "./Permissions";
import CreatedApi from "./CreatedApi";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { generateKey, getKeys } from "../../../../utils/apiCalls/secretKeys";
import {
  openCreateModal,
  reset,
  reloadAPIS,
} from "../../../../redux/api/createAPIslice";
import moment from "moment";
import Paginationn from "../../../../components/Pagination/Pagination";
import IntegrationGuide from "./IntegrationGuide";
import BasicPopover from "./DropdownBox";
import Alertt from "../../../../utils/Alert";
import { NavLink } from "react-router-dom";

function Api() {
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [upload, setUpload] = useState(false);
  const [create, setCreate] = useState(false);
  const [apiName, setApiName] = useState("");
  const [sendSuccess, setSendSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deactivateSuccess, setDeactivateSuccess] = useState(false);
  const [permissionType, setPermissionType] = useState("simple");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.APIs.loading);
  const loadingAPIs = useSelector((state) => state.getAPIs.loading);
  const responseCreated = useSelector((state) => state.APIs.responseCreated);
  const generateError = useSelector((state) => state.APIs.generateError);
  const createModal = useSelector((state) => state.APIs.createModal);
  const reloadAPIs = useSelector((state) => state.APIs.reloadAPIs);
  const APIs = useSelector((state) => state.getAPIs.getResponse);
  const APIsendSuccess = useSelector((state) => state.getAPIs.APIsendSuccess);
  const APIsendSuccessMessage = useSelector(
    (state) => state.getAPIs.APIsendSuccessMessage
  );
  const APIdeleteSuccess = useSelector((state) => state.getAPIs.APIsendSuccess);
  const APIdeleteSuccessMessage = useSelector(
    (state) => state.getAPIs.APIsendSuccessMessage
  );
  const APIdeactivateSuccess = useSelector(
    (state) => state.getAPIs.APIsendSuccess
  );
  const APIdeactivateSuccessMessage = useSelector(
    (state) => state.getAPIs.APIsendSuccessMessage
  );

  useEffect(() => {
    dispatch(getKeys());
    dispatch(reloadAPIS(false));
  }, [reloadAPIs]);
  const [integrate, setIntegration] = useState(false);

  const generate = () => {
    dispatch(generateKey(apiName, permissionType));
  };

  useEffect(() => {
    APIsendSuccess && setSendSuccess(true);
    APIdeactivateSuccess && setSendSuccess(true);
    APIdeleteSuccess && setSendSuccess(true);
  }, [APIsendSuccess, APIdeactivateSuccess, APIdeleteSuccess]);

  const cancel = () => {
    setUpload(false);
    dispatch(reset());
    setApiName("");
  };

  const handleCloseSendAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSendSuccess(false);
  };
  const handleCloseDeleteAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteSuccess(false);
  };
  const handleCloseDeactivateAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeactivateSuccess(false);
  };

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return APIs && APIs.slice(firstPageIndex, lastPageIndex);
  };

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigate = useNavigate();

  return (
    <>
      {createModal && (
        <Permissions
          header={"Create API KEY"}
          apiName={apiName}
          setApiName={setApiName}
          cancel={cancel}
          setPermissionType={setPermissionType}
          permissionType={permissionType}
          generate={generate}
        />
      )}
      {responseCreated && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: "5" }}
          // close={closeModall}
        >
          <CreatedApi closeModal={() => dispatch(reset())} />
        </Modal>
      )}
      <div className={styles.apiContainer}>
        <div className={styles.tableSection}>
          <div className={styles.tableDetails} style={{ paddingTop: 70 }}>
            <API />
            <div className={styles.miniBox}>
              <NavLink
                to="https://exturi.gitbook.io/api"
                target="_blank"
                id={styles.link}
                className={"text-blue-500"}
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://exturi.gitbook.io/api", "_blank");
                }}
              >
                Integration Guide
              </NavLink>
              <Button
                name="GENERATE API KEY"
                style={{ maxWidth: 212 }}
                onClick={() => dispatch(openCreateModal(true))}
              />
            </div>
          </div>
          {/* <div
            className={styles.tableDetails}
            style={{ justifyContent: "flex-end" }}
          >
            <button
              className={styles.sortBtn}
              style={{ justifySelf: "flex-end" }}
            >
              <Filter />
              Sort by
            </button>
          </div> */}
          {loadingAPIs ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress sx={{ color: "#C4C4C4" }} />
            </Box>
          ) : APIs?.length === 0 ? (
            <div style={{ paddingBottom: "10px" }}>
              <center>
                <Alert
                  sx={{ width: 350, marginBottom: "1rem" }}
                  severity="info"
                >
                  No record.
                </Alert>
              </center>
            </div>
          ) : (
            <>
              <div style={{ overflowX: "auto" }}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Secret</th>
                      <th>Creation date</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  {currentTableData().map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.secret_key}</td>
                      <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
                      <td
                        style={{
                          color: item.status === "active" ? "green" : "red",
                        }}
                      >
                        {item.status}
                      </td>
                      <td>
                        <BasicPopover
                          api_name={item.name}
                          setApiName={setApiName}
                          status={item.status}
                          alertStatus={alertStatus}
                          setAlertStatus={setAlertStatus}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <Paginationn
                currentPage={currentPage}
                totalCount={APIs.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
          <Alertt
            handleClose={handleCloseSendAlert}
            openAlert={sendSuccess}
            Text={APIsendSuccessMessage}
            Type="success"
          />
          <Alertt
            handleClose={handleCloseDeactivateAlert}
            openAlert={deactivateSuccess}
            Text={APIdeactivateSuccessMessage}
            Type="success"
          />
          <Alertt
            handleClose={handleCloseDeleteAlert}
            openAlert={deleteSuccess}
            Text={APIdeleteSuccessMessage}
            Type="success"
          />
          <Alertt
            openAlert={alertStatus.openAlert}
            Text={alertStatus.alertMessage}
            Type={alertStatus.alertType}
            handleClose={handleAlertClose}
          />
        </div>
      </div>
    </>
  );
}

export default Api;
