export const getTimeOfDay = () => {
let greeting = ""
const data = [
    [18, "Good evening"],
    [12, "Good afternoon"],
    [0, "Good morning"],
  ],
  hr = new Date().getHours();
for (var i = 0; i < data.length; i++) {
  if (hr >= data[i][0]) {
    greeting = data[i][1];
    break;
  }
}
return greeting
}


