import React, { useEffect, useState } from "react";
import styles from "./import.module.css";
import closeCircle from "../../../assets/closeCircle.svg";
import Papa from "papaparse";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { savePhoneGroup } from "../../../utils/apiCalls/phoneGroup";
import { useDispatch, useSelector } from "react-redux";
import Alertt from "../../../utils/Alert";

const ImportModal = ({ closeModal }) => {
  const [groupName, setGroupName] = useState("");
  const [numbers, setNumbers] = useState("");
  const [file, setFile] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();

  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const saveLoading = useSelector((state) => state.phoneGroup.saveLoading);
  const errorMessage = useSelector((state) => state.phoneGroup.saveErrorData);
  const saveError = useSelector((state) => state.phoneGroup.saveError);
  const saveSuccess = useSelector((state) => state.phoneGroup.saveSuccess);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const changeHandler = (file) => {
    setFile(file);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const csvNumbers = [];
        results.data.map((d) => {
          csvNumbers.push(
            formatPhoneNumber(Object.values(d)[0].replace(/\D/g, ""))
          ); //.replace(/\D/g, '') removes all non-numeric characters
        });
        setNumbers(csvNumbers);
      },
    });
  };

  useEffect(() => {
    if (file) {
      setAlertStatus({
        alertMessage: "Phone numbers uploaded successfully",
        alertType: "success",
        openAlert: true,
      });
    }
  }, [file]);

  const save = () => {
    if (groupName === "" || numbers.length === 0) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
      dispatch(
        savePhoneGroup({
          group_name: groupName,
          number: numbers,
        })
      );
      setTimeout(() => {
        //  window.location.reload()
      }, 1000);
    }
  };

  useEffect(() => {
    if (saveSuccess) {
      closeModal(false);
    } else if (saveError) {
      setAlertStatus({
        alertMessage: errorMessage,
        alertType: "error",
        openAlert: true,
      });
    }
  }, [saveError, saveSuccess]);

  return (
    <div style={{ overflowX: "auto" }}>
      <div className={styles.modalContainer}>
        <div className={styles.imgBox}>
          <img
            src={closeCircle}
            alt=""
            onClick={() => {
              closeModal(false);
              setGroupName("");
              setFile("");
            }}
          />
        </div>
        {
          <p
            style={{
              textAlign: "center",
              marginBottom: 10,
              fontSize: "small",
              color: "red",
            }}
          >
            {errorMessage}
          </p>
        }
        <div className={styles.groupName}>
          <h3>Group Name</h3>
          <input
            type="text"
            id={styles.nameBox}
            style={{ borderColor: saveError && "red" }}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Phone Group Name"
          />
        </div>
        <p>
          Please ensure that only the phone numbers are in the file. E.g.
          2348012345678, 08123334567. The numbers should be in a column with a
          column name Phone.
        </p>

        <div className={styles.excel}>
          <h3>CSV Sheet Upload</h3>
          <span id={styles.fileUpload}>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => changeHandler(e.target.files[0])}
            />
          </span>
        </div>
        <div className={styles.btnClass}>
          <button
            id={styles.uploadBtn}
            disabled={saveLoading}
            style={{ opacity: saveLoading ? 0.5 : 1 }}
            onClick={() => save()}
          >
            {saveLoading ? "Saving..." : "Save Phone Group"}
          </button>
        </div>
      </div>
      <Alertt
        handleClose={handleClose}
        openAlert={openAlert}
        Text="Error: All fields are required"
        Type="error"
      />
      <Alertt
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ImportModal;
