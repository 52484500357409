import React, { useEffect, useState } from "react";
import styles from "./edit.module.css";
import closeCircle from "../../../assets/closeCircle.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPhoneNumbers } from "../../../utils/apiCalls/phoneGroup";
import { resetNumbers } from "../../../redux/sms/messageSummarySlice";
import axios from "../../../utils/axios";
import Alert from "../../../utils/Alert";
import { editPhoneGroupSuccess } from "../../../redux/contacts/phoneGroupSlice";

const Edit = ({ EditcloseModal, groupToEdit }) => {
  const [numbers, setNumbers] = useState("");
  const [groupName, setGroupName] = useState(groupToEdit);
  const [phoneNumbers, setPhoneNumbers] = useState(numbers);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`/user/phone/group-number/${groupName}`)
      .then((response) => {
        const { message } = response.data;
        setNumbers(message.toString());
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    numbers !== "" && setPhoneNumbers(numbers);
  }, [numbers]);

  const close = () => {
    EditcloseModal(false);
    dispatch(resetNumbers());
  };

  const updateDB = () => {
    if (groupName === "" || phoneNumbers === "") {
      setOpenAlert(true);
    } else {
      setLoading(true);
      const trimNumber = phoneNumbers.split(" ").join(""); //remove white spaces
      const numbersArray = trimNumber.split(",");
      // file deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
      axios
        .post("user/phone/update-group", {
          group_name: groupName,
          number: numbersArray,
        })
        .then((response) => {
          const { message } = response.data;
          setLoading(false);
          setOpenAlert(false);
          EditcloseModal(false);
          dispatch(editPhoneGroupSuccess(message));
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <>
      <div className={styles.addBox} style={{ maxWidth: "800px" }}>
        <div id={styles.imgBox} style={{ marginBottom: 10 }}>
          <img src={closeCircle} alt="" onClick={() => close()} />
        </div>
        <div className={styles.addDetails}>
          <span className={styles.titles}>
            <h3>Group Name :</h3>
            <h3>Phone Numbers :</h3>
          </span>
          <span className={styles.inputs}>
            <h3 id={styles.hiddenTxt}>Group Name :</h3>
            <input
              type="text"
              id={styles.nameBox}
              placeholder="Phone Group Name"
              value={groupName}
              disabled
              // onChange={(e)=>setGroupName(e.target.value)}
            />
            <h3 id={styles.hiddenTxt}>Phone Numbers :</h3>

            <textarea
              type="text"
              id={styles.phoneBox}
              value={phoneNumbers}
              placeholder={
                numbers === ""
                  ? "Loading..."
                  : "Enter phone numbers separared by commas"
              }
              onChange={(e) => setPhoneNumbers(e.target.value)}
            />
          </span>
        </div>
        <div className={styles.btnBox}>
          <button
            className={styles.addBtn}
            onClick={() => updateDB()}
            style={{ opacity: loading ? 0.5 : 1 }}
            disabled={loading}
          >
            {loading ? "Please wait..." : "Update Phone Group"}
          </button>
        </div>
        <Alert
          handleClose={handleClose}
          openAlert={openAlert}
          Text="Error: All fields are required"
          Type="error"
        />
      </div>
    </>
  );
};

export default Edit;
