import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../utils/axios";
import { deactivateAPIsuccess } from "../../redux/api/getAPIslice";
import api from "../../assets/api.svg";
import { getKeys } from "../../utils/apiCalls/secretKeys";

function ConfirmCardDeactivate({ deactivate, api_name, status }) {
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState()
  const [actionWord, setActionWord] = useState("")

  useEffect(() => {
    if (status === "active"){
      setStat("paused")
      setActionWord("deactivate")
    }
    else if (status === "paused"){
      setStat("active")
      setActionWord("activate")
    }
  }, [])
  

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const action = () => {
    setLoading(true);
    
    axios
      .post("/user/api-key/status", { name: api_name, status: stat })
      .then((response) => {
        const { message } = response.data;
        dispatch(deactivateAPIsuccess(message));
        setLoading(false);
        deactivate(false);
        dispatch(getKeys());
      })
      .catch((error) => {
    
      });

  };

  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "30px" }}>
        {/* <Warning /> */}
        <div>
          <img src={api} alt="" style={{width:"100px", height:"auto"}} />
        </div>
        <h3>{`Are you sure you want to ${actionWord} this ApiKey (${api_name})?`}</h3>
        <div className={styles.buttonSection}>
          <button onClick={() => deactivate(false)}>Cancel</button>
          <button onClick={() => action()} disabled={loading}>
            {loading ? "Please wait.." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmCardDeactivate;
