import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  response: "",
  manage_roles: false,
  account_manager: false,
  finance_manager: false,
  comm_manager: false
};

export const createTeamSlice = createSlice({
  name: "createTeam",
  initialState,
  reducers: {
    createTeamStart: (state) => {
      state.loading = true;
    },
    createTeamSuccess: (state, { payload }) => {
      state.loading = false;
      state.response = payload;
      state.manage_roles = false;
      state.account_manager = false;
      state.finance_manager = false;
      state.comm_manager = false
    },
    canManageRoles: (state, { payload }) => {
      state.manage_roles = payload;
    },
    canManageAccount: (state, { payload }) => {
      state.account_manager = payload;
    },
    canManageFinance: (state, { payload }) => {
      state.finance_manager = payload;
    },
    canManageComm: (state, { payload }) => {
      state.comm_manager = payload;
    },
  },
});

export const { createTeamStart, createTeamSuccess, canManageAccount, canManageComm, canManageFinance, canManageRoles } = createTeamSlice.actions;

export default createTeamSlice.reducer;
