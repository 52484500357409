import React from "react";
import styles from "./midSection.module.css";

const Tiles = ({ Svg, Title, Details, style, className }) => {
  return (
    <div id={styles.tiles} style={{ ...style }}>
      <span>
        <img src={Svg} alt="" />
      </span>
      <span>
        <big>{Title}</big>
      </span>
      <span>
        <p>{Details}</p>
      </span>
      <button className={className}>Get started</button>
    </div>
  );
};

export default Tiles;
