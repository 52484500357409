import {
  suspendFailed,
  suspendStart,
  suspendSuccessful,
} from "../../../../redux/admin/users/suspendUserSlice";
import axios from "../../../axios";

export const SuspendUser = (data, status) => async (dispatch) => {
  dispatch(suspendStart());

  const url =
    status === ""
      ? `/admin/user/suspend/${data}`
      : `/admin/user/unsuspend/${data}`;

  axios
    .post(url)
    .then((response) => {
      const { message } = response.data;
      dispatch(suspendSuccessful(message));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(suspendFailed(message));
    });
};

export const SuspendAdmin = (data, status) => async (dispatch) => {
  dispatch(suspendStart());

  const url =
    status === "" ? `/admin/suspend/${data}` : `/admin/unsuspend/${data}`;

  axios
    .post(url)
    .then((response) => {
      const { message } = response.data;
      dispatch(suspendSuccessful(message));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(suspendFailed(message));
    });
};
