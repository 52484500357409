import {
  getRecentTransactionsFailed,
  getRecentTransactionsLoading,
  getRecentTransactionsSuccess,
} from "../../../redux/admin/Recent/getRecentTransactions";
import axios from "../../axios";

export const fetchRecentTransactions = () => async (dispatch) => {
  dispatch(getRecentTransactionsLoading());
  axios
    .get("/admin/dash/recent-deposit")
    .then((res) => {
      dispatch(getRecentTransactionsSuccess(res.data.data));
    })
    .catch(function (error) {

      dispatch(getRecentTransactionsFailed(error));
    });
};
