const width  = window.innerWidth;

let scale = width / 1440;
scale = scale.toFixed(2);


export const COLORS = {
  //base colors
  primary: "#4B1A7C", // purple
  lightPurple: "#B79DFF", // light purple
  white: '#ffffff',
  lightGrey: '#808082',
  sidebar: '#363740',
  sidebarText: '#DDE2FF',
  layout: '#F2F2F2',
  black: '#000000',
  darkBlue: '#344767',
  grey: '#7B809A',
  greyBlack: '#2527333'
};

export const SIZES = {

    //app dimensions
    width,
    scale
}

export const FONTSIZE = {
  px40: '2.5rem',
  px16: '1rem',
  px18: '1.125rem',
  px15: '.938rem',
  px24: '1.5rem',
  px25: '1.563rem',
  px32: '2rem',
  px14: '.875rem',
  px36: '2.25rem',
  px20: '1.25rem'
}

const appTheme = {COLORS, SIZES, FONTSIZE};

export default appTheme;