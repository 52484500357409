import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  saveLoading: false,
  saveSuccessData: "",
  saveErrorData: "",
  getSuccessData: null,
  saveError: false,
  saveSuccess: false,
  selectedPhoneGroup: "",
  deletePhoneGsuccess: false,
  editPhoneGsuccess: false,
  deletePhoneGsuccessMessage: "",
  editPhoneGsuccessMessage: "",
};

export const phoneGroupSlice = createSlice({
  name: "phonegroup",
  initialState,
  reducers: {
    savePhoneGroupStart: (state) => {
      state.saveLoading = true;
    },
    savePhoneGroupSuccess: (state, { payload }) => {
      state.saveLoading = false;
      state.saveSuccessData = payload;
      state.saveError = false;
      state.saveSuccess = true;
      state.saveErrorData = "";
    },
    savePhoneGroupFail: (state, { payload }) => {
      state.saveLoading = false;
      state.saveErrorData = payload;
      state.saveSuccessData = "";
      state.saveError = true;
      state.saveSuccess = false;
    },
    getPhoneGroupStart: (state) => {
      state.loading = true;
      state.deletePhoneGsuccess = false;
    },
    getPhoneGroupSuccess: (state, { payload }) => {
      state.loading = false;
      state.getSuccessData = payload;
    },
    selectedPhoneGroup: (state, { payload }) => {
      state.selectedPhoneGroup = payload;
    },
    deletePhoneGroupSuccess: (state, { payload }) => {
      state.deletePhoneGsuccess = true;
      state.deletePhoneGsuccessMessage = payload;
    },
    editPhoneGroupSuccess: (state, { payload }) => {
      state.editPhoneGsuccess = true;
      state.editPhoneGsuccessMessage = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  savePhoneGroupStart,
  savePhoneGroupSuccess,
  savePhoneGroupFail,
  getPhoneGroupStart,
  getPhoneGroupSuccess,
  selectedPhoneGroup,
  deletePhoneGroupSuccess,
  editPhoneGroupSuccess,
} = phoneGroupSlice.actions;

export default phoneGroupSlice.reducer;
