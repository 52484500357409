import styles from "../styles/userStyles.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "react-date-range";
import TextField from "@mui/material/TextField";
import { Alert, Box, CircularProgress } from "@mui/material";
import Modal from "../../../../../components/modal/Modal";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as Calculator } from "../../../../../assets//calculator.svg";
import { ReactComponent as Filter } from "../../../../../assets/filtericon.svg";
import { ReactComponent as Download } from "../../../../../assets/download.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import MaterialUIPickers from "../../../../../components/dateAndTime/MaterialUIPickers";
import Paginationn from "../../../../../components/Pagination/Pagination";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fil
import moment from "moment";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import { getUserTransactionByDate } from "../../../../../utils/apiCalls/admin/user/fetchUserTransaction";
import { useLocation } from "react-router-dom";
import ArrowDown from "../../../../../assets/chevrondown.svg";

const UserTransactions = () => {
  let PageSize = 15;

  const dispatch = useDispatch();
  const { state } = useLocation();

  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const loading = useSelector((state) => state.userTransactions.loading);

  const transactions = useSelector(
    (state) => state.userTransactions.getTransactionData
  );

  const [startDay, setStartDay] = useState(
    moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [stopDay, setStopDay] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  );

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [dateRange, setDateRange] = useState([null, null]);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return transactions && transactions.slice(firstPageIndex, lastPageIndex);
  };

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  const apply = () => {
    setStartDay(moment(date[0].startDate).format("YYYY-MM-DD"));
    setStopDay(moment(date[0].endDate).format("YYYY-MM-DD"));
    setShowCalendar(false);
  };

  useEffect(() => {
    dispatch(getUserTransactionByDate(state, startDay, stopDay));
  }, [startDay, stopDay]);

  const getData = () => {
    let dd = [];
    transactions.map((data, index) => {
      dd.push({
        Description: data.tag,
        Amount: data.type === "debit" ? `-${data.amount}` : data.amount,
        "xWallet Balance": data?.wallet_balance,
        "xUnits Balance": data?.unit_balance,
        Medium: data.medium === "APP" ? "web" : data.medium,
        Date: moment(data.created_at).format("YYYY-MM-DD, h:mm:ss a"),
      });
    });
    return dd;
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={styles.tableDetails}>
          <Calculator />
          <div className={styles.tableActions}>
            {transactions?.length > 0 && (
              <CSVLink
                data={getData()}
                filename={`XMS_transaction_report [${startDay} to ${stopDay}].csv`}
                style={{ textDecoration: "none" }}
              >
                <button className={styles.downloadBtn}>
                  <Download />
                  <b>Download</b>
                </button>
              </CSVLink>
            )}
            <button
              className={styles.carlendarBtn}
              onClick={() => setShowCalendar(true)}
            >
              <div>
                <CalendarIcon />
                <p>
                  <div className={styles.desktopTimePicker}>
                    {moment(startDay).format("DD-MM-YYYY")} to{" "}
                    {moment(stopDay).format("DD-MM-YYYY")}
                  </div>
                </p>
              </div>
              <img src={ArrowDown} alt="arrowDown" />
            </button>
            <div className={styles.mobileTimePicker}>
              <MaterialUIPickers />
            </div>
            {showCalendar && (
              <Modal>
                <div className={styles.calendar}>
                  <DateRangePicker
                    showDateDisplay={false}
                    color="#A382C5"
                    rangeColors={"#4B1A7C"}
                    ranges={date}
                    onChange={(item) => setDate([item.selection])}
                  />
                  <div className={styles.buttonSection}>
                    <button onClick={() => setShowCalendar(false)}>
                      Cancel
                    </button>
                    <button onClick={() => apply()}>Apply</button>
                  </div>
                </div>
              </Modal>
            )}

            {/* <button className={styles.sortBtn}>
                <Filter />
                Sort by
              </button> */}
          </div>
        </div>
        {loading ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress sx={{ color: "#C4C4C4" }} />
          </Box>
        ) : transactions?.length === 0 ? (
          <center>
            <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
              You have not made any transaction
            </Alert>
          </center>
        ) : (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className={styles.customers}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Description</th>
                    <th style={{ textAlign: "center" }}>Amount</th>
                    <th style={{ textAlign: "center" }}>xWallet balance</th>
                    <th style={{ textAlign: "center" }}>xUnits balance</th>
                    <th style={{ textAlign: "center" }}>Medium</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                  </tr>
                </thead>
                {currentTableData()?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{item.tag}</td>
                    <td
                      style={{
                        textAlign: "center",
                        color: item.type === "debit" ? "red" : "green",
                      }}
                    >
                      {item.type === "debit"
                        ? `${formatPriceByCountrySymbol(
                            item.medium === "FUND TRANSFER" ? "Nigeria" : "",
                            item.amount
                          )}`
                        : formatPriceByCountrySymbol(
                            item.medium === "FUND TRANSFER" ? "Nigeria" : "",
                            item.amount
                          )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formatPriceByCountrySymbol(
                        "Nigeria",
                        item.wallet_balance
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formatPriceByCountrySymbol("", item.unit_balance)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.medium === "APP" ? "web" : item.medium}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(item.created_at).format("YYYY-MM-DD, h:mm:ss a")}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <Paginationn
              currentPage={currentPage}
              totalCount={transactions?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UserTransactions;
