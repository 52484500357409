import {
  changePasswordFail,
  changePasswordStart,
  changePasswordSuccess,
} from "../../redux/auth/changePasswordSlice";
import axios from "../axios";

export const changePassword = (data) => async (dispatch) => {
  dispatch(changePasswordStart());
  axios
    .post("/user/change-password", data)
    .then(function (response) {

      dispatch(changePasswordSuccess(response.data));
    })
    .catch(function (error) {
      dispatch(changePasswordFail(error.response.data.message));
    });
};
