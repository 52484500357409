import {
  updateRateFailed,
  updateRateStart,
  updateRateSuccess,
} from "../../../../redux/admin/users/userRateSlice";
import axios from "../../../axios";

export const UserRate = (data, status) => async (dispatch) => {
  dispatch(updateRateStart());

  const url =
    status === "update"
      ? `/admin/unit/update-user-rate`
      : `/admin/user/reset-price`;

  axios
    .post(url, data)
    .then((response) => {
      const { message } = response.data;
      dispatch(updateRateSuccess(message));
    })
    .catch((error) => {
      // dispatch(su(data));
      dispatch(updateRateFailed());
    });
};
