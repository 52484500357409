import React from "react";
import styles from "./kindlyView.module.css";
import closeCircle from "../../../assets/closeCircle.svg";
const KindlyView = ({ closeModal }) => {
  return (
    <>
      {/* <div id="overlayyy"> */}

      <div className={styles.modaalll}>
        <div className={styles.bankDetails}>
          <p> Hey mobile friend,</p>
          <p>
            The best experience is on the PC. This dashboard is best suited for
            larger screens. To continue, view your dashboard in all its glory on
            a desktop or laptop.
          </p>
          <div id={styles.btnBox}>
            <button onClick={() => closeModal(false)}>
              Okay,<span id={styles.thanks}> thanks.</span>
            </button>
            <button onClick={() => closeModal(false)}>
              Continue on mobile.
            </button>
            {/* <img
              src={closeCircle}
              alt=""
              id={styles.closeBtnn}
              onClick={() => closeModal(false)}
            /> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default KindlyView;
