import React, { useEffect, useState } from "react";
import Alert from "../../../../utils/Alert";
import "./senderId.css";
import axios from "../../../../utils/axios";
import { useDispatch } from "react-redux";

const PopUp = (props) => {
  const [sender_id, setSenderID] = useState("");
  const [use_case, setUseCase] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const submit = async () => {
    if (sender_id === "" || use_case === "") {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
      const payload = {
        sender_id,
        use_case,
      };
      setLoading(true);
      await axios
        .post("/user/sender_id/create", payload)
        .then((response) => {
          setOpenSuccess(true);
          props.close();
          props.setRegisterStatus("success");
        })
        .catch((error) => {
          props.close();
          props.setRegisterStatus("error");
        });
    }
  };

  return (
    <div>
      <div className="overlay">
        <div className="modal">
          <div className="miniBox">
            <span style={{ marginTop: 10 }}>
              <p>
                Before you can send bulk SMS to lines on their network with your
                own Sender IDs, <strong>MTN Nigeria</strong>,
                <strong> Airtel Nigeria </strong>,
                <strong> Globacom Nigeria </strong> and
                <strong> 9Mobile Nigeria </strong>,
                now demand pre-registration of Sender IDs. This should take
                within two business days.
              </p>
              {/* <p style={{ marginTop: 10 }}>
                <strong>GLO </strong>and <strong>9Mobile</strong> do not yet
                require sender ID registration, thus your messages will be
                delivered to GLO and 9Mobile lines with or without registration.
              </p> */}
            </span>
            <span className="closeBtn" onClick={props.close}>
              &times;
            </span>
          </div>
          <input
            id="idSender"
            type="text"
            placeholder="Enter Your Sender ID"
            onChange={(e) => setSenderID(e.target.value)}
            maxLength="11"
          />
          <h3>
            Use Case: (an example of the message you'll use the ID to send)
          </h3>
          <textarea
            type="text"
            className="msgBox"
            style={{ marginTop: 10 }}
            onChange={(e) => setUseCase(e.target.value)}
          />

          <button
            type="submit"
            id="btnSubmit"
            style={{ opacity: loading ? 0.5 : 1 }}
            onClick={() => submit()}
            disabled={loading}
          >
            Submit
          </button>
        </div>
        <Alert
          handleClose={handleClose}
          openAlert={openAlert}
          Text="Error: All fields are required"
          Type="error"
        />
        <Alert
          handleClose={handleClose}
          openAlert={openSuccess}
          Text="Sender ID created"
          Type="success"
        />
      </div>
    </div>
  );
};

export default PopUp;
