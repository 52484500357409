import React, { useEffect, useState } from "react";
import styles from "./dropdownStyle.module.css";

const CustomDropdown = ({ options, value, onChange, placeholder }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    closeDropdown();
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (isDropdownOpen && !event.target.closest(".custom-dropdown")) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };
  }, []);

  return (
    <div className={styles.dropdownContainer}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onClick={toggleDropdown}
        className={styles.childBox}
        style={{ width: "300px" }}
      />
      {isDropdownOpen && (
        <ul className={styles.dropdownList}>
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionClick(option)}
              className={styles.list}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
