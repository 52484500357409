import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styles from "./Api.module.css";
import view from "../../../../assets/view.svg";
import deActivate2 from "../../../../assets/deActivate2.svg";
import activate from "../../../../assets/activate.svg";
import userSettings from "../../../../assets/userSettings.svg";
import bin1R from "../../../../assets/bin1R.svg";
import ConfirmCardSendAPI from "../../../../components/confirmCard/ConfirmCardSendAPI";
import ConfirmCardDelete from "../../../../components/confirmCard/ConfirmCardDelete";
import Modal from "../../../../components/modal/Modal";
import ConfirmCardDeactivate from "../../../../components/confirmCard/ConfirmCardDeactivate";
import Permissions from "./Permissions";
import { reset } from "../../../../redux/api/createAPIslice";
import { updateKeyPermission } from "../../../../utils/apiCalls/secretKeys";
import { useDispatch, useSelector } from "react-redux";

export default function BasicPopover({
  api_name,
  status,
  setAlertStatus,
  setApiName,
  alertStatus,
}) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [sendModal, setSendModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [permissionType, setPermissionType] = useState("simple");
  const [updatePermissions, setUpdatePermissions] = useState(false);

  const responseCreated = useSelector((state) => state.APIs.responseCreated);
  const generateError = useSelector((state) => state.APIs.generateError);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function closeModdall(e) {
    if (e.target.id == "modal") {
      setSendModal(false);
    }
  }
  function closeModdal(e) {
    if (e.target.id == "modal") {
      setShowWarning(false);
    }
  }
  function deactivate(e) {
    if (e.target.id == "modal") {
      setShowDeactivate(false);
    }
  }

  const cancel = () => {
    dispatch(reset());
    setUpdatePermissions(false);
  };

  const generate = () => {
    dispatch(updateKeyPermission(api_name, permissionType));
  };

  useEffect(() => {
    if (responseCreated) {
      setAlertStatus({
        alertMessage: "Permission updated successfully",
        alertType: "success",
        openAlert: true,
      });
      setApiName("");
    }
    if (generateError) {
      setAlertStatus({
        alertMessage: generateError,
        alertType: "error",
        openAlert: true,
      });
    }
  }, [responseCreated, generateError]);

  return (
    <div>
      {showWarning && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}
          close={closeModdal}
        >
          <ConfirmCardDelete closeModdal={setShowWarning} api_name={api_name} />
        </Modal>
      )}
      {showDeactivate && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}
          close={deactivate}
        >
          <ConfirmCardDeactivate
            deactivate={setShowDeactivate}
            api_name={api_name}
            status={status}
          />
        </Modal>
      )}
      {sendModal && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}
          close={closeModdall}
        >
          <ConfirmCardSendAPI
            api_name={api_name}
            closeModdall={setSendModal}
            // name={groupToDelete}
          />
        </Modal>
      )}
      {updatePermissions && (
        <Permissions
          cancel={cancel}
          header={"Update API KEY"}
          apiName={api_name}
          setPermissionType={setPermissionType}
          permissionType={permissionType}
          generate={generate}
        />
      )}

      <MoreHorizIcon
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      />
      <Popover
        id={id}
        className={styles.dropDownPosition}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        style={{ marginLeft: "-50px", marginTop: "-20px" }}
      >
        <span
          style={{
            display: "inline-flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px 25px",
            fontFamily: "roboto",
            fontWeight: "600",
            cursor: "pointer",
          }}
        >
          <span
            id={styles.optionBox}
            onClick={() => {
              setSendModal(true);
              setAnchorEl(null);
            }}
          >
            <img
              src={view}
              alt="img"
              style={{ width: "18px", height: "auto" }}
            />
            <h3 id={styles.hover} style={{ color: "var(--purple)" }}>
              View
            </h3>
          </span>
          <span
            id={styles.optionBox}
            onClick={() => {
              setUpdatePermissions(true);
              setAnchorEl(null);
            }}
          >
            <img
              src={userSettings}
              alt=""
              style={{ width: "18px", height: "auto" }}
            />
            <h3 id={styles.hover} style={{ color: "var(--color-red)" }}>
              Change Permissions
            </h3>
          </span>
          {status === "active" && (
            <span
              id={styles.optionBox}
              onClick={() => {
                setUpdatePermissions(true);
                setAnchorEl(null);
              }}
            >
              <img
                src={deActivate2}
                alt=""
                style={{ width: "18px", height: "auto" }}
              />
              <h3 id={styles.hover}>De-Activate</h3>
            </span>
          )}
          {status === "paused" && (
            <span
              id={styles.optionBox}
              onClick={() => {
                setShowDeactivate(true);
                setAnchorEl(null);
              }}
            >
              <img
                src={activate}
                alt=""
                style={{ width: "18px", height: "auto" }}
              />
              <h3 id={styles.hover} style={{ color: "#129C78" }}>
                Activate
              </h3>
            </span>
          )}
          <span
            id={styles.optionBox}
            onClick={() => {
              setShowWarning(true);
              setAnchorEl(null);
            }}
          >
            <img src={bin1R} alt="" style={{ width: "18px", height: "auto" }} />
            <h3 id={styles.hover} style={{ color: "var(--color-red)" }}>
              Delete
            </h3>
          </span>
        </span>
      </Popover>
    </div>
  );
}
