import { getSenderIDSuccess } from "../../redux/sms/senderIDSlice";
import axios from "../axios";

export const getSenderID = () => async (dispatch) => {
  await axios
    .get("/user/sender_id/view")
    .then((response) => {
      const { data } = response.data;
      dispatch(getSenderIDSuccess(data));
    })
    .catch((error) => {});
};
