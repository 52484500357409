import {
  getPaymentFailed,
  getPaymentStart,
  getPaymentSuccess,
} from "../../../../redux/admin/users/userPaymentSlice";
import axios from "../../../axios";

export const getUserPayments = (email, status) => async (dispatch) => {
  dispatch(getPaymentStart());

  await axios
    .get(`/admin/payment/${status}/${email}`)
    .then((response) => {
      const { data } = response.data;
      dispatch(getPaymentSuccess(data));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(getPaymentFailed(message));
    });
};
