import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "./notification.module.css";
import AllNotificationMessages from "./AllNotificationMessages";
import Button from "../../../../../components/Button/Button";
import Modal from "../../../../../components/modal/Modal";
import Message from "./Message";
import InAppMsg from "../../userDetails/components/InAppMsg";
import { useSelector, useDispatch } from "react-redux";
import { AdminGetAllNotifications } from "../../../../../utils/AdminApiCalls/adminGetAllNotifications";
import Alert from "../../../../../utils/Alert";
import { Box, CircularProgress } from "@mui/material";

const GetNotifications = () => {
  const style = {};
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  // const [messageCard, setMessageCard] = useState(false);
  const [createNotificationCard, setcreateNotificationCard] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { getAllNotificationData, sendingSuccessful, getNotificationsLoading } =
    useSelector((state) => state.inAppNotifications);

  useEffect(() => {
    if (sendingSuccessful) {
      setcreateNotificationCard(false);
      setOpenSuccess(true);
    }
    dispatch(AdminGetAllNotifications());
  }, [sendingSuccessful]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  // const displayMessage = (e) => {
  //   if (e.target.id === "msgCard") {
  //     setMessageCard(false);
  //   }
  // };
  // const closeModall = (e) => {
  //   if (e.target.id === "modal") {
  //     setMessageCard(false);
  //   }
  // };

  return (
    <>
      {getNotificationsLoading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : getAllNotificationData === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No data available at the moment
          </Alert>
        </center>
      ) : (
        <div>
          <big
            style={{
              fontWeight: "600",
              fontSize: "var(--fs-500)",
            }}
          >
            In App Notifications
          </big>
          <div className="flex justify-between lg:space-x-4 mt-50 ">
            <div
              className="hidden lg:flex flex-col space-y-4"
              style={{ width: !createNotificationCard ? "100%" : "65%" }}
            >
              <div className={styles.topBar}>
                <input
                  type="text"
                  className=" border-2 rounded-md p-2 border-gray-300"
                  id={styles.inputOutline}
                  placeholder="search"
                />
                <Button
                  name={"Create Notifications"}
                  style={{ padding: "7px" }}
                  onClick={() => setcreateNotificationCard(true)}
                />
              </div>
              <div className={styles.notificationBox}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  className="capitalize  px-4"
                >
                  <Tab label="All" className="capitalize" />
                  {/* <Tab label="Admins" />
              <Tab label="Clients" /> */}
                </Tabs>
                <div className="mt-4 border-t">
                  {value === 0 && <AllNotificationMessages />}
                </div>
              </div>
            </div>
            {createNotificationCard && (
              <div className={styles.rightCard}>
                <div className={styles.body}>
                  {createNotificationCard && (
                    <InAppMsg
                      closeNotificationTab={setcreateNotificationCard}
                      style={style}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <Alert
            openAlert={openSuccess}
            Text={"Notification message successful!"}
            Type={"success"}
            handleClose={handleClose}
          />
        </div>
      )}
    </>
  );
};

export default GetNotifications;
