import React, { useState } from "react";
import styles from "./auditLogs.module.css";
import filter from "../../../../assets/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetAuditLogEndpoint } from "../../../../utils/AdminApiCalls/getAuditLogEndpoint";
import moment from "moment/moment";
import ActivityDetails from "./ActivityDetails";
import { Modal } from "@mui/material";
import { Box, CircularProgress } from "@mui/material";
import Alert from "../../../../utils/Alert";
import { getAuditLogSuccessful } from "../../../../redux/admin/records/auditLogSlice";

const AuditLogs = () => {
  const dispatch = useDispatch();
  const [viewActivity, setViewActivity] = useState(false);
  const [activityDetails, setActivityDetails] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const { getAuditLogData, loading, getAuditLogSuccess } = useSelector(
    (state) => state.getAuditLog
  );

  useEffect(() => {
    dispatch(GetAuditLogEndpoint());
  }, [getAuditLogSuccess]);

  return (
    <>
      {/* {viewActivity && (
        <Modal open={open} onClose={handleClose}>
          <ActivityDetails
            closeModal={setViewActivity}
            data={activityDetails}
          />
        </Modal>
      )} */}
      <big id={styles.title}>Audit Logs</big>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : getAuditLogData === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No data available at the moment
          </Alert>
        </center>
      ) : (
        <div className={styles.mainWrap}>
          <div className={styles.head}>
            <span>
              <img
                src={filter}
                alt="filter"
                style={{ width: "18px", height: "auto" }}
              />
            </span>
            <span>Filter</span>
          </div>
          <div>
            <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
              <div style={{ width: "100%" }} className={styles.cards}>
                <div className="w-full shadow bg-white rounded">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Search users"
                    className="border border-[#C4C4C4] shadow p-3 w-[50%] rounded-[10px] m-4"
                  />

                  <div className="border-gray-200 w-full rounded bg-white overflow-x-auto">
                    <table className="w-full leading-normal ">
                      <thead className=" text-xs font-semibold  bg-red-500 tracking-wider text-left px-5 py-3 hover:cursor-pointer uppercase border-b-2 ">
                        <tr
                          className="border-b border-gray bg-red-500"
                          style={{ width: "100%" }}
                        >
                          <th
                            scope="col"
                            className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                          >
                            Activity
                          </th>
                          <th
                            scope="col"
                            className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                          >
                            Time
                          </th>
                          <th
                            scope="col"
                            className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                          >
                            Name
                          </th>
                        </tr>
                      </thead>
                      <tbody className="px-5">
                        {getAuditLogData.length > 0 &&
                          getAuditLogData.map((data, index) => (
                            <tr
                              key={index}
                              className="hover:cursor-pointer hover:bg-gray-100"
                            >
                              <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                                <p className="text-black whitespace-no-wrap font-semibold">
                                  {data?.activity?.charAt(0).toUpperCase() +
                                    data?.activity?.slice(1)}
                                </p>
                              </td>
                              <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                                <p className="text-black whitespace-no-wrap font-semibold">
                                  {moment(data.date).format("ll")}
                                </p>
                              </td>
                              <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                                <p className="text-black whitespace-no-wrap font-semibold">
                                  {data.time}
                                </p>
                              </td>
                              <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                                <p className="text-black whitespace-no-wrap font-semibold">
                                  {data.name}
                                </p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuditLogs;
