import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getBaseRateLoading: false,
  getBaseRateSuccess: false,
  baseRateData: {},
  getBaseRateFailed: false,
  baseRateUpdateLoading: false,
  baseRateUpdateData: "",
  baseRateUpdateSuccess: false,
  baseRateUpdateFailed: false,
  getBaseRateFailedData: {},
  getBaseRateSuccess: null,
  // getUserRateLoading: false,
  // getUserRateData: {},
  // getUserRateFailed: false,
};

const getRates = createSlice({
  name: "adminRates",
  initialState,
  reducers: {
    getBaseRateStart: (state) => {
      state.getBaseRateLoading = true;
    },
    baseRateSuccessful: (state, { payload }) => {
      state.getBaseRateLoading = false;
      state.baseRateData = payload;
      state.getBaseRateSuccess = true;
      state.getBaseRateFailed = false;
    },
    baseRateError: (state, { payload }) => {
      state.getBaseRateLoading = false;
      state.baseRateData = {};
      state.getBaseRateFailed = true;
      state.getBaseRateFailedData = payload;
    },

    baseRateUpdateStart: (state) => {
      state.baseRateUpdateLoading = true;
    },
    baseRateUpdateSuccessful: (state, { payload }) => {
      state.baseRateUpdateLoading = false;
      state.baseRateUpdateData = payload;
      state.baseRateUpdateSuccess = true;
      state.baseRateUpdateFailed = false;
    },
    baseRateUpdateError: (state, { payload }) => {
      state.baseRateUpdateLoading = false;
      state.baseRateUpdateData = payload;
      state.baseRateUpdateSuccess = false;
      state.baseRateUpdateFailed = true;
    },
    ResetBaseRates: (state) => {
      state.getBaseRateSuccess = false;
      state.baseRateSuccess = false;
      state.baseRateUpdateFailed = false;
      state.baseRateUpdateData = "";
    },
  },
});

export const {
  getBaseRateStart,
  baseRateSuccessful,
  baseRateError,
  baseRateUpdateSuccessful,
  baseRateUpdateStart,
  // getUserRateStart,
  // getUserRateSuccessful,
  // getUserRateError,
  ResetBaseRates,
  baseRateUpdateError,
} = getRates.actions;

export default getRates.reducer;
