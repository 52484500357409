import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWallet } from "../../../utils/apiCalls/getWallet";
import Visa from "../../../assets/Visa.svg";
import creditCard from "../../../assets/creditCard.svg";
import verve from "../../../assets/verve.svg";
import MasterCard from "../../../assets/MasterCard.svg";
import moneyTransfer from "../../../assets/moneyTransfer.svg";
import styles from "./Addfunds.module.css";
import Alert from "../../../utils/Alert";
import PayStackFunding from "./components/paystack";
import { formatPriceByCountrySymbol } from "../../../utils/formatPrice";
import UploadBox from "../../../components/UploadBox/UploadBox";
import Modal from "../../../components/modal/Modal";
import ViewAcctModal from "../dashboard/ViewAcctModal";

function Addfunds() {
  const dispatch = useDispatch();

  const [walletBalance, setWalletBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [payType, setPayType] = useState("card");
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const [upload, setUpload] = useState(false);

  const { wallet_balance, unit_balance } = useSelector(
    (state) => state.wallet.walletData
  );

  useEffect(() => {
    !upload && setPayType("card");
  }, [upload]);

  useEffect(() => {
    dispatch(getWallet());
  }, []);

  // useEffect(() => {
  //   setWalletBalance(wallet?.wallet_balance);
  //   setUnitBalance(wallet?.unit_balance);
  // }, [wallet]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  function closeModall(e) {
    if (e.target.id == "modal") {
      setUpload(false);
    }
  }

  return (
    <>
      {payType === "bank_transfer" && upload && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={closeModall}
        >
          <UploadBox closeModal={setUpload} />
        </Modal>
      )}
      <div className="">
        <div className={styles.addFunds}>
          <div className={styles.details}>
            <div>
              <h3>Add funds</h3>
              <small>Add funds and send messages.</small>
            </div>
            <div className={styles.wallet}>
              <div className={styles.xwallet}>
                <p>Wallet</p>
                <h3 className={`font-bold ${wallet_balance < 1 && "#FF0000"}`}>
                  {formatPriceByCountrySymbol("Nigeria", wallet_balance)}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div>
            <div className="mb-5 font-normal text-xl" id={styles.boxLine}>
              <div id={styles.line}></div> <h3>Select Payment Method</h3>{" "}
              <div id={styles.line}></div>
            </div>
            <div className={styles.cardBox}>
              <div
                onClick={() => setPayType("card")}
                style={{
                  backgroundColor:
                    payType === "card" && "rgba(75, 26, 124, 0.16)",

                  boxShadow:
                    payType === "card"
                      ? " 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)"
                      : "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                className={styles.card1}
              >
                <div className={styles.cardBoxDesign}>
                  <img
                    src={creditCard}
                    alt="card"
                    style={{ width: "130px", height: "auto" }}
                  />
                  <p
                    className="text-sm mb-1 text-"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: payType === "card" && "var(--purple)",
                    }}
                  >
                    Credit Card
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setPayType("bank_transfer");
                }}
                className={styles.card2}
                style={{
                  backgroundColor:
                    payType === "bank_transfer" && "rgba(75, 26, 124, 0.16)",
                  boxShadow:
                    payType === "bank_transfer"
                      ? " 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)"
                      : " 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className={styles.cardBoxDesign}>
                  <img
                    src={moneyTransfer}
                    alt="transfer"
                    style={{
                      width: "130px",
                      height: "auto",
                    }}
                  />
                  <p
                    className="text-sm mb-1 mt-5"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "var(--purple)",
                    }}
                  >
                    Bank Transfer
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.blockDesign}>
              {payType === "card" && (
                <small
                  className=" px-3 py-1 text-black rounded-0"
                  style={{ fontWeight: "600" }}
                >
                  Use your credit card or debit cards for online payment.
                </small>
              )}
              {payType === "bank_transfer" && (
                <div className={styles.blockDesign}>
                  <small
                    className="text-sm text-red-700 px-3 py-1"
                    style={{ color: "red" }}
                  >
                    Note: only send to the account number displayed below
                    <br></br> <br></br>
                    <span className="text-black mt-2 font-bold">
                      Account number: 0572004368
                    </span>{" "}
                    <br></br>
                    <br></br>
                    <span className="text-black my-2 font-bold">
                      Account name: Exturi Integrated Solutions Limited
                    </span>{" "}
                    <br></br>
                    <p style={{ marginTop: "10px" }}>
                      Payment completed? click the button below
                    </p>
                    <div className="flex justify-center">
                      <button
                        className="my-5 py-2 px-3 bg-blue-700 rounded-xl font-bold"
                        style={{ backgroundColor: "purple", color: "white" }}
                        onClick={() => setUpload(true)}
                      >
                        Fill Payment Infomation
                      </button>
                    </div>
                  </small>
                </div>
              )}
              {payType === "card" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                    gap: "20px",
                  }}
                >
                  <span id={styles.atmDesign}>
                    <img src={Visa} alt="visa" style={{ height: "20px" }} />
                  </span>
                  <span id={styles.atmDesign}>
                    <img
                      src={MasterCard}
                      alt="mastercard"
                      style={{ height: "20px" }}
                    />
                  </span>
                  <span id={styles.atmDesign}>
                    <img
                      src={verve}
                      alt="verve"
                      style={{ width: "30px", height: "20px" }}
                    />
                  </span>
                </div>
              )}
              {payType === "card" ? (
                <small
                  className=" px-3 py-4 text-black rounded-0 text-lg"
                  style={{ fontWeight: "300", margintTop: "100px" }}
                >
                  Service coming soon
                </small>
              ) : (
                ""
              )}
            </div>
            {payType === "card" && (
              <div
                style={{
                  display: "flex",
                  flesxDirection: "row",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <div>
                  {/* <input
                    placeholder="Amount"
                    type="number"
                    value={amount}
                    onChange={(e) =>
                      setAmount(e.target.value.replace(/[^0-9]/g, ""))
                    }
                    style={{ width: "200px" }}
                    className="px-2 py-3"
                  /> */}
                  {/* <div
                    className={`mt-5 py-3 text-center text-white font-bold`}
                    style={{
                      width: "200px",

                      backgroundColor:
                        amount > 0 ? "rgb(75 26 124)" : "rgb(156 163 175)",
                    }}
                  >
                    <PayStackFunding
                      setAlertStatus={setAlertStatus}
                      payType={payType}
                      amount={amount}
                    />
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <Alert
          openAlert={alertStatus.openAlert}
          Text={alertStatus.alertMessage}
          Type={alertStatus.alertType}
          handleClose={handleClose}
        />
      </div>
    </>
  );
}

export default Addfunds;
