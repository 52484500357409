import {
  getScheduledInAppNotificationStart,
  setScheduledNotification,
  scheduledInAppNotificationFailed,
  scheduleSuccess,
} from "../../redux/admin/messageScheduleSlices/inAppNotificationScheduleSlice";
import {
  getScheduledSmsNotificationStart,
  scheduledSmsNotificationFailed,
  setScheduledSmsNotification,
} from "../../redux/admin/messageScheduleSlices/SmsNotificationScheduleSlice";
import axios from "../axios";

export const setScheduledNotifications = (details) => async (dispatch) => {
  dispatch(getScheduledInAppNotificationStart());
  axios
    .post("/admin/notification/schedule/in-app", details)
    .then((response) => {
      const { details } = response.data;
      dispatch(setScheduledNotification(details));
      // dispatch(scheduleSuccess())
    })

    .catch((error) => {
      dispatch(scheduledInAppNotificationFailed(error));
    });
};

export const setScheduledSms = (data) => async (dispatch) => {
  dispatch(getScheduledSmsNotificationStart());
  axios
    .post("/admin/notification/schedule/sms", data)
    .then((response) => {
      const { data } = response.data;
      dispatch(setScheduledSmsNotification(data));
    })

    .catch((error) => {
      dispatch(scheduledSmsNotificationFailed(error));
    });
};
