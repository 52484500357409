import Input from "../../../components/Input/Input";
import OnboardingLayout from "../../../components/OnboardingLayout/OnboardingLayout.js";

import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { FONTSIZE, COLORS } from "../../../constants/theme";
import Button from "../../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { Fade } from "@mui/material";
import { clearregisterErrorMessage } from "../../../redux/auth/registerSlice";
import axios from "axios";
// import Alertt from "../../../utils/Alert"

import validator from "validator";
import styles from "./Signup.module.css";
import { Transition } from "@headlessui/react";
import Countdown from "react-countdown";

// import sgMail from "@sendgrid/mail";
import { register, sendOtp, verifyEmail } from "../../../utils/apiCalls/signUp";
import { useDispatch, useSelector } from "react-redux";
import Alertt from "../../../utils/Alert";

const { primary, lightGrey } = COLORS;
const { px40, px18, px14 } = FONTSIZE;

// const sgMail = require("@sendgrid/mail");

// Random component
const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span>00:{seconds <= 9 ? `0${seconds}` : seconds}</span>;
  }
};

export default function Signup() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [isNotValidated, setIsNotValidated] = useState(false);
  const [canRegister, setCanRegister] = useState(false);
  const [successOtp, setSuccessOtp] = useState("");
  const [checkPasswordLength, setCheckPasswordLength] = useState(false);
  const [error, setError] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [showPasswordBox, setShowPasswordBox] = useState(false);
  const dispatch = useDispatch();
  const { registerError, registerSuccessState } = useSelector(
    (state) => state.register
  );
  const [loading, setloading] = useState(false);
  const [buttonName, setbuttonName] = useState("Verify Email");

  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  useEffect(() => {
    if (password !== "" && confirmPassword !== "") {
      setCanRegister(true);
    } else {
      setCanRegister(false);
    }
  }, [password, confirmPassword]);

  function onSubmit() {
    if (password !== confirmPassword) {
      setIsNotValidated(true);
    } else if (password.length < 6) {
      setCheckPasswordLength(true);
    } else {
      setIsNotValidated(false);
      dispatch(
        register({
          email,
          password,
          password_confirmation: confirmPassword,
        })
      );
    }
  }
  function handleProceed() {
    setShowOtp(true);
    //   validateInput();
  }
  useEffect(() => {
    isNotValidated === true && setTimeout(() => setIsNotValidated(false), 2000);
  }, [isNotValidated]);

  useEffect(() => {
    checkPasswordLength &&
      setTimeout(() => setCheckPasswordLength(false), 2000);
  }, [checkPasswordLength]);
  useEffect(() => {
    if (registerError) {
      // setTimeout(() => dispatch(clearregisterErrorMessage()), 1000);
      setloading(false);
      setAlertStatus({
        alertMessage: "Error occurred, please try again",
        alertType: "error",
        openAlert: true,
      });
    }
  }, [registerError]);
  useEffect(() => {
    registerSuccessState && navigate("/settings/edit");
  }, [registerSuccessState]);

  useEffect(() => {
    dispatch(clearregisterErrorMessage());
  }, []);

  // const handleCloseError = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setErrorMessage(false);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  return (
    <div className={styles.wrap}>
      <OnboardingLayout />
      <div
        className={styles.container}
        style={{
          position: "relative",
          padding: "4rem 4rem",
          paddingTop: showPasswordBox ? "3rem" : "8rem",
        }}
      >
        <div className={styles.detailsBox}>
          <div className={styles.form} style={{ width: "500px" }}>
            <div style={{ textAlign: "center" }}>
              <h1
                style={{ fontSize: px40, color: primary, marginBottom: "3rem" }}
              >
                Create a free account!
              </h1>
            </div>
            {isNotValidated && (
              <Alert
                //  variant="filled"
                severity="error"
              >
                Passwords do not match!
              </Alert>
            )}
            {checkPasswordLength && (
              <Alert
                //  variant="filled"
                severity="error"
              >
                Password must be at least 6 characters!
              </Alert>
            )}
            {successOtp && (
              <div
                style={{
                  position: "absolute",
                  top: "70px",
                  width: "fit-content",
                  right: "80px",
                  zIndex: "3",
                }}
              >
                <Alert
                  variant="filled"
                  severity={
                    successOtp.includes("Invalid") ? "error" : "success"
                  }
                >
                  {successOtp}
                </Alert>
              </div>
            )}
            {registerError && (
              <Alert variant="filled" severity={"error"}>
                {registerError}
              </Alert>
            )}
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ marginBottom: "2rem" }}>
                <div
                  style={{
                    color: lightGrey,
                    marginBottom: ".5rem",
                    marginTop: ".5rem",
                  }}
                >
                  * All fields are required
                </div>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  disabled={showPasswordBox}
                />
              </div>
            </div>

            <Transition
              show={showOtp}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <OtpInput
                value={otp}
                numInputs={4}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: 10,
                  marginBottom: 10,
                }}
                inputStyle={{
                  border: "2px solid #4c1a7d",
                  width: "100%",
                  height: "4rem",
                  borderRadius: 5,
                  textTransform: "uppercase",
                }}
                className="hidden"
                onChange={async (value) => {
                  setOtp(value);
                  if (value.length === 4) {
                    const val = value.toUpperCase();
                    const res = await verifyEmail({ email, otp: val });
                    if (res.data) {
                      setSuccessOtp(res.data.message);
                      setShowOtp(false);
                      setShowPasswordBox(true);
                      //  setAlertStatus({
                      //    alertMessage: "Email verified successfully",
                      //    alertType: "success",
                      //    openAlert: true,
                      //  });
                      setTimeout(() => setSuccessOtp(""), 3000);
                    } else {
                      setSuccessOtp("Invalid OTP, please enter a valid OTP");
                    }
                  }
                }}
              />
            </Transition>
            {showPasswordBox && (
              <>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </>
            )}
            <div
              style={{
                fontSize: px14,
                textAlign: "center",
                marginBottom: "3.5rem",
              }}
            >
              By signing up, you confirm that you've read and accepted our{" "}
              <Link
                to="/privacy"
                target={"_blank"}
                style={{ textDecoration: "none" }}
              >
                Terms of Service and Privacy Policy
              </Link>
            </div>
            {!showPasswordBox ? (
              <Button
                name={buttonName}
                margin="0 0 1rem"
                onClick={async () => {
                  try {
                    setloading(true);
                    setbuttonName("Please wait...");
                    const req = await sendOtp({ email });
                    if (req.data) {
                      setloading(false);
                      setbuttonName("Verify Email");
                      setSuccessOtp(req.data.message);
                      setShowOtp(true);
                      // setAlertStatus({
                      //   alertMessage: "Email verified successfully",
                      //   alertType: "success",
                      //   openAlert: true,
                      // });
                    } else {
                      if (email) {
                        setAlertStatus({
                          alertMessage: "Email invalid or already exist",
                          alertType: "error",
                          openAlert: true,
                        });
                      } else if (email === "") {
                        setAlertStatus({
                          alertMessage: "Email field required",
                          alertType: "error",
                          openAlert: true,
                        });
                      }
                      setbuttonName("Verify Email");
                      setloading(false);
                    }
                  } catch (error) {
                    setloading(false);
                    setbuttonName("Verify Email");
                    setShowOtp(false);
                  }
                }}
                style={{
                  width: "100%",
                  opacity: loading || showOtp || email == "" ? 0.5 : 1,
                }}
                customclassName={`capitalize ${
                  showOtp === true && "pointer-events-none"
                } `} //${showOtp === true && "opacity-50"}
                disabled={showOtp || loading || email === ""}
              />
            ) : (
              <Button
                disabled={!canRegister}
                name="Register"
                margin="0 0 1rem"
                //  onClick={() => setShowOtp(true)}
                style={{ width: "100%", opacity: !canRegister ? 0.5 : 1 }}
                customclassName={`capitalize`}
                onClick={onSubmit}
              />
            )}
            {/* <Button
            name="Register"
            margin="0 0 1rem"
            style={{ width: "100%" }}
            className={``}
          /> */}
            <div style={{ fontSize: px18, textAlign: "center" }}>
              Already have an account?{" "}
              <Link to="/login" style={{ textDecoration: "none" }}>
                <span style={{ color: "var(--purple)" }}> Sign in</span>
              </Link>
            </div>
            <Link to="/" id={styles.goBack}>
              Click here to go to Homepage
            </Link>
          </div>
        </div>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="50.5261"
              cy="34.5261"
              r="50"
              transform="rotate(15.4714 50.5261 34.5261)"
              fill="#4B1A7C"
            />
          </svg>
        </div>
      </div>
      {/* <Alertt
        openAlert={errorMessage}
        Text={registerError}
        Type={"error"}
        handleClose={handleCloseError}
      /> */}
      <Alertt
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </div>
  );
}
