import React, { useState } from "react";
import styles from "./Resources.module.css";
import { ReactComponent as Flag } from "../../../assets/flag.svg";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/modal/Modal";
function Resources() {
  const [showContact, setShowContact] = useState(false);
  function closeModal(e) {
    if (e.target.id == "modal") {
      setShowContact(false);
    }
  }
  return (
    <>
      {showContact && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <div className={styles.contactCard}>
            <h3>Hi stranger!</h3>
            <p>We would love to hear from you!</p>
            <p id="messageInfo">
              Please help us make our platform better for you by taking a moment
              to share your thoughts and opinions on your experience with us. If
              you are experiencing issues, please we are help to help.
            </p>
            <textarea placeholder="164 Characters"></textarea>
            <div className={styles.btnSection}>
              <button onClick={() => setShowContact(false)}>cancel</button>
              <button>SEND</button>
            </div>
          </div>
        </Modal>
      )}
      <div>
        <div className={styles.info}>
          <h3>Help Topics</h3>
          <p>
            How can we help each other? You can help us by letting us help you.
          </p>
        </div>
        <div className={styles.cards}>
          <div className={styles.leftCard}>
            {[1, 2, 3, 4, 5, 6, 7].map((index) => (
              <div className={styles.infoBox} key={index}>
                <div className={styles.icon}>
                  <Flag />
                </div>
                <div className={styles.infoText}>
                  <h3>GETTING STARTED WITH XMS </h3>
                  <p>
                    You’ll want the best possible start in your XMS account.
                    Let’s guide you through the beginning of this process.
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.rightCard}>
            <div className={styles.helpCard}>
              <h3>Popular Articles</h3>
              {[1, 2, 3, 4, 5].map((index) => (
                <p key={index}>How to Create an Account</p>
              ))}
            </div>
            <div className={styles.helpCard}>
              <h3>Need Support?</h3>
              <p>
                Can't find the answer you're looking for? Don't worry we're here
                to help!
              </p>
              <Button
                onClick={() => setShowContact(true)}
                name="CONTACT US"
                style={{ marginTop: 63, width: 150, alignSelf: "center" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;
