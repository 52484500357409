import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getWalletLoad: false,
  walletData: {
    wallet_balance: "",
    unit_balance: "",
  },
  dashboardAnalytics: {},
};

export const getWalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getWalletStart: (state) => {
      state.getWalletLoad = true;
    },
    getWalletSuccess: (state, { payload }) => {
      state.getWalletLoad = false;
      state.walletData = payload;
    },
    getDashboardAnalytics: (state, { payload }) => {
      state.dashboardAnalytics = payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { getWalletStart, getWalletSuccess, getDashboardAnalytics } =
  getWalletSlice.actions;

export default getWalletSlice.reducer;
