import axios from "../axios";
import {
  NotificationFailed,
  NotificationSent,
  SendingNotification,
} from "../../redux/admin/Send/sendInAppNotificationsSlice";

export const SendInAppNotifications = (data) => async (dispatch) => {
  dispatch(SendingNotification());

  axios
    .post("/admin/notification/in-app", data)
    .then(function (response) {
      dispatch(NotificationSent(response.data.data));
    })
    .catch(function (error){
      dispatch(NotificationFailed(error));
    });
};
