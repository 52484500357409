import React, { useEffect, useState } from "react";
import styles from "../index.module.css";
import { CalendarIcon } from "../../../assets";
import chevrondown from "../../../assets/chevrondown.svg";
import { fetchAnalysis } from "../../../../utils/apiCalls/admin/fetchAnalysis";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TopCard from "./components/TopCard";
import { useNavigate } from "react-router-dom";
import DashRecentTransactions from "./components/DashboardRecentTransactions";
import DashboardAddedusers from "./components/DashboardAddedUsers";
import { fetchRecentTransactions } from "../../../../utils/apiCalls/admin/fetchRecentTransactions";
import AnalysisGraph from "./components/AnalysisGraph";
import Eyes from "../../../assets/Show.png";
import PieChart from "./components/PieChart";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Loading from "./components/Loading";
import { fetchRecentAddedUser } from "../../../../utils/apiCalls/admin/fetchRecentUser";
import { formatPriceByCountrySymbol } from "../../../../utils/formatPrice";
import DashboardMessages from "./components/DashboardMessages";
import { fetchMessageStatistics } from "../../../../utils/apiCalls/admin/fetchMessageStatistics";

function Admin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startDate = "2022-01-01";
  const endDate = "2023-11-02";
  const perpager = 10;

  const [pieChartData, setPieChartData] = useState();
  const [analysisPeriod, setAnalysisPeriod] = useState("TM");
  const [firstName, setFirstName] = useState("");

  const { dataError, analysisData, loading } = useSelector(
    (state) => state.dashboardData
  );

  useEffect(() => {
    dispatch(fetchAnalysis({ analysis_for: analysisPeriod }));
    dispatch(fetchRecentTransactions());
    dispatch(fetchRecentAddedUser());
    dispatch(fetchMessageStatistics(startDate, endDate, perpager));
  }, [analysisPeriod]);

  // useEffect(() => {
  //   if (dataError !== "" && !loading) {
  //     localStorage.removeItem("userDetails");
  //     navigate("/office");
  //   }
  // }, [analysisData]);

  useEffect(() => {
    if (dataError === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [dataError]);

  let index;
  const localData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    index = localData.user.name.indexOf(" ");
    setFirstName(localData.user.name.substring(index));
  }, [localData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-3xl font-medium">Overview</h3>
          <p className="text-[#343A40] text-[1.2rem] ">
            Welcome back {firstName}
          </p>
        </div>
        <div
          className={`${styles.calendar} flex justify-between items-center lg:px-[14px] w-[10rem] w-52 h-[2.75rem] bg-red-600 rounded-xl shadow[0px 4px 4px rgba(0, 0, 0, 0.25)] bg-[#ffffff]`}
        >
          <div className="flex w-full">
            <img src={CalendarIcon} className="hidden lg:block w-2/12" />
            <FormControl variant="standard" fullWidth className="">
              {/* <InputLabel id="demo-simple-select-label">Timeline</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={analysisPeriod}
                label="Timeline"
                className="self-center ml-[8px] text-center w-5/6 "
                onChange={(e) => setAnalysisPeriod(e.target.value)}
              >
                <MenuItem value={"TM"}>This Month</MenuItem>
                <MenuItem value={"T"}>Today</MenuItem>
                <MenuItem value={"Y"}>Yesterday</MenuItem>
                <MenuItem value={"TW"}>This Week</MenuItem>
                <MenuItem value={"LW"}>Last Week</MenuItem>
                <MenuItem value={"LM"}>Last Month</MenuItem>
                <MenuItem value={"Q1"}>Q1</MenuItem>
                <MenuItem value={"Q2"}>Q2</MenuItem>
                <MenuItem value={"Q3"}>Q3</MenuItem>
                <MenuItem value={"Q4"}>Q4</MenuItem>
                <MenuItem value={"TY"}>This Year</MenuItem>
                <MenuItem value={"LY"}>Last Year</MenuItem>
                <MenuItem value={"A"}>All Time</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        {analysisData !== null && <TopCard dashboardData={analysisData} />}
      </div>
      <div className="mt-[2.25rem] flex flex-col lg:flex-row h-[580px]">
        {analysisData?.message?.chart && <AnalysisGraph />}
        <div className="lg:w-[3%]"></div> {/* middle width */}
        <div className="h-full w-[100%] lg:w-[23%] rounded">
          {analysisData?.message && <PieChart />}
          <div className="h-2/6 pt-5 rounded-xl shadow-md">
            <div className="bg-white h-full">
              <div className="flex justify-between bg-[#4B1A7C] py-2 px-3 mb-2 text-white text-sm font-semibold">
                <p>Top Sender</p>
                <div className="flex items-center cursor-pointer">
                  <img src={Eyes} />
                  <p className="ml-1">View All</p>
                </div>
              </div>
              {analysisData?.top_sender?.length > 0 ? (
                analysisData?.top_sender.map((user, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between text-sm px-3 py-1"
                    >
                      <p>
                        {index + 1}.{" "}
                        <span
                          className="text-[#4B1A7C] cursor-pointer"
                          onClick={() =>
                            navigate("/admin/user-dashboard", {
                              state: { email: user.email },
                            })
                          }
                        >
                          {user.name}
                        </span>
                      </p>
                      <p>{formatPriceByCountrySymbol("", user.pages)}</p>
                    </div>
                  );
                })
              ) : (
                <p className="grid place-content-center h-full text-gray-600">
                  No Data found
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <DashRecentTransactions />
      </div>

      <div>
        <DashboardAddedusers />
      </div>
      <div>
        <DashboardMessages />
      </div>
    </>
  );
}

export default Admin;
