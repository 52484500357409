import { getPhoneGroupStart, getPhoneGroupSuccess, savePhoneGroupFail, savePhoneGroupStart, savePhoneGroupSuccess } from "../../redux/contacts/phoneGroupSlice";
import { savePhoneNumbersInState, savePhoneNumbersInStateStart } from "../../redux/sms/messageSummarySlice";
import axios from "../axios";

export const getPhoneGroup = () => async(dispatch) => {
    dispatch(getPhoneGroupStart());
 await axios.get("/user/phone/group-name").then((response) => {
     const {message} = response.data
    dispatch(getPhoneGroupSuccess(message));
  })
  .catch((error)=>{

  })
}; 

export const savePhoneGroup = (payload) => async (dispatch) => {
    dispatch(savePhoneGroupStart())
    await axios.post("/user/phone/create-group", payload)
    .then((response)=>{
        const {message} = response.data
        dispatch(savePhoneGroupSuccess(message))
        dispatch(getPhoneGroup());
    })
    .catch((error)=>{
        const { message } = error.response.data;
        dispatch(savePhoneGroupFail(message));
    })
}

export const getPhoneNumbers = (payload) => async (dispatch) => {
    dispatch(savePhoneNumbersInStateStart());
    await axios
      .get(`/user/phone/group-number/${payload}`)
      .then((response) => {
        const { message } = response.data;
        dispatch(savePhoneNumbersInState(message.toString()));
     
      })
      .catch((error) => {});
}
