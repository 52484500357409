import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  scheduleData: {},
  getScheduledSmsNotificationError: false,
  SmsNotificationScheduled: false,
  scheduledSmsNotificationSent: false,
};

export const SmsNotificationScheduleSlice = createSlice({
  name: "scheduled-SmsNotification",
  initialState,
  reducers: {
    getScheduledSmsNotificationStart: (state) => {
      state.loading = true;
    },
    setScheduledSmsNotification: (state, { payload }) => {
      state.loading = false;
      state.SmsNotificationScheduled = true;
      state.scheduleData = payload;
    },
    getScheduledSmsNotificationSuccess: (state, { payload }) => {
      state.loading = false;
      state.scheduleData = payload;
    },
    scheduledSmsNotificationFailed: (state, { payload }) => {
      state.loading = false;
      state.scheduleData = {};
      state.getScheduledSmsNotificationError = true;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getScheduledSmsNotificationStart,
  getScheduledSmsNotificationSuccess,
  scheduledSmsNotificationFailed,
  setScheduledSmsNotification,
} = SmsNotificationScheduleSlice.actions;

export default SmsNotificationScheduleSlice.reducer;
