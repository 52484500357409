import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminNotificationCard from "../../../../../components/confirmCard/AdminNotificationCard";
// import Modal from "../../../../../components/modal/Modal";
import {
  resetScheduleSuccess,
  SmsFailed,
  SmsSent,
} from "../../../../../redux/admin/Send/sendSmsAllUsersSlice";
import Alert from "../../../../../utils/Alert";
import SmsNotificationScheduler from "./SmsNotificationScheduler";

function SmsNotification() {
  const dispatch = useDispatch();

  const [sender_id, setSenderId] = useState("");
  const [message, setMessage] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [scheduleSuccess, setScheduleSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    sendingSuccess,
    sendingLoading,
    SmsSent,
    scheduleNotificationSuccess,
  } = useSelector((state) => state.sendSmsAll);

  useEffect(() => {
    message !== "" ? setCanSubmit(true) : setCanSubmit(false);
  }, [sender_id, message]);

  useEffect(() => {
    sendingLoading && SmsSent ? dispatch(SmsSent()) : dispatch(SmsFailed());
  }, [sendingLoading]);

  useEffect(() => {
    if (sendingSuccess) {
      setOpenSuccess(true);
      setShowWarning(false);
      setSenderId("");
      setMessage("");
    }
  }, [sendingSuccess]);

  useEffect(() => {
    if (scheduleNotificationSuccess) {
      setShowWarning(false);
      setScheduleSuccess(true);
    }
    setMessage("")
    dispatch(resetScheduleSuccess())
  }, [scheduleNotificationSuccess]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setScheduleSuccess(false);
  };

  const handleSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setScheduleSuccess(false);
  };

  const submit = (e) => {
    e.preventDefault();
    setShowWarning(true);
    handleOpen();
  };

  return (
    <>
      <big style={{ fontWeight: "600", fontSize: "var(--fs-500)" }}>
        Send Sms Notification to Users
      </big>
      <div className="bg-white w-full rounded p-[31px]">
        {showWarning && (
          <Modal
            open={open}
            onClose={handleClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AdminNotificationCard
              close={() => setShowWarning(false)}
              sender_id={sender_id}
              message={message}
            />
          </Modal>
        )}
        <h3 className="font-medium">Send sms to all users</h3>
        <form class="w-full" onSubmit={(e) => submit(e)}>
          <div class="flex flex-wrap -mx-3 mb-6"></div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                className="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                for="grid-password"
              >
                Sender ID
              </label>
              <input
                className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="text"
                onChange={(e) => setSenderId(e.target.value)}
                value={"XMS"}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                for="grid-password"
              >
                Message
              </label>
              <textarea
                rows="10"
                className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              ></textarea>
            </div>
            <div className="flex justify-end px-3 mt-10 w-full">
              <button
                className=" w-full lg:w-[180px] bg-[#4B1A7C] shadow focus:shadow-outline rounded-md focus:outline-none text-white font-bold py-2 px-6 rounded"
                type="submit"
                style={{
                  cursor: "pointer",
                  opacity: !canSubmit ? 0.5 : 1,
                }}
                disabled={!canSubmit ? true : false}
              >
                SEND
              </button>
            </div>
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
              <SmsNotificationScheduler
                message={message}
                sender_id={sender_id}
              />
            </div>
          </div>
        </form>
        <Alert
          openAlert={openSuccess}
          Text={"Sms has been sent"}
          Type={"success"}
          handleClose={handleCloseAlert}
        />
        <Alert
          openAlert={scheduleSuccess}
          Text={"Sms has been Scheduled"}
          Type={"success"}
          handleClose={handleSuccess}
        />
      </div>
    </>
  );
}

export default SmsNotification;
