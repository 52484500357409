import styles from "../styles/layout.module.css";
import { DateRangePicker } from "react-date-range";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as Download } from "../../../../../assets/download.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import MaterialUIPickers from "../../../../../components/dateAndTime/MaterialUIPickers";
import Paginationn from "../../../../../components/Pagination/Pagination";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fil
import moment from "moment";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDown from "../../../../../assets/chevrondown.svg";
import Modal from "../../../../../components/modal/Modal";
import { getUsermessageHistoryByDate } from "../../../../../utils/apiCalls/admin/user/fetchUserMessageHistory";
import { getUserPayments } from "../../../../../utils/apiCalls/admin/user/fetchUserPayments";
import axios from "../../../../../utils/axios";
import ConfirmationBox from "../../../../../components/confirmationBox/ConfirmationBox";
import SenderIDAction from "./senderIDAction";
import SendAlert from "../../../../../utils/Alert";

const SenderIdLayout = () => {
  let PageSize = 15;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);

  // const showCalendar = useSelector((state)=>state.transactions.showCalendar)
  const [allSenderIds, setAllSenderIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(false);
  const [actionResponse, setActionResponse] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [senderAction, setSenderAction] = useState({});
  const [sender_type, setSenderType] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (!action) {
      setLoading(true);
      axios
        .get(`/admin/sender_id/${status === "all" ? status : `${status}/view`}`)
        .then((response) => {
          const { data } = response.data;
          setAllSenderIds(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    }
  }, [status, action]);

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  useEffect(() => {
    if (error === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [error]);

  useEffect(() => {
    if (actionResponse) {
      setActionLoading(true);
      setOpenDialogBox(true);
    }
  }, [actionResponse]);

  useEffect(() => {
    if (confirmation) {
      const data = {
        sender_id: senderAction.sender_id,
        status: actionResponse,
        sender_type,
      };
      axios
        .post("/admin/sender_id/update", data)
        .then((response) => {
          setAlertStatus({
            alertMessage: response.data.message,
            alertType: "success",
            openAlert: true,
          });
          setActionLoading(false);
          setOpenDialogBox(false);
          setConfirmation(false);
          setAction(false);
          setSenderType("");
          setActionResponse("");
        })
        .catch((error) => {
          setAlertStatus({
            alertMessage: `Error verifying transactions`,
            alertType: "error",
            openAlert: true,
          });
          setActionLoading(false);
          setConfirmation(false);
          setOpenDialogBox(false);
          setAction(false);
          setSenderType("");
          setActionResponse("");
        });
    }
  }, [confirmation]);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allSenderIds && allSenderIds.slice(firstPageIndex, lastPageIndex);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const getData = () => {
    let dd = [];
    allSenderIds.map((data, index) => {
      dd.push({
        Reference: data.reference,
        Amount: data.amount,
        Bank: data?.bank,
        "Payment Date": data.payment_date,
        Channel: data.channel,
      });
    });
    return dd;
  };

  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={`${styles.tableDetails}`}>
          <div className="flex items-center">
            <p className="text-lg font-bold ml-2">Sender IDs</p>
          </div>
          <div className={styles.tableActions}>
            {allSenderIds?.length > 0 && (
              <CSVLink
                data={getData()}
                filename={`XMS_${status}_sender_report [Sender_Id].csv`}
                style={{ textDecoration: "none" }}
              >
                <button className={styles.downloadBtn}>
                  <Download />
                  <b>Download</b>
                </button>
              </CSVLink>
            )}
            <button className={styles.carlendarBtn}>
              <FormControl variant="standard" fullWidth className="">
                {/* <InputLabel id="demo-simple-select-label">Timeline</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Timeline"
                  className="self-center ml-[8px] text-center w-5/6 "
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"approved"}>Approved</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"declined"}>Declined</MenuItem>
                </Select>
              </FormControl>
              {/* <img src={ArrowDown} alt="arrowDown" /> */}
            </button>
          </div>
        </div>
        {loading ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress sx={{ color: "#C4C4C4" }} />
          </Box>
        ) : allSenderIds.length === 0 ? (
          <center>
            <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
              Sender Ids not available
            </Alert>
          </center>
        ) : (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className={`${styles.customers} table-auto`}>
                <thead>
                  <tr>
                    <th style={{ fontSize: 15 }}>Name</th>
                    <th style={{ fontSize: 15 }}>Sender_ID</th>
                    <th style={{ fontSize: 15 }}>Sender_Type</th>
                    {status === "all" && (
                      <th style={{ fontSize: 15 }}>Status</th>
                    )}
                    <th style={{ fontSize: 15 }}>Use_case</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData()?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: 13 }}>{item.name}</td>
                      <td
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {item.sender_id}
                      </td>
                      <td style={{ fontSize: 13 }}>{item.sender_type}</td>
                      {status === "all" && (
                        <td
                          style={{
                            fontSize: 13,
                            color:
                              item.status === "approved"
                                ? "green"
                                : item.status === "declined"
                                ? "red"
                                : "",
                          }}
                        >
                          {item.status}
                        </td>
                      )}
                      <td style={{ fontSize: 13 }}>{item.use_case}</td>
                      <td>
                        <button
                          disabled={item.status === "approved"}
                          className={`px-5 py-1 text-white font-semibold ${
                            item.status === "approved" ||
                            item.status === "declined"
                              ? "bg-gray-600"
                              : "bg-green-700"
                          }`}
                          onClick={() => {
                            setSenderAction(item);
                            setAction(true);
                          }}
                        >
                          Action
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginationn
              currentPage={currentPage}
              totalCount={allSenderIds?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
      {action && (
        <SenderIDAction
          setActionResponse={setActionResponse}
          setAction={setAction}
          action={action}
          loading={actionLoading}
          setActionLoading={setActionLoading}
          setSenderType={setSenderType}
          setAlertStatus={setAlertStatus}
          senderType={sender_type}
        />
      )}

      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
        />
      )}

      <SendAlert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </div>
  );
};

export default SenderIdLayout;
