import { Skeleton } from "@mui/material";

const Loading = () => {
  return (
    <div className="h-screen">
      <div className="w-full mt-12 h-full border-4 px-5 py-10">
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={40}
          width="100%"
          style={{ marginBottom: 6 }}
        />
      </div>
    </div>
  );
};

export default Loading;
