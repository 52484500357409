import styles from "./EditProfile.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { COLORS, FONTSIZE } from "../../../../constants/theme";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import React, { useEffect, useState, useMemo } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../../../utils/Alert";
import { profileUpdate } from "../../../../utils/apiCalls/updateProfile";
import { getProfile } from "../../../../utils/apiCalls/getProfile";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useFormControl,
} from "@mui/material";
import { countries } from "../../../../utils/country";
import { sendOTPaction } from "../../../../utils/apiCalls/sendOTPaction";
import { clearprofileUpdateErrorMessage } from "../../../../redux/profile/profileSlice";

const { white, darkBlue, grey, primary, black } = COLORS;
const { px20, px14, px18, px36 } = FONTSIZE;

export default function EditProfile() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.getprofile);
  const { profileUpdated, profileUpdateLoad, profileUpdateError } = useSelector(
    (state) => state.profile
  );

  const sendOTP = useSelector((state) => state.sendOTP);
  //   const { first_name, last_name, email, company_name, sender_id } = data;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState(
    data?.getProfileData?.data?.email
  );
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  useEffect(() => {
    if (profileUpdated) {
      setAlertStatus({
        alertMessage: "Profile Update Successful",
        alertType: "success",
        openAlert: true,
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } else if (profileUpdateError !== "") {
      setAlertStatus({
        alertMessage: "Profile Update Failed, Phone Number exist",
        alertType: "error",
        openAlert: true,
      });
      dispatch(clearprofileUpdateErrorMessage());
    }
  }, [profileUpdated, profileUpdateError]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  function update() {
    if (phone === "" || phone === null) {
      setAlertStatus({
        alertMessage: "Input phone Number",
        alertType: "error",
        openAlert: true,
      });
    } else if (companyName === "" || companyName === null) {
      setAlertStatus({
        alertMessage: "Company field iis required",
        alertType: "error",
        openAlert: true,
      });
    } else {
      dispatch(
        profileUpdate({
          first_name: firstName,
          last_name: lastName,
          company_name: companyName,
          phone: phone,
        })
      );
    }
  }

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    setFirstName(data?.getProfileData?.data?.first_name);
    setLastName(data?.getProfileData?.data?.last_name);
    setCompanyName(data?.getProfileData?.data?.company_name);
    setPhone(data?.getProfileData?.data?.phone);
  }, [data.getProfileData]);

  const ariaLabel = { "aria-label": "description" };

  function MyFormHelperText() {
    const { focused } = useFormControl() || {};

    const helperText = useMemo(() => {
      if (focused) {
        return "We will send an OTP to this number";
      }

      return "";
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  return (
    <>
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
      <div
        className={styles.container}
        style={{
          background: white,
          padding: "2rem 4rem",
          width: "100%",
          margin: "0px auto 1rem",
          borderRadius: "12px",
        }}
      >
        <h1
          style={{ fontSize: px36, color: primary, marginBottom: "2.938rem" }}
        >
          Edit Profile
        </h1>
        {/* <div
          className={styles.nameNButtonWrapper}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            flexWrap: "wrap",
            marginBottom: "3.8rem",
            maxWidth: "900px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "1.688rem" }}>
              <img
                src="/images/profile.png"
                width="100px"
                height="100px"
                alt="profile"
              />
            </div>
            <div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: px20,
                  marginBottom: "1.25rem",
                  color: black,
                }}
              >
                Upload Profile Image
              </div>
              <input
                type="file"
                id="file"
                name="file"
                style={{ backgroundColor: white, fontSize: "px18" }}
              />
            </div>
          </div>
          <Button
            name="Upload Image"
            width="200px"
            height="max-content"
            marginTop="1rem"
          />
        </div> */}
        {/* {!data?.getProfileData?.data?.country && (
          <Autocomplete
            id="country-select-demo"
            style={{ marginTop: "1rem" }}
            className="w-[100%] lg:w-[237px]"
            options={countries}
            autoHighlight
            onChange={(e, value) => setCountry(value.label)}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                style={{ marginBottom: "2rem" }}
              />
            )}
          />
        )} */}
        {/* <div style={{ maxWidth: "900px" }}> */}
        <div>
          <span className={styles.fieldDetails}>
            <div className="w-full">
              <Input
                label="First Name"
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                mb="2rem"
                style={{ width: "100%" }}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="w-full">
              <Input
                label="Last Name"
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                mb="2rem"
                style={{ width: "100%" }}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </span>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
          <span className={styles.fieldDetails}>
            <div className="w-full">
              <Input
                label="Phone Number"
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone number"
                value={phone}
                mb="2rem"
                style={{ width: "100%" }}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="w-full">
              <span style={{ opacity: 0.5 }}>
                <Input
                  label="Email Address"
                  type="text"
                  id="emailAddress"
                  name="emailAddress"
                  placeholder="Email Address"
                  value={data?.getProfileData?.data?.email}
                  mb="2rem"
                  style={{ width: "100%" }}
                  //  onChange={(e) => setEmailAddress(e.target.value)}
                  disabled
                />
              </span>
            </div>
            {/* <LoadingButton
              // endIcon={<SendIcon />}
              loading={sendOTP.sendOTPLoad}
              // loadingPosition="end"
              variant="outlined"
              size="medium"
              // loadingIndicator="Loading..."
              style={{
                padding: "6px 6px",
                lineHeight: 1.5,
                textTransform: "none",
                height: 54,
                width: "13%",
                borderColor: sendOTP.sendOTPLoad ? "#808082" : "#4B1A7C",
                color: "#4B1A7C",
              }}
              onClick={() => dispatch(sendOTPaction())}
            >
              {!sendOTP.sendOTPLoad
                ? "Send OTP"
                : sendOTP.sendOTPDone
                ? "Resend OTP"
                : "Sending..."}
            </LoadingButton> */}
            {/* <Button variant="outlined" name="Send Code" /> */}
          </span>
          <span className={styles.fieldDetails}>
            <Input
              label="Organization Name"
              type="text"
              id="companyName"
              name="companyName"
              placeholder="Organization Name"
              value={companyName}
              mb="2rem"
              width="29.25rem"
              onChange={(e) => setCompanyName(e.target.value)}
              disabled
            />
          </span>

          {/* <InputLabel id="defaultSenderID-label">Default Sender ID</InputLabel>
          <Select
            labelId="defaultSenderID-label"
            value={defaultSenderID}
            onChange={(e) => setDefaultSenderID(e.target.value)}
            sx={{ minWidth: 200, color: "#000000" }}
            autoWidth
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {/* <MenuItem>Kounty</MenuItem> 
          </Select> */}
          {/* <Input
            // label="Default Sender ID"
            type="text"
            id="defaultSenderID"
            name="defaultSenderID"
            placeholder="Default Sender ID"
            value={defaultSenderID}
            mb="2rem"
            onChange={(e) => setDefaultSenderID(e.target.value)}
          /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "4rem",
            }}
          >
            <Button
              name="CANCEL"
              width="150px"
              height="max-content"
              marginTop="1rem"
              bc="transparent"
              tc="#000000"
              mr="1rem"
              b="1px solid #000000"
              onClick={() => navigate("/settings/profile")}
            />
            <Button
              name={!profileUpdateLoad ? "UPDATE" : "UPDATING"}
              width="150px"
              height="max-content"
              marginTop="1rem"
              onClick={update}
              style={{ opacity: profileUpdateLoad ? 0.5 : 1 }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
