import { Alert, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSignInErrorMessage } from "../../../../redux/admin/auth/signInSlice";
import { login } from "../../../../utils/apiCalls/admin/auth/login";
import { AdminLogout } from "../../../../utils/apiCalls/admin/auth/logout";
import SwitchLabels from "../../../components/muiComponents/switchToggle/SwitchLabels";
import styles from "./signIn.module.css";
import Toggle from "./Toggle";
import Button from "../../../components/Button/Button";

const SignIn = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonName, setButtonName] = useState("Sign In");
  const [canSubmit, setCanSubmit] = useState(false);

  const dispatch = useDispatch();

  const { signInLoading, signInError, signInData } = useSelector(
    (state) => state.adminSignIn
  );

  useEffect(() => {
    localStorage.userDetails && AdminLogout();
  }, []);

  useEffect(() => {
    setCanSubmit(true);
    let timer =
      signInError &&
      setTimeout(() => dispatch(clearSignInErrorMessage()), 3000);
    return () => clearTimeout(timer);
  }, [signInError]);

  useEffect(() => {
    const user = localStorage.userDetails;
    user && navigate("/admin");
  }, [localStorage.userDetails]);

  useEffect(() => {
    email !== "" && password !== "" ? setCanSubmit(true) : setCanSubmit(false);
  }, [email, password]);

  useEffect(() => {
    signInLoading ? setCanSubmit(false) : email !== "" && setCanSubmit(true); //if loading is true then setCan Submit to false else if email is not equal to empty then setcansubmit to true
    signInLoading
      ? setButtonName("Authenticating...")
      : setButtonName("Sign In");
  }, [signInLoading]);

  const submit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <>
      <div className={styles.bground}>
        <form onSubmit={submit} className={styles.centerBox}>
          <div className={styles.arrangement}>
            <span>
              <h1>Sign In</h1>
              <h5>Sign in as an admin</h5>
            </span>
            <div className={styles.center}>
              <input
                className={styles.input}
                id={styles.email}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                id={styles.password}
                className={styles.input}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <h4
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/AdminForgotPassword")}
              >
                Forgot password?
              </h4>
              {signInError && <Alert severity="error">{signInError}</Alert>}
            </div>
          </div>
          {/* <div className={styles.switchBox}>
            <SwitchLabels />
            <Toggle />
          </div> */}
          <div className={styles.buttonBox}>
            <button
              id={styles.button}
              style={{ opacity: !canSubmit ? 0.5 : 1 }}
              disabled={!canSubmit ? true : false}
            >
              {buttonName}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignIn;
