import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

import { FONTSIZE, COLORS } from "../../../constants/theme";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "../../../components/modal/Modal";
import UserType from "../../../components/userType/UserType";

const { black, white, primary } = COLORS;
const { px36, px24 } = FONTSIZE;

export default function Verify() {
  const [step, setCode] = useState("step1");
  const [verified, setVerified] = useState(false);
  const submitPhone = (e) => {
    e.preventDefault();

    setCode("step2");
  };
  function handleVerify(e) {
    e.preventDefault();
    setVerified(true);
  }
  return (
    <>
      {verified === true && (
        <Modal>
          <UserType />
        </Modal>
      )}
      {step === "step1" ? (
        <VerifyPhone submitPhone={submitPhone} />
      ) : (
        <VerifyCode verify={handleVerify} />
      )}
    </>
  );
}

const VerifyPhone = ({ submitPhone }) => {
  const [phone, setPhone] = useState("");

  return (
    <form
      style={{
        backgroundColor: white,
        minHeight: "85vh",
        borderRadius: "5px",
        padding: "4rem 2rem",
      }}
      //   onSubmit={(e) => submitPhone(e)}
    >
      <div style={{ marginBottom: "7rem" }}>
        <h1
          style={{
            color: black,
            fontWeight: 500,
            fontSize: px36,
            marginBottom: "2.938rem",
          }}
        >
          Verify Your phone number
        </h1>
        <p style={{ fontSize: px24 }}>
          Provide your phone number and we will send you an SMS verification
          code.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          maxWidth: "900px",
          justifyContent: "space-between",
          marginBottom: "11rem",
        }}
      >
        <Input
          type="text"
          id="phone"
          name="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Enter your phone number"
          bordert="0px"
          borderl="0px"
          borderr="0px"
          br="0px"
          maxWidth="444px"
          mr="1rem"
        />
        <Button name="NEXT" width="163px" onClick={submitPhone} />
      </div>
      <p style={{ fontSize: px24, marginBottom: "3rem" }}>
        Are you having problems verifiying your phone number?
      </p>
      <div style={{ fontSize: px24, color: primary, fontWeight: 600 }}>
        CONTACT US
      </div>
    </form>
  );
};

const VerifyCode = ({ verify }) => {
  let navigate = useNavigate();
  const [code, setCode] = useState("");

  return (
    <form
      style={{
        backgroundColor: white,
        minHeight: "85vh",
        borderRadius: "5px",
        padding: "4rem 2rem",
      }}
    >
      <div style={{ marginBottom: "7rem" }}>
        <h1
          style={{
            color: black,
            fontWeight: 500,
            fontSize: px36,
            marginBottom: "2.938rem",
          }}
        >
          Verify Your phone number
        </h1>
        <p style={{ fontSize: px24, marginBottom: "1.9rem" }}>
          A verification code has been sent to +234 810 234 4876
        </p>
        <p style={{ fontSize: px24, fontWeight: 500 }}>
          Please enter the code below
        </p>
      </div>
      <div
        style={{
          display: "flex",
          maxWidth: "900px",
          justifyContent: "space-between",
          marginBottom: "11rem",
        }}
      >
        <Input
          type="text"
          id="code"
          name="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter 5-digit code"
          bordert="0px"
          borderl="0px"
          borderr="0px"
          br="0px"
          maxWidth="444px"
          mr="1rem"
        />

        <Button name="NEXT" width="163px" onClick={verify} />
      </div>
      <div
        style={{
          fontSize: px24,
          color: primary,
          fontWeight: 600,
          marginBottom: "2rem",
        }}
      >
        RESEND VERIFICATION CODE
      </div>
      <div
        style={{
          fontSize: px24,
          color: primary,
          fontWeight: 600,
          marginBottom: "2rem",
        }}
      >
        CHANGE PHONE NUMBER
      </div>
      <div
        style={{
          fontSize: px24,
          color: primary,
          fontWeight: 600,
          marginBottom: "2rem",
        }}
      >
        CONTACT US
      </div>
    </form>
  );
};
