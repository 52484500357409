import styles from "./ChangePassword.module.css";
import Alert from "@mui/material/Alert";
import { COLORS, FONTSIZE } from "../../../../constants/theme";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { useState, useEffect, useRef } from "react";
import { ReactComponent as EyeHide } from "../../../../assets/eyehide.svg";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../../utils/apiCalls/changePassword";
import { clearchangePasswordErrorMessage } from "../../../../redux/auth/changePasswordSlice";
const { white, darkBlue, grey, primary, black } = COLORS;
const { px20, px14, px18, px36 } = FONTSIZE;

export default function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { changePasswordData, changePasswordLoad, changePasswordError } =
    useSelector((state) => state.changePassword);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updatePassword, setUpdatePassword] = useState(false);
  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const passwordRef = useRef("");

  useEffect(() => {
    let timer =
      changePasswordError &&
      setTimeout(() => dispatch(clearchangePasswordErrorMessage()), 3000);
    return () => clearTimeout(timer);
  }, [changePasswordError]);

  useEffect(() => {
    if (
      currentPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== ""
    ) {
      setUpdatePassword(true);
    } else {
      setUpdatePassword(false);
    }
  }, [currentPassword, confirmPassword, newPassword]);

  const showPassword = (id) => {
    switch (id) {
      case "current_password":
        currentPasswordType === "password"
          ? setCurrentPasswordType("text")
          : setCurrentPasswordType("password");
        break;
      case "new_password":
        newPasswordType === "password"
          ? setNewPasswordType("text")
          : setNewPasswordType("password");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div
        className={styles.container}
        style={{
          borderBottom: "5px solid #4B1A7C",
          margin: "0px auto 1rem",
          borderRadius: "5px",
        }}
      >
        <h1
          style={{
            fontSize: px36,
            color: primary,
            marginBottom: "1.538rem",
            fontWeight: "500",
          }}
        >
          Change Password
        </h1>

        <div
          style={{
            width: "100%",
          }}
        >
          <div className={styles.inputBox}>
            <Input
              label="Current Password"
              type={currentPasswordType}
              id="currentPassword"
              name="currentPassword"
              placeholder="Current Password"
              value={currentPassword}
              mb="1.5rem"
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <EyeHide
              onClick={() => showPassword("current_password")}
              id={styles.eye}
            />
          </div>
          <div className={styles.inputBox}>
            <Input
              label="New Password"
              type={newPasswordType}
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              value={newPassword}
              mb="1.5rem"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <EyeHide
              onClick={() => showPassword("new_password")}
              id={styles.eye}
            />
          </div>
          <div className={styles.inputBox}>
            <Input
              label="Confirm Password"
              type={newPasswordType}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              mb="1.5rem"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <EyeHide
              onClick={() => showPassword("new_password")}
              id={styles.eye}
            />
          </div>
          {changePasswordError && (
            <Alert severity="error" className="mb-8">
              {changePasswordError}
            </Alert>
          )}
          {changePasswordData && (
            <Alert severity="success" className="mb-8">
              {changePasswordData.message}
            </Alert>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1.85rem",
            }}
            className={styles.buttonSection}
          >
            <Button
              name="CANCEL"
              width="150px"
              // height="40px"
              marginTop="1rem"
              bc="transparent"
              tc="#000000"
              mr="1rem"
              b="1px solid #000000"
              style={{ borderRadius: 3, padding: "6px 20px" }}
              onClick={() => navigate(-1)}
            />
            <Button
              name={changePasswordLoad === true ? "UPDATING" : "UPDATE"}
              width="150px"
              marginTop="1rem"
              style={{
                borderRadius: 5,
                // opacity: changePasswordLoad === true ? 0.5 : 1,
                opacity: !updatePassword ? 0.5 : 1,
                padding: "10px 20px",
              }}
              disabled={
                !newPassword &&
                !confirmPassword.length &&
                !currentPassword.length
              }
              onClick={() =>
                dispatch(
                  changePassword({
                    password: currentPassword,
                    new_password: newPassword,
                    confirm_password: confirmPassword,
                  })
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
