import React from 'react'
import styles from "./settings.module.css"

const UserDetails = ({Fname,Email,Phone,SenderID,Location}) => {
  return (
    <>
      <div className={styles.userCredentials}>
        <big>Profile Information</big>
        <div className={styles.detailbox}>
          <span className={styles.nameField}>
            <h3>Full Name:</h3>
            <h3>Email Address:</h3>
            <h3>Phone Number:</h3>
            <h3>Sender ID:</h3>
            <h3>Location:</h3>
          </span>
          <span id={styles.values}>
            <h4>{Fname}</h4>
            {/* <h4>{Lname}</h4> */}
            <h4>{Email}</h4>
            <h4>{Phone}</h4>
            <h4>{SenderID}</h4>
            <h4>{Location}</h4>
          </span>
        </div>
      </div>
    </>
  );
}

export default UserDetails