import React, { useState } from "react";
import styles from "./footer.module.css";
import FormGroup from "../form/FormGroup";
import HiddenBox from "./HiddenBox";
import { Fade, Slide } from "react-reveal";
import { useDispatch, useSelector } from "react-redux";
import { SetRate, SetVolume } from "../../../redux/calculateRate/calculateRate";

const Footer = () => {
  const [show, setShow] = useState(false);
  const [showCalc, setShowCalc] = useState(true);

  const dispatch = useDispatch();

  const calculateRate = useSelector((state) => state.calculateRate);

  const [volume, setVolume] = useState();

  const calculate = () => {
    if (calculateRate.type !== null && volume) {
      dispatch(SetVolume(volume));

      let volumeRate;

      calculateRate.type === "promotional"
        ? (volumeRate = 2.87 * volume)
        : (volumeRate = 3 * volume);

      dispatch(SetRate(volumeRate));
      setShowCalc(false);
      setShow(true);
    }
  };

  return (
    <div className={styles.footerWrap} id="pricing">
      <div className={styles.topFooter}>
        <Slide left>
          <div id={styles.footTexts}>
            <h2>Get a quick estimate of your bulk SMS pricing</h2>
            <p>
              No contracts, no hidden fees and easy to use.
              You can also top up or upgrade anytime!
            </p>
          </div>
        </Slide>
        <Slide right>
          <div className={styles.blurBackground}>
            <div className={styles.infoBox}>
              {showCalc && (
                <div id={styles.form}>
                  <span id={styles.priceCal}>
                    <h2>Price Calculator</h2>
                  </span>
                  <div id={styles.volumeBox}>
                    <label>Volume per month</label>
                    <input
                      id={styles.volume}
                      type="text"
                      placeholder="Enter volume"
                      value={volume}
                      onChange={(e) => setVolume(e.target.value)}
                    />
                  </div>
                  {/* <div id={styles.country}> */}
                  {/* <label>Destination</label> */}
                  {/* <CountrySelect /> */}
                  {/*/<CountrySelector /> */}
                  {/* </div>. */}
                  <div className={styles.formWrap}>
                    <FormGroup />
                  </div>
                  <div className={styles.btnBox}>
                    <button id={styles.btnn} onClick={calculate}>
                      Get Estimate
                    </button>
                  </div>
                </div>
              )}
              {show && (
                <Fade when={show}>
                  <div className={styles.hiddenBox}>
                    {<HiddenBox closeModal={setShow} showCalc={setShowCalc} />}
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </Slide>
      </div>

      <div className={styles.mobileView}>
        <Slide left>
          <div id={styles.footTexts}>
            <h2>Simple and transparent pricing</h2>
            <p>
              No contracts, no hidden fees and easy to use. <br />
              You can also top up or upgrade anytime!
            </p>
          </div>
        </Slide>
        <Slide right>
          <div id={styles.formMobile}>
            {showCalc && (
              <>
                <span id={styles.priceCal}>
                  <h2>Price Calculator</h2>
                </span>
                <div id={styles.volumeBox}>
                  <h5>Volume per month</h5>
                  <input
                    id={styles.volume}
                    type="text"
                    placeholder="Enter volume"
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </div>
                <div className={styles.formWrap}>
                  <FormGroup />
                </div>
                <div className={styles.btnBox}>
                  <button id={styles.btnn} onClick={calculate}>
                    get Estimate
                  </button>
                </div>
              </>
            )}
            {show && (
              <Fade when={show}>
                <div className={styles.hiddenBox}>
                  {<HiddenBox closeModal={setShow} showCalc={setShowCalc} />}
                </div>
              </Fade>
            )}
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Footer;
