import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  recentTransactions: null,
  failed: null,
};

const RecentTransactionSlice = createSlice({
  name: "Recent Transaction Slice",
  initialState,
  reducers: {
    getRecentTransactionsLoading: (state) => {
      state.loading = true;
    },
    getRecentTransactionsSuccess: (state, { payload }) => {
      state.recentTransactions = payload;
      state.failed = null;
      state.loading = false;
    },
    getRecentTransactionsFailed: (state, { payload }) => {
      state.recentTransactions = null;
      state.failed = payload;
      state.loading = false;
    },
  },
});

export const {
  getRecentTransactionsFailed,
  getRecentTransactionsLoading,
  getRecentTransactionsSuccess,
} = RecentTransactionSlice.actions;
export default RecentTransactionSlice.reducer;
