import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addChannel, toggleWarning } from "../../redux/sms/messageSummarySlice";
import { getWallet } from "../../utils/apiCalls/getWallet";
import ConfirmCard from "../confirmCard/ConfirmCard";
import Modal from "../modal/Modal";
import styles from "./SelectAccount.module.css";
import closeCircle from "../../assets/closeCircle.svg"

function SelectAccount({close}) {
  const dispatch = useDispatch()
  
  const [channel, setChannel] = useState("");

  useEffect(() => {
    dispatch(getWallet());
  }, []);
  const wallet = useSelector((state)=>state.wallet.walletData)
  const showWarning = useSelector(state=>state.summary.showWarning)
  
  function handleChange() {  
    dispatch(addChannel(channel))
    dispatch(toggleWarning())
  }
  
  return (
    <>
      {showWarning === true && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ConfirmCard close={() => dispatch(toggleWarning())} type="sms" />
        </Modal>
      )}
      <div className={styles.selectContainer}>
        <img onClick={close}  src={closeCircle} width={25} style={{alignSelf: "flex-end", marginTop: -60, marginRight: -60, marginBottom: 20, cursor: "pointer"}} alt="close" />
        <header>
          <h1>Select payment account</h1>
        </header>
        <h3>Pick from which account you want your SMS to be charged from.</h3>
        <div className={styles.selectType}>
          <div className={styles.radioType}>
            <div className={styles.radioBox}>
              <input
                type="radio"
                name="payment_wallet"
                value={channel}
                onChange={(e)=>setChannel("wallet")}
              />
              <div className={styles.option}>
                <b>Xms wallet</b>
                <p>{wallet?.wallet_balace}</p>
              </div>
            </div>

            <div className={styles.radioBox}>
              <input
                type="radio"
                name="payment_wallet"
                value={channel}
                onChange={(e)=>setChannel("unit")}
              />
              <div className={styles.option}>
                <b>xUnit wallet</b>
                <p>{wallet?.unit_balance}</p>
              </div>
            </div>
            
          </div>
          
        </div>
        {/* <div style={{display: "flex"}}> */}
          {/* <button onClick={close} className={styles.saveBtn}>CLOSE</button> */}
          <button onClick={()=>handleChange()} className={styles.saveBtn}>PROCEED</button>
        {/* </div> */}
        
      </div>
    </>
  );
}

export default SelectAccount;
