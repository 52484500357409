import React, { useEffect, useState } from "react";
import Navbar2 from "../components/nav/Navbar2";
import Navbar from "../components/nav/Navbar";
import rightArrow from "../assets/rightArrow.svg";
import styles from "./contact.module.css";
import BottomFooter from "../components/footer/BottomFooter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import man from "../assets/man.png"
import axios from "../../utils/axios";
import Alertt from "../../utils/Alert";


const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState(false);
  const [agreeError, setShowAgreeError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const details = [
    {
      Title: "Full name",
      Placeholder: "Full Name",
    },
    {
      Title: "Email",
      Placeholder: "Email",
    },
    {
      Title: "Company name",
      Placeholder: "Company Name",
    },
  ];

  useEffect(() => {
    if (
      fullName !== "" &&
      email !== "" &&
      message !== "" &&
      company !== "" &&
      agree
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [fullName, email, message, company, agree]);

  const submit = (e) => {
    e.preventDefault();
    if (fullName === "" || email === "" || message === "" || company === "") {
      setError(true);
    } else if (agree === false) {
      setShowAgreeError(true);
    } else {
      // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
      axios
        .post("user/contact-us", {
          full_name: fullName,
          company: company,
          email: email,
          message: message,
          country: "",
        })
        .then((response) => {
          setShowSuccess(true);
          setFullName("");
          setEmail("");
          setCompany("");
          setMessage("");
          setAgree(false);
          setError(false);
          setShowAgreeError(false);
          setTimeout(() => {
            setShowSuccess(false);
          }, 500);
        });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccess(false);
  };

  return (
    <>
      <Alertt
        handleClose={handleClose}
        openAlert={showSuccess}
        Text="Your message has been sent successfully."
        Type="success"
      />
      <div className={styles.faqWrap}>
        <Navbar2 />

        <div id={styles.need}>
          <big>Need to talk?</big>
          <small>
            Fill in the form and one of our agents will be in touch.
          </small>
        </div>
        <form onSubmit={(e) => submit(e)}>
          <div className={styles.childContainer}>
            <div className={styles.detailWrap}>
              {error && (
                <p style={{ color: "red", fontWeight: 800 }}>
                  Error: All fields are important
                </p>
              )}
              <div className={styles.details}>
                <big>Full Name</big>
                <input
                  type="text"
                  placeholder="Full name"
                  id={styles.input}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className={styles.details}>
                <big>Email</big>
                <input
                  type="email"
                  placeholder="Email"
                  id={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className={styles.details}>
                <big>Company Name</big>
                <input
                  type="text"
                  placeholder="Company name"
                  id={styles.input}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>

              <div className={styles.details}>
                <big>Message</big>
                <textarea
                  type="text"
                  placeholder="Leave a message"
                  id={styles.input}
                  style={{ minHeight: "120px" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.manImage}>
              <img src={man} alt="manImage" />
            </div>
          </div>

          <FormGroup className={styles.formBox}>
            <span id={styles.promotional}>
              <FormControlLabel
                control={<Checkbox />}
                value="agree"
                onChange={(e) => setAgree(e.target.checked)}
              />
              <p>
                Please check this box if you're happy for us to contact you. To
                understand how we use this data, you can read our Privacy
                Policy.
              </p>
            </span>

            <span id={styles.btnBox} style={{ opacity: canSubmit ? 1 : 0.5 }}>
              <button disabled={!canSubmit}>Contact us</button>
            </span>
          </FormGroup>
        </form>
        <div>
          <BottomFooter />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Contact;
