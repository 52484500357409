import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendOTPLoad: false,
  otpResponse: null,
  sendOTPDone: false
};

export const sendOTPslice = createSlice({
  name: "sendOTP",
  initialState,
  reducers: {
    sendOTPStart: (state) => {
      state.sendOTPLoad = true;
    },
    sendOTPSuccess: (state, { payload }) => {
      state.sendOTPLoad = false;
      state.sendOTPDone = true
      state.otpResponse = payload;
    },
  },
});

export const { sendOTPStart, sendOTPSuccess } = sendOTPslice.actions;

export default sendOTPslice.reducer;



