import ConfirmationDialog from "../../assets/confirmationDialog.png";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

const ConfirmationBox = ({
  openDialogBox,
  setConfirmation,
  setOpenDialogBox,
  confirmation,
  text,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={openDialogBox}
      onClose={() => setOpenDialogBox(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        <div className="bg-white w-[350px] rounded p-[31px]">
          <div className="flex justify-center">
            <img
              src={ConfirmationDialog}
              alt="Dialog Warning"
              className="cursor-pointer"
            />
          </div>
          <p className="text-center">{text ? text : "Are you sure?"}</p>
          <div className="flex justify-between px-3 mt-7 w-full">
            <button
              onClick={() => setOpenDialogBox(false)}
              className="bg-[#C4C4C4] font-bold py-2 px-5 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() => setConfirmation(true)}
              className="text-white bg-[#4B1A7C] font-bold py-2 w-28 rounded-md"
              type="submit"
            >
              {confirmation ? <CircularProgress size={20} /> : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationBox;
