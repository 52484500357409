import {
  MessageDeliveredData,
  RecentTransactions,
} from "../../../../redux/admin/users/SingleUserInfoSlice";
import {
  BalanceDetailsData,
  CustomPriceData,
  FailedData,
  FetchingData,
  MessageHistoryData,
  RecentPayment,
  StopFetchingData,
  UserDetailsData,
} from "../../../../redux/admin/users/SingleUserInfoSlice";
import axios from "../../../axios";

export const FetchSingleUserInfo =
  (email, startDate, endDate, perpage, ref) => async (dispatch) => {
    dispatch(FetchingData());

    try {
      // Fetch user details
      const userDetailsResponse = await axios.get(
        `/admin/user/details/${email}`
      );
      const { data: userDetails } = userDetailsResponse.data;
      dispatch(UserDetailsData(userDetails));

      // Fetch balance details
      const balanceDetailsResponse = await axios.get(
        `/admin/user/${email}/details`
      );
      const { data: balanceDetails } = balanceDetailsResponse.data;
      dispatch(BalanceDetailsData(balanceDetails));

      // Fetch recent payment
      const recentPaymentResponse = await axios.get(
        `/admin/payment/${email}/recent`
      );
      const recentPayment = recentPaymentResponse.data;
      dispatch(RecentPayment(recentPayment));

      // Fetch recent transactions
      const recentTransactionsResponse = await axios.get(
        `/admin/trans/${email}/recent`
      );
      const recentTransactionsData = recentTransactionsResponse.data;
      dispatch(RecentTransactions(recentTransactionsData));

      // Fetch message history
      const messageHistoryResponse = await axios.get(
        `/admin/user/sms-history/${email}?startDate=${startDate}&endDate=${endDate}&perpage=${perpage}`
      );
      const { data: messageHistory } = messageHistoryResponse.data;
      dispatch(MessageHistoryData(messageHistory));

      // Fetch message delivered
      const messageDelivery = await axios.get(
        `/admin/user/sms-delivery/${email}/${ref}`
      );
      const { data: messageStats } = messageDelivery.data;
      dispatch(MessageDeliveredData(messageStats));

      // Fetch custom price
      const customPriceResponse = await axios.get(`/admin/user/custom-price`);
      const { data: customPrice } = customPriceResponse.data;
      dispatch(CustomPriceData(customPrice));

      dispatch(StopFetchingData());
    } catch (error) {
      console.error("Error in FetchSingleUserInfo:", error);
      const { message } = error.response?.data || { message: "Unknown error" };
      dispatch(FailedData(message));
      dispatch(StopFetchingData());
    }
  };
