import React, { useState } from "react";
import codeCircle from "../../assets/codeCircle.svg";
import briefcase from "../../assets/briefcase.svg";
import styles from "./midSection.module.css";
import Tiles from "./Tiles";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import prevArrow from "../../assets/prevArrow.svg";
import nextArrow from "../../assets/nextArrow.svg";

const MidSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevCard = () => {
    if (activeIndex === 0) {
      setActiveIndex(1);
    } else {
      setActiveIndex(0);
    }
  };

  const handleNextCard = () => {
    if (activeIndex === 0) {
      setActiveIndex(1);
    } else {
      setActiveIndex(0);
    }
  };

  const carouselTranslate = activeIndex === 0 ? 0 : -100;

  return (
    <div className={styles.midWrap}>
      <div className={styles.left}>
        <Slide left>
          <Fade>
            <h3 className={styles.header}>FOR BUSINESSES AND DEVELOPERS</h3>
          </Fade>
        </Slide>
        <div className={styles.arrowButtons}>
          <button className={styles.prevButton} onClick={handlePrevCard}>
            <img src={prevArrow} alt="Previous" />
          </button>
          <button className={styles.nextButton} onClick={handleNextCard}>
            <img src={nextArrow} alt="Next" />
          </button>
        </div>
      </div>

      <div className={styles.carouselContainer}>
        <div
          className={styles.carousel}
          style={{ transform: `translateX(${carouselTranslate}%)` }}
        >
          <Slide right>
            <div
              className={`${styles.tiles} ${
                activeIndex === 0 ? styles.active : ""
              }`}
            >
              <Tiles
                style={{ background: "#FFBA4F" }}
                Svg={codeCircle}
                Title={"For Developers"}
                Details="With our single API integration, you will be
                able to deliver scalable messaging from
                your systems straight to your users fast
                and securely."
                className={styles.btn1}
              />
            </div>
          </Slide>
          <Slide right>
            <div
              className={`${styles.tiles} ${
                activeIndex === 1 ? styles.active : ""
              }`}
            >
              <Tiles
                style={{ background: "#EEA0B2" }}
                Svg={briefcase}
                Title={"For Businesses"}
                Details="With our powerful messaging solutions,
              you'll never have to worry about reaching
              your customers because we'll take care of
              that for you."
                className={styles.btn2}
              />
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default MidSection;
