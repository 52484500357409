const formatPrice = (price, symbol) => {
  return `${symbol}${Number(price)
    .toFixed(symbol ? 2 : 0)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};

export const formatFloat = (value) => {
  return Number(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const formatPriceByCountrySymbol = (country, price) => {
  let symbol = "₦";
  switch (country) {
    case "Nigeria":
      break;

    case "Uganda":
      symbol = "UGX";
      break;

    case "Ghana":
      symbol = "GH₵";
      break;

    case "Zambia":
      symbol = "ZK";
      break;

    case "Mozanbique":
      symbol = "MT";
      break;

    default:
      symbol = "";
      break;
  }
  return formatPrice(price, symbol);
};
