import { getRateStart, getRateSuccess } from "../../redux/wallet/rateSlice"
import axios from "../axios";

export const getRates = () => async (dispatch) => {
    dispatch(getRateStart("/user/unit/user-rate"));
    await axios
      .get("user/unit/user-rate")
      .then((response) => {
        const { data } = response;
        dispatch(getRateSuccess(data.message));
      })
      .catch((error) => {});
}