import { CircularProgress, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationBox from "../../../../../components/confirmationBox/ConfirmationBox";
import { updateRateReset } from "../../../../../redux/admin/users/userRateSlice";
import { UserRate } from "../../../../../utils/apiCalls/admin/user/UserRate";
import Close from "../../../../assets/closeCircle.svg";

const EditPrice = ({
  openPriceBox,
  email,
  setOpenPriceBox,
  trRate,
  prRate,
  setAlertStatus,
  onPriceUpdate,
}) => {
  const dispatch = useDispatch();
  const [newTrRate, setNewTrRate] = useState(trRate);
  const [newPrRate, setNewPrRate] = useState(prRate);

  const { loading, status, message } = useSelector(
    (state) => state.updateUserRate
  );

  const [rate, setRate] = useState({
    transactional: trRate || 0,
    promotional: prRate || 0,
  });

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [type, setType] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const newBalanceDetails = {
      transactional: newTrRate,
      promotional: newPrRate,
    };
    onPriceUpdate(newBalanceDetails);
    setOpenPriceBox(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (confirmation) {
      const data = {
        email,
        pr_rate: rate.promotional,
        tr_rate: rate.transactional,
      };
      dispatch(UserRate(data, type === "update" ? "update" : ""));
    }
  }, [confirmation]);

  useEffect(() => {
    if (message) {
      setAlertStatus({
        alertMessage: message,
        alertType: status === "success" ? "success" : "error",
        openAlert: true,
      });
      setTimeout(() => {
        dispatch(updateRateReset());
        setOpenDialogBox(false);
        setConfirmation(false);
        setOpenPriceBox(false);
      }, 2000);
      onPriceUpdate(newPrRate, rate.transactional);
    }
  }, [loading]);

  return (
    <div>
      <Modal
        open={openPriceBox}
        onClose={() => setOpenPriceBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="w-[477px]">
          <div className="bg-white w-full rounded-xl">
            <div className="flex justify-between items-center py-3 border-b-2 px-[31px]">
              <h3 className="font-semibold text-gray-700">
                Edit price for {email}
              </h3>
              <img
                src={Close}
                alt="close the side panel"
                onClick={() => setOpenPriceBox(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="w-full px-[31px] bg-gray-100 pb-4 rounded-xl">
              <div className="">
                <div className="w-full pt-5 mb-7">
                  <label
                    className="block tracking-wide font-bold text-gray-800 my-4"
                    htmlFor="promotional-rate"
                  >
                    Promotional Rate
                  </label>
                  <input
                    name="promotional-rate"
                    className="w-full p-1 mb-7"
                    // value={newPrRate}
                    // onChange={(e) => setNewPrRate(e.target.value)}
                    // placeholder="Enter the new transactional rate"
                    // required
                    value={rate.promotional}
                    onChange={(e) =>
                      setRate({
                        ...rate,
                        promotional: e.target.value,
                      })
                    }
                  />
                  <label
                    className="block tracking-wide font-bold text-gray-800 mb-2"
                    htmlFor="transactional-rate"
                  >
                    Transactional Rate
                  </label>
                  <input
                    name="transactional-rate"
                    className="w-full p-1"
                    // value={newTrRate}
                    // onChange={(e) => setNewTrRate(e.target.value)}
                    // placeholder="Enter the new transactional rate"
                    // required
                    value={rate.transactional}
                    onChange={(e) =>
                      setRate({
                        ...rate,
                        transactional: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex justify-between w-full py-3">
                <div className="border rounded-md border-[#D11A2A]">
                  <button
                    onClick={() => setOpenDialogBox(true)}
                    className="font-bold px-4 py-1 text-[#D11A2A]"
                  >
                    Delete Price
                  </button>
                </div>
                <div className="border border-[#4B1A7C] rounded-md">
                  <button
                    className={`text-[#4B1A7C] font-bold py-1 px-4
                  `}
                    type="submit"
                    onClick={() => {
                      setType("update");
                      setOpenDialogBox(true);
                      handleSubmit();
                    }}
                  >
                    {loading ? <CircularProgress size={20} /> : "Update Price"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={"Are you sure you want to edit price?"}
        />
      )}
    </div>
  );
};

export default EditPrice;
