import React, { useState } from "react";
import styles from "./Teams.module.css";
// import Primaryfill from "../../../assets/Primaryfill.svg";
import RolesBox from "./RolesBox";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import "./viewModal.css"
import { useDispatch, useSelector } from "react-redux";

const AddTeam = () => {
  const dispatch = useDispatch()

  const canManageRoles = useSelector((state)=>state.createTeam.manage_roles)
  const canManageAccount = useSelector((state)=>state.createTeam.account_manager)
  const canManageFinance = useSelector((state)=>state.createTeam.finance_manager)
  const canManageComm = useSelector((state)=>state.createTeam.comm_manager)

  const [teamName, setTeamName] = useState("")
  const [teamDescription, setTeamDescription] = useState("")

  
  
  const data = {
    team_name: teamName,
    description: teamDescription,
    manage_roles: canManageRoles,
    account_manager: canManageAccount,
    finance_manager: canManageFinance,
    communication_manager: canManageComm
  }


  const tableData = [
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "few seconds ago",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "Never logged in",
    },
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "2 months ago",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "Few seconds ago",
    },
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "Never logged in",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "5 minutes ago",
    },
    {
      name: "John",
      email: "Hokage@gmail.com",
      status: "pending",
      activity: "2 months ago",
    },
    {
      name: "Kakashi",
      email: "Hokage@gmail.com",
      status: "active",
      activity: "2 months ago",
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.mainBox}>
        <h3>Create new team</h3>
        <div className={styles.userInfo}>
          <div className={styles.firstField}>
            <span>
              <h4>Name</h4>
              <input id={styles.userName} type="text" placeholder="Your Awesome Team name" onChange={(e)=>setTeamName(e.target.value)} />
            </span>
            <span>
              <h4>Description</h4>
              <input
                id={styles.teamDesc}
                type="text"
                placeholder="Description"
                onChange={(e)=>setTeamDescription(e.target.value)}
              />
            </span>
          </div>
        </div>

        <RolesBox />
        <div className={styles.transactionBox} style={{ width: "1080px" }}>
          <div
            className={styles.tableSection}
            style={{ border: "none", justifyContent: "space-between" }}
          >
            <div className={styles.sortSection}>
              <div className={styles.tableActions}>
                <button
                  className={styles.importBtn}
                  style={{ marginLeft: "700px" }}
                  // onClick={() => navigate("/people/add-user")}
                >
                  ADD USER
                </button>
              </div>
              <div></div>
              <button className={styles.sortBtn}>
                <Filter />
                Sort by
              </button>
            </div>
            <div style={{ overflowX: "auto" }}>
              <table className={styles.customers}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.status}</td>
                    <td>{item.activity}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeam;
