import React, { useEffect, useState } from "react";
import ConfirmCard from "../confirmCard/ConfirmCard";
import Modal from "../modal/Modal";
import styles from "./buyUnit.module.css";
import closeCircle from "../../assets/closeCircle.svg";
import { getWallet } from "../../utils/apiCalls/getWallet";
import { useDispatch, useSelector } from "react-redux";
import {
  formatPriceByCountrySymbol,
  formatFloat,
} from "../../utils/formatPrice";
import { getRates } from "../../utils/apiCalls/userRates";
import { COLORS } from "../../constants/theme";
import ConfirmBuyUnit from "../confirmCard/ConfirmBuyUnit";
function BuyUnit({ closeModal }) {
  const [showWarning, setShowWarning] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [unit, setUnit] = useState();
  const [unitAmount, setUnitAmount] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [unitBalance, setUnitBalance] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWallet());
    dispatch(getRates());
  }, []);
  const wallet = useSelector((state) => state.wallet.walletData);
  const userRate = useSelector((state) => state.userRates.rateData);


  useEffect(() => {
    setUnit((unitAmount / userRate?.promtional_rate).toFixed(2));
  }, [unitAmount]);


  useEffect(() => {
    setWalletBalance(wallet?.wallet_balance);
    setUnitBalance(wallet?.unit_balance);
  }, [wallet]);

  function handleChange(e) {
    e.preventDefault();
    if (+unitAmount > walletBalance) {
      setShowAlert("Insufficient balance");
    } else if (unitAmount < 1) {
      setShowAlert("Amount cannot be less than 1");
    } else {
      setShowWarning(true);
    }
  }

  return (
    <>
      {showWarning && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ConfirmBuyUnit
            message={`You are about to buy ${unit} units.`}
            amount={unitAmount}
            close={() => setShowWarning(false)}
          />
        </Modal>
      )}
      <div className={styles.transferCardContainer}>
        <div className={styles.bttns}>
          <h3>Buy xUnits</h3>
          <img
            src={closeCircle}
            alt=""
            id={styles.closeBtnn}
            onClick={() => closeModal(false)}
          />
        </div>
        <p style={{ color: COLORS.grey, fontSize: 12 }}>
          Convert all or part of your wallet balance to xUnits. Message costs
          are deducted from xUnit balance.
        </p>
        {showAlert !== "" ? (
          <p style={{ color: "red", fontSize: 12 }}>{showAlert}</p>
        ) : (
          ""
        )}

        {/* showAlert !=="" && <p style={{ color: "red" }}>{alert}</p> */}

        <form onSubmit={(e) => handleChange(e)}>
          <div className={styles.available}>
            <span id={styles.available}>
              Available:{""}
              {formatPriceByCountrySymbol("Nigeria", walletBalance ?? 0)} |
            </span>
            <span
              id={styles.available}
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => setUnitAmount(walletBalance)}
            >
              Use all
            </span>
          </div>
          <div className={styles.details}>
            <div className={styles.row}>
              <p>Amount In Naira</p>
              <input
                type="number"
                min={0}
                max={walletBalance}
                // step="0.01"
                // id={styles.inputOutline1}
                value={unitAmount}
                placeholder={0}
                onChange={(e) => setUnitAmount(e.target.value)}
              />
            </div>
            {unitAmount > 1 && (
              <div className={styles.unitRow}>
                <span id={styles.unitLine}>
                  {formatPriceByCountrySymbol("Nigeria", unitAmount)} =
                </span>
                <span
                  id={styles.unitLine}
                  style={{ fontWeight: 600 }}
                >{`${formatFloat(unit)} xUnits`}</span>
              </div>
            )}
            <span className={styles.Btnrow}>
              {/* <small>
                NOTE: The cost of your messages are deducted from your xUnit
                balance.
              </small> */}
              <button id={styles.bttn}>BUY</button>
            </span>
          </div>
        </form>
      </div>
    </>
  );
}

export default BuyUnit;
