import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  Data: [],
  getScheduledSmsError: false,
  errorExist: false,
  getScheduledSmsErrorMessage: null,
  Success: false,
  getScheduledErrorMessage: "",
  deleteLoading: false,
  deleteSuccess: false,
  sendLoading: false,
  sendSuccess: false,
  deleteSuccessMessage: "",
  sendSuccessMessage: ""
};

export const scheduledSmsSlice = createSlice({
  name: "scheduled-sms",
  initialState,
  reducers: {
    getScheduledSmsStart: (state) => {
      state.loading = true;
    },
    getScheduledSmsSuccess: (state, { payload }) => {
      state.loading = false;
      state.Data = payload;
      state.Success = true;
      state.getScheduledSmsErrorMessage = null;
      state.errorExist = false;
    },
    getScheduledSmsFail: (state, { payload }) => {
      state.loading = false;
      state.Success = false;
      state.Data = [];
      // state.errorExist = true;
      state.getScheduledErrorMessage = payload;
    },
    deleteScheduledSmsStart: (state) => {
      state.deleteLoading = true;
    },
    deleteScheduledSmsSuccess: (state, { payload }) => {
      state.deleteLoading = false;
      state.deleteSuccess = true;
      state.deleteSuccessMessage = payload
    },
    sendScheduledSmsStart: (state) => {
      state.sendLoading = true;
    },
    sendScheduledSmsSuccess: (state, { payload }) => {
      state.sendLoading = false;
      state.sendSuccess = true;
      state.sendSuccessMessage = payload;
    },
    resetStatus: (state) => {
      state.sendSuccess = false;
      state.deleteSuccess = false;
    }

  },
});
// Action creators are generated for each case reducer function
export const {
  getScheduledSmsStart,
  getScheduledSmsSuccess,
  getScheduledSmsFail,
  deleteScheduledSmsStart,
  deleteScheduledSmsSuccess,
  sendScheduledSmsStart,
  sendScheduledSmsSuccess,
  resetStatus,
} = scheduledSmsSlice.actions;

export default scheduledSmsSlice.reducer;
