import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SendEmail } from "../../../../../utils/apiCalls/admin/send/Email";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Close from "../../../../assets/closeCircle.svg";
import { EditorState, convertToRaw } from "draft-js";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  EmailFailed,
  EmailSent,
} from "../../../../../redux/admin/Send/sendEmail";
import Alert from "../../../../../utils/Alert";
import ConfirmationBox from "../../../../../components/confirmationBox/ConfirmationBox";

const EmailBox = ({ setSendEmail, sendEmail, email, name }) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [rawMessage, setRawMessage] = useState([]);
  const [emailSender, setEmailSender] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const dispatch = useDispatch();

  const { sendingLoading, sent, failed } = useSelector(
    (state) => state.sendEmailMessage
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (confirmation) {
      const data = {
        from: emailSender,
        email,
        message: rawMessage.join(" "),
        subject,
      };
      dispatch(SendEmail(data));
    }
  }, [confirmation]);

  const Timeout = () => {
    setTimeout(() => {
      setOpenDialogBox(false);
      setConfirmation(false);
      setSendEmail(false);
      dispatch(EmailSent(null));
      dispatch(EmailFailed(null));
    }, 2000);
  };

  useEffect(() => {
    if (sent) {
      setAlertStatus({
        alertMessage: sent,
        alertType: "success",
        openAlert: true,
      });
      Timeout();
    } else if (failed) {
      setAlertStatus({
        alertMessage: failed,
        alertType: "error",
        openAlert: true,
      });
      Timeout();
    }
  }, [sent, failed]);

  const sendEmailMessage = (e) => {
    e.preventDefault();
    const check = rawMessage.filter((text) => text !== "");
    if (check.length > 0 && sendingLoading === false) {
      if (emailSender && subject) {
        setOpenDialogBox(true);
      } else {
        setAlertStatus({
          alertMessage: "Sender type or subject invalid",
          alertType: "error",
          openAlert: true,
        });
      }
    }
  };

  const handleReactEditorInput = (state) => {
    setMessage(state);
  };

  useEffect(() => {
    if (message) {
      const newMessage = { ...convertToRaw(message.getCurrentContent()) };
      setRawMessage(newMessage.blocks.map((text) => text.text));
    }
  }, [message]);

  return (
    <>
      <Modal
        open={sendEmail}
        onClose={() => setSendEmail(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div className="bg-white w-full rounded p-[31px]">
            <div className="flex justify-between items-center">
              <h3 className="font-medium">Send email to {name}</h3>
              <img
                src={Close}
                alt="close the side panel"
                onClick={() => setSendEmail(false)}
                className="cursor-pointer"
              />
            </div>
            <form className="w-full">
              <div className="flex flex-wrap -mx-3 mb-6"></div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <div className="flex justify-between items-center mb-4">
                    <label
                      className="block tracking-wide font-light text-gray-700 text-xs text-[18px]"
                      htmlFor="grid-password"
                    >
                      Subject
                    </label>
                    <FormControl variant="standard" className="w-40">
                      <InputLabel id="demo-simple-select-helper-label">
                        Sender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select"
                        value={emailSender}
                        label="Timeline"
                        className=""
                        onChange={(e) => setEmailSender(e.target.value)}
                      >
                        <MenuItem value={"support"}>Support</MenuItem>
                        <MenuItem value={"self"}>My Email</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-email"
                    type="text"
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                    for="grid-password"
                  >
                    Message
                  </label>
                  <div>
                    <p className="my-2">Dear {name}</p>
                    <Editor
                      className="h-full"
                      editorStyle={{
                        border: "1px solid",
                        height: "150px",
                        padding: 10,
                      }}
                      editorState={message}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={handleReactEditorInput}
                    />
                  </div>
                  {/* <textarea
                  
                </div>
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="10"
                  className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                ></textarea> */}
                </div>
                <div className="flex justify-end px-3 mt-10 w-full">
                  <button
                    className={`w-full lg:w-[180px] bg-[#4B1A7C] shadow focus:shadow-outline rounded-md focus:outline-none text-white font-bold py-2 px-6 rounded ${
                      (rawMessage.filter((text) => text !== "").length < 1 ||
                        sendingLoading) &&
                      "opacity-30"
                    }`}
                    type="submit"
                    onClick={sendEmailMessage}
                  >
                    {sendingLoading ? <CircularProgress size={20} /> : "SEND"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={"Are you sure you want to send Sms?"}
        />
      )}
    </>
  );
};

export default EmailBox;
