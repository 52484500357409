import axios from "../axios";
import {
  registeringAdminSuccess,
  registeringAdminStart,
  registeringAdminFail,
  getAdminSuccess,
  getAdminStart,
} from "../../redux/admin/auth/addAdminSlice";

export const registerAdmin = (data) => async (dispatch) => {
  dispatch(registeringAdminStart());
  axios
    .post("/admin/register", data)
    .then((response) => {
      dispatch(registeringAdminSuccess());
    })
    .catch((error) => {
      dispatch(registeringAdminFail(error));
    });
};

export const getAdminUsers = () => async (dispatch) => {
  dispatch(getAdminStart());
  axios
    .get("/admin/all")
    .then((response) => {
      const { data } = response.data;
      dispatch(getAdminSuccess(data));
    })
    .catch((error) => {});
};
