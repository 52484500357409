import { FONTSIZE, COLORS } from "../../constants/theme";
import { useEffect, useState, useRef } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import { settings } from "../../topmenu";
import { listOne } from "../Sidebar/Sidebar";
import styles from "./TopBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FetchNotifications } from "../../utils/apiCalls/clients/fetchNotifications";
import ClientNotifcations from "./component/ClientNotifications";
const { white, greyBlack } = COLORS;
const { px14, px24 } = FONTSIZE;

export default function TopBar({ handeOpen }) {
  const dispatch = useDispatch();
  const ref = useRef();
  let navigate = useNavigate();
  let location = useLocation();

  const [showNotification, setShowNotification] = useState(false);

  // useEffect(() => {
  //   if (showNotification) {

  //   }
  // }, [showNotification]);

  const menuList =
    location.pathname.slice(1).split("/")[0] === "settings" ? settings : [];

  const { loading, singleNotification, allNotifications, failed } = useSelector(
    (state) => state.clientNotifications
  );

  // useEffect(() => {
  //   dispatch(FetchNotifications());
  // }, [singleNotification]);

  return (
    <div
      style={{
        backgroundColor: white,
        padding: "0rem 3rem 0 1rem",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        height: "80px",
        position: "sticky",
        top: 0,
        zIndex: 2,
        // overflowX: "none",
      }}
    >
      <button onClick={handeOpen} className={styles.btn}>
        <i className={`${styles.closeIcon} ri-menu-fill`}></i>
      </button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 700,
          fontSize: px24,
          color: greyBlack,
        }}
      >
        {window.location.pathname.includes("dashboard") && (
          <div
            style={{
              textDecoration: "none",
              borderBottom:
                window.location.pathname.includes("dashboard") &&
                "4px solid #4B1A7C",
              height: "100%",

              padding: "0 1rem",
              color: greyBlack,
              cursor: "pointer",
              marginRight: ".5rem",
              textTransform: "uppercase",
            }}
            className={styles.topBarNav}
          >
            {window.location.pathname.includes("dashboard") && "dashboard"}
          </div>
        )}

        {window.location.pathname.includes("instant-sms") && (
          <>
            <div
              style={{
                textDecoration: "none",
                borderBottom:
                  window.location.pathname.includes("instant-sms") &&
                  "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("instant-sms") && "SEND SMS"}
            </div>

            <div
              onClick={() => navigate("scheduled-sms")}
              style={{
                textDecoration: "none",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("instant-sms") &&
                "SCHEDULED SMS"}
            </div>

            <div
              onClick={() => navigate("history")}
              style={{
                textDecoration: "none",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("instant-sms") &&
                "SMS HISTORY"}
            </div>
          </>
        )}

        {window.location.pathname.includes("scheduled-sms") && (
          <>
            <div
              onClick={() => navigate("instant-sms")}
              style={{
                textDecoration: "none",
                borderBottom:
                  window.location.pathname.includes("instant-sms") &&
                  "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("scheduled-sms") && "SEND SMS"}
            </div>

            <div
              onClick={() => navigate("scheduled-sms")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("scheduled-sms") &&
                  "4px solid #4B1A7C",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("scheduled-sms") &&
                "SCHEDULED SMS"}
            </div>

            <div
              onClick={() => navigate("history")}
              style={{
                textDecoration: "none",
                borderBottom:
                  window.location.pathname.includes("history") &&
                  "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("scheduled-sms") &&
                "SMS HISTORY"}
            </div>
          </>
        )}

        {window.location.pathname.includes("history") && (
          <>
            <div
              onClick={() => navigate("instant-sms")}
              style={{
                textDecoration: "none",
                borderBottom:
                  window.location.pathname.includes("instant-sms") &&
                  "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("history") && "SEND SMS"}
            </div>

            <div
              onClick={() => navigate("scheduled-sms")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("scheduled-sms") &&
                  "4px solid #4B1A7C",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("history") && "SCHEDULED SMS"}
            </div>

            <div
              onClick={() => navigate("history")}
              style={{
                textDecoration: "none",
                borderBottom:
                  window.location.pathname.includes("history") &&
                  "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("history") && "SMS HISTORY"}
            </div>
          </>
        )}

        {window.location.pathname.includes("wallet") && (
          <>
            <div
              onClick={() => navigate("wallet")}
              style={{
                textDecoration: "none",
                borderBottom:
                  window.location.pathname === "/wallet" && "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("wallet") && "WALLET"}
            </div>

            <div
              onClick={() => navigate("wallet/addfunds")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("wallet/addfunds") &&
                  "4px solid #4B1A7C",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("wallet") && "ADD FUNDS"}
            </div>

            {/* <div
              onClick={() => navigate("fund-sms/plans")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("plans") &&
                  "4px solid #4B1A7C",
                height: "100%",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("fund-sms") && "PLANS"}
            </div> */}
          </>
        )}

        {window.location.pathname.includes("settings") && (
          <>
            <div
              onClick={() => navigate("settings/profile")}
              style={{
                textDecoration: "none",
                borderBottom: window.location.pathname.includes("profile")
                  ? "4px solid #4B1A7C"
                  : window.location.pathname.includes("edit") &&
                    "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("settings") && "PROFILE"}
            </div>

            <div
              onClick={() => navigate("settings/change-password")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("change-password") &&
                  "4px solid #4B1A7C",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("settings") &&
                "CHANGE PASSWORD"}
            </div>

            <div
              onClick={() => navigate("settings/api")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("api") &&
                  "4px solid #4B1A7C",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("settings") && "API"}
            </div>
            <div
              onClick={() => navigate("settings/sender-ids")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("sender-ids") &&
                  "4px solid #4B1A7C",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                // textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("settings") && "SENDER IDs"}
            </div>
          </>
        )}

        {window.location.pathname.includes("people") && (
          <>
            <div
              onClick={() => navigate("people/users")}
              style={{
                textDecoration: "none",
                borderBottom: window.location.pathname.includes("users")
                  ? "4px solid #4B1A7C"
                  : window.location.pathname.includes("add-users") &&
                    "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("people") && "USERS"}
            </div>

            <div
              onClick={() => navigate("people/teams")}
              style={{
                textDecoration: "none",
                borderBottom: window.location.pathname.includes("teams")
                  ? "4px solid #4B1A7C"
                  : window.location.pathname.includes("add-team") &&
                    "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("people") && "TEAMS"}
            </div>
          </>
        )}

        {window.location.pathname.includes("phonegroups") && (
          <>
            <div
              onClick={() => navigate("phonegroups")}
              style={{
                textDecoration: "none",
                borderBottom: window.location.pathname.includes("phonegroups")
                  ? "4px solid #4B1A7C"
                  : window.location.pathname.includes("edit") &&
                    "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("phonegroups") &&
                "PHONE GROUPS"}
            </div>

            {/* <div
              onClick={() => navigate("contacts/groups")}
              style={{
                textDecoration: "none",
                height: "100%",
                borderBottom:
                  window.location.pathname.includes("groups") &&
                  "4px solid #4B1A7C",
                height: "100%",
                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("contacts") && "GROUPS"}
            </div> */}
          </>
        )}
        {window.location.pathname.includes("resources") && (
          <>
            <div
              onClick={() => navigate("resources")}
              style={{
                textDecoration: "none",
                borderBottom: window.location.pathname.includes("resources")
                  ? "4px solid #4B1A7C"
                  : window.location.pathname.includes("resources") &&
                    "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("resources") && "USER GUIDE"}
            </div>
          </>
        )}
        {/* {window.location.pathname.includes("history") && (
          <>
            <div
              onClick={() => navigate("history")}
              style={{
                textDecoration: "none",
                borderBottom: window.location.pathname.includes("history")
                  ? "4px solid #4B1A7C"
                  : window.location.pathname.includes("history") &&
                    "4px solid #4B1A7C",
                height: "100%",

                padding: "0 1rem",
                color: greyBlack,
                cursor: "pointer",
                marginRight: ".5rem",
                textTransform: "uppercase",
              }}
              className={styles.topBarNav}
            >
              {window.location.pathname.includes("history") &&
                "MESSAGE HISTORY"}
            </div>
          </>
        )} */}
      </div>
      {location.pathname.slice(1).split("/")[0] === "verify-phone" ? (
        ""
      ) : (
        <div
          style={{ display: "flex", alignItems: "center", padding: "1rem 0" }}
        >
          {/* Uncomment this block when you are ready to work on notifications */}
          {/* <div className="relative">
            <button
              style={{ display: "flex" }}
              className="mr-4 text-2xl"
              onClick={() => setShowNotification((prev) => !prev)}
            >
              <i className="ri-notification-3-line"></i>
              {allNotifications.length > 0 && (
                <div
                  style={{
                    borderRadius: "50%",
                    background: "#BE2828",
                    position: "relative",
                    left: "-11px",
                  }}
                >
                  <p className="text-white text-xs px-1 font-bold">
                    {allNotifications.length}
                  </p>
                </div>
              )}
            </button>
            {showNotification && (
              <ClientNotifcations
                showNotification={showNotification}
                setShowNotification={setShowNotification}
                styles={styles}
              />
            )}
          </div> */}
          <div
            id={styles.userNameDashboard}
            style={{
              padding: ".6rem 1rem",
              // fontSize: px14,
              color: "#252733",
              fontWeight: 600,
              borderLeft: "1px solid #DFE0EB",
            }}
          >
            {JSON.parse(localStorage.userDetails)?.user?.first_name}{" "}
            {JSON.parse(localStorage.userDetails)?.user?.last_name}
          </div>
          {/* <button className="w-4 h-4 bg-[#4B1A7C40] rounded-[4px] flex items-center">
            <i className="ri-arrow-down-s-line"></i>
          </button> */}
        </div>
      )}
    </div>
  );
}
