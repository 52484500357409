// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB4hwYM01w8_kctTtCCL8i4z4OLbS26R4A",
  authDomain: "practice-212ad.firebaseapp.com",
  projectId: "practice-212ad",
  storageBucket: "practice-212ad.appspot.com",
  messagingSenderId: "363332610908",
  appId: "1:363332610908:web:f408e05c10932008e36f99",
  measurementId: "G-8V7YVC5N23",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
