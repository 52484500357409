import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationBox from "../../../../../components/confirmationBox/ConfirmationBox";
import {
  MessageFailed,
  MessageSent,
} from "../../../../../redux/admin/Send/sendSmsSlice";
import Alert from "../../../../../utils/Alert";
import { SendSMS } from "../../../../../utils/apiCalls/admin/send/SMS";
import Close from "../../../../assets/closeCircle.svg";

const MessageBox = ({ setSendMessage, sendMessage, phone, name }) => {
  const dispatch = useDispatch();

  const { sendingLoading, sent, failed } = useSelector(
    (state) => state.sendSms
  );

  const [message, setMessage] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  useEffect(() => {
    if (confirmation) {
      const data = {
        destination: phone,
        message,
      };

      dispatch(SendSMS(data));
    }
  }, [confirmation]);

  const sendSMS = (e) => {
    e.preventDefault();
    if (message && !sendingLoading) {
      setOpenDialogBox(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const Timeout = () => {
    setTimeout(() => {
      setOpenDialogBox(false);
      setConfirmation(false);
      setSendMessage(false);
      dispatch(MessageSent(null));
      dispatch(MessageFailed(null));
    }, 2000);
  };

  useEffect(() => {
    if (sent) {
      setAlertStatus({
        alertMessage: sent,
        alertType: "success",
        openAlert: true,
      });
      Timeout();
    } else if (failed) {
      setAlertStatus({
        alertMessage: failed,
        alertType: "error",
        openAlert: true,
      });
      Timeout();
    }
  }, [sent, failed]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={sendMessage}
        onClose={() => setSendMessage(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div className="bg-white w-full rounded p-[31px]">
            <div className="flex justify-between items-center">
              <h3 className="font-medium">Send SMS to {name}</h3>
              <img
                src={Close}
                alt="close the side panel"
                onClick={() => setSendMessage(false)}
                className="cursor-pointer"
              />
            </div>
            <form className="w-full">
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mt-5 h-72">
                  <label
                    className="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                    for="grid-password"
                  >
                    Message
                  </label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="10"
                    className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  ></textarea>
                </div>
                <div className="flex justify-end px-3 mt-10 w-full">
                  <button
                    className={`w-full lg:w-[180px] bg-[#4B1A7C] shadow focus:shadow-outline rounded-md focus:outline-none text-white font-bold py-2 px-6 rounded ${
                      (message === "" || sendingLoading) && "opacity-30"
                    }`}
                    type="submit"
                    onClick={sendSMS}
                  >
                    {sendingLoading ? <CircularProgress size={20} /> : "SEND"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={"Are you sure you want to send Sms?"}
        />
      )}
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
};

export default MessageBox;
