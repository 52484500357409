import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardAddedusers = () => {
  const navigate = useNavigate();
  const { addedUsers } = useSelector((state) => state.recentAddedUser);

  return (
    <div className="w-full rounded-xl shadow-md">
      <div className="mt-8 overflow-x-auto">
        <div className="inline-block min-w-full overflow-hidden bg-white px-11">
          <div>
            <div className="pb-6 border-b mt-16">
              <p className="text-[#747474] text-2xl font-bold">
                Recent Added Users{" "}
              </p>
            </div>
          </div>
          {addedUsers ? (
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Email
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Date
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {addedUsers &&
                  addedUsers.map((recent, index) => {
                    return (
                      <tr
                        className={index % 2 === 0 && "bg-[#E5E5E5]"}
                        key={index}
                        onClick={() =>
                          navigate("/admin/user-dashboard", {
                            state: { email: recent.email },
                          })
                        }
                      >
                        <td className={`px-5 py-5 text-sm`}>
                          <div className="flex">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {recent.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {recent.email}
                          </p>
                        </td>
                        <td className="px-5 py-5 text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {recent.date_joined}
                          </p>
                        </td>
                        <td className="px-5 py-5 text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              className={`relative ${
                                recent.status === "Completed"
                                  ? "text-[#3A974C]"
                                  : "text-[#F29339]"
                              }`}
                            >
                              {recent.status === "Completed"
                                ? "Active"
                                : "Pending"}
                            </span>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className="h-52">
              <p className="mt-16 text-center text-xl font-gray-600">
                No Users found
              </p>
            </div>
          )}
          {addedUsers && (
            <div className="flex justify-center mt-11">
              <button className="flex justify-center items-center w-[10rem] text-white py-2 mb-7 rounded-md bg-[#4B1A7C] rounded-xl">
                View all
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DashboardAddedusers;
