import React from "react";
import styles from "./Summary.module.css";
const Permissions = ({ Cost, Title, Value, icon }) => {
  return (
    <>
      <div className={styles.cardBox}>
        <div className={styles.card}>
          <div className="flex flex-row justify-between">
            <h2 id={styles.title}>{Title}</h2>
            <img src={icon} alt="svgIcon" />
          </div>
          <div>
            <h2 id={styles.details}>{Value}</h2>
          </div>
          {/* <div className="flex flex-row justify-between">
            <h2 id={styles.value}>{Cost}</h2>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Permissions;
