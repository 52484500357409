import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginLoad: false,
  loginError: null,
  loginData: null,
};

export const loginSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loginLoad = true;
    },
    loginSuccess: (state, { payload }) => {
      localStorage.setItem("userDetails", JSON.stringify(payload.data));
      state.loginLoad = false;
      state.loginData = payload;
      state.loginError = null;
    },
    loginFail: (state, { payload }) => {
      state.loginLoad = false;
      state.loginData = null;
      state.loginError = "invalid credentials";
    },
    clearLoginErrorMessage: (state) => {
      state.loginError = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginStart, loginSuccess, loginFail, clearLoginErrorMessage } =
  loginSlice.actions;

export default loginSlice.reducer;
