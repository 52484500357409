import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getRatesLoad: false,
  rateData: null,
};

export const rateSlice = createSlice({
  name: "rates",
  initialState,
  reducers: {
    getRateStart: (state) => {
      state.getRatesLoad = true;
    },
    getRateSuccess: (state, { payload }) => {
      state.getRatesLoad = false;
      state.rateData = payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { getRateStart, getRateSuccess } =
  rateSlice.actions;

export default rateSlice.reducer;
