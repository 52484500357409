import axios from "../axios";
import {
  fetchTotalUsersFailure,
  fetchTotalUsersStart,
  fetchTotalUsersSuccess,
} from "../../redux/getStats/statisticsSlice"; 

export const fetchTotalUsers = () => async (dispatch) => {
  dispatch(fetchTotalUsersStart());

  axios
    .get("/user/stat")
    .then(function (res) {
      dispatch(fetchTotalUsersSuccess(res.data.data));
    })
    .catch(function (error) {
      dispatch(fetchTotalUsersFailure(error));
    });
};
