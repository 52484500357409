export default function Switch({ status }) {
    return(
        <div style={{marginRight: '.5rem'}}>
            {
                status ? <SwitchOn/> : <SwitchOff/>
            }
        </div>
    )
}

const SwitchOn = () => {
    return(
        <div style={{cursor: 'pointer'}}>
            <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="30" y="21" width="30" height="15" rx="7.5" transform="rotate(-180 30 21)" fill="#4B1A7C" fillOpacity="0.5"/>
                <g filter="url(#filter0_d_0_1)">
                <circle cx="24" cy="14" r="10" transform="rotate(-180 24 14)" fill="#4B1A7C"/>
                <circle cx="24" cy="14" r="9.5" transform="rotate(-180 24 14)" stroke="#4B1A7C" strokeOpacity="0.5"/>
                </g>
                <defs>
                <filter id="filter0_d_0_1" x="8" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="3"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                </filter>
                </defs>
            </svg>
        </div>
    )
}

const SwitchOff = () => {
    return(
        <div style={{cursor: 'pointer'}}>
            <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="26" y="21" width="30" height="15" rx="7.5" transform="rotate(-180 30 21)" fill="grey" fillOpacity="0.5"/>
                <g>
                <circle cx="10" cy="14" r="10" fill="white"/>
                <circle cx="10" cy="14" r="9.5" stroke="#C7CCD0" strokeOpacity="0.5"/>
                </g>
                <defs>
                <filter id="filter0_d_0_1" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="3"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                </filter>
                </defs>
            </svg>
        </div>
    )
}