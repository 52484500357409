import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Alert from "../../../../utils/Alert";
import { fetchDeposits } from "../../../../utils/apiCalls/admin/fetchDeposits";
import Card from "./components/Card";
import DepositTableLayout from "./components/depositTableLayout";
import ViewDetails from "./components/viewDetails";

function Deposit() {
  const [type, setType] = useState("pending");
  const [viewDetails, setViewDetails] = useState(null);

  const admin = useSelector((state) => state.addAdmin);

  const dispatch = useDispatch();

  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  useEffect(() => {
    dispatch(fetchDeposits(type));
  }, [type]);

  return (
    <>
      <div className="">
        <Card type={type} setType={setType} />
      </div>

      <DepositTableLayout setViewDetails={setViewDetails} />

      {viewDetails && (
        <ViewDetails
          setViewDetails={setViewDetails}
          viewDetails={viewDetails}
          typeStatus={type}
          setAlertStatus={setAlertStatus}
        />
      )}
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
}

export default Deposit;
