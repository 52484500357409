import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  getAuditLogData: [{}],
  getAuditLogSuccess: false,
  getAuditLogError: false,
};

export const getAuditLogSlice = createSlice({
  name: "getAuditLog",
  initialState,
  reducers: {
    getAuditLogStart: (state) => {
      state.loading = true;
    },
    getAuditLogSuccessful: (state, { payload }) => {
      state.loading = false;
      state.getAuditLogData = payload;
      state.getAuditLogSuccess = true;
      state.getAuditLogError = false;
    },
    getAuditLogFailed: (state) => {
      state.loading = false;
      state.getAuditLogData = {};
      state.getAuditLogSuccess = false;
      state.getAuditLogError = true;
    },
  },
});

export const { getAuditLogStart, getAuditLogSuccessful, getAuditLogFailed } =
  getAuditLogSlice.actions;

export default getAuditLogSlice.reducer;
