import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/modal/Modal";
import Summary from "../../../components/summary/Summary";
import {
  resetNumbers,
  savePhoneNumbersInState,
  saveSummary,
  toggleSelectAccount,
  toggleShowSMScsv,
  toggleShowSummary,
  toggleWarning,
  updateSmsError,
} from "../../../redux/sms/messageSummarySlice";
import styles from "./Sendsms.module.css";
import { getPages } from "../../../utils/getPages";
import Alertt from "../../../utils/Alert";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Popover,
  Typography,
} from "@mui/material";
import { getSenderID } from "../../../utils/apiCalls/senderID";
import { filter } from "lodash";
import UploadCsv from "./UploadCsv";
import { resetStatus } from "../../../redux/sms/SmsActionsSlice";
import PopOverDateandTime from "./PopOverDateandTime";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getPhoneGroup,
  getPhoneNumbers,
} from "../../../utils/apiCalls/phoneGroup";
import { getCostPreview } from "../../../utils/apiCalls/sendSms";
import CustomDropdown from "../../../components/dropDown/CustomDropdown";

function Sendsms() {
  const user = JSON.parse(localStorage.getItem("userDetails")).user;
  const [senderID, setSenderID] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [numbersArray, setNumbersArray] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [file, setFile] = useState(null);
  const [disabled, setDisable] = useState(false);
  const [senderIDerror, setSenderIDerror] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("manual");
  const [openBox, setOpenBox] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [costBreakdown, setCostBreakdown] = useState();
  const [msgSummary, setMsgSummary] = useState();
  const [selectedSenderID, setSelectedSenderID] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [displayInputBox, setDisplayInputBox] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const forward = useSelector((state) => state.smsActions.forward);
  // const resend = useSelector((state) => state.smsActions.resend);
  const stateSenderID = useSelector((state) => state.smsActions.senderID);
  const stateMessage = useSelector((state) => state.smsActions.message);
  const stateNumbers = useSelector((state) => state.smsActions.numbers);

  useEffect(() => {
    dispatch(getPhoneGroup());
    stateMessage !== "" && setMessage(stateMessage);
    stateSenderID !== "" && setSenderID(stateSenderID);
    stateNumbers !== "" && dispatch(savePhoneNumbersInState(stateNumbers));
    dispatch(resetStatus());
    dispatch(resetNumbers());
    dispatch(getSenderID());
  }, [dispatch, senderID, stateMessage, stateNumbers, stateSenderID]);
  useEffect(() => {
    setErrorMessage(senderIDerror);
  }, [senderIDerror]);

  const selectedGroup = useSelector(
    (state) => state.phoneGroup.selectedPhoneGroup
  );

  const { numbers, sendSmsSuccess, sendSmsError, sendSmsErrorMessage } =
    useSelector((state) => state.summary);

  const scheduleSmsSuccess = useSelector(
    (state) => state.summary.scheduleSmsSuccess
  );
  const ref = useSelector((state) => state.summary.reference);
  const openModal = useSelector((state) => state.summary.showSummary);
  const openSelectModal = useSelector(
    (state) => state.summary.showSelectAccount
  );
  const { getCostLoading, getCostSuccess, getCostFailed } = useSelector(
    (state) => state.summary
  );
  const loading = useSelector((state) => state.summary.sendSmsLoad);
  const uploadSMScsv = useSelector((state) => state.summary.uploadSMScsv);
  const senderIDs = useSelector((state) => state.senderIDs.senderIDs);
  const phoneGroup = useSelector((state) => state.phoneGroup.getSuccessData);
  const loadingNumbers = useSelector((state) => state.summary.loadingNumbers);
  const loadingGroups = useSelector((state) => state.phoneGroup.loading);
  const date = useSelector((state) => state.summary.date);
  const time = useSelector((state) => state.summary.time);

  useEffect(() => {
    if (sendSmsSuccess) {
      setTimeout(() => {
        navigate(`/delivery-report/${ref}`);
      }, 2000);
    }
  }, [navigate, ref, sendSmsSuccess]);

  useEffect(() => {
    if (scheduleSmsSuccess) {
      setTimeout(() => {
        navigate(`/scheduled-sms/`);
      }, 2000);
    }
  }, [scheduleSmsSuccess]);

  const changeHandler = (file) => {
    setFile(file);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const csvNumbers = [];

        results.data.map((d) => {
          csvNumbers.push(Object.values(d)[0].replace(/\D/g, ""));
        });
        dispatch(savePhoneNumbersInState(csvNumbers.toString()));
      },
    });
  };

  useEffect(() => {
    setPage(getPages(message?.length));
    // message.length > 160 ? setPage(Math.floor(message.length/160)) : setPage(1)
  }, [message, numbers]);

  useEffect(() => {
    const trimNumber = numbers && numbers?.split(" ").join(""); //remove white spaces
    setNumbersArray(trimNumber.split(","));
    // setNumbersArray('2347037845610')
  }, [numbers]);

  function handleSubmit(e) {
    e.preventDefault();
  }
  function closeModal(e) {
    if (e && e.target) {
      if (e.target.id === "modal") {
        setOpenBox(false);
      }
    }
  }

  function closeSelectModal(e) {
    if (e.target.id === "modal") {
      dispatch(toggleSelectAccount());
    }
  }

  useEffect(() => {
    if (sendSmsError) {
      setTimeout(() => {
        dispatch(updateSmsError());
      }, 3000);
    }
  }, [sendSmsError, dispatch]);

  const handleClose = (event, reason) => {
    dispatch(updateSmsError());

    setOpenAlert(false);
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  useEffect(() => {
    selectedSenderID === "" || message === "" || numbers.length < 1
      ? setCanProceed(false)
      : setCanProceed(true);
  }, [message, numbers, selectedSenderID]);

  const proceed = async () => {
    setPreviewLoading(true);
    try {
      const messageSummary =
        date !== ""
          ? {
              sender_id: selectedSenderID,
              msgContent: message,
              destPhone: numbersArray,
              time: time,
              date: date,
            }
          : {
              sender_id: selectedSenderID,
              msgContent: message,
              destPhone: numbersArray,
            };
      const previewData = await getCostPreview(messageSummary);
      if (previewData) {
        setPreviewLoading(false);
        setMsgSummary(messageSummary);
        setCostBreakdown(previewData);
        setOpenBox(true);
      }
    } catch (error) {
      setPreviewLoading(false);
    }
  };
  function closeModall(e) {
    if (e.target.id === "modal") {
      dispatch(toggleShowSMScsv());
    }
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getNumbers = (groupName) => {
    dispatch(getPhoneNumbers(groupName));
  };

  const handleSenderIdChange = (e, type) => {
    setSelectedSenderID(type === "promotional" ? e.target.value : e);
  };

  const approvedSenderId = senderIDs.filter(
    (senderID) => senderID.status === "approved"
  );

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    if (selectedOption === "promotional") {
      setDisplayInputBox(true);
      setShowDropdown(false);
    } else if (selectedOption === "transactional") {
      setDisplayInputBox(false);
      setShowDropdown(true);
    }
  }, [selectedOption]);

  return (
    <>
      {uploadSMScsv && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={closeModall}
        >
          <UploadCsv closeModal={() => dispatch(toggleShowSMScsv())} />
        </Modal>
      )}

      {openBox && (
        <Modal close={closeModal}>
          <Summary
            close={() => setOpenBox(false)}
            proceed={() => {
              dispatch(toggleWarning());
              dispatch(toggleShowSummary());
            }}
            costBreakdown={costBreakdown}
            msgSummary={msgSummary}
          />
        </Modal>
      )}

      <div className={styles.instantContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Alertt
            handleClose={handleClose}
            openAlert={openAlert}
            Text="Error: All fields are required"
            Type="error"
          />

          <div className={styles.row}>
            <div className={styles.labelBox}>
              <label htmlFor="sendername">SMS Route</label>
            </div>

            <div>
              <RadioGroup
                aria-labelledby="sendTo-group-label"
                defaultValue={selectedOption}
                onChange={handleRadioChange}
                name="sendTo"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "30px",
                  fontSize: "0.875rem !important",
                }}
              >
                <FormControlLabel
                  value="promotional"
                  control={<Radio />}
                  label="Promotional "
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <FormControlLabel
                  value="transactional"
                  control={<Radio />}
                  label="Transactional"
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
              </RadioGroup>

              <div className={styles.labelBox} style={{ marginTop: 20 }}>
                <label htmlFor="sendername">From</label>
              </div>

              {displayInputBox && (
                <div>
                  <input
                    type="text"
                    id="senderId"
                    name="senderId"
                    placeholder="Enter SenderID"
                    style={{ paddingLeft: 10, width: "300px" }}
                    className={styles.childBox}
                    value={selectedSenderID}
                    onChange={(e) => handleSenderIdChange(e, "promotional")}
                  />
                </div>
              )}
              {showDropdown && (
                <div>
                  <CustomDropdown
                    options={approvedSenderId.map((option) => option.sender_id)}
                    value={selectedSenderID}
                    onChange={(e) => handleSenderIdChange(e, "transactional")}
                    placeholder="Select Sender_ID"
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.labelBox}>
              <label for="sendername">to</label>
            </div>
            <div className={styles.selectType}>
              <div className={styles.radioType}>
                <div className={styles.radioBox}>
                  <RadioGroup
                    aria-labelledby="sendTo-group-label"
                    defaultValue="manual"
                    name="sendTo"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      fontSize: "0.875rem !important",
                    }}
                  >
                    <FormControlLabel
                      value="manual"
                      control={<Radio />}
                      label="Manual Input"
                      onChange={(e) => e.target.value}
                    />
                    <FormControlLabel
                      value="contacts"
                      control={<Radio />}
                      label="Phone Groups"
                      onChange={(e) => setAnchorEl(e.currentTarget)}
                    />
                  </RadioGroup>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      <span id={styles.suggestions}>
                        <span className={styles.rBtn}>
                          {phoneGroup?.length > 0 && (
                            <small>Phone Groups</small>
                          )}
                        </span>
                        {loadingGroups ? (
                          <span>Fetching Data...</span>
                        ) : phoneGroup?.length === 0 ? (
                          <span>No record</span>
                        ) : (
                          <span id={styles.options}>
                            {loadingNumbers ? (
                              <span>Please wait...</span>
                            ) : (
                              phoneGroup?.map((data, index) => (
                                <ul
                                  key={index}
                                  id={styles.list}
                                  onClick={() => getNumbers(data.group_name)}
                                >
                                  {data.group_name}
                                </ul>
                              ))
                            )}
                          </span>
                        )}
                      </span>
                    </Typography>
                  </Popover>
                </div>
              </div>
              <div className={styles.uploadBtnWrapper}>
                {file ? (
                  <>
                    <button className={styles.btn}>{file.name}</button>
                    <input
                      type="file"
                      name="csv"
                      accept=".csv"
                      onChange={(e) => {
                        changeHandler(e.target.files[0]);
                        setDisable(false);
                      }}
                      className={styles.bannerUpload}
                      // style={{cursor: "pointer"}}
                    />
                  </>
                ) : (
                  <>
                    <button
                      className={styles.btn}
                      onClick={() => dispatch(toggleShowSMScsv())}
                    >
                      UPLOAD CSV
                    </button>
                  </>
                )}
              </div>
            </div>
            <textarea
              type="text"
              className={styles.contactList}
              style={{ padding: "5px 10px 5px 10px" }}
              value={numbers}
              placeholder="When manually entering number seperate by using comma using country code format. 
          (e.g 2348012343556, 23480123456781)"
              onChange={(e) =>
                dispatch(savePhoneNumbersInState(e.target.value))
              }
            ></textarea>
          </div>

          <div className={styles.row}>
            <label for="sendername">Message</label>
            <textarea
              type="text"
              className={styles.messageBox}
              placeholder="1 Page / 160 Characters"
              style={{ padding: "5px 10px 5px 10px" }}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
            <span style={{ fontSize: 12, color: "#000000" }}>
              {page} page{page > 1 && "s"} / {message?.length} characters
            </span>
          </div>
          <div className={styles.bottomActions}>
            <div className={styles.radioBox}>
              <PopOverDateandTime />
            </div>
          </div>

          <div className={styles.buttonSection}>
            <button>cancel</button>
            <button
              onClick={() => proceed()}
              style={{ opacity: !canProceed ? 0.5 : 1 }}
              disabled={!canProceed ? true : false}
            >
              {previewLoading ? (
                <CircularProgress sx={{ color: "#C4C4C4" }} size={25} />
              ) : (
                "continue"
              )}
            </button>
          </div>
        </form>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
          <p>Sending message...</p>
        </div>
      </Backdrop>
      <Alertt
        handleClose={handleClose}
        openAlert={sendSmsSuccess || sendSmsError}
        Text={
          sendSmsError
            ? `Error: ${sendSmsErrorMessage.message || "Something happened"}`
            : "Message sent successfully"
        }
        Type={sendSmsError ? "error" : "success"}
      />
      <Alertt
        handleClose={handleClose}
        openAlert={scheduleSmsSuccess}
        Text="Message scheduled successfully"
        Type="success"
      />
      <Alertt
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
}

export default Sendsms;
