import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  messageStatistics: {},
  failed: {},
};

const messageStatistcs = createSlice({
  name: "MessageStatistcs",
  initialState,
  reducers: {
    getMessageStatisticsLoading: (state) => {
      state.loading = true;
    },
    getMessageStatisticSuccess: (state, { payload }) => {
      state.messageStatistics = payload;
      state.failed = null;
      state.loading = false;
    },
    getMessageStatisticsFailed: (state, { payload }) => {
      state.messageStatistics = null;
      state.failed = payload;
      state.loading = false;
    },
  },
});

export const {
  getMessageStatisticsFailed,
  getMessageStatisticsLoading,
  getMessageStatisticSuccess,
} = messageStatistcs.actions;
export default messageStatistcs.reducer;
