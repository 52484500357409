import axios from "../axios"
import {
  SendingSms,
  SmsFailed,
  SmsSent,
} from "../../redux/admin/Send/sendSmsAllUsersSlice";

export const SendSmsAll = (data) => async (dispatch) => {
  dispatch(SendingSms());

  axios
    .post("/admin/notification/sms", data)
    .then(function (response) {
 
      dispatch(SmsSent(response.data.data));
    })
    .catch(function (error) {
   
      dispatch(SmsFailed(error));
    });
};
