import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { sendSms } from "../../utils/apiCalls/sendSms";
import Alert from "../../utils/Alert";
import {
  resetStatus,
  sendSmsStart,
  sendSuccess,
} from "../../redux/sms/messageSummarySlice";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "../../utils/axios";
import { deletePhoneGroupSuccess } from "../../redux/contacts/phoneGroupSlice";
import { getPhoneGroup } from "../../utils/apiCalls/phoneGroup";
import { deleteAPIsuccess } from "../../redux/api/getAPIslice";
import { getKeys } from "../../utils/apiCalls/secretKeys";
import api from "../../assets/api.svg";

function ConfirmCardDelete({ closeModdal, api_name }) {
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const action = () => {
    setLoading(true);
    axios
      .post("/user/api-key/delete", { name: api_name })
      .then((response) => {
        const { message } = response.data;
        dispatch(deleteAPIsuccess(message));
        setLoading(false);
        closeModdal(false);
        dispatch(getKeys());
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "40px" }}>
        {/* <Warning /> */}
        <div>
          <img src={api} alt="" style={{ width: "100px", height: "auto" }} />
        </div>
        <h3>Are you sure you want to delete this ApiKey</h3>
        <div className={styles.buttonSection}>
          <button onClick={() => closeModdal(false)}>Cancel</button>
          <button onClick={() => action()} disabled={loading}>
            {loading ? "Please wait.." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmCardDelete;
