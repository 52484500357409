import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  scheduleData: {},
  getScheduledInAppNotificationError: false,
  inAppNotificationScheduled: false,
  scheduledInAppNotificationSent: false,
  sendScheduledNotificationLoad: false,
  scheduleNotificationSuccess: false,
  scheduledTime: false,
};

export const inAppNotificationScheduleSlice = createSlice({
  name: "scheduled-InAppNotification",
  initialState,
  reducers: {
    getScheduledInAppNotificationStart: (state) => {
      state.loading = true;
    },
    setScheduledNotification: (state, { payload }) => {
      state.loading = false;
      state.inAppNotificationScheduled = true;
      state.scheduleData = payload;
      state.scheduledTime = false;
    },
    getScheduledInAppNotificationSuccess: (state, { payload }) => {
      state.loading = false;
      state.scheduleData = payload;
    },
    scheduledInAppNotificationFailed: (state, { payload }) => {
      state.loading = false;
      state.scheduleData = {};
      state.getScheduledInAppNotificationError = true;
    },
    notificationScheduledSuccess: (state) => {
      state.loading = true;
    },
    resetScheduleSuccess: (state) => {
      state.inAppNotificationScheduled = false;
    },
    showScheduledTime: (state) => {
      state.scheduledTime = true;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getScheduledInAppNotificationStart,
  getScheduledInAppNotificationSuccess,
  scheduledInAppNotificationFailed,
  setScheduledNotification,
  notificationScheduledSuccess,
  resetScheduleSuccess,
  showScheduledTime,
} = inAppNotificationScheduleSlice.actions;

export default inAppNotificationScheduleSlice.reducer;
