import Logo from "../Logo/Logo";
import LogoFooter from "../Logo/LogoFooter";

import { FONTSIZE, COLORS } from "../../constants/theme";

import styles from "./OnboardingLayout.module.css";
import { Link } from "react-router-dom";
// import backWhite from "../../assets/backWhite.svg";

export default function OnboardingLayout() {
  const { px32, px25, px14 } = FONTSIZE;
  const { white } = COLORS;

  return (
    <div
      className={`${styles.container} top-[0] left-[0] `}
      style={{
        flex: 1,
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Link
        to="/"
        // style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {/* <span>
          <img
            src={backWhite}
            alt=""
            style={{ width: "35px", height: "auto", marginTop:"-10px",}}
          />
        </span> */}
        <Logo />
      </Link>
      <div
        style={{
          color: white,
          maxWidth: "100%",
          backgroundColor: "#33256A",
          height: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          letterSpacing: ".18rem",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: px25, fontWeight: 400 }}>
          INSPIRED BY THE FUTURE:
        </div>
        <div style={{ fontSize: px32, fontWeight: 700 }}>
          SAY A LITTLE AND SAY IT WELL
        </div>
      </div>
      <LogoFooter />
    </div>
  );
}
