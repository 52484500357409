import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../utils/Alert";
import InAppMsgConfirmationCard from "../../../../../components/confirmCard/InAppMsgConfirmationCard";
// import Modal from "../../../../../components/modal/Modal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InAppNotificationScheduler from "./InAppNotificationScheduler";
import { setScheduledNotifications } from "../../../../../utils/AdminApiCalls/scheduleMailandNotifications";
import { Modal } from "@mui/material";
import { setScheduledNotification } from "../../../../../redux/admin/messageScheduleSlices/inAppNotificationScheduleSlice";

const InAppMsg = ({ style, closeNotificationTab }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [canSend, setCanSend] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [rawMessage, setRawMessage] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleCllose = () => setOpen(false);
  const [scheduledSuccess, setScheduledSuccess] = useState(false);

  const dispatch = useDispatch();

  const { sendingLoading, sendingSuccessful } = useSelector(
    (state) => state.inAppNotifications
  );

  const { inAppNotificationScheduled } = useSelector(
    (state) => state.inAppNotificationScheduler
  );

  const date = useSelector((state) => state.getAllNotifications.date);
  const time = useSelector((state) => state.getAllNotifications.time);

  const handleReactEditorInput = (state) => {
    setMessage(state);
  };

  useEffect(() => {
    message !== "" && subject !== "" ? setCanSend(true) : setCanSend(false);
  }, [message, subject]);

  useEffect(() => {
    if (message) {
      const newMessage = { ...convertToRaw(message.getCurrentContent()) };
      setRawMessage(newMessage.blocks.map((text) => text.text));
    }
  }, [message]);

  useEffect(() => {
    if (sendingSuccessful) {
      setShowWarning(false);
      setSubject("");
      setMessage("");
    }
  }, [sendingSuccessful]);

  useEffect(() => {
    if (inAppNotificationScheduled) {
      setScheduledSuccess(true);
      setShowWarning(false);
      setSubject("");
      setMessage("");
      setRawMessage("");
    }
  }, [inAppNotificationScheduled]);

  const sendInAppMsg = (e) => {
    e.preventDefault();
    handleOpen();
    if (message !== "" && subject !== "") {
      setShowWarning(true);
    } else {
      const scheduledMessage =
        date !== ""
          ? {
              subject,
              message: rawMessage.join(message),
              date,
              time,
            }
          : {
              subject,
              message: rawMessage.join(message),
            };
      dispatch(setScheduledNotifications(scheduledMessage));
    }
    // setErrorMsg(true);
  };

  const handleErrorMsg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMsg(false);
  };

  useEffect(() => {}, [sendingLoading]);

  const handleSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setScheduledSuccess(false);
  };

  return (
    <div style={style}>
      <div className="bg-white w-full rounded p-[21px]">
        {showWarning && (
          <Modal
            open={open}
            onClose={handleCllose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InAppMsgConfirmationCard
              close={() => setShowWarning(false)}
              subject={subject}
              message={message}
              rawMessage={rawMessage}
            />
          </Modal>
        )}
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon
            fontSize="10"
            width="20px"
            onClick={() => closeNotificationTab(false)}
          />
        </span>

        <h3 className="font-medium">Send notifications to all users</h3>
        <form className="w-full">
          <div className="flex flex-wrap -mx-3 mb-6"></div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                for="grid-password"
              >
                Subject
              </label>
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="text"
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <label
                className="block tracking-wide font-light text-gray-700 text-xs  mb-3 text-[18px]"
                for="grid-password"
              >
                Message
              </label>

              <Editor
                className="h-full"
                editorStyle={{
                  border: "1px solid",
                  height: "150px",
                  padding: 10,
                }}
                editorState={message}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleReactEditorInput}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              />
            </div>
            <div className="flex justify-end px-3 mb-[-25px] mt-5 w-full">
              <button
                className=" w-full lg:w-[180px] bg-[#4B1A7C] shadow focus:shadow-outline rounded-md focus:outline-none text-white font-bold py-2 px-6 rounded"
                type="submit"
                onClick={sendInAppMsg}
                style={{ opacity: !canSend ? 0.5 : 1, width: "auto" }}
              >
                {inAppNotificationScheduled && date !== ""
                  ? "Schedule Message"
                  : "SEND"}
              </button>
            </div>
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
              <InAppNotificationScheduler
                message={message}
                subject={subject}
                rawMessage={rawMessage}
              />
            </div>
          </div>
        </form>
      </div>
      <Alert
        openAlert={errorMsg}
        Text={"Message & subject must be filled"}
        Type={"error"}
        handleClose={handleErrorMsg}
      />
      <Alert
        openAlert={scheduledSuccess}
        Text={"Notification has been Scheduled"}
        Type={"success"}
        handleClose={handleSuccess}
      />
    </div>
  );
};

export default InAppMsg;
