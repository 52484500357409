import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// import { flexbox } from "@mui/system";
import moment from "moment";
import { useDispatch } from "react-redux";
// import {
//   resetDate,
//   saveDateInState,
// } from "../../redux/admin/";
import Alert from "../../../../../utils/Alert";
import {
  resetDate,
  saveDateInState,
} from "../../../../../redux/admin/Send/sendSmsAllUsersSlice.js";

export default function MaterialUIPickers({ setStateValue }) {
  const time = moment(new Date(Date.now())).format("HH:mm");
  const day = moment(new Date(Date.now())).format("yyyy-MM-DD");

  const dispatch = useDispatch();

  const [value, setValue] = useState(moment(new Date(Date.now())));
  const [dateToSend, setDateToSend] = useState("");
  const [timeToSend, setTimeToSend] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  useEffect(() => {
    if (dateToSend !== "" && timeToSend !== "") {
      if (dateToSend === day && time > timeToSend) {
        setStateValue("now");
        dispatch(resetDate());
        setAlertStatus({
          alertMessage: "Please select future time",
          alertType: "error",
          openAlert: true,
        });
      } else {
        dispatch(saveDateInState({ time: timeToSend, date: dateToSend }));
      }
    }
  }, [dateToSend, timeToSend]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}
      > */}
        <Stack
          spacing={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"10px"}
        >
        
          <DateTimePicker
            label="Date"
            inputFormat="dd/MM/yyyy HH:mm"
            value={value}
            disablePast
            // onChange={(e)=>console.log(e)}
            onChange={(e) => {
              setDateToSend(moment(e).format("yyyy-MM-DD"));
              setTimeToSend(moment(e).format("HH:mm"));
              setValue(e);
              
            }}
            renderInput={(params) => (
              <TextField hiddenLabel size="small" {...params} />
            )}
          />

          

          {/* <DesktopDatePicker
            label="Date"
            inputFormat="dd/MM/yyyy"
            value={value}
            // onChange={(e)=>console.log(e)}
            onChange={(e)=>{
              setDateToSend(moment(e).format("yyyy-MM-DD"))
              setValue(e)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Time"
            value={value}
            onChange={(e)=>{
              setTimeToSend(moment(e).format("HH:mm"))
              setValue(e)
            }}
            renderInput={(params) => <TextField {...params} />}
          /> */}
        </Stack>
        {/* </div> */}
      </LocalizationProvider>
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
}
