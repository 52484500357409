import React from "react";
import styles from "./ProjectCard.module.css";

function ProjectCard({ item }) {
  return (
    <div className={styles.projectCard}>
      <div style={{ alignItems: "center" }}>{item.icon}</div>
      <div className={styles.statDetails}>
        <p>{item.title}</p>
        <h4>{item.stat ?? 0}</h4>
      </div>
    </div>
  );
}

export default ProjectCard;
