import { useSelector } from "react-redux";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import { useNavigate } from "react-router-dom";
import { Message } from "@mui/icons-material";
import { useEffect, useState } from "react";

const DashRecentTransactions = () => {
  const { recentTransactions } = useSelector(
    (state) => state.recentTransactions
  );

  const { revenue, period } = useSelector(
    (state) => state.dashboardData?.analysisData
  );

  const [periodName, setPeriodName] = useState("");

  useEffect(() => {
    if (period === "Y") {
      setPeriodName("Yesterday");
    } else if (period === "T") {
      setPeriodName("Today");
    } else if (period === "LW") {
      setPeriodName("Last Week");
    } else if (period === "TW") {
      setPeriodName("This Week");
    } else if (period === "LM") {
      setPeriodName("Last Month");
    } else if (period === "TM") {
      setPeriodName("This Month");
    } else if (period === "TY") {
      setPeriodName("This Year");
    } else if (period === "LY") {
      setPeriodName("Last Year");
    } else {
      setPeriodName(period);
    }
  }, [period]);

  const navigate = useNavigate();

  return (
    <div className="w-full rounded-xl shadow-md">
      <div className="mt-8 overflow-x-auto">
        <div className="inline-block min-w-full overflow-hidden bg-white px-11">
          <div>
            <div className="flex justify-end mt-8">
              <div className="flex flex-row">
                <div className="border border-[#3A974C] px-5 mr-11 py-3 flex justify-center ">
                  <div>
                    <p className="underline">
                      Approved payments for {periodName}
                    </p>
                    <p className="text-center mt-2">
                      {revenue &&
                        formatPriceByCountrySymbol(
                          "Nigeria",
                          +revenue.approved
                        )}
                    </p>
                  </div>
                </div>
                <div className="border border-[#F29339] px-5 py-3 flex justify-center ">
                  <div>
                    <p className="underline">
                      Pending payments for {periodName}
                    </p>
                    <p className="text-center mt-2">
                      {revenue &&
                        formatPriceByCountrySymbol("Nigeria", +revenue.pending)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-6 border-b mt-2">
              <p className="text-[#747474] text-2xl font-bold">
                Recent Transactions{" "}
              </p>
            </div>
          </div>
          {recentTransactions ? (
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Amount
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Date
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {recentTransactions &&
                  recentTransactions?.map((recent, index) => {
                    return (
                      <tr
                        className={index % 2 === 0 && "bg-[#E5E5E5]"}
                        key={index}
                      >
                        <td className={`px-5 py-5 text-sm`}>
                          <div className="flex">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {recent.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {formatPriceByCountrySymbol(
                              "Nigeria",
                              +recent.amount
                            )}
                          </p>
                        </td>
                        <td className="px-5 py-5 text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {recent.date}
                          </p>
                        </td>
                        <td className="px-5 py-5 text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              className={`relative ${
                                recent.status === "approved"
                                  ? "text-[#3A974C]"
                                  : "text-[#F29339]"
                              }`}
                            >
                              {recent.status === "approved"
                                ? "Approved"
                                : "Pending"}
                            </span>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className="h-52">
              <p className="mt-16 text-center text-xl font-gray-600">
                No Users found
              </p>
            </div>
          )}
          {recentTransactions && (
            <div className="flex justify-center mt-11">
              <button
                onClick={() => navigate("deposits")}
                className="flex justify-center items-center w-[10rem] text-white py-2 mb-7 rounded-md bg-[#4B1A7C] rounded-xl"
              >
                View all
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashRecentTransactions;
