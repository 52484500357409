import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  status: "",
  message: "",
};

export const userRateSlice = createSlice({
  name: "get-user-transactions",
  initialState,
  reducers: {
    updateRateStart: (state) => {
      state.loading = true;
    },
    updateRateSuccess: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      state.message = payload;
    },
    updateRateFailed: (state, { payload }) => {
      state.loading = false;
      state.status = "failed";
      state.message = payload;
    },
    updateRateReset: (state) => {
      state.status = "";
      state.message = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateRateReset,
  updateRateSuccess,
  updateRateStart,
  updateRateFailed,
} = userRateSlice.actions;

export default userRateSlice.reducer;
