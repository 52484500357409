import React from "react";
import Navbar2 from "../components/nav/Navbar2";
import rightArrow from "../assets/rightArrow.svg";
import styles from "./faq.module.css";
import BottomFooter from "../components/footer/BottomFooter";

const FAQ = () => {
  return (
    <>
      <div className={styles.faqWrap}>
        <Navbar2 />
        <div className={styles.headTxts}>
          <span>
            <h3>Home</h3>
          </span>
          <span>
            <img
              src={rightArrow}
              alt=""
              style={{ width: "18px", height: "18px" }}
            />
          </span>
          <span>
            <h3>FAQ</h3>
          </span>
        </div>
        <div className={styles.disclaimer}>
          <div className={styles.boxes}>
            <div className={styles.boxA}>
              <span>
                <h1>How do I get Started Sending Bulk SMS ?</h1>
              </span>
              <span>
                <h4>Sign up for a risk-free account</h4>
              </span>
              <span>
                <h4>Write your message, select your preferences.</h4>
              </span>
              <span>
                <h4>Sign up for a risk-free account</h4>
              </span>
              <span>
                <h4>Start sending!</h4>
              </span>
            </div>
            <div className={styles.boxB}>
              <span>
                <h1>How do I get Started Sending Bulk SMS ?</h1>
              </span>
              <span>
                <h4>Sign up for a risk-free account</h4>
              </span>
              <span>
                <h4>Write your message, select your preferences.</h4>
              </span>
              <span>
                <h4>Sign up for a risk-free account</h4>
              </span>
              <span>
                <h4>Start sending!</h4>
              </span>
            </div>
          </div>
          <p>
            DISCLAIMER: This site is a cursory summary only and likely contains
            errors and omissions. This is not legal advice. You should not act
            or rely on any information contained on this website without seeking
            the advice of an attorney.
          </p>
        </div>
        <div>
          <BottomFooter />
        </div>
      </div>
    </>
  );
};

export default FAQ;
