import {
  getAllNotificationStart,
  getAllNotificationSuccessful,
} from "../../redux/admin/notifications/getAllNotificationSlice";
import axios from "../axios";

export const AdminGetAllNotifications = () => async (dispatch) => {
  dispatch(getAllNotificationStart());
  await axios
    .get("/admin/notification/all/2022-01-01/2022-10-31")
    .then((response) => {
      const { data } = response.data;
      dispatch(getAllNotificationSuccessful(data));
    });
};
