import React, { useEffect } from "react";
import styles from "./modalCss.module.css";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendAdminScheduledMessages } from "../../../../utils/AdminApiCalls/adminScheduledMessages";

const SendNowModal = ({ closeModal, messageID }) => {
  const dispatch = useDispatch();
  const { sendLoading } = useSelector((state) => state.sendSmsAll);
  const { sendSuccess } = useSelector((state) => state.sendSmsAll);

  useEffect(() => {
    if (sendSuccess) {
      closeModal(false);
    }
  }, [sendSuccess]);

  const onSend = () => {
    dispatch(sendAdminScheduledMessages(messageID));
    sendSuccess && closeModal(false);
  };
  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "20px" }}>
        <Warning />
        <h3>Are you sure you want to send this message now?</h3>
        <div className={styles.buttonSection}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button
            onClick={() => onSend()}
            disabled={sendLoading}
            style={{ opacity: sendLoading ? 0.5 : 1 }}
          >
            {sendLoading ? "Please wait..." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SendNowModal;
