import React from "react";
import PlanCard from "../../../components/plancard/PlanCard";
import styles from "./Plans.module.css";
function Plans() {
  const data = [
    {
      package: "phantom",
      unit:890,
    },
    {
      package: "mage",
      unit:1780,
    },
    {
      package: "spirit",
      unit:3560,
    },
    {
      package: "guardian",
      unit:2670,
    },
    {
      package: "warrior",
      unit:4450,
    },
    {
      package: "spirit",
      unit:3560
    },
    {
      package: "phantom",
      unit:890,
    },
    {
      package: "mage",
      unit:1780,
    },
    {
      package: "spirit",
      unit:3560,
    },
  ];
  return (
    <div className={styles.planContainer}>
      <div className={styles.details}>
        <h3>Grow your business with our discounted plans/bundle</h3>
        <div className={styles.wallet}>
          <div className={styles.xwallet}>
            <p>Xwallet balance</p>
            <h3>223,875.00</h3>
          </div>
          <div className={styles.unit}>
            <p>Xunit balance</p>
            <h3 style={{ color: "#FF0000" }}>0.00</h3>
          </div>
        </div>
      </div>
      <div className={styles.planCards}>
        {data.map((item, index) => (
          <PlanCard plan={item} key={index} />
        ))}
      </div>
    </div>
  );
}

export default Plans;
