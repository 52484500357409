import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  senderIDs: []
};

export const senderIDslice = createSlice({
  name: "sendOTP",
  initialState,
  reducers: {
    getSenderIDStart: (state) => {
      state.loading = true;
    },
    getSenderIDSuccess: (state, { payload }) => {
      state.loading = false;
      state.senderIDs = payload
    },
  },
});

export const { getSenderIDStart, getSenderIDSuccess } = senderIDslice.actions;

export default senderIDslice.reducer;
