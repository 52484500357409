import {
  generateAPIerror,
  generateAPIstart,
  generateAPIsuccess,
  openCreateModal,
} from "../../redux/api/createAPIslice";
import { getAPIsuccess, getAPIstart } from "../../redux/api/getAPIslice";
import axios from "../axios";

export const generateKey = (name, type) => async (dispatch) => {
  dispatch(generateAPIstart());
  await axios
    .post("user/api-key/create", { name, type })
    .then((response) => {
      const { data } = response.data;

      dispatch(generateAPIsuccess(data));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(generateAPIerror(message));
    });
};

export const updateKeyPermission = (name, type) => async (dispatch) => {
  dispatch(generateAPIstart());
  try {
    const res = await axios.post("/user/api-key/permission", { name, type });
    const { message } = res.data;
    dispatch(generateAPIsuccess(message));
  } catch (err) {
    const { message } = err.response.data;
    dispatch(generateAPIerror(message));
  }
};

export const getKeys = () => async (dispatch) => {
  dispatch(getAPIstart());
  await axios
    .get("user/api-key/view")
    .then((response) => {
      const { data } = response.data;
      dispatch(getAPIsuccess(data));
    })
    .catch((error) => {});
};

export const deleteKey = () => async (dispatch) => {};
