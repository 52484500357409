import React from "react";
import styles from "./settings.module.css";
import settingsBanner from "../../../assets/settingsBanner.png";
import settingsIcon from "../../../assets/settingsIcon.svg";
import basePriceIcon from "../../../assets/basePriceIcon.svg";
import userProfile from "../../../assets/userProfile.svg";
import { NavLink } from "react-router-dom";
import UserDetails from "./UserDetails";

const SettingsNav = () => {
  const navLinkStyles = ({ isActive }) => {
    return {
      fontSize: isActive ? "16px" : "16px",
      fontWeight: isActive ? "700" : "400",
      color: isActive ? "#4b1a7c" : "#B8B8B8",
      background: isActive ? "#fff" : "#e6eaf0",
      borderRadius: isActive ? " 12px 12px 0px 0px" : "12px 12px 0px 0px",
    };
  };

  return (
    <div>
      <div className={styles.navbar}>
        <NavLink style={navLinkStyles} to="/admin/settings" id={styles.links}>
          <img src={settingsIcon} alt="img" />
          <h3>SETTINGS</h3>
        </NavLink>
        <NavLink style={navLinkStyles} id={styles.links} to="/admin/base-price">
          <img src={basePriceIcon} alt="img" />
          <h3>BASE PRICE</h3>
        </NavLink>
        <NavLink style={navLinkStyles} id={styles.links} to="/admin/user-price">
          <img src={userProfile} alt="img"  />
          <h3>USER PRICE</h3>
        </NavLink>
      </div>
    </div>
  );
};

export default SettingsNav;
