import React from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { SendSmsAll } from "../../utils/AdminApiCalls/sendSmsAll";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { setScheduledSms } from "../../utils/AdminApiCalls/scheduleMailandNotifications";
import { scheduleSuccess } from "../../redux/admin/Send/sendSmsAllUsersSlice";
import { getScheduledSmsNotificationStart } from "../../redux/admin/messageScheduleSlices/SmsNotificationScheduleSlice";

const AdminNotificationCard = ({ close, sender_id, message }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const { loading } = useSelector((state) => state.smsScheduleFile);
  const date = useSelector((state) => state.sendSmsAll.date);
  const time = useSelector((state) => state.sendSmsAll.time);

  const sendSms = () => {
    if (date == " ") {
      const data = {
        sender_id,
        message,
      };
      dispatch(SendSmsAll(data));
    } else {
      const scheduledSms =
        date !== ""
          ? {
              sender_id,
              message,
              date,
              time,
            }
          : {
              sender_id,
              message,
            };
      dispatch(setScheduledSms(scheduledSms));
      dispatch(scheduleSuccess());
    }
  };

  useEffect(() => {
    if (loading) {
      setLoader(true);
    }
  }, [loading]);

  return (
    <div className={styles.confirmContainer}>
      <Warning />
      <h3>
        Are you sure you want to {date !== "" ? "schedule" : "send"} sms to all
        users?
      </h3>
      <div className={styles.buttonSection}>
        <button onClick={close}>cancel</button>
        <button onClick={sendSms}>
          {loader ? <CircularProgress sx={{ color: "#C4C4C4" }} /> : "continue"}
        </button>
      </div>
    </div>
  );
};

export default AdminNotificationCard;
