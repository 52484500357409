import React from "react";
import Pagination from "@mui/material/Pagination";

const Paginationn = (props) => {
  const { onPageChange, totalCount, currentPage, pageSize } = props;

  return (
    <div
      style={{
        display: "flex",
        paddingTop: "1.25rem",
        paddingBottom: "1.25rem",
        justifyContent: "center",
      }}
    >
      <Pagination
        variant="outlined"
        shape="rounded"
        count={Math.ceil(totalCount / pageSize)}
        page={currentPage}
        onChange={(event, value) => onPageChange(value)}
        
      />
    </div>
  );
};

export default Paginationn;
