import DateRangeIcon from "@mui/icons-material/DateRange";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import Loading from "./Loading";

const DepositTableLayout = ({ setViewDetails }) => {
  const navigate = useNavigate();

  const { loading, depositsFetched, depositsError } = useSelector(
    (state) => state.deposits
  );

  useEffect(() => {
    if (depositsError === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [depositsError]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full shadow bg-white rounded px-14">
          <div className="pt-8 pb-14">
            <p className="font-bold text-2xl">Deposits</p>
          </div>
          {depositsFetched && depositsFetched.length > 0 ? (
            <div className="w-full rounded bg-white overflow-x-auto pb-4">
              <table className="w-full leading-normal">
                <thead className=" text-xs font-semibold tracking-wider text-left px-5 py-3 hover:cursor-pointer uppercase">
                  <tr className="" style={{}}>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-xs font-semibold text-black uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-xs font-semibold text-black uppercase tracking-wider"
                    >
                      Amount Deposited
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-xs font-semibold text-black uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-xs font-semibold text-black uppercase tracking-wider"
                    >
                      View details
                    </th>
                  </tr>
                </thead>
                <tbody className="px-5">
                  {depositsFetched.map((deposit, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 && "bg-[#C4C4C480]"
                        } cursor-pointer`}
                      >
                        <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                          <p className="text-black whitespace-no-wrap font-semibold">
                            {deposit.user}
                          </p>
                        </td>
                        <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                          <p className="text-black whitespace-no-wrap font-semibold">
                            {formatPriceByCountrySymbol(
                              "Nigeria",
                              +deposit.amount
                            )}
                          </p>
                        </td>
                        <td className="py-4 border-b border-gray-200 text-gray-900 text-sm ">
                          <p className="text-black whitespace-no-wrap font-semibold">
                            <DateRangeIcon
                              className="text-[#4285F4]"
                              fontSize="12"
                            />{" "}
                            {deposit.payment_date}
                          </p>
                        </td>
                        <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                          <button
                            onClick={() => setViewDetails(deposit)}
                            className="relative inline-block bg-[#c4bbce] py-3.5 px-10 text-[#4B1A7C] font-bold rounded-full"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="h-screen">
              <p className="text-center text-gray-600 text-2xl">
                No transactions found
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DepositTableLayout;
