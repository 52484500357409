import React, { useEffect, useState } from "react";
import styles from "./integration.module.css";
import { useLocation, useNavigate } from "react-router-dom";

function IntegrationGuide() {
  const navigate = useNavigate();

  const [urlPath, setUrlPath] = useState("");

  const location = useLocation();

  useEffect(() => {
    setUrlPath(window.location.host);
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.intro}>
          <h3>Introduction</h3>
        </div>
        <div className={styles.head}>
          <h4>Integrate using our Web API</h4>
          <h6>
            This guide provides you with all of the information you'll need to
            use our API.
          </h6>
          <span id={styles.miniA}>
            <small>
              As you scroll, you’ll see code examples for working with the API.
              {/* in different programming languages in the dark area to the right
              (or as part of the content on mobile). You can switch the language
              used with the tabs at the top right (or from the nav menu at the
              top left on mobile) */}
            </small>
          </span>
          <span id={styles.miniB}>
            <small>
              To use our API, please ensure that your xUnit wallet is funded.
              The cost of your sms is deducted from your xUnit wallet. Please
              note that your messages will not be delivered if you have
              insufficient balance.
              <span
                style={{
                  // color: "var(--color-red)",
                  fontWeight: "900",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/wallet/addfunds")}
              >
                Click here
              </span>{" "}
              to fund your xUnit wallet.
            </small>
          </span>
        </div>
        <div className={styles.mid}>
          <span className={styles.midA}>
            <h3>Send Bulk SMS Message</h3>
            <button>requires Authentication</button>
          </span>
          <small>
            The endpoint supports POST
            {/* /GET Methods */}. Send your parameters
          </small>
        </div>
        <div className={styles.section}>
          <h5>Request</h5>
          <span id={styles.post}>
            <big>POST</big>
            <small>api/v1/send-sms</small>
          </span>
          {/* <span id={styles.get}>
            <big>GET</big>
            <small>api/v1/send-sms</small>
          </span> */}
          <div className={styles.newDesign}>
            <div id={styles.rightBox}>
              <box id={styles.addressHttp}>
                {`http://${
                  urlPath === "testnet.xms.ng"
                    ? "staging.xms.ng"
                    : urlPath === "xms.ng"
                    ? "live.xms.ng"
                    : "127.0.0.1:8000"
                }/api/v1/send-sms`}
              </box>
              <span>
                <div id={styles.Headers}>HEADERS</div>
                <span id={styles.secretKeys}>
                  <h3>secret</h3>
                  <p>
                    APL-ed8df424454521c18df468c57e71c1fcc209999192adc5c3332fcc209999192adc5c3332
                  </p>
                </span>
              </span>
              <span>
                <div id={styles.Headers}>
                  BODY{" "}
                  <span style={{ color: "#666666", letterSpacing: "0px" }}>
                    raw
                  </span>
                </div>
                <span id={styles.secretKeys}>
                  <box
                    id={styles.addressHttp}
                    style={{ minWidth: "350px", padding: "20px 10px" }}
                  >
                    <code id={styles.rawData}>
                      &#10100;{" "}
                      <span
                        style={{
                          color: "var(--color-red)",
                          fontFamily: "var(--font-family3)",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        <br />
                        "destPhone"
                      </span>{" "}
                      : [{" "}
                      <span
                        style={{
                          color: "#2f9c0a",
                          fontFamily: "var(--font-family3)",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        "070378877610"
                      </span>
                      ], <br /> <br />{" "}
                      <span
                        style={{
                          color: "var(--color-red)",
                          fontFamily: "var(--font-family3)",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        "msgContent"
                      </span>{" "}
                      :{" "}
                      <span
                        style={{
                          color: "#2f9c0a",
                          fontFamily: "var(--font-family3)",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        "Message from API"
                      </span>{" "}
                      , <br /> <br />{" "}
                      <span
                        style={{
                          color: "var(--color-red)",
                          fontFamily: "var(--font-family3)",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        "sender_id"
                      </span>{" "}
                      :{" "}
                      <span
                        style={{
                          color: "#2f9c0a",
                          fontFamily: "var(--font-family3)",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        "loli"
                      </span>{" "}
                      <br />
                      &#10101;
                    </code>
                  </box>
                </span>
              </span>
            </div>
            <div id={styles.leftBox}>
              <div id={styles.response}>
                <h3>Example Request</h3>
                {/* <h4>response</h4> */}
              </div>
              <div className={styles.responseBox}>
                <code style={{ color: "#fff" }}>
                  curl --location --request{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    &nbsp; POST &nbsp; &nbsp;
                  </span>
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    '
                    {`http://${
                      urlPath === "testnet.xms.ng"
                        ? "staging.xms.ng"
                        : urlPath === "xms.ng"
                        ? "live.xms.ng"
                        : "127.0.0.1:8000"
                    }/api/v1/send-sms`}
                    '
                  </span>
                  <br /> --header &nbsp; &nbsp;
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    'secret: APL-ed8df468c57e7ba7e192adc5c3332'
                  </span>
                  <br /> --data-raw ' &#10100;{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <br />
                    "destPhone"
                  </span>{" "}
                  : [{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "09065678987"
                  </span>
                  ], <br />{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "msgContent"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "Message from API"
                  </span>{" "}
                  , <br />{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "sender_id"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "loli"
                  </span>{" "}
                  <br /> &#10101;'
                </code>
              </div>
              <div id={styles.response}>
                <h3>Response</h3>
                {/* <h4>response</h4> */}
              </div>
              <div className={styles.responseBox}>
                <code style={{ color: "#fff" }}>
                  curl --location --request{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    &nbsp; POST &nbsp; &nbsp;
                  </span>
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    '
                    {`http://${
                      urlPath === "testnet.xms.ng"
                        ? "staging.xms.ng"
                        : urlPath === "xms.ng"
                        ? "live.xms.ng"
                        : "127.0.0.1:8000"
                    }/api/v1/send-sms`}
                    '
                  </span>
                  <br /> --header &nbsp; &nbsp;
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    'secret: APL-ed8df468c57e7ba7e742adc5c3332'
                  </span>
                  <br /> --data-raw ' &#10100;{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <br />
                    "message"
                  </span>{" "}
                  : [{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "Message Sent Successfully"
                  </span>
                  ], <br />{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "reference"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "XMS-Messaih"
                  </span>{" "}
                  , <br />{" "}
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "sender_id"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "loli"
                  </span>
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "message"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "Message from Api"
                  </span>
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "pages"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    1
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "total_receiver"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    1
                  </span>
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "total_cost"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    1
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "cost"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    1
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "word_count"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    16
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "route"
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "API"
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "created_at"
                  </span>
                  :
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "2022-07-04T19:32:11.000000Z"
                  </span>
                  <br />
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    "updated_at"
                  </span>
                  :
                  <span
                    style={{
                      color: "#2f9c0a",
                      fontFamily: "var(--font-family3)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    "2022-07-04T19:32:11.000000Z"
                  </span>
                  <br /> &#10101;'
                </code>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.queryBlock}>
          <span id={styles.qP}>
            <h3>Query Parameters</h3>
          </span>
          <span id={styles.detailBlock} style={{ gap: "17px" }}>
            <big>Header</big>
            <small>secret</small>
            <h6>
              string
              <span
                style={{
                  color: "var(--color-red)",
                  fontWeight: "800",
                  marginLeft: "5px",
                }}
              >
                required
              </span>
            </h6>
          </span>
          <span id={styles.detailBlockB} style={{ marginTop: "-10px" }}>
            <small>
              {/* <big
                style={{
                  width: "auto",
                  backgroundColor: "#f7f7f8",
                  borderRadius: "5px",
                  padding: " 8px 18px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                }}
              >
                API Key
              </big>{" "} */}
              This is the API Key. You can retrieve/generate your token by
              visiting your
              <small
                onClick={() => navigate("/settings/api")}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                &nbsp; API Settings Page
              </small>
            </small>
          </span>
          <span
            id={styles.detailBlockB}
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "0px",
              gap: "30px",
            }}
          >
            <big>Body</big>
            <small>
              <span
                style={{
                  width: "auto",
                  background: "#f7f7f8",
                  padding: "8px 18px",
                  borderRadius: "5px",
                  fontFamily: "var(--font-family3)",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "var(--color-black)",
                }}
              >
                {" "}
                destPhone
              </span>
              <span
                style={{
                  fontFamily: "var(--font-family3)",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "var(--color-black)",
                  fontStyle: "italic",
                  marginLeft: "10px",
                  padding: "auto",
                }}
              >
                array of strings
                <span
                  style={{
                    color: "var(--color-red)",
                    fontWeight: "600",
                    marginLeft: "5px",
                    marginBottom: "10px",
                    fontSize: "13px",
                  }}
                >
                  required
                </span>
                <br />
              </span>
              <p
                style={{
                  marginTop: "20px",
                  fontFamily: "var(--font-family3)",
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "var(--color-black)",
                }}
              >
                Phone numbers you want to send the message to. Phone numbers
                should start with 234. Eg. 2347031234561.
              </p>
            </small>
          </span>
          <span style={{ marginLeft: "65px" }} id={styles.detailBlock}>
            <h6>
              <big
                style={{
                  width: "auto",
                  backgroundColor: "#f7f7f8",
                  borderRadius: "5px",
                  padding: " 8px 18px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginRight: "10px",
                }}
              >
                msgContent
              </big>
              string
              <span
                style={{
                  color: "var(--color-red)",
                  fontWeight: "800",
                  marginLeft: "5px",
                }}
              >
                required
              </span>
              <p
                style={{
                  color: "var(--color-black)",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontStyle: "normal",
                  background: "none",
                  fontFamily: "var(--font-family3)",
                  marginTop: "20px",
                }}
              >
                This is the message
              </p>
            </h6>
          </span>
          <span style={{ marginLeft: "65px" }} id={styles.detailBlock}>
            <h6>
              <big
                style={{
                  width: "auto",
                  backgroundColor: "#f7f7f8",
                  borderRadius: "5px",
                  padding: " 8px 18px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "auto",
                  color: "#000000",
                  marginRight: "10px",
                }}
              >
                sender_id
              </big>
              string length: 11 characters
              <span
                style={{
                  color: "var(--color-red)",
                  fontWeight: "800",
                  marginLeft: "5px",
                }}
              >
                required
              </span>
              <p
                style={{
                  color: "var(--color-black)",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontStyle: "normal",
                  background: "none",
                  fontFamily: "var(--font-family3)",
                  marginTop: "20px",
                }}
              >
                Your preferred Sender ID has to registered before you can use
                it. If you use a sender id not registered, we will send your
                message using XMS as the default sender id. To register your
                sender id,
                <span
                  style={{
                    color: "var(--color-red)",
                    fontWeight: "500",
                    fontSize: "14px",
                    background: "none",
                    padding: "0px 5px",
                    cursor: "pointer",
                    lineHeight: "21px",
                  }}
                  onClick={() => navigate("/settings/sender-ids")}
                >
                  click here.
                </span>
              </p>
            </h6>
          </span>
        </div>
        {/* <div className={styles.bottomBox}>
          <span id={styles.example}>
            <h3>Example request</h3>
          </span>
          <div id={styles.codeBg} style={{ overflowX: "auto" }}>
            <code>
              <pre>
                curl --location --request POST
                'https://api.xms.ng/api/v1/send-sms' \
              </pre>
              <pre>--header 'Content-Type: application/json' \</pre>
              <pre>
                --header 'secret_key:
                APL-74a51247c9471d0hmvmhvhmvv8950557rffjhcfhchvxdxegsx45f2e44c66b388889b3332'
              </pre>
              <pre>
                --data-raw 'destPhone: ["2347040511066”, “2348091234567”],
              </pre>
              <pre>
                "msgContent": "Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Maxime mollitia,molestiae quas vel sint
                commodi.",
              </pre>
              <pre>"sender_id": “mysenderid”</pre>
            </code>
          </div>
        </div> */}
      </div>
      {/* <div className={`${styles.apiBox}, ${styles.slideInRight}`}></div> */}
    </>
  );
}

export default IntegrationGuide;
