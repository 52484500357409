import { useEffect, useState } from "react";
import axios from "../../../../../utils/axios";
import Close from "../../../../assets/closeCircle.svg";
import styles from "../styles/userStyles.module.css";
import { DateRangePicker } from "react-date-range";
import backIcon from "../../../../assets/backIcon.svg";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { ReactComponent as Download } from "../../../../../assets/download.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import MaterialUIPickers from "../../../../../components/dateAndTime/MaterialUIPickers";
import Paginationn from "../../../../../components/Pagination/Pagination";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fil
import moment from "moment";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDown from "../../../../../assets/chevrondown.svg";
import Modal from "../../../../../components/modal/Modal";
import SendAlert from "../../../../../utils/Alert";

const SingleUserSenderIds = ({}) => {
  const { state } = useLocation();

  let PageSize = 15;
  const [senderIds, setSenderIds] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  // const [switchStates, setSwitchStates] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (state === null) {
      setAlertStatus({
        alertMessage: `Error fetching data`,
        alertType: "error",
        openAlert: true,
      });
    } else {
      setLoading(true);
      axios
        .get(`admin/sender_id/view/${state.email}`)
        .then((response) => {
          const { data } = response.data;
          setSenderIds(data);

          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return (
      senderIds.length > 0 && senderIds.slice(firstPageIndex, lastPageIndex)
    );
  };

  //   const handleClose = (event, reason) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }
  //     setAlertStatus({ ...alertStatus, openAlert: false });
  //   };

  const getData = () => {
    let dd = [];
    senderIds.map((data, index) => {
      //   dd.push({
      //     Reference: data.reference,
      //     Amount: data.amount,
      //     Bank: data?.bank,
      //     "Payment Date": data.payment_date,
      //     Channel: data.channel,
      //   });
    });
    return dd;
  };

  // const handleSwitchChange = (event, senderId, senderType) => {
  //   const newSwitchStates = { ...switchStates };
  //   newSwitchStates[senderId] = event.target.checked;
  //   setSwitchStates(newSwitchStates);

  //   const route = event.target.checked ? "enable-route" : "disable-route";

  //   const user_email = state.email;

  //   axios
  //     .post(`/admin/user/${route}`, {
  //       user_email,
  //       route: senderType,
  //     })
  //     .then((response) => {
  //       setAlertStatus({
  //         alertMessage: response.data.message,
  //         alertType: "success",
  //         openAlert: true,
  //       });
  //     })
  //     .catch((error) => {
  //       setAlertStatus({
  //         alertMessage: error.response.data.error,
  //         alertType: "error",
  //         openAlert: true,
  //       });
  //     });
  // };

  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={`${styles.tableDetails}`}>
          <div className="">
            <div
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => navigate(-1)}
            >
              <img src={backIcon} alt="" />
            </div>
            <p className="text-lg font-bold ml-2">
              Sender IDs {senderIds.length > 0 && `for ${senderIds[0]?.name}`}
            </p>
            <p className="text-sm text-gray-600 ml-2 font-semibold mt-2">
              {senderIds.length > 0 && `${senderIds[0]?.email}`}
            </p>
          </div>
          <div className={styles.tableActions}>
            {/* {senderIds?.length > 0 && (
              <CSVLink
                data={getData()}
                filename={`XMS_${}_sender_report [Sender_Id].csv`}
                style={{ textDecoration: "none" }}
              >
                <button className={styles.downloadBtn}>
                  <Download />
                  <b>Download</b>
                </button>
              </CSVLink>
            )} */}
            {/* <button className={styles.carlendarBtn}>
              <FormControl variant="standard" fullWidth className="">
                {/* <InputLabel id="demo-simple-select-label">Timeline</InputLabel> */}
            {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Timeline"
                  className="self-center ml-[8px] text-center w-5/6 "
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"approved"}>Approved</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"declined"}>Declined</MenuItem>
                </Select>
              </FormControl> */}
            {/* <img src={ArrowDown} alt="arrowDown" /> */}
            {/* </button>  */}
          </div>
        </div>
        {loading ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress sx={{ color: "#C4C4C4" }} />
          </Box>
        ) : senderIds.length === 0 ? (
          <center>
            <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
              Sender Ids for {state ? `${state.email}` : "user"} not available
            </Alert>
          </center>
        ) : (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className={`${styles.customers} table-fixed`}>
                <thead>
                  <tr>
                    <th style={{ fontSize: 15 }}>Date Approved</th>
                    <th style={{ fontSize: 15 }}>Date Created</th>
                    <th style={{ fontSize: 15 }}>Sender_ID</th>
                    <th style={{ fontSize: 15 }}>Sender_Type</th>

                    <th style={{ fontSize: 15 }}>Status</th>
                    <th style={{ fontSize: 15 }}>Use_case</th>
                    <th style={{ fontSize: 15, paddingLeft: 10 }}>
                      Updated By
                    </th>
                    {/* <th style={{ fontSize: 15, paddingLeft: 10 }}>
                      Enable route
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentTableData()?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: 13 }}>
                        {moment(item.date_approved).format("MMM Do YY")}
                      </td>
                      <td style={{ fontSize: 13 }}>
                        {moment(item.date_created).format("MMM Do YY")}
                      </td>
                      <td
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {item.sender_id}
                      </td>
                      <td style={{ fontSize: 13 }}>{item.sender_type}</td>
                      <td
                        style={{
                          fontSize: 13,
                          color:
                            item.status === "approved"
                              ? "green"
                              : item.status === "declined"
                              ? "red"
                              : "",
                        }}
                      >
                        {item.status}
                      </td>
                      <td style={{ fontSize: 13 }}>{item.use_case}</td>
                      <td style={{ fontSize: 13, paddingLeft: 10 }}>
                        {item.updated_by}
                      </td>
                      {/* <td style={{ fontSize: 13, paddingLeft: 10 }}>
                        <Switch
                          checked={switchStates[item.sender_id] || false}
                          onChange={(event) =>
                            handleSwitchChange(
                              event,
                              item.sender_id,
                              item.sender_type
                            )
                          }
                        />
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginationn
              currentPage={currentPage}
              totalCount={senderIds?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
      <SendAlert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
      {alertStatus.openAlert && (
        <Alert
          openAlert={alertStatus.openAlert}
          Text={alertStatus.alertMessage}
          Type={alertStatus.alertType}
          handleClose={() =>
            setAlertStatus({
              ...alertStatus,
              openAlert: false,
            })
          }
        />
      )}
    </div>
  );
};

export default SingleUserSenderIds;
