import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileUpdateLoad: false,
  profileUpdateError: "",
  profileUpdateData: null,
  profileUpdated: false,
  errorExist: false,
};

export const profileUpdateSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    profileUpdateStart: (state) => {
      state.profileUpdateLoad = true;
    },
    profileUpdateSuccess: (state, { payload }) => {
      state.profileUpdateLoad = false;
      state.profileUpdated = true;
      state.profileUpdateData = payload;
      state.profileUpdateError = null;
      state.errorExist = false;
    },
    profileUpdateFail: (state, { payload }) => {
      state.profileUpdateLoad = false;
      state.profileUpdateData = null;
      state.profileUpdateError = payload;
      state.errorExist = true;
      state.profileUpdated = false;
    },
    clearprofileUpdateErrorMessage: (state) => {
      state.profileUpdateError = "";
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  profileUpdateStart,
  profileUpdateSuccess,
  profileUpdateFail,
  clearprofileUpdateErrorMessage,
} = profileUpdateSlice.actions;

export default profileUpdateSlice.reducer;
