import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Headings from "./Headings";
import styles from "./UserDashboard.module.css";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import greenArrow from "../../../assets/greenArrow.svg";
import redArrow from "../../../assets/redArrow.svg";
import VerifyPayment from "../userDetails/VerifyPayment";
import { useSelector } from "react-redux";
import DashboardLoading from "./components/dashboardLoading";
import { useDispatch } from "react-redux";
import { FetchSingleUserInfo } from "../../../../utils/apiCalls/admin/user/fetchSingleUserInfo";
import {
  FetchingData,
  StopFetchingData,
} from "../../../../redux/admin/users/SingleUserInfoSlice";
import { formatPriceByCountrySymbol } from "../../../../utils/formatPrice";
import { getTimeFromTimestamp } from "./ClientList";
import MessageBox from "./components/MessageBox";
import EmailBox from "./components/EmailBox";
import ConfirmationBox from "../../../../components/confirmationBox/ConfirmationBox";
import { SuspendUser } from "../../../../utils/apiCalls/admin/user/SuspendUser";
import { cloneDeep } from "lodash";
import AdminTopUp from "./components/AdminTopUp";
import { getUserTransactionByDate } from "../../../../utils/apiCalls/admin/user/fetchUserTransaction";
import moment from "moment";
import { getAllClients } from "../../../../utils/apiCalls/admin/getAllClients";
import { suspendReset } from "../../../../redux/admin/users/suspendUserSlice";
import UserSenderIds from "./components/userSenderIds";
import Alert from "../../../../utils/Alert";

const UserDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [recentMessageHistory, setRecentMessageHistory] = useState([]);
  const [recentPaymentHistory, setRecentPaymentHistory] = useState([]);
  const [sendMessage, setSendMessage] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [openTopUp, setOpenTopUp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const startDate = "2023-01-01";
  const endDate = "2023-11-11";
  const perpage = 5;

  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  // for sender id
  const [showUserSenderIds, setShowUserSenderIds] = useState(false);

  const localData =
    localStorage.userDetails && JSON.parse(localStorage.userDetails);

  const {
    loading,
    failed,
    userDetails,
    balanceDetails,
    recentPayment,
    messageHistory,
    customPrice,
    recentTransactions,
    page,
  } = useSelector((state) => state.singleUserInfo);

  const suspendUser = useSelector((state) => state.SuspendUser);
  useEffect(() => {
    dispatch(FetchSingleUserInfo(state.email, startDate, endDate, perpage));

    setRecentMessageHistory(messageHistory);
    setCurrentPage(page);
  }, [
    state.email,
    startDate,
    endDate,
    perpage,
    dispatch,
    messageHistory,
    page,
  ]);

  useEffect(() => {
    if (!openTopUp && localData.user.role === "superadmin") {
      dispatch(FetchSingleUserInfo(state.email, startDate, endDate, perpage));
    }
  }, [
    dispatch,
    localData.user.role,
    openTopUp,
    state.email,
    startDate,
    endDate,
    perpage,
  ]);

  useEffect(() => {
    const temp = [...messageHistory.data];
    setRecentMessageHistory(temp.splice(0, 5));
  }, [messageHistory]);

  useEffect(() => {
    const temp = recentPayment.data.slice(0, 6);
    setRecentPaymentHistory(temp);
  }, [recentPayment]);

  useEffect(() => {
    if (confirmation) {
      dispatch(
        SuspendUser(
          userDetails.email,
          userDetails.status === "suspended" ? "suspended" : ""
        )
      );
    }
  }, [confirmation, dispatch, userDetails.email, userDetails.status]);

  useEffect(() => {
    if (failed === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [failed]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  useEffect(() => {
    if (!suspendUser.loading && suspendUser.status) {
      setAlertStatus({
        alertMessage: suspendUser.message,
        alertType: suspendUser.status === "success" ? "success" : "error",
        openAlert: true,
      });
      setTimeout(() => {
        setOpenDialogBox(false);
        setConfirmation(false);
        dispatch(suspendReset());
        dispatch(FetchSingleUserInfo(state.email, currentPage));
        setRecentMessageHistory(messageHistory);
      }, 2000);
    }
  }, [
    currentPage,
    dispatch,
    messageHistory,
    state.email,
    suspendUser.loading,
    suspendUser.message,
    suspendUser.status,
  ]);

  // useEffect(() => {
  //   const temp = cloneDeep(transactions);
  //   setTransactionDetails(temp.splice(0, 5));
  // }, [transactions]);

  const formatData = (value) => {
    return formatPriceByCountrySymbol("Nigeria", Number(value));
  };

  return (
    <>
      {loading ? (
        <DashboardLoading />
      ) : (
        <div className="">
          <Headings
            userDetails={userDetails}
            balanceDetails={balanceDetails}
            setSendMessage={setSendMessage}
            setSendEmail={setSendEmail}
            setOpenDialogBox={setOpenDialogBox}
            setAlertStatus={setAlertStatus}
          />

          {/* <div className="flex justify-end mt-5">
            <button
              className="px-4 py-1 text-white rounded-xl my-5 font-bold"
              style={{ backgroundColor: "rgb(59 130 246)" }}
              onClick={() =>
                navigate("singleusersenderid", {
                  state: { email: state.email },
                })
              }
            >
              View Sender IDs
            </button>
          </div> */}

          <div className=" flex flex-col lg:flex-row mt-[2.6rem] gap-12">
            <div
              className={`${styles.card1} w-[100%] lg:width-[25%] h-40 bg-white rounded-xl p-7`}
            >
              <main className={styles.orderInBox}>
                <span id={styles.boxA}>
                  <h5 className="text-gray-400">Wallet balance</h5>
                  <h3>
                    {userDetails.wallet_blc
                      ? formatData(Number(userDetails.wallet_blc))
                      : formatData(0)}
                  </h3>
                </span>
              </main>
              <div className="mt-7" onClick={() => setOpenTopUp(true)}>
                <span id={styles.btnA}>
                  <button>Top Up</button>
                </span>
              </div>
            </div>
            <div
              className={`${styles.card2} w-[100%] lg:width-[25%] h-40 bg-white rounded-xl p-7`}
            >
              <main className={styles.orderInBox}>
                <span id={styles.boxA}>
                  <h5 className="text-gray-400">Approved Payments</h5>
                  <h3>
                    {userDetails.approved_payment
                      ? formatData(+userDetails.approved_payment)
                      : formatData(0)}
                  </h3>
                </span>
                <span id={styles.boxA}>
                  <h5 className="text-gray-400">Pending Payments</h5>
                  <h3>
                    {userDetails.pending_payment
                      ? formatData(+userDetails.pending_payment)
                      : formatData(0)}
                  </h3>
                </span>
              </main>
              <div className="mt-7">
                <span id={styles.btnA}>
                  <button
                    style={{ color: "#7C836A" }}
                    onClick={() =>
                      navigate(`/admin/user-payments`, {
                        state: {
                          email: userDetails.email,
                          name: userDetails.first_name,
                        },
                      })
                    }
                  >
                    View All
                  </button>
                </span>
              </div>
            </div>
            <div
              className={`${styles.card3} w-[100%] lg:width-[25%] h-36 rounded-xl p-7 bg-cover`}
            >
              <main
                className="flex justify-between"
                style={{ flexWrap: "wrap" }}
              >
                <span id={styles.boxA}>
                  <h5 className="text-gray-400">Successful Messages</h5>
                  <h3>
                    {formatPriceByCountrySymbol(
                      "",
                      messageHistory.info?.delivered ?? 0
                    )}
                  </h3>
                </span>
                <span id={styles.boxA}>
                  <h5 className="text-gray-400">Pending Delivery</h5>
                  <h3>
                    {formatPriceByCountrySymbol(
                      "",
                      messageHistory.info?.pending ?? 0
                    )}
                  </h3>
                </span>
              </main>

              <div className="flex justify-between items-center mt-7">
                <span id={styles.boxA}>
                  {/* <button
                    style={{ color: "#6C6178" }}
                    onClick={() =>
                      navigate(`/admin/message-history`, {
                        state: userDetails.email,
                      })
                    }
                  >
                    View All
                  </button> */}
                  <h5 className="text-gray-400">Total Messages</h5>
                  <h3>
                    {+balanceDetails.message_sent
                      ? formatPriceByCountrySymbol(
                          "",
                          +balanceDetails.message_sent
                        )
                      : formatPriceByCountrySymbol("", 0)}
                  </h3>
                </span>
                <span id={styles.boxA} style={{}}>
                  <h5 className="text-gray-400">Failed Messages</h5>
                  <h3>
                    {formatPriceByCountrySymbol(
                      "",
                      messageHistory.info?.failed ?? 0
                    )}
                  </h3>
                </span>
              </div>
            </div>
          </div>

          <div className="py-2 mt-16">
            <div className="flex justify-between items-center h-[320px]">
              <div className="w-full px-3 h-[320px]">
                <div className="border rounded-xl h-[320px] pb-7 bg-white">
                  <span id={styles.row1}>
                    <small>Recent Payment</small>
                  </span>
                  {recentPayment.data.length > 0 ? (
                    <div className="h-full">
                      <div className="h-72 px-3">
                        {recentPaymentHistory.map((payment, index) => {
                          return (
                            <div key={index} className="py-2 border-b">
                              <div>
                                <div className="flex justify-between text-[10px]">
                                  <p>{payment.date}</p>
                                  <p
                                    style={{
                                      color:
                                        payment.status === "approved"
                                          ? "green"
                                          : payment.status === "declined" &&
                                            "red",
                                    }}
                                  >
                                    {payment.status}
                                  </p>
                                </div>
                                <div className="flex justify-between text-[12px] font-bold">
                                  <p>
                                    {formatPriceByCountrySymbol(
                                      "Nigeria",
                                      payment.amount
                                    )}
                                  </p>
                                  <p>{payment.channel}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div id={styles.btnBox} className="py-2">
                        <button
                          id={styles.viewAllBtn}
                          onClick={() =>
                            navigate(`/admin/user-payments`, {
                              state: {
                                email: userDetails.email,
                                name: userDetails.first_name,
                              },
                            })
                          }
                        >
                          View all
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="h-full">
                      <div className="h-72 py-5">
                        <p className="text-gray-700 text-center mt-10 py-5">
                          No Recent Payments
                        </p>
                      </div>
                      <div id={styles.btnBox} className="py-2">
                        <button id={styles.viewAllBtn2}></button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full px-3 h-full">
                <div className="border rounded-xl h-full bg-white">
                  <span id={styles.row1}>
                    <small>Recent Messages</small>
                  </span>
                  {recentMessageHistory.length > 0 ? (
                    <div className="h-full">
                      <div className="px-3 h-72">
                        {recentMessageHistory.map((history, index) => {
                          return (
                            <div key={index} className="py-1 border-b">
                              <div>
                                <div className="flex justify-between text-[10px]">
                                  <p>
                                    {getTimeFromTimestamp(history.created_at)}
                                  </p>
                                  <p>
                                    {formatPriceByCountrySymbol(
                                      "Nigeria",
                                      history.cost
                                    )}
                                  </p>
                                </div>
                                <div className="flex justify-between  text-[12px]">
                                  <div>
                                    <p className="text-[10px] font-bold">
                                      Sender ID
                                    </p>
                                    <p>{history.sender_id}</p>
                                  </div>
                                  <div>
                                    <p className="text-[10px] font-bold">
                                      Total Receipents
                                    </p>
                                    <p className="text-center">
                                      {history.total_reciever}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-[10px] font-bold">
                                      Total Pages
                                    </p>
                                    <p className="text-center">
                                      {history.pages}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div id={styles.btnBox} className="py-2">
                        <button
                          id={styles.viewAllBtn}
                          onClick={() =>
                            navigate(`/admin/message-history`, {
                              state: userDetails.email,
                            })
                          }
                        >
                          View all
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="h-full">
                      <div className="h-72 py-5">
                        <p className="text-gray-700 text-center mt-10 py-5">
                          No Recent Messages
                        </p>
                      </div>
                      <div id={styles.btnBox} className="py-2">
                        <button id={styles.viewAllBtn2}></button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="px-3 w-full h-full">
                <div className="border rounded-xl h-full bg-white">
                  <span id={styles.row1}>
                    <small>Transaction</small>
                  </span>
                  {recentTransactions.message &&
                  recentTransactions.message.length > 0 ? (
                    <div className="h-full">
                      <div className="h-72 px-3 overflow-hidden">
                        {recentTransactions.message.map((history, index) => {
                          return (
                            <div key={index} className="py-2 border-b">
                              <div>
                                <div className="flex justify-between text-[10px]">
                                  <p>
                                    {getTimeFromTimestamp(history.updated_at)}
                                  </p>
                                  <div className="flex">
                                    {history.type === "debit" ? (
                                      <img src={redArrow} alt="Debit" />
                                    ) : (
                                      <img src={greenArrow} alt="Credit" />
                                    )}
                                    <p className="ml-2">
                                      {formatPriceByCountrySymbol(
                                        "Nigeria",
                                        history.amount
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex justify-between text-[12px]">
                                  <p>{history.tag}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div id={styles.btnBox} className="py-2">
                        <button
                          id={styles.viewAllBtn}
                          onClick={() =>
                            navigate(`/admin/user-transactions/`, {
                              state: userDetails.email,
                            })
                          }
                        >
                          View all
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="h-full">
                      <div className="h-72 py-5">
                        <p className="text-gray-700 text-center mt-10 py-5">
                          No Recent Transactions
                        </p>
                      </div>
                      <div id={styles.btnBox} className="py-2">
                        <button id={styles.viewAllBtn2}></button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {sendMessage && (
        <MessageBox
          name={userDetails.first_name}
          phone={userDetails.phone}
          sendMessage={sendMessage}
          setSendMessage={setSendMessage}
        />
      )}

      {sendEmail && (
        <EmailBox
          email={userDetails.email}
          setSendEmail={setSendEmail}
          sendEmail={sendEmail}
          name={userDetails.first_name + " " + userDetails.last_name}
        />
      )}

      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
        />
      )}

      {openTopUp && (
        <AdminTopUp
          openTopUp={openTopUp}
          setOpenTopUp={setOpenTopUp}
          user={state.email}
        />
      )}

      {showUserSenderIds && (
        <UserSenderIds
          showUserSenderIds={showUserSenderIds}
          setShowUserSenderIds={setShowUserSenderIds}
          userEmail={state.email}
        />
      )}
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </>
  );
};

export default UserDashboard;
