import React, { useState } from "react";
import styles from "./uploadCsv.module.css";
import closeCircle from "../../../assets/closeCircle.svg";
import Papa from "papaparse";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { savePhoneGroup } from "../../../utils/apiCalls/phoneGroup";
import { useDispatch, useSelector } from "react-redux";
import Alertt from "../../../utils/Alert";
import {
  savePhoneNumbersInState,
  toggleShowSMScsv,
} from "../../../redux/sms/messageSummarySlice";

const UploadCsv = ({ closeModal }) => {
  const [groupName, setGroupName] = useState("");
  const [file, setFile] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [csvNumbers, setCSVNumbers] = useState([]);
  const dispatch = useDispatch();

  const saveLoading = useSelector((state) => state.phoneGroup.saveLoading);
  const errorMessage = useSelector((state) => state.phoneGroup.saveErrorData);
  const saveError = useSelector((state) => state.phoneGroup.saveError);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeHandler = (file) => {
    setFile(file);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const processedNumbers = [];
        results.data.map((d) => {
          processedNumbers.push(
            formatPhoneNumber(Object.values(d)[0].replace(/\D/g, ""))
          );
        });
        setCSVNumbers(processedNumbers.toString());
      },
    });
  };

  const save = () => {
    dispatch(savePhoneNumbersInState(csvNumbers));
    dispatch(toggleShowSMScsv());
  };

  return (
    <>
      <div className={`${styles.modalContainer}, ${styles.slideInTop}`}>
        <div className={styles.imgBox}>
          <img src={closeCircle} alt="" onClick={() => closeModal(false)} />
        </div>
        {
          <p
            style={{
              textAlign: "center",
              marginBottom: 10,
              fontSize: "small",
              color: "red",
            }}
          >
            {errorMessage}
          </p>
        }
        {/* <div className={styles.groupName}>
          <h3>Group Name</h3>
          <input
            type="text"
            id={styles.nameBox}
            style={{ borderColor: saveError && "red" }}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Phone Group Name"
          />
        </div> */}
        <p>
          Please ensure that only the phone numbers are in the file. E.g.
          2348012345678, 08123334567. The numbers should be in a column with a
          column name Phone.
        </p>
        <div className={styles.excel}>
          <h3>Excel Sheet Upload</h3>
          <span id={styles.fileUpload}>
            <input
              type="file"
              name="csv"
              accept=".csv"
              onChange={(e) => changeHandler(e.target.files[0])}
              className={styles.bannerUpload}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
        <div className={styles.btnClass}>
          <button disabled={saveLoading} id={styles.uploadBtn} onClick={save}>
            Upload Phone numbers
          </button>
        </div>
      </div>
      <Alertt
        handleClose={handleClose}
        openAlert={openAlert}
        Text="Error: All fields are required"
        Type="error"
      />
    </>
  );
};

export default UploadCsv;
