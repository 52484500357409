import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { sendSms } from "../../utils/apiCalls/sendSms";
import Alert from "../../utils/Alert";
import {
  resetDate,
  resetStatus,
  scheduleSuccess,
  sendError,
  sendSmsStart,
  sendSuccess,
  toggleShowSummary,
  updateSmsError,
} from "../../redux/sms/messageSummarySlice";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "../../utils/axios";
import moment from "moment";
import Modal from "../modal/Modal";
import { Box } from "@mui/material";

function ConfirmCard({ close, message, channel, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);

  const msgPayload = useSelector((state) => state.summary.msgSummary);

  const confirmMessage =
    msgPayload.date === undefined
      ? "Are you sure you want to send this message?"
      : `Are you sure you want to schedule this message for ${moment(
          msgPayload.date + " " + msgPayload.time
        ).format("LLL")}?`;

  const action = () => {
    dispatch(sendSmsStart());
    if (msgPayload.date === undefined || msgPayload.date === "") {
      axios
        .post("/user/sms/create", msgPayload)
        .then((response) => {
          const { data } = response;
          const ref = data.reference;
          dispatch(sendSuccess(ref));
          // setAlertOpen(true);
          // setAlertText(response.data.message);
          // setAlertType("success");
        })
        .catch((error) => {
          const { data } = error.response;
          dispatch(sendError(data));
        });
    } else {
      axios
        .post("/user/schedule-sms/create", msgPayload)
        .then((response) => {
          const { data } = response;
          dispatch(toggleShowSummary());
          dispatch(resetDate());
          dispatch(scheduleSuccess());
        })
        .catch((error) => {
          const { data } = error.response;
          dispatch(sendError(data.error));
        });
    }
  };

  return (
    <>
      <div className={styles.confirmContainer}>
        <Warning />

        <h3 style={{ textAlign: "center" }}>{message ?? confirmMessage}</h3>

        {loading ? (
          // <Modal>
          <div
            style={{ marginTop: "30px" }}
            className="flex flex-col items-center"
          >
            <p>Please Wait...</p>
            <CircularProgress />
          </div>
        ) : (
          // </Modal>
          <div className={styles.buttonSection}>
            <button onClick={close}>cancel</button>
            <button
              onClick={() => {
                action();
                setLoading(true);
              }}
            >
              continue
            </button>
          </div>
        )}
      </div>
      {alertOpen && (
        <Alert
          openAlert={alertOpen}
          Text={alertText}
          Type={alertType}
          handleClose={() => setAlertOpen(false)}
        />
      )}
    </>
  );
}

export default ConfirmCard;
