import React, { useEffect } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { SendEmail } from "../../utils/apiCalls/admin/send/SMS";

const AdminConfirmMailAll = ({ close, subject, message, rawMessage }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const { sendMailLoading } = useSelector((state) => state.sendMail);
  const data = {
    subject,
    message: "<p>" + rawMessage.join("</p><p>") + "</p>",
  };
  const sendMail = () => {
    dispatch(SendEmail(data));
  };

  useEffect(() => {
    sendMailLoading ? setLoader(true) : setLoader(false);
  }, [sendMailLoading]);

  return (
    <div className={styles.confirmContainer}>
      <Warning />
      <h3>Are you sure you want to send the message?</h3>
      <div className={styles.buttonSection}>
        <button onClick={close}>cancel</button>
        <button onClick={sendMail}>
          {" "}
          {loader ? <CircularProgress sx={{ color: "#C4C4C4" }} /> : "continue"}
        </button>
      </div>
    </div>
  );
};

export default AdminConfirmMailAll;
