import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import Button from "../../../components/Button/Button";
const All = () => {
  return (
    <div className="border-b">
      {[1, 2, 3, 4, 5].map((item, index) => (
        <div className="border-b" key={index}>
          <div className="flex p-4 space-x-4 justify-between" key={index}>
            <div className="flex flex-col">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[12px] mt-2">
                I have a new feature for this project.....
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#C4C4C4] text-[10px]">
                30 Dec 2024, 12:34
              </span>
              <div className="mt-2 self-end w-4 h-4 bg-[#4B1A7C] rounded-md flex items-center justify-center">
                <span className="text-[9px] text-white">1</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
function Inbox() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="flex justify-between lg:space-x-4">
      <div className="w-[25%] hidden lg:flex flex-col space-y-4">
        <input
          type="text"
          className=" border-2 rounded-xl p-2 border-gray-300"
          placeholder="search"
        />
        <div className="bg-white rounded border-gray-300">
          <Tabs
            value={value}
            onChange={handleChange}
            className="capitalize  px-4 "
          >
            <Tab label="All" className="capitalize" />
            <Tab label="Admins" />
            <Tab label="Clients" />
          </Tabs>
          <div className="mt-4 border-t">{value === 0 && <All />}</div>
        </div>
      </div>
      <div className="w-[100%] bg-white rounded border-b border-gray-300 lg:w-[50%] py-4">
        <div className="border-b  p-6 border-gray-300 h-[56px] flex space-x-[4rem] items-center">
          <span className="text-xs">
            <b>Lindsey Stroud</b>
          </span>
          <span className="text-[10px] text-[#90A0B7]">
            Typing a message...
          </span>
        </div>
        <div className="mt-4 p-6">
          <div className="flex flex-col w-[80%]">
            <div className="flex space-x-[2rem]">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[10px]">11:12</span>
            </div>
            <div className="h-auto  bg-[#4B1A7C] rounded-xl p-[1rem] text-white mt-2">
              Your idea for this application is nice!{" "}
            </div>
          </div>

          <div className="flex flex-col float-right mt-4 w-[80%]">
            <div className="flex space-x-[2rem]">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[10px]">11:12</span>
            </div>
            <div className="h-auto  border rounded-xl p-[1rem] text-[#334D6E] mt-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Id
              aliquet lectus proin nibh nisl. Suspendisse faucibe lorem ipsum
              dolor sit amee lorem ipsum dolor sit amee lorem ipsum dolor sit
              ameus interdum posuere lorem ipsum dolor sit amet consecteturg.
            </div>
          </div>

          <div className="flex flex-col w-[80%]">
            <div className="flex space-x-[2rem]">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[10px]">11:12</span>
            </div>
            <div className="h-auto  bg-[#4B1A7C] rounded-xl p-[1rem] text-white mt-2">
              Your idea for this application is nice!{" "}
            </div>
          </div>

          <div className="flex flex-col float-right mt-4 w-[80%]">
            <div className="flex space-x-[2rem]">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[10px]">11:12</span>
            </div>
            <div className="h-auto  border rounded-xl p-[1rem] text-[#334D6E] mt-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Id
              aliquet lectus proin nibh nisl. Suspendisse faucibe lorem ipsum
              dolor sit amee lorem ipsum dolor sit amee lorem ipsum dolor sit
              ameus interdum posuere lorem ipsum dolor sit amet consecteturg.
            </div>
          </div>

          <div className="flex flex-col float-right mt-4 w-[80%]">
            <div className="flex space-x-[2rem]">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[10px]">11:12</span>
            </div>
            <div className="h-auto  border rounded-xl p-[1rem] text-[#334D6E] mt-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Id
              aliquet lectus proin nibh nisl. Suspendisse faucibe lorem ipsum
              dolor sit amee lorem ipsum dolor sit amee lorem ipsum dolor sit
              ameus interdum posuere lorem ipsum dolor sit amet consecteturg.
            </div>
          </div>
          <div className="flex flex-col float-right mt-4 w-[80%]">
            <div className="flex space-x-[2rem]">
              <span className="text-xs">
                <b>Lindsey Stroud</b>
              </span>
              <span className="text-[#C4C4C4] text-[10px]">11:12</span>
            </div>
            <div className="h-auto  border rounded-xl p-[1rem] text-[#334D6E] mt-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Id
              aliquet lectus proin nibh nisl. Suspendisse faucibe lorem ipsum
              dolor sit amee lorem ipsum dolor sit amee lorem ipsum dolor sit
              ameus interdum posuere lorem ipsum dolor sit amet consecteturg.
            </div>
          </div>
        </div>
      </div>
      <div className="w-[25%] hidden lg:flex bg-white rounded-xl px-[1.5rem]">
        <div className="mt-[4rem] w-full flex flex-col items-center ">
          <img src="/images/profile.png" className="w-[4rem] h-[4rem]" />
          <p className="text-[#030229] font-semibold mt-2">John Deo</p>
          <br />
          <br />
          <hr className="w-full" />

          <p className="text-[#030229] font-semibold self-start mt-10">
            Contact Info
          </p>
          <p className="text-[#030229] self-start mt-10 text-opacity-70">
            <EmailIcon fontSize="small" /> kajope5182@ummoh.com
          </p>
          <hr className="w-full mt-4" />
          <p className="text-[#030229] self-start mt-4 text-opacity-70">
            <CallIcon fontSize="small" /> 33757005467
          </p>
          <hr className="w-full mt-4" />
          <p className="text-[#030229] self-start mt-4 text-opacity-70">
            <LocationOnIcon fontSize="small" /> 2239 Hog Camp Road Schaumburg
          </p>
          <Button
            name="View full details"
            margin="4rem 0 1rem"
            customclassName={`capitalize w-[80%] mt-10`}
          />
        </div>
      </div>
    </div>
  );
}

export default Inbox;
