import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/modal/Modal";
import styles from "./settings.module.css";
import settingsBanner from "../../../assets/settingsBanner.png";
import UserDetails from "./UserDetails";
import SettingsNav from "./SettingsNav";
import avatar from "../../../assets/avatar.svg";
import ChangePasswordModal from "./ChangePasswordModal";

const Settings = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const UserData = [
    {
      Fname: JSON.parse(localStorage.userDetails).user.name,
      Email: JSON.parse(localStorage.userDetails).user.email,
      Phone: JSON.parse(localStorage.userDetails).user.phone,
      Location: JSON.parse(localStorage.userDetails).user.location,
    },
  ];

  function closeModal(e) {
    if (e.target.id == "modal") {
      setShowChangePasswordModal(false);
    }
  }

  const navigate = useNavigate();

  return (
    <>
      {showChangePasswordModal && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <ChangePasswordModal close={setShowChangePasswordModal} />
        </Modal>
      )}

      <div className="mainWrap">
        <div id={styles.banner}>
          <img src={settingsBanner} alt="banner" />
        </div>
        <div className={styles.mainContentWrap}>
          <div className={styles.mainContents}>
            <SettingsNav />
            <div>
              <div className={styles.header}>
                <div className={styles.profile}>
                  <img src={avatar} alt="" id={styles.avatar} />
                </div>
                <div id={styles.tag}>
                  <h3>{JSON.parse(localStorage.userDetails).user.name}</h3>
                  <small>
                    {JSON.parse(localStorage.userDetails).user.role}
                  </small>
                </div>
                <div className={styles.btnBox}>
                  <button
                    id={styles.changepword}
                    onClick={() => setShowChangePasswordModal((prev) => !prev)}
                  >
                    Change password
                  </button>
                </div>
              </div>
              {UserData.map((User, index) => (
                <UserDetails
                  key={index}
                  Fname={User.Fname}
                  Email={User.Email}
                  Phone={User.Phone}
                  SenderID={User.SenderID}
                  Location={User.Location}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
