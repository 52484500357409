import { async } from "@firebase/util";
import React, { useEffect, useState } from "react";
import styles from "./UploadBox.module.css";
import closeCircle from "../../assets/closeCircle.svg";
import FormComponent from "./FormComponent";
import money from "../../assets/money.svg";
import myCalendar from "../../assets/myCalendar.svg";
import myAccount from "../../assets/myAccount.svg";
import exchange from "../../assets/exchange.svg";
import myWarning from "../../assets/myWarning.svg";
import CustomizedSelects from "../BasicSelectDropdownList/CustomizedSelects";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import axios from "../../utils/axios";
import Alert from "../../utils/Alert";
import moment from "moment";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    background: "url(../../assets/exchange.svg)",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "14px 26px 14px 2px",
    marginLeft: -9,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 5,
      borderColor: "#fff",
      boxShadow:
        " 0px 5px 8px 0px rgba(92, 92, 92, 0.2), 0px 7px 10px 0px rgba(92, 92, 92, 0.2)",
    },
  },
}));

function UploadBox({ closeModal, setMessage, setOpenAlert, handleClose }) {
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [sender, setSender] = useState("");
  const [channel, setChannel] = useState("");
  const [reference, setReference] = useState("");
  const [allow, setAllow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitButton, setSubmitButton] = useState("SUBMIT");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });


  useEffect(() => {
    if (amount !== "" && date !== "" && sender !== "" && channel !== "") {
      setAllow(true);
    } else {
      setAllow(false);
    }
  }, [amount, date, sender, channel, reference]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  function onSubmit(event) {
    setAllow(false);
    event.preventDefault();
    if (amount && date && sender && channel && reference) {
      setSubmitButton("PLEASE WAIT...");

      axios
        .post("user/fund/pop", {
          amount: amount,
          sender: sender,
          bank: "GTB",
          date: moment(date).format("YYYY-MM-DD"),
          channel: channel,
          reference,
        })
        .then((response) => {
          setLoading(false);
          const { message } = response.data;
          setMessage(message);
          setOpenAlert(true);
          setAlertStatus({
            alertMessage: "Payment uploaded successfully",
            alertType: "success",
            openAlert: true,
          });
          closeModal(false);
        })
        .catch((err) => {
          const { error } = err.response.data;

          setErrorMessage(`Error: ${error}`);
          setAlertStatus({
            alertMessage: "Error uploading payment details",
            alertType: "error",
            openAlert: true,
          });
          setAllow(true);
          setSubmitButton("SUBMIT");
        });
    } else {
      setAlertStatus({
        alertMessage: "Fill all fields",
        alertType: "error",
        openAlert: true,
      });
    }
  }
  const close = () => {
    closeModal(false);
  };

  const fields = [
    {
      Title: "Amount Paid",
      Holder: "Enter Amount Paid (e.g. 8755432)",
      Icon: money,
      state: setAmount,
      required: true,
    },
    {
      Title: "Date and Estimated Time of Payment (MM-DD-YYYY)",
      Holder: "2022-07-04 22:01:30",
      Icon: myCalendar,
      state: setDate,
      required: true,
    },
    {
      Title: "Depositor's Name/Your Bank Account Name (used for the transfer) ",
      Holder:
        "Depositors Name or the name of the account used to make the transfer",
      Icon: myWarning,
      state: setSender,
      required: true,
    },
    {
      Title:
        "Kindly enter any other details that might help us easily identify your payment such as reference number. ATM's location, Narration, Bank Name & Branch etc ",
      Holder: "E.g Account Name & Reference Number of Depositors Name",
      Icon: myWarning,
      state: setReference,
      required: false,
    },
  ];

  return (
    <div className={styles.uploadContainer} id={styles.uploadContainer}>
      <div className={styles.newBox}>
        <div className={styles.bttns} onClick={() => close()}>
          <img src={closeCircle} alt="" id={styles.closeBtnn} />
        </div>
        <form onSubmit={onSubmit}>
          <div id={styles.box} style={{ marginBottom: "30px" }}>
            <p style={{ color: "red", marginTop: 20 }}>{errorMessage}</p>
            <div id={styles.miniBox}>
              <FormControl sx={{ m: 1, width: "99.2%" }} variant="standard">
                <h3 id="label"> What payment channel did you use?</h3>
                <Select
                  labelId="demo-customized-select-label2"
                  id="demo-customized-select2"
                  route={channel}
                  name="channel"
                  onChange={(e) => setChannel(e.target.value)}
                  input={<BootstrapInput />}
                  required
                >
                  <MenuItem route="1" placeholder="which bank">
                    {/* <em>None</em> */}
                  </MenuItem>
                  <MenuItem value="ATM Transfer">ATM Transfer</MenuItem>
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                  <MenuItem value="Cash Deposit">Cash Deposit</MenuItem>
                  <MenuItem value="USSD">USSD</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            {fields.map((field, index) => (
              <FormComponent
                key={index}
                Title={field.Title}
                Holder={field.Holder}
                Icon={field.Icon}
                State={field.state}
                Required={field.required}
              />
            ))}
          </div>

          <button
            className={styles.uploadBtn}
            style={{ opacity: allow ? 1 : 0.5,}}
          >
            {submitButton}
          </button>
        </form>
      </div>
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleCloseAlert}
      />
    </div>
  );
}

export default UploadBox;
