import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getUserRateLoading: false,
  getUserRateData: [],
  getUserRateFailed: false,
  updateUserRateLoading: false,
  updateUserRateData: {},
  updateUserRateFailed: false,
  updateSuccess: false,
};

const getUserRates = createSlice({
  name: "userRates",
  initialState,
  reducers: {
    getUserRateStart: (state) => {
      state.getUserRateLoading = true;
    },
    getUserRateSuccessful: (state, { payload }) => {
      state.getUserRateLoading = false;
      state.getUserRateData = payload;
      state.getUserRateFailed = false;
    },
    getUserRateError: (state) => {
      state.getUserRateLoading = false;
      state.getUserRateData = null;
      state.getUserRateFailed = true;
    },
    UserRateUpdateStart: (state) => {
      state.updateUserRateLoading = true;
    },
    UserRateUpdateSuccessful: (state) => {
      state.updateUserRateLoading = false;
      // state.updateUserRateData = payload;
      state.updateSuccess = true;
      state.updateUserRateFailed = false;
    },
    resetUpdateSuccess: (state) => {
      state.updateSuccess = false;
    },
    UserRateUpdateError: (state) => {
      state.updateUserRateLoading = false;
      // state.updateUserRateData = null;
      state.updateUserRateFailed = true;
    },
  },
});

export const {
  getUserRateStart,
  getUserRateSuccessful,
  getUserRateError,
  UserRateUpdateStart,
  UserRateUpdateSuccessful,
  UserRateUpdateError,
  resetUpdateSuccess,
} = getUserRates.actions;

export default getUserRates.reducer;
