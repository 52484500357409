import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { UpdateBaseRate } from "../../utils/AdminApiCalls/priceRate";
import { CircularProgress } from "@mui/material";
import { ResetBaseRates } from "../../redux/admin/settingsEndpoints/priceSlice";
import Alert from "../../utils/Alert";

const ConfirmAdminEditBaseRate = ({
  close,
  promotional_rate,
  transactional_rate,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const priceRate = {
    pr_rate: promotional_rate,
    tr_rate: transactional_rate,
  };

  const { getBaseRateSuccess } = useSelector((state) => state.adminRates);

  useEffect(() => {
    if (getBaseRateSuccess) {
      dispatch(ResetBaseRates());
      setLoader(false);
    }
  }, [getBaseRateSuccess]);

  const continueBaseRateUpdate = () => {
    setLoader(true);
    dispatch(UpdateBaseRate(priceRate));
    setOpenSuccess(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <div className={styles.confirmContainer}>
      <Warning />
      <h3>Are you sure you want to Update Price?</h3>
      <div className={styles.buttonSection}>
        <button onClick={close}>cancel</button>
        <button onClick={continueBaseRateUpdate}>
          {loader ? <CircularProgress sx={{ color: "#C4C4C4" }} /> : "continue"}
        </button>
      </div>
    </div>
  );
};

export default ConfirmAdminEditBaseRate;
