import React, { useEffect, useState } from "react";
import styles from "./History.module.css";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import ArrowDown from "../../../assets/chevrondown.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetStatus } from "../../../redux/sms/messageSummarySlice";
import moment from "moment";
import Paginationn from "../../../components/Pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  getMessageHistory,
  getMessageSummaryBySenderId,
  getMessageSummaryPerpage,
  messageHistoryDownload,
  messageSummaryByMedium,
  messageSummaryByRoute,
} from "../../../utils/apiCalls/history";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";
import view from "../../../assets/view.svg";
import { useNavigate } from "react-router-dom";
import FilterDropdown from "../../../components/filter/FilterDropdown";
import PerpageDropdown from "../../../components/RowDropdown/PerpageDropdown";
import DownloadDropdown from "../../../components/DownloadDropdown/DownloadDropdown";
import search from "../../../assets/searchNew.svg";

function History() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const report = useSelector((state) => state.history.HistoryData);
  const loading = useSelector((state) => state.history.loading);

  const [startDay, setStartDay] = useState(
    moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [stopDay, setStopDay] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  );
  const [selectedSenderId, setSelectedSenderId] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const filterByRoute = [
    { label: "promotional", value: "promotional" },
    { label: "transactional", value: "transactional" },
  ];
  const filterByMedium = [
    { label: "WEB", value: "WEB" },
    { label: "API", value: "API" },
  ];
  const DownloadOptions = [
    { label: "CSV", value: "csv" },
    { label: "EXCEL", value: "excel" },
  ];

  useEffect(() => {
    dispatch(resetStatus());
  }, []);

  const apply = () => {
    setStartDay(moment(date[0].startDate).format("YYYY-MM-DD"));
    setStopDay(moment(date[0].endDate).format("YYYY-MM-DD"));
    setShowCalendar(false);
  };

  useEffect(() => {
    dispatch(getMessageHistory(startDay, stopDay, currentPage));
  }, [startDay, stopDay, currentPage, dispatch]);

  useEffect(
    (value) => {
      if (report && report.to) {
        setSelectedRow(value);
      }
    },
    [report, report.to]
  );

  const handleSenderIdChange = (e) => {
    setSelectedSenderId(e.target.value);
  };

  const handleSearch = () => {
    if (selectedSenderId === "") {
      dispatch(getMessageHistory(startDay, stopDay, currentPage));
    } else {
      dispatch(
        getMessageSummaryBySenderId(startDay, stopDay, selectedSenderId)
      );
    }
  };

  useEffect(() => {
    if (selectedSenderId === "") {
      dispatch(getMessageHistory(startDay, stopDay, currentPage));
    }
  }, [selectedSenderId, dispatch, startDay, stopDay, currentPage]);

  const handlePerpageChange = (value) => {
    setSelectedRow(value);
    dispatch(getMessageSummaryPerpage(startDay, stopDay, value));
  };

  const handleDownloadOptionChange = async (option) => {
    setSelectedDownloadOption(option);

    dispatch(
      messageHistoryDownload({
        start_date: startDay,
        end_date: stopDay,
        export_type: option,
      })
    );
  };

  const [selectedDownloadOption, setSelectedDownloadOption] = useState("csv");
  const [filterMedium, setFilterMedium] = useState("Medium");
  const [filterRoute, setFilterRoute] = useState("Route");
  const [selectedRow, setSelectedRow] = useState(report?.to);

  const handleFilterMediumChange = (value) => {
    setFilterMedium(value);
    dispatch(messageSummaryByMedium(startDay, stopDay, value));
  };

  const handleFilterRouteChange = (value) => {
    setFilterRoute(value);
    dispatch(messageSummaryByRoute(startDay, stopDay, value));
  };
  return (
    <>
      <div className="flex flex-row justify-end mb-3">
        <button>
          <DownloadDropdown
            items={DownloadOptions}
            selectedFilter={selectedDownloadOption}
            onFilterChange={handleDownloadOptionChange}
          />
        </button>
      </div>
      <div className={styles.transactionBox}>
        <div className={styles.tableSection}>
          <div className={styles.tableDetails}>
            <div className={styles.left}>
              <input
                className={styles.inputBox}
                type="text"
                placeholder={`Search by Sender ID...`}
                value={selectedSenderId}
                onChange={handleSenderIdChange}
              />
              <button
                onClick={handleSearch}
                style={{ marginLeft: "-23px", zIndex: 10, cursor: "pointer" }}
              >
                <img
                  src={search}
                  alt="search"
                  className="w-4 h-auto cursor-point"
                />
              </button>
            </div>
            <div className={styles.middleFilters}>
              <FilterDropdown
                items={filterByMedium}
                selectedFilter={filterMedium}
                onFilterChange={handleFilterMediumChange}
              />
              <FilterDropdown
                items={filterByRoute}
                selectedFilter={filterRoute}
                onFilterChange={handleFilterRouteChange}
              />
            </div>
            <div className={styles.tableActions}>
              <button
                className={styles.carlendarBtn}
                onClick={() => setShowCalendar(true)}
              >
                <div>
                  <CalendarIcon />
                  <p>
                    {moment(startDay).format("DD-MM-YYYY")} to{" "}
                    {moment(stopDay).format("DD-MM-YYYY")}
                  </p>
                </div>
                <img src={ArrowDown} alt="" />
              </button>
              {showCalendar && (
                <Modal>
                  <div className={styles.calendar}>
                    <DateRangePicker
                      showDateDisplay={false}
                      color="#A382C5"
                      rangeColors={"#4B1A7C"}
                      ranges={date}
                      onChange={(item) => setDate([item.selection])}
                    />
                    <div className={styles.buttonSection}>
                      <button onClick={() => setShowCalendar(false)}>
                        Cancel
                      </button>
                      <button onClick={() => apply()}>Apply</button>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
          {loading ? (
            <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
              <CircularProgress sx={{ color: "#C4C4C4" }} />
            </Box>
          ) : (
            <>
              {report?.data?.length === 0 ? (
                <center>
                  <Alert
                    sx={{ width: 350, marginBottom: "1rem" }}
                    severity="info"
                  >
                    No record. Start sending messages to see message history
                  </Alert>
                </center>
              ) : (
                <>
                  <div style={{ overflowX: "auto" }}>
                    <table
                      className={styles.customers}
                      style={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sender ID</th>
                          <th>Recipients</th>
                          <th>Time sent</th>
                          <th>Medium</th>
                          <th>Route</th>
                          <th>Total cost</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {report?.data?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.sender_id}</td>
                            <td>{item.total_reciever}</td>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              {moment(item.created_at).format(
                                "YYYY-MM-DD, HH:mm:ss"
                              )}
                            </td>
                            <td>{item.route}</td>
                            <td>{item.sender_type}</td>
                            <td>{item.total_cost}</td>
                            <td>{item.status}</td>
                            <td>
                              <span
                                onClick={() =>
                                  navigate(`/delivery-report/${item.reference}`)
                                }
                                style={{
                                  color: "var(--purple)",
                                  fontSize: "14px",
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                  gap: "3px",
                                }}
                              >
                                View
                                <img
                                  src={view}
                                  alt="img"
                                  style={{ width: "18px", height: "auto" }}
                                />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-row justify-between px-5">
                    <div className="flex flex-row gap-1 items-center">
                      <PerpageDropdown
                        selectedFilter={selectedRow}
                        onFilterChange={handlePerpageChange}
                        data={report}
                      />
                    </div>
                    <div style={{ marginLeft: "-130px" }}>
                      {report?.data?.length > 0 && (
                        <Paginationn
                          currentPage={report?.current_page}
                          totalCount={report?.total}
                          pageSize={report?.per_page}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      )}
                    </div>
                    <div></div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default History;
