import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendingLoading: false,
  sent: null,
  failed: null,
  sendingSuccessful: false
};

const SendInAppNotifications = createSlice({
  name: "IN APP MSG TO ALL USERS",
  initialState,
  reducers: {
    SendingNotification: (state) => {
      state.sendingLoading = true;
      state.sent = null;
      state.failed = null;
    },
    NotificationSent: (state, { payload }) => {
      state.sendingLoading = false;
      state.sendingSuccessful = true;
      state.sent = payload;
      state.failed = null;
    },
    NotificationFailed: (state, { payload }) => {
      state.sent = null;
      state.failed = payload;
      state.sendingLoading = false;
    },
    ResetInAppNotification: (state) => {
      state.sendingSuccessful = false;
    },
  },
});

export const {
  SendingNotification,
  NotificationFailed,
  NotificationSent,
  ResetInAppNotification,
} = SendInAppNotifications.actions;

export default SendInAppNotifications.reducer;
