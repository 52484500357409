import { forwardRef, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = ({ openAlert, Text, Type, handleClose }) => {
  const Alertt = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar
      open={openAlert}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      // message="All fields are required"
    >
      <Alertt onClose={handleClose} severity={Type} sx={{ width: "100%" }}>
        {Text || (Type === "success" ? "Successful" : "Error")}
      </Alertt>
    </Snackbar>
  );
};

export default Alert;
