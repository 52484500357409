import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearSignInErrorMessage,
  signInSuccess,
} from "../../../../redux/admin/auth/signInSlice";
import { setNewAdminPassword } from "../../../../utils/apiCalls/admin/auth/login";
import styles from "./signIn.module.css";

const AdminSetPassword = () => {
  let navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [buttonName, setButtonName] = useState("Sign In");
  const [canSubmit, setCanSubmit] = useState(false);
  const [passwordUnmatch, setPasswordUnmatch] = useState(false);

  const dispatch = useDispatch();

  const { signInLoading, signInError } = useSelector(
    (state) => state.adminSignIn
  );

  useEffect(() => {
    signInLoading ? setCanSubmit(false) : password !== "" && setCanSubmit(true);
  }, [signInLoading]);

  useEffect(() => {
    !signInLoading && setButtonName("Sign In");
    setCanSubmit(true);
  }, [signInSuccess]);

  useEffect(() => {
    password !== "" && new_password !== "" && confirm_password !== ""
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [password, new_password, confirm_password]);

  useEffect(() => {
    signInLoading && !signInError && navigate("/office");
  }, [signInLoading]);

  const submit = (e) => {
    e.preventDefault();

    const data = {
      password,
      new_password,
      confirm_password,
    };

    if (password === "" || new_password === "" || confirm_password === "") {
      return;
    } else if (new_password !== confirm_password) {
      setPasswordUnmatch(true);
    } else {
      setButtonName("Authenticating...");
      dispatch(setNewAdminPassword(data));
    }
  };

  return (
    <div className={styles.bground}>
      <form onSubmit={submit} className={styles.centerBox}>
        <div className={styles.arrangement}>
          <span>
            <h1>Sign In</h1>
            <h5>Sign in as an admin</h5>
          </span>
          <div className={styles.center}>
            <input
              id={styles.password}
              className={styles.input}
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <input
              id={styles.password}
              className={styles.input}
              type="password"
              placeholder="New Password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              value={new_password}
              required
            />
            <input
              id={styles.password}
              className={styles.input}
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirm_password}
              required
            />

            {passwordUnmatch && (
              <Alert severity="error">Passwords do not match</Alert>
            )}
          </div>
        </div>

        <div className={styles.buttonBox}>
          <button
            id={styles.button}
            style={{ opacity: !canSubmit ? 0.5 : 1 }}
            disabled={!canSubmit ? true : false}
          >
            {buttonName}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminSetPassword;


