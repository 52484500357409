import React, { useEffect, useState } from "react";
import Modal from "../../../components/modal/Modal";
import styles from "./deliveryReport.module.css";
import { Alert } from "@mui/material";
import forwardIcon from "../../../assets/forward.svg";
import backward from "../../../assets/backward.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetStatus } from "../../../redux/sms/messageSummarySlice";
import refresh from "../../../assets/refresh.svg";
import ResendFailedMsgs from "../../../components/confirmCard/ResendFailedMsgs";
import { useParams } from "react-router-dom";
import {
  deliveryReportDownload,
  getDeliveryReport,
  getDeliveryReportByNetwork,
  getDeliveryReportPerpage,
} from "../../../utils/apiCalls/history";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
import Paginationn from "../../../components/Pagination/Pagination";
import {
  forwardMessage,
  resendMessage,
} from "../../../redux/sms/SmsActionsSlice";
import { useNavigate } from "react-router-dom";
import { resetHStatus } from "../../../redux/sms/historySlice";
import FilterDropdown from "../../../components/filter/FilterDropdown";
import { formatPriceByCountrySymbol } from "../../../utils/formatPrice";
import PerpageDropdown from "../../../components/RowDropdown/PerpageDropdown";
import DownloadDropdown from "../../../components/DownloadDropdown/DownloadDropdown";
// import { ReactComponent as CalendarIcon } from "../../../assets/CalendarIcon.svg";
// import ArrowDown from "../../../assets/chevrondown.svg";
// import { DateRangePicker } from "react-date-range";

const DeliveryReport = () => {
  const navigate = useNavigate();
  let PageSize = 15;
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportData, setReportData] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredReportData, setFilteredReportData] = useState(reportData);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [status, setStatus] = useState("all");
  const [recipient, setRecipient] = useState("");
  const [network, setNetwork] = useState("");

  const dispatch = useDispatch();
  const { ref } = useParams();

  const {
    DeliveryReportData: report,
    loading,
    ReportSuccess: reportSuccess,
  } = useSelector((state) => state.history);

  const { forward, resend } = useSelector((state) => state.smsActions);

  useEffect(() => {
    resend && navigate("/instant-sms");
    forward && navigate("/instant-sms");
  }, [resend, forward]);

  useEffect(() => {
    reportSuccess && setReportData(report?.report_details);
    dispatch(resetHStatus());
  }, [reportSuccess]);

  useEffect(() => {
    dispatch(resetStatus());
    dispatch(getDeliveryReport(ref));
  }, [dispatch, ref]);

  const doForward = () => {
    dispatch(
      forwardMessage({ senderID: report.sender_id, message: report.message })
    );
  };

  const doResend = async () => {
    setResendLoading(true);
    let rNumbers = "";
    reportData.forEach((data, index) => {
      rNumbers += data.recipient;
      rNumbers += reportData.length - 1 === index ? "" : ",";
    });
    rNumbers.length > 0 &&
      dispatch(
        resendMessage({
          senderID: report.sender_id,
          message: report.message,
          numbers: rNumbers,
        })
      );
  };
  const [selectedRow, setSelectedRow] = useState(report?.to);

  const doResendFailedMsg = () => {
    if (parseInt(report.total_failed) === 0) {
      return;
    } else {
      setShowWarning(true);
    }
  };

  const handlePerpageChange = (value) => {
    setSelectedRow(value);
    dispatch(getDeliveryReportPerpage(ref, value));
  };

  const handleDownloadOptionChange = async (option) => {
    setSelectedDownloadOption(option);

    dispatch(deliveryReportDownload(ref, option));
  };

  const DownloadOptions = [
    { label: "CSV", value: "csv" },
    { label: "EXCEL", value: "excel" },
  ];

  const filterOptions = [
    { label: "All", value: "All" },
    { label: "Delivered", value: "delivered" },
    { label: "Failed", value: "failed" },
    { label: "Pending", value: "pending" },
  ];

  const filterByNetwork = [
    { label: "MTN", value: "MTN" },
    { label: "GLO", value: "GLO" },
    { label: "AIRTEL", value: "AIRTEL" },
    { label: "9MOBILE", value: "9MOBILE" },
  ];

  const [selectedDownloadOption, setSelectedDownloadOption] = useState("csv");
  const [filterNetwork, setFilterNetwork] = useState("Network");

  const handleFilterNetworkChange = (value) => {
    setFilterNetwork(value);
    dispatch(getDeliveryReportByNetwork(ref, value));
  };

  useEffect(() => {
    let filteredData = reportData;

    if (status !== "all") {
      filteredData = filteredData.filter((item) =>
        item.status.toLowerCase().includes(status.toLowerCase())
      );
    }

    if (recipient) {
      const lowerCaseRecipient = recipient.toLowerCase();
      filteredData = filteredData.filter((item) =>
        item.recipient.toLowerCase().includes(lowerCaseRecipient)
      );
    }
    if (network) {
      const lowerCaseNetwork = network.toLowerCase();
      filteredData = filteredData.filter((item) =>
        item.network.toLowerCase().includes(lowerCaseNetwork)
      );
    }

    if (selectedFilter !== "All") {
      filteredData = filteredData.filter((item) =>
        item.status.toLowerCase().includes(selectedFilter.toLowerCase())
      );
    }

    setFilteredReportData(filteredData);
  }, [selectedFilter, searchQuery, reportData, status, recipient, network]);

  console.log(report);

  return (
    <>
      {showWarning === true && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ResendFailedMsgs
            message="You are about to Resend all failed messages"
            close={() => setShowWarning(false)}
            reference={ref}
          />
        </Modal>
      )}
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : reportData?.length === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            There are no messages for this reference.
          </Alert>
        </center>
      ) : (
        <>
          <button>
            <DownloadDropdown
              items={DownloadOptions}
              selectedFilter={selectedDownloadOption}
              onFilterChange={handleDownloadOptionChange}
            />
          </button>
          <div className={styles.transactionBox}>
            <div className={styles.detailsBox}>
              <div className={styles.senderInfo}>
                <span className={styles.senderId}>
                  <h3>Sender ID: {report?.sender_id ?? 0}</h3>
                  <h6>via {report?.route}</h6>
                </span>
                <h5 id={styles.timeDate}>
                  {moment(report?.created_at).format("YYYY-MM-DD, h:mm:ss a")} |{" "}
                  {moment(report?.created_at).fromNow()}
                </h5>
                <span id={styles.amt}>
                  <span id={styles.amtValue}>
                    <small>Amount Spent: </small>
                    <h6>
                      {" "}
                      {formatPriceByCountrySymbol(
                        "Nigeria",
                        report?.total_cost
                      )}
                    </h6>
                  </span>
                  <span className={styles.total_receivers}>
                    <h6>
                      {report?.total_sent} recipient(s) | {report?.pages}{" "}
                      page(s)
                    </h6>
                  </span>
                </span>
              </div>
              <div className={styles.msgBoxContainer}>
                <div className={styles.actionBtns}>
                  <button id={styles.total}>
                    <h3>Sent</h3>
                    <small>{report?.total_sent || 0} ( 100%)</small>
                  </button>
                  <button id={styles.delivered}>
                    <h3>Delivered</h3>
                    <small>
                      {report?.total_delivered} (
                      {(
                        (report?.total_delivered / report?.total_sent) *
                        100
                      ).toFixed(2)}
                      %)
                    </small>
                  </button>
                  <button id={styles.failed}>
                    <h3>Failed</h3>
                    <small>
                      {report?.total_failed} (
                      {(report?.total_failed / report?.total_sent).toFixed(2)}
                      %)
                    </small>
                  </button>
                </div>
                <div className={styles.msgBoxx}>
                  <p>{report?.message}.</p>
                </div>
              </div>
              <div className={styles.detailss}>
                <span id={styles.actionz} onClick={() => doResendFailedMsg()}>
                  <img src={refresh} alt="img" />
                  <h3>Resend Failed Messages</h3>
                </span>
                <span
                  onClick={() => doForward()}
                  id={styles.actionz}
                  style={{ marginRight: "50px" }}
                >
                  <img src={forwardIcon} alt="svg" />
                  <h3>Forward</h3>
                </span>
                <span id={styles.actionz} onClick={() => doResend()}>
                  <img src={backward} alt="img" />
                  <h3>Edit & Resend</h3>
                </span>
              </div>
            </div>

            <div className={styles.tableSection}>
              <div className={styles.tableActionss}>
                <div className={styles.box1}>
                  <small>Delivery Report</small>
                  <FilterDropdown
                    items={filterOptions}
                    selectedFilter={selectedFilter}
                    onFilterChange={(newFilter) => {
                      setSelectedFilter(newFilter);
                    }}
                  />
                  <FilterDropdown
                    items={filterByNetwork}
                    selectedFilter={filterNetwork}
                    onFilterChange={handleFilterNetworkChange}
                  />
                  <input
                    className={styles.inputBox}
                    type="text"
                    placeholder={`Search by Recipient Number...`}
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                  />
                  <input
                    className={styles.inputBox}
                    type="text"
                    placeholder={`Search by Network...`}
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                  />
                </div>
                <div className={styles.box2}></div>
              </div>

              <div style={{ overflowX: "auto" }}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th>s/n</th>
                      <th>Recipient</th>
                      <th>Network</th>
                      <th>Status</th>
                      <th>Time sent</th>
                    </tr>
                  </thead>
                  {filteredReportData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.recipient}</td>
                      <td>{item.network}</td>
                      <td
                        style={{
                          color:
                            item.status === "delivered"
                              ? "#3A974C"
                              : item.status === "sent"
                              ? "#f29339"
                              : "#d11a2a",
                        }}
                      >
                        {item.status === "sent"
                          ? "awaiting_delivery"
                          : item.status}
                      </td>
                      <td>
                        {moment(item.createdAt).format("YYYY-MM-DD, h:mm:ss a")}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <div className="flex flex-row justify-between px-5">
                <PerpageDropdown
                  selectedFilter={selectedRow}
                  onFilterChange={handlePerpageChange}
                  data={reportData}
                />
                <div style={{ marginLeft: "-130px" }}>
                  {filteredReportData.length > 0 && (
                    <Paginationn
                      currentPage={currentPage}
                      totalCount={filteredReportData?.length}
                      pageSize={PageSize}
                      onPageChange={(newPage) => {
                        setCurrentPage(newPage);
                      }}
                    />
                  )}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryReport;
