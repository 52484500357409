import {
  loginFail,
  loginStart,
  loginSuccess,
} from "../../redux/auth/loginSlice";
import axios from "../axios";

export const login = (data) => async (dispatch) => {
  dispatch(loginStart());
  axios
    .post("/user/login", data)
    .then(function (response) {

      dispatch(loginSuccess(response.data));
    })
    .catch(function (error) {
 
      dispatch(loginFail("Invalid credentials provided"));
    });
};
