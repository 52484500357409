import React from "react";
import styles from "./footer.module.css";
import closeCircle from "../../assets/closeCircle.svg";
import { useSelector } from "react-redux";
import {
  formatFloat,
  formatPriceByCountrySymbol,
} from "../../../utils/formatPrice";

const HiddenBox = ({ closeModal, showCalc }) => {
  const calculateRate = useSelector((state) => state.calculateRate);
  return (
    <>
      <div className={styles.contentWrap}>
        <div id={styles.boxImg}>
          <img
            src={closeCircle}
            alt=""
            onClick={() => {
              showCalc(true);
              closeModal(false);
            }}
          />
        </div>
        <div className={styles.hBoxContents}>
          <div className={styles.lpgraph}>
            <p>Please note that this price is inclusive of all taxes.</p>
          </div>
          <div className={styles.Rpgraph}>
            <h2>Estimated price </h2>
            <h3> Number of messages</h3>
            <h4> {formatFloat(calculateRate.volume)} </h4>
            <h1>{formatFloat(calculateRate.volume)} volume =</h1>
            <h6>{formatPriceByCountrySymbol("Nigeria", calculateRate.rate)}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default HiddenBox;
