import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";
import Close from "../../../../assets/closeCircle.svg";

const SenderIDAction = ({
  setAction,
  setActionResponse,
  action,
  loading,
  setAlertStatus,
  setActionLoading,
  setSenderType,
  senderType,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  const verifyTrans = (type) => {
    if (senderType) {
      setActionResponse(type);
    } else {
      setAlertStatus({
        alertMessage: "Choose Sender Type",
        alertType: "error",
        openAlert: true,
      });
      setActionLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={action}
        onClose={() => setAction(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="w-[477px]">
          <div className="bg-white w-full rounded-xl">
            <div className="flex justify-between items-center py-3 border-b-2 px-[31px]">
              <h3 className="font-semibold text-gray-700">
                Approve or Decline Sender ID
              </h3>
              <button
                className="px-3 py-1 bg-red-700 text-white rounded"
                onClick={() => setAction(false)}
              >
                Close
              </button>
            </div>
            <div className="flex justify-center my-5">
              <FormControl>
                <FormLabel className="text-center">
                  Choose Sender Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={senderType}
                  onChange={(e) => setSenderType(e.target.value)}
                >
                  <FormControlLabel
                    value={"transactional"}
                    control={<Radio />}
                    label="Transactional"
                  />
                  <FormControlLabel
                    value={"promotional"}
                    control={<Radio />}
                    label="Promotional"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="w-full px-[31px] bg-gray-100 pb-4 rounded-xl">
              <div className="flex justify-between w-full py-3">
                <div className="border rounded-md border-[#D11A2A]">
                  <button
                    onClick={() => verifyTrans("declined")}
                    className="font-bold px-4 py-1 text-[#D11A2A]"
                  >
                    {loading ? <CircularProgress size={20} /> : "Decline ID"}
                  </button>
                </div>
                <div className="border border-[#4B1A7C] rounded-md">
                  <button
                    className={`text-[#4B1A7C] font-bold py-1 px-4
                  `}
                    onClick={() => verifyTrans("approved")}
                  >
                    {loading ? <CircularProgress size={20} /> : "Approve ID"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SenderIDAction;
