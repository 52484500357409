import {
  depositFetchingError,
  depositFetchingStart,
  depositFetchingSuccess,
} from "../../../redux/admin/deposits/depositsSlice";
import axios from "../../axios";

export const fetchDeposits = (type) => async (dispatch) => {
  dispatch(depositFetchingStart());

  axios
    .get(`/admin/payment/${type}`)
    .then((response) => {
      dispatch(depositFetchingSuccess(response.data.data));
    })
    .catch((error) => {
  
      const { message } = error.response.data;
      dispatch(depositFetchingError(message));
    });
};
