import { FONTSIZE } from "../../../constants/theme";

export default function Input({
  type,
  id,
  name,
  value,
  placeholder,
  onChange,
  p,
  br,
  bc,
  width,
  bordert,
  borderl,
  borderr,
  maxWidth,
  mr,
  mb,
  label,
}) {
  const { px18 } = FONTSIZE;

  return (
    <div>
      <label
        style={{
          marginBottom: "1.1rem",
          display: "block",
          fontSize: 24,
          fontWeight: 500,
          color: "#000000",
        }}
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          padding: p ?? ".8rem 1rem",
          borderRadius: br ?? "6px",
          backgroundColor: bc ?? "#ffffff",
          width: width ?? "100%",
          maxWidth: maxWidth,
          outline: 0,
          border: "1px solid #808082",
          borderTop: bordert ?? "1px solid #808082",
          borderLeft: borderl ?? "1px solid #808082",
          borderRight: borderr ?? "1px solid #808082",
          fontSize: px18,
          marginRight: mr,
          marginBottom: mb,
          borderColor: "#C4C4C4",
        }}
      />
    </div>
  );
}
