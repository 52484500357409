import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  Data: [],
  getScheduledMessagesError: false,
  errorExist: false,
  getScheduledMessagesErrorMessage: null,
  Success: false,
  getScheduledErrorMessage: "",
  deleteLoading: false,
  deleteSuccess: false,
  sendLoading: false,
  sendSuccess: false,
  deleteSuccessMessage: "",
  sendSuccessMessage: "",
  scheduleMessageSuccess: false,
  sendScheduledMessageFailed: false,
};

export const scheduledMessagesSlice = createSlice({
  name: "AdminScheduledMessages",
  initialState,
  reducers: {
    getScheduledMessagesStart: (state) => {
      state.loading = true;
    },
    getScheduledMessagesSuccess: (state, { payload }) => {
      state.loading = false;
      state.Data = payload;
      state.Success = true;
      state.getScheduledMessagesErrorMessage = null;
      state.errorExist = false;
    },
    getScheduledMessagesFail: (state, { payload }) => {
      state.loading = false;
      state.Success = false;
      state.Data = [];
      state.getScheduledErrorMessage = payload;
    },
    deleteScheduledMessagesStart: (state) => {
      state.deleteLoading = true;
    },
    deleteScheduledMessagesSuccess: (state, { payload }) => {
      state.deleteLoading = false;
      state.deleteSuccess = true;
      state.deleteSuccessMessage = payload;
    },
    sendScheduledMessagesStart: (state) => {
      state.sendLoading = true;
    },
    sendScheduledMessagesSuccess: (state, { payload }) => {
      state.sendLoading = false;
      state.sendSuccess = true;
      state.sendSuccessMessage = payload;
    },
    resetStatus: (state) => {
      state.sendSuccess = false;
      state.deleteSuccess = false;
    },
    scheduleSuccess: (state) => {
      state.scheduleMessageSuccess = true;
      state.sendLoading = false;
    },
    resetScheduleSuccess: (state) => {
      state.scheduleMessageSuccess = false;
    },
    sendScheduledMessageFailed: (state) => {
      state.sendLoading = false;
      state.sendScheduledMessageFailed = true;
    },
  },
});
export const {
  getScheduledMessagesStart,
  getScheduledMessagesSuccess,
  getScheduledMessagesFail,
  deleteScheduledMessagesStart,
  deleteScheduledMessagesSuccess,
  sendScheduledMessagesStart,
  sendScheduledMessagesSuccess,
  resetStatus,
  resetScheduleSuccess,
  scheduleSuccess,
  sendScheduledMessageFailed,
} = scheduledMessagesSlice.actions;

export default scheduledMessagesSlice.reducer;
