import { sendScheduledMessageFailed } from "../../redux/admin/messageScheduleSlices/scheduledMessagesSlice";
import {
  getScheduledSmsSuccess,
  getScheduledSmsFail,
  getScheduledSmsStart,
  deleteScheduledSmsStart,
  deleteScheduledSmsSuccess,
  sendScheduledSmsStart,
  sendScheduledSmsSuccess,
} from "../../redux/sms/scheduledSmsSlice";
import axios from "../axios";

export const getScheduledSms = () => async (dispatch) => {
  dispatch(getScheduledSmsStart());
  axios
    .get("user/schedule-sms/view")
    .then((response) => {
      const { data } = response.data;
      dispatch(getScheduledSmsSuccess(data));
    })
    .catch((error) => {
      const { data } = error.response.data;
      dispatch(getScheduledSmsFail(data));
    });
};

export const deleteScheduledSms = (id) => async (dispatch) => {
  dispatch(deleteScheduledSmsStart());
  // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
  // file deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
  axios.post(`user/schedule-sms/cancel/${id}`).then((response) => {
    const { message } = response.data;
    dispatch(deleteScheduledSmsSuccess(message));
    dispatch(getScheduledSms());
  });
};

export const sendScheduledSms = (id) => async (dispatch) => {
  dispatch(sendScheduledSmsStart());
  axios.post(`user/schedule-sms/send/${id}`)
  .then((response) => {
    const { message } = response.data;
    dispatch(sendScheduledSmsSuccess(message));
    dispatch(getScheduledSms());
  })
  .catch((error) => {
    const { message } = error.message
    dispatch(sendScheduledMessageFailed(message));
  })

};
