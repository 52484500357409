import React, { useEffect, useState } from "react";
import styles from "./settings.module.css";
import settingsBanner from "../../../assets/settingsBanner.png";
import SettingsNav from "./SettingsNav";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BasePriceRate,
  UpdateBaseRate,
} from "../../../../utils/AdminApiCalls/priceRate";
import Alert from "../../../../utils/Alert";
import Modal from "../../../../components/modal/Modal";
import ConfirmAdminEditBaseRate from "../../../../components/confirmCard/ConfirmAdminEditBaseRate";
import { Box, CircularProgress } from "@mui/material";
import { ResetBaseRates } from "../../../../redux/admin/settingsEndpoints/priceSlice";

const BasePrice = () => {
  const adminRole = JSON.parse(localStorage.getItem("userDetails")).user.role;
  const dispatch = useDispatch();
  const {
    baseRateData,
    getBaseRateSuccess,
    getBaseRateLoading,
    baseRateUpdateFailed,
    baseRateUpdateSuccess,
    baseRateUpdateData,
  } = useSelector((state) => state.adminRates);

  const initialPromotionalRates = baseRateData[0]?.promotional || [];
  const initialTransactionalRates = baseRateData[0]?.transactional || [];

  const [promotionalRates, setPromotionalRates] = useState(
    initialPromotionalRates
  );
  const [transactionalRates, setTransactionalRates] = useState(
    initialTransactionalRates
  );

  const [updated_at, setUpdatedAt] = useState("");
  const [updated_by, setUpdatedBy] = useState("");
  const [priceUpdate, setPriceUpdate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [rateInvalid, setRateInvalid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  useEffect(() => {
    dispatch(BasePriceRate());
  }, []);

  useEffect(() => {
    if (baseRateData[0]) {
      setPromotionalRates(baseRateData[0].promotional_rates || []);
      setTransactionalRates(baseRateData[0].transactional_rates || []);
      setUpdatedAt(baseRateData[0].updated_at);
      setUpdatedBy(baseRateData[0].updated_by);
      setShowWarning(false);
    }
  }, [baseRateData]);

  const updateRate = () => {
    const hasInvalidPromotionalRate = promotionalRates.some(
      (rate) => rate.rate <= 0
    );

    const hasInvalidTransactionalRate = transactionalRates.some(
      (rate) => rate.rate <= 0
    );

    if (hasInvalidPromotionalRate || hasInvalidTransactionalRate) {
      setRateInvalid(true);
    } else {
      const data = {
        promotional: promotionalRates,
        transactional: transactionalRates,
      };

      dispatch(UpdateBaseRate(data));
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
    setOpenSuccess(false);
  };

  const Timeout = () => {
    setTimeout(() => {
      setShowWarning(false);
      dispatch(ResetBaseRates());
    }, 2000);
  };

  useEffect(() => {
    if (baseRateUpdateData) {
      if (baseRateUpdateSuccess) {
        setAlertStatus({
          alertMessage: baseRateUpdateData,
          alertType: "success",
          openAlert: true,
        });
        Timeout();
      } else if (baseRateUpdateFailed) {
        setAlertStatus({
          alertMessage: baseRateUpdateFailed,
          alertType: "error",
          openAlert: true,
        });
        Timeout();
      }
    }
  }, [baseRateUpdateData]);
  return (
    <>
      {showWarning && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ConfirmAdminEditBaseRate
            close={() => setShowWarning(false)}
            promotional_rate={promotionalRates}
            transactional_rate={transactionalRates}
          />
        </Modal>
      )}

      <div className="mainWrap">
        <div id={styles.banner}>
          <img src={settingsBanner} alt="banner" />
        </div>
        <div className={styles.mainContentWrap}>
          <div className={styles.mainContents}>
            <SettingsNav />
            {getBaseRateLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "300px",
                  paddingTop: "100px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : Object.keys(baseRateData).length === 0 ? (
              <center>
                <Alert
                  sx={{ width: 350, marginBottom: "1rem" }}
                  severity="info"
                >
                  No record available at the moment
                </Alert>
              </center>
            ) : (
              <div className={styles.body}>
                <div className={styles.routes}>
                  {promotionalRates && promotionalRates.length > 0 && (
                    <div className={styles.promotionalRoutes}>
                      <span id={styles.labelBox}>
                        <small>Promotional Rate </small>
                      </span>
                      <span className={styles.routesContainer}>
                        {promotionalRates.map((promotionalRate, index) => (
                          <div className={styles.mtnRoute} key={index}>
                            <small>{promotionalRate.network}</small>
                            <input
                              id={styles.inputBox}
                              type="text"
                              placeholder="0.45273663"
                              onChange={(e) => {
                                const updatedRates = [...promotionalRates];
                                updatedRates[index] = {
                                  ...updatedRates[index],
                                  rate: e.target.value,
                                };
                                setPromotionalRates(updatedRates);
                              }}
                              value={promotionalRate.rate}
                              required
                            />
                          </div>
                        ))}
                      </span>
                    </div>
                  )}
                  {transactionalRates && transactionalRates.length > 0 && (
                    <div className={styles.transactionalRoutes}>
                      <span id={styles.labelBox}>
                        <small>Transactional Rate </small>
                      </span>
                      <span className={styles.routesContainer}>
                        {transactionalRates.map((transactionalRate, index) => (
                          <div className={styles.mtnRoute} key={index}>
                            <input
                              id={styles.inputBox}
                              type="text"
                              placeholder="0.45273663"
                              onChange={(e) => {
                                const updatedRates = [...transactionalRates];
                                updatedRates[index] = {
                                  ...updatedRates[index],
                                  rate: e.target.value,
                                };
                                setTransactionalRates(updatedRates);
                              }}
                              value={transactionalRate.rate}
                              required
                            />
                          </div>
                        ))}
                      </span>
                    </div>
                  )}
                </div>

                <div className={styles.bottom}>
                  {adminRole === "superadmin" && (
                    <button id={styles.updateBtn} onClick={updateRate}>
                      Update Price
                    </button>
                  )}
                  <small value={updated_at}>
                    Last updated {moment(updated_at).format("lll")}
                  </small>
                  <small style={{ marginTop: "-30px" }} value={updated_by}>
                    By {updated_by}
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>
        <Alert
          openAlert={alertStatus.openAlert}
          Text={alertStatus.alertMessage}
          Type={alertStatus.alertType}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default BasePrice;
