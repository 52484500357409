import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "../../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminUsers,
  registerAdmin,
} from "../../../utils/AdminApiCalls/registerAdmin";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../../utils/Alert";
import Button from "../../../components/Button/Button";
import { FONTSIZE } from "../../../constants/theme";
import SideCardAdmin from "./SideCardAdmin";
import { set } from "lodash";
import SmsBox from "./userDetails/components/InAppMsg";
import AdminConfirmAddUser from "../../../components/confirmCard/AdminConfirmAddUser";
import Modal from "../../../components/modal/Modal";
import { clearRegisteringAdminErrorMessage } from "../../../redux/admin/auth/addAdminSlice";
import { Box, CircularProgress } from "@mui/material";

function AddUser() {
  const [age, setAge] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [addUserCard, setAddUserCard] = useState(false);
  const [viewAdminCard, setviewAdminCard] = useState(false);
  const [singleUserData, setSingleUserData] = useState({});
  const [sendSms, setSendSms] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  const adminRole = JSON.parse(localStorage.getItem("userDetails")).user.role;

  const dispatch = useDispatch();
  const navigate = useNavigate;

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage(false);
  };

  const {
    adminRegistered,
    loading,
    getAdminLoading,
    getAllAdminData,
    registeringAdminError,
  } = useSelector((state) => state.addAdmin);

  useEffect(() => {
    name !== "" && email !== "" && role !== ""
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [email, name, role]);

  useEffect(() => {
    loading ? setCanSubmit(false) : email !== "" && setCanSubmit(true);
  }, [loading]);

  useEffect(() => {
    if (adminRegistered) {
      setOpenSuccess(true);
      setName("");
      setEmail("");
      setPhone("");
      setRole("");
    }
    dispatch(getAdminUsers());
  }, [adminRegistered]);

  useEffect(() => {
    registeringAdminError && setErrorMessage(true);
    setShowWarning(false);
    let timer =
      registeringAdminError &&
      setTimeout(() => dispatch(clearRegisteringAdminErrorMessage()), 3000);
    return () => clearTimeout(timer);
  }, [registeringAdminError]);

  const performAction = (e) => {
    e.preventDefault();
    setShowWarning(true);
  };

  return (
    <>
      {showWarning && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <AdminConfirmAddUser
            close={() => setShowWarning(false)}
            email={email}
            name={name}
            phone={phone}
            role={role}
          />
        </Modal>
      )}
      {getAdminLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : getAllAdminData === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No record available at the moment
          </Alert>
        </center>
      ) : (
        <div>
          <big style={{ fontWeight: "600", fontSize: "var(--fs-500)" }}>
            Add Users
          </big>
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
            <div style={{ width: !addUserCard ? "100%" : "75%" }}>
              <div className="w-full shadow bg-white rounded">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Search users"
                  className="border border-[#C4C4C4] shadow p-3 w-[50%] rounded-[10px] m-4"
                />
                {adminRole === "superadmin" ? (
                  <Button
                    name={"ADD USER"}
                    style={{
                      fontSize: "18px",
                      padding: "9px 20px",
                    }}
                    onClick={() => {
                      setviewAdminCard(false);
                      setAddUserCard(true);
                    }}
                  />
                ) : (
                  ""
                )}

                <div className="border-gray-200 w-full rounded bg-white overflow-x-auto">
                  <table className="w-full leading-normal ">
                    <thead className=" text-xs font-semibold  bg-red-500 tracking-wider text-left px-5 py-3 hover:cursor-pointer uppercase border-b-2 ">
                      <tr
                        className="border-b border-gray bg-red-500"
                        style={{ backgroundColor: "red" }}
                      >
                        <th
                          scope="col"
                          className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                        >
                          User Type
                        </th>
                        <th
                          scope="col"
                          className=" border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 bg-gray-100 text-left text-xs font-semibold text-black  tracking-wider"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="px-5">
                      {getAllAdminData.length > 0 &&
                        getAllAdminData.map((data, index) => (
                          <tr
                            key={index}
                            className="hover:cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              setAddUserCard(false);
                              setviewAdminCard(true);
                              setSingleUserData(data);
                            }}
                          >
                            <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                              <p className="text-black whitespace-no-wrap font-semibold">
                                {data.name}
                              </p>
                            </td>
                            <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                              <p className="text-black whitespace-no-wrap font-semibold">
                                {data.email}
                              </p>
                            </td>

                            <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                              <p className="text-black whitespace-no-wrap font-semibold">
                                {data.phone}
                              </p>
                            </td>
                            <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                              <p className="text-black whitespace-no-wrap font-semibold">
                                {data.role === "superadmin"
                                  ? "Super Admin"
                                  : "Admin"}
                              </p>
                            </td>
                            <td className="py-4 px-3 border-b border-gray-200 text-gray-900 text-sm ">
                              <p
                                style={{
                                  color:
                                    data.status === "active"
                                      ? "#03CC2F"
                                      : "#FFBF00",
                                  fontWeight: "400",
                                }}
                              >
                                {data.status}
                              </p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {addUserCard && (
              <div
                className="w-full lg:w-[25%]"
                style={{ display: addUserCard ? "block" : "none" }}
              >
                <div className="rounded-lg shadow flex flex-col bg-white p-4">
                  <div className="flex items-center justify-between">
                    <h3 className="font-semibold">Add User</h3>
                    <button className="bg-[#fef3f5] w-10 h-10 rounded-full">
                      <CloseIcon
                        fontSize="10"
                        onClick={() => setAddUserCard(false)}
                      />
                    </button>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center rounded-full w-[6.375rem] h-[6.375rem] bg-[#F7F7F8]">
                      <CameraAltIcon />
                    </div>
                    <div className="w-full">
                      <div className="bg-white">
                        <form onSubmit={(e) => performAction(e)}>
                          <div className="mb-12">
                            <label
                              for="name"
                              className="block mb-2 font-bold text-gray-600"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="John "
                              className="bg-[#C4C4C426]  shadow p-3 w-full rounded mb-"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              required
                            />
                          </div>

                          <div className="mb-12">
                            <label
                              for="twitter"
                              className="block mb-2 font-bold text-gray-600"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id="twitter"
                              name="twitter"
                              placeholder="Example@gmail.com"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              className="bg-[#C4C4C426]  shadow p-3 w-full rounded mb-"
                              required
                            />
                          </div>
                          <div className="mb-12">
                            <label
                              for="twitter"
                              className="block mb-2 font-bold text-gray-600"
                            >
                              Phone
                            </label>
                            <Input
                              type="number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                            <label
                              for="twitter"
                              className="block mb-2 font-bold text-gray-600"
                              style={{ marginTop: "50px" }}
                            >
                              Add Role
                            </label>

                            <Select
                              value={role}
                              onChange={(e) => setRole(e.target.value)}
                              required
                              displayEmpty
                              fullWidth
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={"superadmin"}>
                                Super Admin
                              </MenuItem>
                              <MenuItem value={"admin"}>Admin</MenuItem>
                            </Select>
                          </div>
                          <button
                            style={{
                              cursor: "pointer",
                              opacity: !canSubmit ? 0.5 : 1,
                            }}
                            disabled={!canSubmit ? true : false}
                            className="block w-full bg-[#4B1A7C] text-white font-bold p-4 rounded-lg"
                            // onClick={() => }
                          >
                            {adminRegistered ? "Please wait..." : "Add User"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {Object.keys(singleUserData).length > 0 && viewAdminCard && (
              <SideCardAdmin
                singleUserData={singleUserData}
                setSingleUserData={setSingleUserData}
                setSendSms={setSendSms}
                status={singleUserData.status}
              />
            )}

            {sendSms && (
              <SmsBox
                singleUserData={singleUserData}
                senderId={singleUserData.senderId}
                sendSms={sendSms}
                setSendSms={setSendSms}
                style={style}
                name={singleUserData.first_name}
              />
            )}
          </div>

          <Alert
            openAlert={openSuccess}
            Text={"User has been Created"}
            Type={"success"}
            handleClose={handleClose}
          />
          <Alert
            openAlert={errorMessage}
            Text={"Admin Registration Failed"}
            Type={"error"}
            handleClose={handleCloseError}
          />
        </div>
      )}
    </>
  );
}

export default AddUser;
