import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "../footer/footer.module.css";
import { Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SetType } from "../../../redux/calculateRate/calculateRate";

export default function CheckboxLabels() {

  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState("a");

  const calculateRate = useSelector(state => state.calculateRate)

  useEffect(() => {
    setSelectedValue(calculateRate.type)
  }, [])

  useEffect(() => {
    if(selectedValue !== 'a'){
      dispatch(SetType(selectedValue))
    }
  }, [selectedValue])

  return (
    <FormGroup className={styles.formBox}>
      <span id={styles.promotional}>
        <FormControlLabel
          control={<Radio />}
          // label="Promotional SMS delivery"
          value="promotional"
          onChange={(e) => setSelectedValue("promotional")}
          checked={selectedValue === "promotional"}
          // sx={{ size: 190 }}
          id={styles.textSize}
        />

        <div id={styles.promHide}>
          <big>Promotional SMS Delivery</big>
        </div>
        <p>
          Suitable for non-urgent, non-time-sensitive messages like promotions,
          announcements, or CRM messages.
        </p>
      </span>
      <span id={styles.promotional}>
        <FormControlLabel
          control={<Radio />}
          // label="Priority SMS delivery"
          value="transactional"
          checked={selectedValue === "transactional"}
          onChange={(e) => setSelectedValue("transactional")}
        />
        <div id={styles.promHide}>
          <big>Priority SMS delivery</big>
        </div>
        <p>
          Delivery to numbers on DND. Solely for critical messages, including
          transactional messages, time-sensitive alerts, and one-time PINs
          (OTPs).
        </p>
      </span>
    </FormGroup>
  );
}
