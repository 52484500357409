import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  historyData: [],
  showCalendar: false,
  failed: "",
};

export const userMessageHistorySlice = createSlice({
  name: "userMessageHistory",
  initialState,
  reducers: {
    getMessageHistoryStart: (state) => {
      state.loading = true;
    },
    getMessageHistorySuccess: (state, { payload }) => {
      state.loading = false;
      state.historyData = payload;
      state.failed = "";
    },
    getMessageHistoryFailed: (state, { payload }) => {
      state.loading = false;
      state.historyData = [];
      state.failed = payload;
    },
    setShowCalendar: (state, { payload }) => {
      state.showCalendar = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getMessageHistoryStart,
  getMessageHistorySuccess,
  setShowCalendar,
  getMessageHistoryFailed,
} = userMessageHistorySlice.actions;

export default userMessageHistorySlice.reducer;
