import React, { useEffect } from "react";
import styles from "./modalCss.module.css";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendScheduledSms } from "../../../utils/apiCalls/scheduledSms";
const SendNowModal = ({ closeModal, messageID, scheduled }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.scheduledSms.sendLoading);
  const sendSuccess = useSelector((state) => state.scheduledSms.sendSuccess);

  useEffect(() => {
    if (sendSuccess) {
      closeModal(false);
    } else {
    }
  }, [sendSuccess]);
  const onSend = () => {
    dispatch(sendScheduledSms(messageID));
    sendSuccess && closeModal(false);
  };
  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "20px" }}>
        <Warning />
        <h3>Are you sure you want to send this message now?</h3>
        <div className={styles.buttonSection}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button
            onClick={() => onSend()}
            disabled={loading}
            style={{ opacity: loading ? 0.5 : 1 }}
          >
            {loading ? "Please wait..." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SendNowModal;
