import { useEffect } from "react";

import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import OnboardingLayout from "../../../components/OnboardingLayout/OnboardingLayout.js";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { FONTSIZE, COLORS } from "../../../constants/theme";
import { Link, useNavigate } from "react-router-dom";
import {
  clearLoginErrorMessage,
  increment,
  loginFail,
  loginStart,
  loginSuccess,
} from "../../../redux/auth/loginSlice";
import styles from "./Signin.module.css";
import { signWithGoogle } from "../../../utils/apiCalls/signGoogle";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../../utils/axios";
import { login } from "../../../utils/apiCalls/login";

const { primary, lightPurple } = COLORS;
const { px40, px18, px15 } = FONTSIZE;

export default function Signin() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const dispatch = useDispatch();

  const { loginData, loginError, loginLoad } = useSelector(
    (state) => state.login
  );
  const resetSuccessMessage = useSelector(
    (state) => state.forgotPassword.resetPasswordData
  );
  function submit(e) {
    e.preventDefault();
    dispatch(login({ email, password }));
  }

  useEffect(() => {
    email !== "" && password !== "" ? setCanSubmit(true) : setCanSubmit(false);
  }, [email, password]);

  useEffect(() => {
    let timer =
      loginError && setTimeout(() => dispatch(clearLoginErrorMessage()), 3000);
    return () => clearTimeout(timer);
  }, [loginError]);

  useEffect(() => {
    const user = localStorage.userDetails;
    user && navigate("/dashboard");
  }, [localStorage.userDetails]);
  return (
    <div
      className={`w-[100%] justify-end ${styles.signinContainer}`}
      style={{ display: "flex" }}
    >
      <OnboardingLayout />

      <div
        className={`${styles.container}`}
        style={{ position: "relative", padding: "8rem 4rem 4rem" }}
      >
        <div className={styles.detailsBox}>
          <div className={styles.form} style={{ width: "500px" }}>
            <div style={{ textAlign: "center" }}>
              <h1
                style={{
                  fontSize: px40,
                  color: primary,
                  marginBottom: ".5rem",
                }}
              >
                Nice to see you!
              </h1>
              <p style={{ fontSize: px18, marginBottom: "4rem" }}>
                Login to continue
              </p>
              {resetSuccessMessage && (
                <Alert severity="success">{resetSuccessMessage}</Alert>
              )}
            </div>
            <form onSubmit={submit}>
              <div style={{ marginBottom: "1rem" }}>
                <div style={{ marginBottom: "2rem" }}>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    required
                  />
                </div>
                <div>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div></div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // alignItems: "flex-end",
                  fontSize: px15,
                  // marginBottom: "0.75rem",
                }}
              >
                {/* <div style={{ display: "flex", alignItems: "center" }}>
               <input
                type="checkbox"
                id="remember"
                name="remember"
                style={{ display: "block", height: "19px", width: "19px" }}
                />
                   <div style={{ marginLeft: "6px" }}>Remember me</div>
                  </div> */}
                <div style={{ color: primary }}>
                  <Link
                    to="/forgot-password"
                    style={{ textDecoration: "none" }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              {loginError && (
                <Alert severity="error" className="mb-8">
                  {loginError}
                </Alert>
              )}
              <Button
                type="submit"
                name={
                  loginLoad ? (
                    <CircularProgress sx={{ color: "white" }} />
                  ) : (
                    "Login"
                  )
                }
                margin="0 0 6.785rem"
                style={{
                  width: "100%",
                  opacity: !canSubmit ? 0.5 : 1,
                  marginTop:"30px",
                }}
                disabled={!canSubmit ? true : false}
              />
            </form>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-4.75rem",
              }}
            >
              <div style={{ fontSize: px18 }}>
                No account yet?{" "}
                <Link to="/signup" style={{ textDecoration: "none" }}>
                  <span style={{ color: "var(--purple)" }}> Register</span>
                </Link>
              </div>
              <Link id={styles.link} to="/" style={{ textDecoration: "none" }}>
                or Click here to go to homepage
              </Link>
            </div>
          </div>
        </div>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="50.5261"
              cy="34.5261"
              r="50"
              transform="rotate(15.4714 50.5261 34.5261)"
              fill="#4B1A7C"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
