import React from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { SuspendAdmin } from "../../utils/apiCalls/admin/user/SuspendUser";

const SuspendAdminConfimation = ({ close, suspendAdmin, status, email }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const suspendAdminOnClick = () => {
    if (!loader) {
      setLoader(true);
      dispatch(SuspendAdmin(email, status === "suspended" ? "suspended" : ""));
    }
  };

  return (
    <div className={styles.confirmContainer}>
      <Warning />
      <h3>
        Are you sure you want to
        {status === "active" ? " suspend" : " UnSuspend"} this Admin
      </h3>
      <div className={styles.buttonSection}>
        <button onClick={close}>cancel</button>
        <button onClick={suspendAdminOnClick}>
          {" "}
          {loader ? <CircularProgress sx={{ color: "#C4C4C4" }} /> : "continue"}
        </button>
      </div>
    </div>
  );
};

export default SuspendAdminConfimation;
