import React from 'react'
import styles from "./dark.module.css"
import tickCircle from "../../assets/tickCircle.svg"
const ButtonRows = ({Txt}) => {
  return (
    <div className={styles.listItems}>
    <img src={tickCircle} alt='tick'/>
      <button id={styles.btns}>{Txt}</button>
    </div>
  );
}

export default ButtonRows