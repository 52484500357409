import React, { useState } from "react";
import key from "../../../../assets/key.svg";
import closeCircle from "../../../../assets/closeCircle.svg";
import copy from "../../../../assets/copy.svg";
import styles from "./create.module.css";
import { useDispatch, useSelector } from "react-redux";
import { reloadAPIS, reset } from "../../../../redux/api/createAPIslice";
const CreatedApi = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);

  const response = useSelector((state) => state.APIs.generateResponse);
  const done = () => {
    dispatch(reset());
    dispatch(reloadAPIS(false));
  };

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(response.secret_key)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className={styles.mainCont}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            cursor: "pointer",
          }}
        >
          <img src={closeCircle} alt="" id="closeBtnn" onClick={() => done()} />
        </div>
        <div className={styles.miniCont}>
          <div className={styles.imgCon}>
            <img src={key} alt="" />
          </div>
          <h1>
            {response?.secret_key ? "API Key Created" : "API key updated"}
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {response?.secret_key && (
              <h4>Please copy this key and save it somewhere safe.</h4>
            )}
            {response?.secret_key && (
              <h5>For security reasons, we can not show it to you again.</h5>
            )}
          </div>

          <span id={styles.keyText}>
            {response?.secret_key && (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={handleCopyClick}
              >
                <img src={copy} alt="" />
              </span>
            )}
            <p>{response?.secret_key || response}</p>
          </span>
          {isCopied && <span style={{ color: "green" }}>Copied</span>}

          <div className={styles.btnBox}>
            <button onClick={() => done()}>Done</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatedApi;
