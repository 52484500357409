import styles from "./Profile.module.css";

import { COLORS, FONTSIZE } from "../../../../constants/theme";
import Button from "../../../../components/Button/Button";
import Switch from "../../../../components/Switch/Switch";
import { useNavigate } from "react-router-dom";

const { white, darkBlue, grey } = COLORS;
const { px20, px24, px14, px18 } = FONTSIZE;

export default function Profile() {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={styles.banner}
        style={{ height: "300px", width: "100%" }}
      ></div>
      <div
        style={{
          background: white,
          padding: "1rem 2rem",
          width: "96%",
          margin: "-110px auto 1rem",
          borderRadius: "12px",
        }}
      >
        <div
          className={styles.nameNButtonWrapper}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            // justifyContent: "flex-end",
            justifyContent: "space-between",
            marginBottom: "3.8rem",
          }}
        >
          {/* <div style={{ display: "flex" }}>
            <div style={{ marginRight: "2rem" }}>
              <img
                src="/images/profile.png"
                width="150px"
                height="150px"
                alt="profile"
              />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: px20,
                  marginBottom: ".5rem",
                  color: darkBlue,
                }}
              >
                Richard Davis
              </div>
              <div style={{ fontSize: px14, color: darkBlue }}>
                Individual User
              </div>
            </div>
          </div> */}
          <div className={styles.information} style={{ width: "600px" }}>
            <div
              style={{
                fontWeight: 700,
                fontSize: px24,
                color: darkBlue,
                marginBottom: "2.75rem",
              }}
            >
              Profile Information
            </div>
            <div>
              <Information
                title="First Name:"
                details={JSON.parse(localStorage.userDetails).user.first_name}
              />
              <Information
                title="Last Name:"
                details={JSON.parse(localStorage.userDetails).user.last_name}
              />
              <Information
                title="Email Address:"
                details={JSON.parse(localStorage.userDetails).user.email}
              />
              <Information
                title="Phone Number:"
                details={JSON.parse(localStorage.userDetails).user.phone}
              />
              <Information
                title="Organization Name:"
                details={JSON.parse(localStorage.userDetails).user.company_name}
              />
              {/* <Information
                title="Sender IDs:"
                details={JSON.parse(localStorage.userDetails).user.sender_id}
              /> */}
            </div>
          </div>
          <Button
            name="Edit Account"
            width="200px"
            height="max-content"
            marginTop="1rem"
            onClick={() => navigate("/settings/edit")}
          />
        </div>
        <div
          className={styles.profile}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "2rem",
          }}
        >
          {/* <div className={styles.platform} style={{ maxWidth: "440px" }}>
            <div
              style={{
                fontWeight: 700,
                fontSize: px20,
                color: darkBlue,
                marginBottom: "1.813rem",
              }}
            >
              Platform Settings
            </div>
            <div
              style={{ marginBottom: ".813rem", fontWeight: 700, color: grey }}
            >
              ACCOUNT
            </div>
            <Platform title="Email me when someone follows me" status={true} />
            <Platform
              title="Email me when someone answers on my feedback questions."
              status={false}
            />
            <Platform
              title="Email me when someone mentions me"
              status={false}
            />

            <div
              style={{
                margin: "3rem 0rem.813rem",
                fontWeight: 700,
                color: grey,
              }}
            >
              APPLICATION
            </div>
            <Platform title="New launches and projects" status={true} />
            <Platform title="Monthly product updates" status={true} />
            <Platform title="Subscribe to newsletter" status={true} />
          </div> */}
          {/* <div className={styles.information} style={{ width: "600px" }}>
            <div
              style={{
                fontWeight: 700,
                fontSize: px20,
                color: darkBlue,
                marginBottom: "2.75rem",
              }}
            >
              Profile Information
            </div>
            <div>
              <Information
                title="First Name:"
                details={JSON.parse(localStorage.userDetails).user.first_name}
              />
              <Information
                title="Last Name:"
                details={JSON.parse(localStorage.userDetails).user.last_name}
              />
              <Information
                title="Email Address:"
                details={JSON.parse(localStorage.userDetails).user.email}
              />
              <Information
                title="Phone Number:"
                details={JSON.parse(localStorage.userDetails).user.phone}
              />
              <Information
                title="Organization Name:"
                details={JSON.parse(localStorage.userDetails).user.company_name}
              />
              <Information
                title="Sender ID:"
                details={JSON.parse(localStorage.userDetails).user.sender_id}
              />
              <Information title="Location:" details="kels" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

const Information = ({ title, details }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "1.75rem" }}
    >
      <div
        id={styles.title}
        // style={{
        //   fontSize: px18,
        //   fontWeight: 700,
        //   width: "200px",
        //   color: darkBlue,
        // }}
      >
        {title}
      </div>
      <div id={styles.details}>{details}</div>
    </div>
  );
};

const Platform = ({ title, status }) => {
  return (
    <div style={{ display: "flex", marginBottom: "1.68rem" }}>
      <Switch status={status} />
      <div style={{ color: grey, fontSize: px18, paddingTop: ".2rem" }}>
        {title}
      </div>
    </div>
  );
};
