import {
  getProfileFail,
  getProfileStart,
  getProfileSuccess,
} from "../../redux/profile/getProfileSlice";
import axios from "../axios";

export const getProfile = (data) => async (dispatch) => {
  dispatch(getProfileStart());
  axios
    .get("/user/get-profile")
    .then(function (response) {
               
      dispatch(getProfileSuccess(response.data));
    })
    .catch(function (error) {

      dispatch(getProfileFail("cannot get data at the moment"));
    });
};
