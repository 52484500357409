import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message_sent: null,
  number_reached: null,
  business_served: null,
  loading: false,
  error: null,
};


const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    fetchTotalUsersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchTotalUsersSuccess(state, action) {
      const { message_sent, number_reached, business_served } = action.payload;
      state.message_sent = message_sent;
      state.number_reached = number_reached;
      state.business_served = business_served;
      state.loading = false;
    },
    fetchTotalUsersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTotalUsersStart,
  fetchTotalUsersSuccess,
  fetchTotalUsersFailure,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
