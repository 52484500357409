const settings = [
  {
    title: "PROFILE",
    url: "/profile",
  },
  {
    title: "CHANGE PASSWORD",
    url: "/change-password",
  },
  {
    title: "API",
    url: "/api",
  },
  {
    title: "SENDER IDs",
    url: "/sender-ids",
  },
];


module.exports = { settings }