import {
  fetchCost,
  getCostFailed,
  getCostStart,
  getCostSuccess,
  sendError,
  sendSmsStart,
  sendSuccess,
} from "../../redux/sms/messageSummarySlice";
import axios from "../axios";

export const sendSms = (type, payload) => async (dispatch) => {
  dispatch(sendSmsStart());
  await axios
    .post("/user/sms/create", payload)
    .then((response) => {
      dispatch(sendSuccess());
    })
    .catch((error) => {
      dispatch(sendError(error));
    });
};
export const getUnitCost = () => async (dispatch) => {
  dispatch(sendSmsStart());
  await axios.get("/user/unit/cost").then((response) => {
    const { data } = response.data;
    dispatch(fetchCost(data));
  });
};
export const getCostPreview = async (payload) => {
  try {
    const response = await axios.post("/user/sms/cost-preview", payload);
    const { data } = response.data;
    return data;
  } catch (error) {}
  // await axios
  //   .post("/user/sms/cost-preview", payload)
  //   .then((response) => {
  //     const { data } = response.data;
  //     return data;
  //   })
  //   .catch(() => {
  //     // dispatch(getCostFailed());
  //   });
};
