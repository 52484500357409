import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  responseCreated: false,
  errorCreated: false,
  generateError: "",
  createModal: false,
  generateResponse: {},
  reloadAPIs: false,
};

export const APISlice = createSlice({
  name: "APIs",
  initialState,
  reducers: {
    generateAPIstart: (state) => {
      state.loading = true;
      state.generateError = "";
      state.errorCreated = false;
      state.responseCreated = false;
    },
    generateAPIsuccess: (state, { payload }) => {
      state.loading = false;
      state.responseCreated = true;
      state.generateResponse = payload;
      state.errorCreated = false;
      state.createModal = false;
      state.reloadAPIs = true;
    },
    generateAPIerror: (state, { payload }) => {
      state.loading = false;
      state.responseCreated = false;
      state.generateError = payload;
      state.errorCreated = true;
    },
    reset: (state) => {
      state.loading = false;
      state.responseCreated = false;
      state.errorCreated = false;
      state.generateError = "";
      state.createModal = false;
      state.generateResponse = "";
    },
    openCreateModal: (state, { payload }) => {
      state.createModal = payload;
    },
    reloadAPIS: (state, { payload }) => {
      state.reloadAPIs = payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  generateAPIstart,
  generateAPIsuccess,
  generateAPIerror,
  reset,
  openCreateModal,
  reloadAPIS,
} = APISlice.actions;

export default APISlice.reducer;
