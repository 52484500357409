import {
  getTransactionStart,
  getTransactionSuccess,
} from "../../redux/wallet/transactionsSlice";
import axios from "../axios";

export const getRecentTransactions = () => async (dispatch) => {
  dispatch(getTransactionStart());
  //   deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
  await axios.get("user/trans/recent").then((response) => {
    const { message } = response.data;
    dispatch(getTransactionSuccess(message));
  });
};

export const getTransactionByDate =
  ({ start_date, end_date, currentPage }) =>
  async (dispatch) => {
    dispatch(getTransactionStart());
    // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
    await axios
      .get(
        `user/trans/by-date?startDate=${start_date}&stopDate=${end_date}&page=${currentPage}`
      )
      .then((response) => {
        const { message } = response.data;

        dispatch(getTransactionSuccess(message));
      });
  };

export const getAllTransactions =
  ({ perpage, medium, start_date, end_date }) =>
  async (dispatch) => {
    dispatch(getTransactionStart());
    await axios
      .get(
        `user/trans/all?perpage=${perpage}&medium=${medium}&startDate=${start_date}&stopDate=${end_date}`
      )
      .then((response) => {
        const message = response;
        dispatch(getTransactionSuccess());
      });
  };

export const getTransactionPerpage = (perpage) => async (dispatch) => {
  dispatch(getTransactionStart());
  await axios
    .get(`user/trans/all?perpage=${perpage}`)
    .then((response) => {
      const { message } = response.data;
      dispatch(getTransactionSuccess(message));
    })
    .catch((error) => {});
};
export const downloadTransactions =
  ({ exportType }) =>
  async (dispatch) => {
    dispatch(getTransactionStart());
    await axios
      .get(`user/trans/all?export-type=${exportType}`)
      .then((response) => {
        const message = response.data.message;
        dispatch(getTransactionSuccess(message));
      });
  };
export const getTransactionByMedium =
  ({ medium }) =>
  async (dispatch) => {
    dispatch(getTransactionStart());
    await axios.get(`user/trans/all?medium=${medium}`).then((response) => {
      const message = response.data.message;
      dispatch(getTransactionSuccess(message));
    });
  };
export const getTransactionByType =
  ({ type }) =>
  async (dispatch) => {
    dispatch(getTransactionStart());
    await axios.get(`user/trans/all?type=${type}`).then((response) => {
      const { message } = response.data;
      dispatch(getTransactionSuccess(message));
    });
  };
