import React from "react";
import styles from "./notification.module.css";
import closeIcon from "../../../../assets/closeIcon.svg";
import moment from "moment/moment";

const Message = ({ closeModal, data }) => {
  return (
    <>
      <div className={styles.modaalll}>
        <div id={styles.buttonPosition}>
          <img src={closeIcon} alt="close" onClick={() => closeModal(false)} />
        </div>
        <div className={styles.bankDetails}>
          <span className={styles.topDetails}>
            <big>{data.subject}</big>
            <small>{data.from}</small>
            <h5> {moment(data.created_at).format("lll")}</h5>
          </span>
          <span className={styles.bodyBottom}>
            <p>{data.message}</p>
          </span>
        </div>
      </div>
    </>
  );
};

export default Message;
