import SenderIdLayout from "./component/layout";

const AdminSenderID = () => {
  return (
    <div>
      <SenderIdLayout />
    </div>
  );
};

export default AdminSenderID;
