import styles from "../styles/userStyles.module.css";
import { DateRangePicker } from "react-date-range";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as Download } from "../../../../../assets/download.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import MaterialUIPickers from "../../../../../components/dateAndTime/MaterialUIPickers";
import Paginationn from "../../../../../components/Pagination/Pagination";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fil
import moment from "moment";
import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDown from "../../../../../assets/chevrondown.svg";
import Modal from "../../../../../components/modal/Modal";
import { getUsermessageHistoryByDate } from "../../../../../utils/apiCalls/admin/user/fetchUserMessageHistory";
import { getUserPayments } from "../../../../../utils/apiCalls/admin/user/fetchUserPayments";

const UserPayments = () => {
  let PageSize = 15;

  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [status, setStatus] = useState("approved");
  const [currentPage, setCurrentPage] = useState(1);

  // const showCalendar = useSelector((state)=>state.transactions.showCalendar)

  const { paymentData, loading, failed } = useSelector(
    (state) => state.userPayments
  );

  useEffect(() => {
    dispatch(getUserPayments(state.email, status));
  }, [status]);

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  useEffect(() => {
    if (failed === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [failed]);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return paymentData && paymentData.slice(firstPageIndex, lastPageIndex);
  };

  const getData = () => {
    let dd = [];
    paymentData.map((data, index) => {
      dd.push({
        Reference: data.reference,
        Amount: data.amount,
        Bank: data?.bank,
        "Payment Date": data.payment_date,
        Channel: data.channel,
      });
    });
    return dd;
  };

  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={`${styles.tableDetails}`}>
          <div className="flex items-center">
            <p className="text-lg font-bold ml-2">Payments</p>
          </div>
          <div className={styles.tableActions}>
            {paymentData?.length > 0 && (
              <CSVLink
                data={getData()}
                filename={`XMS_${status}_payment_report [${state}].csv`}
                style={{ textDecoration: "none" }}
              >
                <button className={styles.downloadBtn}>
                  <Download />
                  <b>Download</b>
                </button>
              </CSVLink>
            )}
            <button className={styles.carlendarBtn}>
              <FormControl variant="standard" fullWidth className="">
                {/* <InputLabel id="demo-simple-select-label">Timeline</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Timeline"
                  className="self-center ml-[8px] text-center w-5/6 "
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"approved"}>Approved</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"declined"}>Declined</MenuItem>
                </Select>
              </FormControl>
              {/* <img src={ArrowDown} alt="arrowDown" /> */}
            </button>
          </div>
        </div>
        {loading ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress sx={{ color: "#C4C4C4" }} />
          </Box>
        ) : paymentData?.length === 0 ? (
          <center>
            <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
              {state.name} does not have any {status} transaction
            </Alert>
          </center>
        ) : (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className={styles.customers}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Reference
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Amount
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>Bank</th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Channel
                    </th>
                    <th style={{ textAlign: "center", fontSize: 15 }}>
                      Payment Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData()?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {item.reference}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontSize: 13,
                        }}
                      >
                        {formatPriceByCountrySymbol("Nigeria", item.amount)}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {item.bank}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {item.channel}
                      </td>
                      <td style={{ textAlign: "center", fontSize: 13 }}>
                        {item.payment_date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginationn
              currentPage={currentPage}
              totalCount={paymentData?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UserPayments;
