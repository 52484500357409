import {
  signInStart,
  signInSuccess,
  signInFail,
} from "../../../../redux/admin/auth/signInSlice";
import axios from "../../../axios";

export const login = (data) => (dispatch) => {
  dispatch(signInStart());
  axios
    .post("/admin/login", data)
    .then(function (response) {
      dispatch(signInSuccess(response.data));
    })

    .catch(function (error) {
      const { message } = error.response.data;
      dispatch(signInFail(message));
    });
};

export const setNewAdminPassword = (data) => (dispatch) => {
  dispatch(signInStart());
  axios
    .post("/admin/change-password", data)
    .then((response) => {
      dispatch(signInSuccess(response.data));
    })
    .catch((error) => {
    });
};
