import React, { useEffect } from "react";
import BottomFooter from "./components/footer/BottomFooter";
import Companys from "./components/companys/Companys";
import Dark from "./components/dark/Dark";
import Footer from "./components/footer/Footer";
import IncludedToolsBox from "./components/included/IncludedToolsBox";
import MidSection from "./components/midSection/MidSection";
import Navbar from "./components/nav/Navbar";
import TopContents from "./components/topContents/TopContents";
import Xturi from "./components/xturi/Xturi";
import styles from "./general.module.css";
import LastSection from "./components/sectionAboveFooter/LastSection";
const LandingPage = () => {
  useEffect(() => {
    localStorage.removeItem("userDetails");
  }, []);
  return (
    <>
      {/* <BrowserRouter> */}
      <div className={styles.homeContainer} style={{overflow:"hidden"}}>
        <div className={styles.topBox}>
          <Navbar />
          <div className={styles.topConBox}>
            <TopContents />
          </div>
        </div>
        <div className={styles.companyWrap}>
          <Companys />
        </div>
        <div>
          <MidSection />
        </div>
        <IncludedToolsBox />
        <div style={{ marginTop: "0px" }}>
          <Xturi />
        </div>
        <Dark />
        <Footer />
        <LastSection/>
        <div className={styles.bottomFooterWrap}>
          <BottomFooter />
        </div>
      </div>
      {/* </BrowserRouter> */}
    </>
  );
};

export default LandingPage;
