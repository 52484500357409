import React, { useState, useEffect } from "react";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import Logo from "./../../../components/Logo/Logo";
import { FONTSIZE, COLORS } from "../../../../constants/theme";

import styles from "./ResetPassword.module.css";
import ExturiLogo from "../../../assets/ExturiLogo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { runResetPassword } from "../../../../utils/apiCalls/admin/auth/runForgotPassword";

const { white, primary, lightGrey } = COLORS;
const { px40, px18, px14 } = FONTSIZE;

const AdminResetPwd = () => {
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [OTP, setOTP] = useState("");
  const [pwdUnMatch, setPwdUnMatch] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const successMessage = useSelector(
    (state) => state.forgotPassword.forgotPasswordData
  );
  const loading = useSelector(
    (state) => state.forgotPassword.resetPasswordLoad
  );
  const error = useSelector((state) => state.forgotPassword.resetPasswordError);
  const resetSuccessMessage = useSelector(
    (state) => state.forgotPassword.resetPasswordData
  );

  useEffect(() => {
    resetSuccessMessage && navigate("/office");
  }, [resetSuccessMessage]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (OTP === "" || pwd === "" || confirmPwd === "") {
      return;
    } else if (pwd !== confirmPwd) {
      setPwdUnMatch(true);
    } else {
      dispatch(runResetPassword(OTP, pwd, confirmPwd));
    }
  };

  return (
    <div className={styles.container}>
      <Logo />

      <div
        className={styles.wrapper}
        style={{
          backgroundColor: white,
          width: "700px",
          margin: "3rem auto",
          display: "flex",
          justifyContent: "center",
          padding: "2rem 2rem 6rem",
          borderRadius: "10px",
        }}
      >
        <form
          className={styles.form}
          style={{ width: "500px", padding: "0 3rem" }}
          onSubmit={(e) => onSubmit(e)}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                fontSize: px40,
                color: primary,
                marginBottom: ".5rem",
              }}
            >
              Reset password?
            </h1>

            <p
              style={{
                fontSize: px14,
                marginBottom: "3rem",
                color: lightGrey,
                maxWidth: "333px",
                margin: "0 auto 4rem",
              }}
            >
              Enter your new password and confirm your new password.
            </p>
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {pwdUnMatch && (
              <Alert severity="error">Passwords do not match</Alert>
            )}
            {error && <Alert severity="error">{error}</Alert>}
          </div>
          <div className={styles.inputDiv} style={{ marginBottom: "3rem" }}>
            <div style={{ position: "relative" }}>
              <Input
                type="text"
                id="password"
                name="otp"
                placeholder="Enter OTP"
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
                required
              />
              <div></div>
            </div>
            <div style={{ position: "relative" }}>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="New Password"
                onChange={(e) => {
                  setPwd(e.target.value);
                  setConfirmPwd("");
                  setPwdUnMatch(false);
                }}
              />
              <div></div>
            </div>
            <div style={{ position: "relative" }}>
              <Input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPwd(e.target.value)}
                value={confirmPwd}
                required
              />
            </div>
          </div>
          <Button
            name={loading ? "Updating..." : "Proceed"}
            margin="0 0 4.3rem"
            style={{ opacity: loading ? 0.5 : 1, width: "100%" }}
          />
        </form>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ color: white, fontSize: px14, marginBottom: ".4rem" }}>
          Powered by
        </div>
        <div>
          <img src={ExturiLogo} width="" height="" alt="exturi" />
        </div>
      </div>
    </div>
  );
};

export default AdminResetPwd;
