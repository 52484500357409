import React from "react";

function Card({ type, setType }) {
  const deposittype = [
    {
      type: "Pending",
      subType: "Awaiting approval",
      id: "pending",
    },
    {
      type: "Accepted",
      subType: "Accepted deposits",
      id: "approved",
    },
    {
      type: "Declined",
      subType: "Declined deposits",
      id: "declined",
    },
  ];

  return (
    <div className="flex mb-10">
      {deposittype.map((deposit) => {
        return (
          <div
            key={deposit.id}
            onClick={() => setType(deposit.id)}
            className=" bg-white px-5 py-4 w-[224px] mr-16 cursor-pointer"
            style={{
              boxShadow: `0px 4px 4px ${
                type === deposit.id ? "#4B1A7C" : "rgba(0, 0, 0, 0.25"
              }`,
            }}
          >
            <h2 className="font-medium text-2xl">{deposit.type}</h2>
            <p className="font-light text-lg pt-0.5">{deposit.subType} </p>
          </div>
        );
      })}
    </div>
  );
}

export default Card;
