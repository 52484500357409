import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import styles from "../../../../../clientDashboard/pages/sendsms/Sendsms.module.css";
import { useDispatch, useSelector } from "react-redux";
import MaterialUIPickers from "./MaterialUIPickers";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import bin1R from "../../../../../assets/bin1R.svg";
import { resetDate } from "../../../../../redux/admin/notifications/getAllNotificationSlice";
import { showScheduledTime } from "../../../../../redux/admin/messageScheduleSlices/inAppNotificationScheduleSlice";

export default function InAppNotificationScheduler() {
  const date = useSelector((state) => state.getAllNotifications.date);
  const time = useSelector((state) => state.getAllNotifications.time);

  const { scheduledTime } = useSelector(
    (state) => state.inAppNotificationScheduler
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("now");
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(showScheduledTime());
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const clear = () => {
    setValue("now");
    dispatch(resetDate());
  };

  useEffect(() => {
    if (value === "later" && anchorEl !== "") {
    }
  }, [anchorEl]);

  return (
    <>
      <div>
        <RadioGroup
          aria-labelledby="time-group-label"
          defaultValue="now"
          name="time"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value="now"
            control={<Radio />}
            label="Send Now"
            checked={value === "now"}
            id={styles.sendTxt}
          />
          <FormControlLabel
            value="later"
            control={<Radio />}
            label="Send Later"
            checked={value === "later"}
            onChange={(e) => {
              setAnchorEl(e.currentTarget);
              setValue("later");
            }}
          />
        </RadioGroup>
        {scheduledTime && date !== "" ? (
          <p>
            Your message would be sent on {`${date} ${time}`}
            <span>
              <button onClick={() => clear()}>
                <img
                  id={styles.clearBtn}
                  src={bin1R}
                  alt=""
                  style={{ width: "15px", height: "15px" }}
                />
              </button>
            </span>
          </p>
        ) : (
          ""
        )}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <Typography sx={{ p: 2 }}>
          <MaterialUIPickers
            setStateValue={setValue}
            stateValue={value}
            handleClose={handleClose}
          />
        </Typography>
      </Popover>
    </>
  );
}
