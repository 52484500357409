import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msgSummary: {},
  sendSmsLoad: false,
  sendSmsError: false,
  sendSmsSuccess: false,
  scheduleSmsSuccess: false,
  reference: {},
  sendSmsErrorMessage: "",
  showWarning: false,
  showSelectAccount: false,
  showSummary: false,
  numbers: "",
  uploadSMScsv: false,
  loadingNumbers: false,
  time: "",
  date: "",
  getCostSuccess: false,
  costData: null,
  getCostData: {},
  getCostLoading: false,
  getCostFailed: false,
};

export const messageSummary = createSlice({
  name: "messageSummary",
  initialState,
  reducers: {
    saveSummary: (state, { payload }) => {
      state.msgSummary = payload;
    },
    addChannel: (state, { payload }) => {
      state.msgSummary = { ...state.msgSummary, channel: payload };
    },
    clearChannel: (state) => {
      state.msgSummary = { ...state.msgSummary, channel: undefined };
    },
    resetStatus: (state) => {
      state.sendSmsSuccess = false;
      state.showSelectAccount = false;
      state.showSummary = false;
    },
    sendSmsStart: (state, { payload }) => {
      state.sendSmsLoad = true;
      state.showWarning = !state.showWarning;
      state.showSelectAccount = !state.showSelectAccount;
    },
    sendSuccess: (state, { payload }) => {
      state.sendSmsLoad = false;
      state.sendSmsSuccess = true;
      state.reference = payload;
      state.sendSmsError = false;
      state.sendSmsErrorMessage = "";
    },
    sendError: (state, { payload }) => {
      state.sendSmsLoad = false;
      state.sendSmsError = true;
      state.sendSmsErrorMessage = payload;
      state.sendSmsSuccess = false;
    },
    updateSmsError: (state) => {
      state.sendSmsError = false;
    },
    toggleWarning: (state) => {
      state.showWarning = !state.showWarning;
    },
    toggleSelectAccount: (state) => {
      state.showSelectAccount = !state.showSelectAccount;
    },
    toggleShowSummary: (state) => {
      state.showSummary = !state.showSummary;
    },
    savePhoneNumbersInState: (state, { payload }) => {
      state.loadingNumbers = false;
      state.numbers = payload;
    },
    savePhoneNumbersInStateStart: (state) => {
      state.loadingNumbers = true;
    },
    toggleShowSMScsv: (state) => {
      state.uploadSMScsv = !state.uploadSMScsv;
    },
    saveDateInState: (state, { payload }) => {
      state.time = payload.time;
      state.date = payload.date;
    },
    resetDate: (state) => {
      state.time = "";
      state.date = "";
    },
    scheduleSuccess: (state) => {
      state.sendSmsLoad = false;
      state.scheduleSmsSuccess = true;
    },
    resetNumbers: (state) => {
      state.numbers = "";
    },
    resetScheduleSuccess: (state) => {
      state.scheduleSmsSuccess = false;
    },
    resetMessageSummary: (state) => {
      state.msgSummary = {};
    },

    fetchCost: (state, { payload }) => {
      state.getCostSuccess = true;
      state.costData = payload;
    },
    getCostStart: (state) => {
      state.getCostLoading = true;
    },
    getCostSuccess: (state, { payload }) => {
      state.getCostLoading = false;
      state.getCostData = payload;
      state.getCostSuccess = true;
    },
    getCostFailed: (state) => {
      state.getCostLoading = false;
      state.getCostFailed = true;
      state.getCostData = null;
    },
  },
});

export const {
  saveSummary,
  addChannel,
  sendSmsStart,
  sendSuccess,
  sendError,
  resetStatus,
  toggleWarning,
  toggleSelectAccount,
  toggleShowSummary,
  savePhoneNumbersInState,
  toggleShowSMScsv,
  savePhoneNumbersInStateStart,
  saveDateInState,
  resetDate,
  scheduleSuccess,
  resetNumbers,
  resetScheduleSuccess,
  resetMessageSummary,
  fetchCost,
  getCostFailed,
  getCostStart,
  getCostSuccess,
  updateSmsError,
} = messageSummary.actions;

export default messageSummary.reducer;
