import React, { useState } from "react";
import styles from "./Contacts.module.css";
import { ReactComponent as GroupIcon } from "../../../assets/group.svg";
import SearchIcon from "../../../assets/search.svg";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { ReactComponent as Contacts } from "../../../assets/Contacts.svg";
import bin from "../../../assets/bin.svg";
import movefile from "../../../assets/movefiles.svg";
import ArrowDown from "../../../assets/chevrondown.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/modal/Modal";
function Groups() {
  const tableData = [
    {
      name: "John",
      info: "2348023498776",
      email: "2348023498776",
    },
  ];
  const [showCalendar, setShowCalendar] = useState(false);
  const [saveDetails, setSaveDetails] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);

  const [upload, setUpload] = useState(false);
  function handleShowCalendar() {
    setShowCalendar((prev) => !prev);
  }
  function handleSelectDate(date) {}
  function closeModal(e) {
    if (e.target.id == "modal") {
      setShowGroup(false);
    }
  }

  return (
    <>
      {showGroup && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <div className={styles.createGroup}>
            <h3>Create group</h3>
            <p>
              Fill out these fields to add a number. You will always be able to
              edit this information later.
            </p>
            <form className={styles.addNumberForm}>
              <div>
                <input type="text" placeholder="Group name" />
              </div>
              <div>
                <button className={styles.select}>
                  <Contacts />
                  SELECT FROM CONTACT
                </button>
              </div>
              <div>
                <textarea resize placeholder="Click me!"></textarea>
              </div>
              <div>
                <button className={styles.importBtn}> CREATE GROUP </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {showEditGroup && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <div className={styles.createGroup}>
            <h3>Edit group</h3>
            <p>
              Made a mistake go ahead and make an edit. But be careful on this
              page.
            </p>
            <form className={styles.editNumberForm}>
              <div>
                <input type="text" placeholder="Group name" />
              </div>
              <div>
                <textarea resize placeholder="Click me!"></textarea>
              </div>
              <div>
                <button className={styles.importBtn}> UPDATE GROUP</button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      <div className={styles.transactionBox}>
        <div className={styles.tableSection}>
          <div className={styles.tableDetails}>
            <GroupIcon />
            <div className={styles.tableActions}>
              <button
                onClick={() => setShowGroup(true)}
                className={styles.importBtn}
              >
                CREATE NEW GROUP
              </button>
            </div>
          </div>
          <div className={styles.searchSection}>
            <div className={styles.searchBox}>
              <img src={SearchIcon} />
              <input
                type="text"
                className={styles.search}
                placeholder="Search by first name, last name, number"
              />
            </div>
          </div>
          <div className={styles.sortSection}>
            <div>
              <p> Showing 1 - 8 of 1000</p>
              <div className={styles.actions}>
                <input type="checkbox" />
                <img src={movefile} />
                <img src={bin} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className={`${styles.carlendarBtn} ${styles.groupCalendar}`}
                onClick={handleShowCalendar}
              >
                <div>
                  {" "}
                  <CalendarIcon />
                  <p> 19.12.2020 - 25.12.2020</p>
                </div>
                <img src={ArrowDown} />
                {showCalendar && (
                  <div className={styles.calendar}>
                    <Calendar date={new Date()} onChange={handleSelectDate} />
                  </div>
                )}
              </button>
              <button className={styles.sortBtn}>
                <Filter />
                Sort by
              </button>
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <table className={styles.customers}>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Contact information</th>
                  <th>Email address</th>
                </tr>
              </thead>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.info}</td>
                  <td>{item.email}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Groups;
