import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../../../utils/apiCalls/admin/getAllClients";
import SingleUser from "./components/SingleUser";
import SortIcon from "../../../assets/sortIcon.png";
import UserTableLayout from "./components/UserTableLayout";
import MessageBox from "./components/MessageBox";
import EmailBox from "./components/EmailBox";

export const getTimeFromTimestamp = (time) => {
  return time?.substring(0, 10) ?? "";
};
// const createdAt = singleUser.created_at ?? "";
// const updatedAt = singleUser.updated_at ?? "";

function ClientList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clientError, clientList, loading } = useSelector(
    (state) => state.clients
  );

  const [sendMessage, setSendMessage] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [singleUser, setSingleUser] = useState();

  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  useEffect(() => {
    if (clientError === "Unauthenticated") {
      localStorage.removeItem("userDetails");
      navigate("/office");
    }
  }, [clientError]);

  return (
    <>
      <div>
        <div className="flex flex-row lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 w-full">
          <div className="w-full">
            <div className="w-full shadow bg-white rounded px-5">
              <p className="pt-3 font-bold text-2xl underline ">CLIENTS</p>
              <div className="border-gray-200 w-full rounded bg-white overflow-x-auto">
                <UserTableLayout
                  clientList={clientList}
                  setSingleUser={setSingleUser}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          {singleUser && (
            <SingleUser
              name={singleUser.first_name + " " + singleUser.last_name}
              email={singleUser.email}
              phone={singleUser.phone}
              status={singleUser.status}
              createdAt={getTimeFromTimestamp(singleUser.created_at)}
              updatedAt={getTimeFromTimestamp(singleUser.updated_at)}
              setSendMessage={setSendMessage}
              setSingleUser={setSingleUser}
              setSendEmail={setSendEmail}
            />
          )}
        </div>
      </div>
      {sendMessage && (
        <MessageBox
          name={singleUser.first_name}
          phone={singleUser.phone}
          sendMessage={sendMessage}
          setSendMessage={setSendMessage}
        />
      )}

      {sendEmail && (
        <EmailBox
          email={singleUser.email}
          setSendEmail={setSendEmail}
          sendEmail={sendEmail}
          name={singleUser.first_name + " " + singleUser.last_name}
        />
      )}
    </>
  );
}

export default ClientList;
