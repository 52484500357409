import {
  getMessageHistoryFailed,
  getMessageHistoryStart,
  getMessageHistorySuccess,
} from "../../../../redux/admin/users/userMessageHistorySlice";
import axios from "../../../axios";

export const getUsermessageHistoryByDate =
  (email, startDate, endDate,perpage) => async (dispatch) => {
    dispatch(getMessageHistoryStart());

    await axios
      // .get(`admin/user/sms-history/${email}/${start_date}/${end_date}`)
      .get(`/admin/user/sms-history/${email}?startDate=${startDate}&endDate=${endDate}&perpage=${perpage}`)
      .then((response) => {
        const { data } = response.data;
        dispatch(getMessageHistorySuccess(data));
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(getMessageHistoryFailed(message));
      });
  };
