import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signInLoading: false,
  signInData: null,
  signInError: false,
};

export const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    signInStart: (state) => {
      state.signInLoading = true;
    },
    signInSuccess: (state, { payload }) => {
      localStorage.setItem("userDetails", JSON.stringify(payload.data));
      state.signInLoading = false;
      state.signInError = null;
    },
    signInFail: (state, { payload }) => {
      state.signInLoading = false;
      state.signInError = payload;
      state.signInData = null;
    },
    clearSignInErrorMessage: (state) => {
      state.loginError = null;
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFail,
  clearSignInErrorMessage,
} = signInSlice.actions;

export default signInSlice.reducer;
