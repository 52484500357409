import axios from "axios";

const instance = axios.create({
  baseURL: "https://live.xms.ng/api",
});

instance.interceptors.request.use(
  (config) => {
    const localData =
      localStorage.userDetails && JSON.parse(localStorage.userDetails);
    if (localData !== null && localData?.token !== null) {
      config.headers.Authorization = `Bearer ${localData?.token}`;
    }
    // else {
    //   config.headers.Authorization = `Bearer `;
    // }
    return config;
  },
  (error) => {
    void Promise.reject(error);
  }
);
export default instance;
