import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  DeliveryReportData: [],
  getDeliveryReportError: false,
  errorExist: false,
  getDeliveryReportErrorMessage: null,
  ReportSuccess: false,
  getHistoryErrorMessage: "",
  HistoryData: [],
  HistorySuccess: false,
  smsSummaryData: [],
  smsSummarySuccess: false,
  getSmsSummaryReportError: null,
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    getHistoryStart: (state) => {
      state.loading = true;
    },
    getReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.DeliveryReportData = payload;
      state.ReportSuccess = true;
      state.getDeliveryReportError = null;
      state.errorExist = false;
    },
    getReportFail: (state, { payload }) => {
      state.loading = false;
      state.ReportSuccess = false;
      state.getDeliveryReportData = null;
      state.errorExist = true;
      state.getDeliveryReportErrorMessage = payload;
    },
    getHistorySuccess: (state, { payload }) => {
      state.loading = false;
      state.HistoryData = payload;
      state.HistorySuccess = true;
      state.getHistoryErrorMessage = null;
      state.errorExist = false;
    },
    getHistoryFail: (state, { payload }) => {
      state.loading = false;
      state.HistorySuccess = false;
      state.HistoryData = [];
      // state.errorExist = true;
      state.getHistoryErrorMessage = payload;
    },
    resetHStatus: (state) => {
      state.ReportSuccess = false;
    },
    getSmsSummaryStart: (state) => {
      state.loading = true;
    },
    getSmsSummarySuccess: (state, payload) => {
      state.loading = false;
      state.smsSummaryData = payload;
      state.smsSummarySuccess = true;
      state.getSmsSummaryReportError = null;
      state.errorExist = false;
    },
    getSmsSummaryFail: (state, payload) => {
      state.loading = false;
      state.smsSummaryData = payload;
      state.smsSummarySuccess = false;
      state.getSmsSummaryReportError = payload;
      state.errorExist = true;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getHistoryStart,
  getReportSuccess,
  getReportFail,
  getHistorySuccess,
  getHistoryFail,
  resetHStatus,
  getSmsSummaryStart,
  getSmsSummarySuccess,
  getSmsSummaryFail
} = historySlice.actions;

export default historySlice.reducer;
