import {
  getBaseRateStart,
  baseRateSuccessful,
  baseRateUpdateStart,
  baseRateError,
  baseRateUpdateSuccessful,
  baseRateUpdateError,
} from "../../redux/admin/settingsEndpoints/priceSlice";
import axios from "../axios";

export const BasePriceRate = () => async (dispatch) => {
  dispatch(getBaseRateStart());
  try {
    const response = await axios.get("/admin/system/base-rate");
    const { data } = response.data;
    dispatch(baseRateSuccessful(data));
  } catch (error) {
    const { message } = error.response;
    dispatch(baseRateError(message));
  }
};

export const UpdateBaseRate = (priceRate) => async (dispatch) => {
  dispatch(baseRateUpdateStart());
  try {
    const response = await axios.post("admin/system/set-base-rate", priceRate);
    const { message } = response.data;
    dispatch(baseRateUpdateSuccessful(message));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(baseRateUpdateError(message));
  }
};
