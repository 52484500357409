import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  addedUsers: null,
  failed: null,
};

const RecentAddedUserSlice = createSlice({
  name: "Recent Added User",
  initialState,
  reducers: {
    getRecentAddedUserLoading: (state) => {
      state.loading = true;
    },
    getRecentAddedUserSuccess: (state, { payload }) => {
      state.addedUsers = payload;
      state.loading = false;
      state.failed = null;
    },
    getRecentAddedUserFailed: (state, { payload }) => {
      state.addedUsers = null;
      state.loading = false;
      state.failed = payload;
    },
  },
});

export const {
  getRecentAddedUserSuccess,
  getRecentAddedUserLoading,
  getRecentAddedUserFailed,
} = RecentAddedUserSlice.actions;

export default RecentAddedUserSlice.reducer;
