import {
  getRecentAddedUserFailed,
  getRecentAddedUserLoading,
  getRecentAddedUserSuccess,
} from "../../../redux/admin/Recent/getRecentAddedUser";
import axios from "../../axios";

export const fetchRecentAddedUser = () => async (dispatch) => {
  dispatch(getRecentAddedUserLoading());
  axios
    .get("/admin/dash/recent-user")
    .then((res) => {
      dispatch(getRecentAddedUserSuccess(res.data.data));
    })
    .catch(function (error) {
      dispatch(getRecentAddedUserFailed(error));
    });
};
