import React from "react";
import styles from "./included.module.css";
import analytics from "../../assets/analytics.png";
import Slide from "react-reveal/Slide";

const IncludedToolsBox = () => {
  return (
    <>
      <div className={styles.parentWrap}>
        <div>
          <Slide left>
            <img src={analytics} alt="img" id={styles.image} />
          </Slide>
        </div>

        <div>
          <Slide right>
            <div className={styles.childWrap}>
              <span>
                <big className={styles.bigText}>Real Time Analytics</big>
                <p className={styles.subText}>
                  Get real-time reports. Track your customers' delivery reports
                </p>
              </span>
              <span>
                <big className={styles.bigText}>
                  Communicate Without Disruptions
                </big>
                <p className={styles.subText}>
                  Get real-time reports. Track your customers' delivery reports.
                </p>
              </span>
            </div>
          </Slide>
        </div>
      </div>
    </>
  );
};

export default IncludedToolsBox;
