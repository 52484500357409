import { Routes, Route, Link, Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({ redirectPath = "/login", children }) => {
  const user = localStorage.userDetails;
  if (!user) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};

export const AdminPrivateRoute = ({ redirectPath = "/office", children }) => {
  const user = localStorage.userDetails;
  if (!user) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};
