import { Skeleton } from "@mui/material";

const Loading = () => {
  return (
    <div className="h-screen">
      <div className="flex justify-between mb-5">
        <Skeleton
          className="w-1/4"
          sx={{ height: 120 }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          className="w-1/4"
          sx={{ height: 120 }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          className="w-1/4"
          sx={{ height: 120 }}
          animation="wave"
          variant="rectangular"
        />
      </div>
      <div className="flex justify-between h-96">
        <div className="w-[65%] mt-12 h-full border-4 px-5 py-10">
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
        </div>
        <div className="w-[30%] ">
          <Skeleton height={200} width="100%" style={{}} />
          <Skeleton height={100} width="100%" style={{ marginBottom: 6 }} />
        </div>
      </div>
    </div>
  );
};

export default Loading;
