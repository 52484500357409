import React from 'react'
// import organicChemistry from "../assets/organicChemistry.svg";
import styles from "./xturi.module.css";


const Tiles = ({Svg,Title,Details, style}) => {
  return (
      <div id={styles.tiles} style={{...style}}>
        <span>
          <img src={Svg} alt="" />
        </span>
        <span>
          <h3>{Title}</h3>
        </span>
        <span>
          <p >
            {Details}
          </p>
        </span>
      </div>
  );
}

export default Tiles