import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../utils/Alert";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";

function ConfirmBuyUnit({ close, message, amount }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const action = () => {
    setLoading(true);
    axios
      .post("/user/unit/from-wallet", { amount: amount })
      .then((response) => {
        const { message } = response.data;
        setSuccess(true);
        setLoading(false);
        setSuccessMessage(message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        const { message } = error.response.data;
        setErrorMessage(message);
      });
  };

  return (
    <>
      <div className={styles.confirmContainer}>
        <Warning />
        <h3>{message ?? "Are you sure you want to send the message?"}</h3>
        <div className={styles.buttonSection}>
          <button onClick={close}>cancel</button>
          <button onClick={() => action()}>
            {loading ? "Please Wait..." : "confirm"}
          </button>
        </div>
        <Alert
          Text={`Successfully bought ${amount} Units`}
          Type="success"
          openAlert={success}
        />
        <Alert Text={errorMessage} Type="error" openAlert={error} />
      </div>
    </>
  );
}

export default ConfirmBuyUnit;
