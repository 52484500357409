import React, { useEffect, useState } from "react";
import { ExturiLogo, CategoryIcon } from "../../assets";
import { Transition } from "@headlessui/react";
import styles from "./index.module.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import axios from "../../../utils/axios";
import ConfirmationBox from "../../../components/confirmationBox/ConfirmationBox";

function Layout({ children }) {
  const navigate = useNavigate();
  const [isShowing, setIsShowing] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [name, setName] = useState("");
  const [initials, setInitals] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [activeNav, setActiveNav] = useState("Dashboard");

  const localData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    setName(localData.user.name);
  }, []);

  useEffect(() => {
    if (name) {
      setInitals(name.split(" ")[0][0] + name.split(" ")[1][0]);
    }
  }, [name]);

  const clearStorage = async () => {
    // file deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
    await axios.post("/admin/logout").then(() => {
      localStorage.removeItem("userDetails");
      setTimeout(() => {
        navigate("/office");
      }, 500);
    });
  };

  const logOut = () => {
    setOpenDialogBox(true);
  };

  useEffect(() => {
    if (confirmation) {
      clearStorage();
      // setOpenDialogBox(false);
      // setConfirmation(false);
    }
  }, [confirmation]);

  return (
    <div className="bg-[#F7F7F8] w-full">
      <div
        className={`scroll-auto h-screen pt-[1.2rem] bg-white lg:w-[16rem] ${
          isShowing === true ? "flex" : "hidden"
        } ${
          isShowing === true && "w-screen"
        } lg:flex flex-col fixed top-0 left-0`}
        style={{ zIndex: 99 }}
        id={styles.sidebar}
      >
        <div className="flex flex-row items-center justify-between">
          {" "}
          <img
            src={ExturiLogo}
            style={{ width: 159, height: 55, objectFit: "contain" }}
          />
          <i
            className="lg:hidden ri-close-line"
            onClick={() => setIsShowing(false)}
          ></i>
        </div>

        <div
          className={`mt-10 pl-8 py-4 ${
            window.location.pathname === "/admin"
              ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
              : "bg-white"
          }`}
        >
          <NavLink
            to=""
            className={` flex flex-row space-x-4 w-[8rem]`}
            onClick={() => {
              setActiveNav("Dashboard");
              setIsShowing(false);
            }}
          >
            <img src={CategoryIcon} />
            <p className="ml-[1.6rem] text-sm">Dashboard</p>
          </NavLink>
        </div>
        <div className="pl-8">
          <button
            className="flex flex-row space-x-4 w-[8rem] py-4 items-center"
            onClick={() => setShowMessage((prev) => !prev)}
          >
            <i className="ri-discuss-line"></i>
            <p className="ml-[1.6rem] text-sm">Communication</p>
            {showMessage ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </button>
          <div className={`${!showMessage ? "hidden" : "block"} pl-4`}>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/mailNotification"
                  ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
                  : "bg-white"
              }`}
            >
              <NavLink
                to="mailNotification"
                className=" flex flex-row space-x-4  w-[8rem] items-center"
                onClick={() => {
                  setActiveNav("mailNotification");
                  setIsShowing(false);
                }}
              >
                <p className="pl-[1.6rem] text-sm">Mail</p>
              </NavLink>
            </div>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/getNotification"
                  ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
                  : "bg-white"
              }`}
            >
              <NavLink
                to="getNotification"
                className=" flex flex-row space-x-4  w-[8rem] items-center"
                onClick={() => {
                  setActiveNav("getNotification");
                  setIsShowing(false);
                }}
              >
                <p className="pl-[1.6rem] text-sm">Notifications</p>
              </NavLink>
            </div>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/smsNotification"
                  ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
                  : "bg-white"
              }`}
            >
              <NavLink
                to="smsNotification"
                className="flex flex-row space-x-4  w-[8rem]"
                onClick={() => {
                  setActiveNav("smsNotification");
                  setIsShowing(false);
                }}
              >
                <p className="pl-[1.6rem] text-sm">SMS</p>
              </NavLink>
            </div>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/adminScheduledSms"
                  ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
                  : "bg-white"
              }`}
            >
              <NavLink
                to="adminScheduledSms"
                className="flex flex-row space-x-4  w-[8rem]"
                onClick={() => {
                  setActiveNav("adminScheduledSms");
                  setIsShowing(false);
                }}
              >
                <p className="pl-[1.6rem] text-sm">Scheduled Messages</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          className={`py-4 pl-8 ${
            window.location.pathname === "/admin/deposits" &&
            "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
          }`}
        >
          <NavLink
            to="deposits"
            className="flex flex-row space-x-4  w-[8rem] items-center"
            onClick={() => {
              setActiveNav("Deposit");
              setIsShowing(false);
            }}
          >
            <i className="ri-luggage-deposit-line"></i>
            <p className="ml-[1.6rem] text-sm">Payments</p>
          </NavLink>
        </div>
        <div
          className={`py-4 pl-8 ${
            window.location.pathname === "/admin/adminsenderIDs"
              ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
              : "bg-white"
          }`}
        >
          <NavLink
            to="adminsenderIDs"
            className="flex flex-row space-x-4  w-[8rem] items-center"
            onClick={() => {
              setActiveNav("senderIds");
              setIsShowing(false);
            }}
          >
            <i className="ri-account-pin-box-line"></i>
            <p className="ml-[1.6rem] text-sm">Sender IDs</p>
          </NavLink>
        </div>
        <div className="pl-8">
          <button
            className="py-4 flex flex-row space-x-4 w-[8rem] items-center"
            onClick={() => setShowRecord((prev) => !prev)}
          >
            <i className="ri-user-add-line"></i>
            <p className="ml-[1.6rem] text-sm">Users</p>
            {showRecord ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </button>
          <div className={`${!showRecord ? "hidden" : "block"} pl-4`}>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/adminList" &&
                "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
              }`}
            >
              <NavLink
                to="adminList"
                className=" flex flex-row space-x-4  w-[8rem] items-center"
                onClick={() => {
                  setActiveNav("AddUser");
                  setIsShowing(false);
                }}
              >
                <p className="ml-[1.6rem] text-sm">Admins</p>
              </NavLink>
            </div>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/client-list" ||
                window.location.pathname === "/admin/user-dashboard" ||
                window.location.pathname ===
                  "/admin/user-dashboard/singleusersenderid" ||
                window.location.pathname === "/admin/user-payments" ||
                window.location.pathname === "/admin/message-history" ||
                window.location.pathname === "/admin/user-transactions/"
                  ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
                  : "bg-white"
              }`}
            >
              <NavLink
                to="client-list"
                className="flex flex-row space-x-4  w-[8rem]"
                onClick={() => {
                  setActiveNav("Clients");
                  setIsShowing(false);
                }}
              >
                <p className="pl-[1.6rem] text-sm">Clients</p>
              </NavLink>
            </div>
            <div
              className={`py-4 ${
                window.location.pathname === "/admin/auditLogs"
                  ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
                  : "bg-white"
              }`}
            >
              <NavLink
                to="auditLogs"
                className="flex flex-row space-x-4  w-[8rem]"
                onClick={() => {
                  setActiveNav("auditLogs");
                  setIsShowing(false);
                }}
              >
                <p className="pl-[1.6rem] text-sm">Audit Logs</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          className={`pl-8 py-4 ${
            window.location.pathname === "/admin/settings" ||
            window.location.pathname === "/admin/base-price" ||
            window.location.pathname === "/admin/user-price"
              ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
              : "bg-white"
          }`}
        >
          <NavLink
            to="settings"
            className="flex flex-row space-x-4 w-[8rem] items-center"
            onClick={() => {
              setActiveNav("Settings");
              setIsShowing(false);
            }}
          >
            <i className="ri-settings-4-line"></i>
            <p className="ml-[1.6rem] text-sm">Settings</p>
          </NavLink>
        </div>
        <div
          className={`pl-8 py-4 ${
            window.location.pathname === "Logout"
              ? "bg-gradient-to-r from-purple-200 via-purple-100 to-purple-50 "
              : "bg-white"
          }`}
          onClick={() => {
            logOut();
            setActiveNav("Logout");
            setIsShowing(false);
          }}
        >
          <div
            className="flex flex-row space-x-4 w-[8rem] items-center"
            style={{ cursor: "pointer" }}
          >
            <i className="ri-logout-box-line text-1xl"></i>
            <p className="ml-[1.6rem] text-sm">Logout</p>
          </div>
        </div>
      </div>
      <div
        className={`${styles.topBar} lg:pr-[3rem] bg-white h-[80px] flex flex-row items-center justify-between lg:justify-end p-[2rem]`}
      >
        <i
          className="ri-menu-line lg:hidden"
          onClick={() => setIsShowing(true)}
        ></i>
        <div className="flex flex-row items-center">
          <p className="text-[#252733] text-sm font-semibold mr-[1rem] lg:mr-[1rem]">
            {name}
          </p>
          <Avatar>{initials}</Avatar>
        </div>
      </div>
      <div className="lg:ml-[18rem] lg:mr-[2rem] mt-[3rem] p-[1rem] lg:px[0]">
        {children}
        <Outlet />
      </div>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={"Are you sure you want to Log out?"}
        />
      )}
    </div>
  );
}

export default Layout;
