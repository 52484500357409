import {
  forgotPasswordFail,
  forgotPasswordStart,
  forgotPasswordSuccess,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
} from "../../../../redux/admin/auth/adminForgotPasswordSlice";
import axios from "../../../axios";

export const runForgotPassword = (email) => async (dispatch) => {
  dispatch(forgotPasswordStart());
  await axios
    .post("admin/forget-password", { email, userType: "admin" }) // this is a post request by sending payload in form of object here the payload is email and the one in quotes is the url
    .then((response) => {
      const { message } = response.data;
      dispatch(forgotPasswordSuccess(message));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(forgotPasswordFail(message)); //stori
    });
};

export const runResetPassword =
  (otp, password, confirm_password) => async (dispatch) => {
    dispatch(resetPasswordStart());
    await axios
      .post("admin/update-password", { otp, password, confirm_password })
      .then((response) => {
        const { message } = response.data;
        dispatch(resetPasswordSuccess(message));
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(resetPasswordFail(message));
      });
  };
