import React from "react";
import styles from "./xturi.module.css";
import share from "../../assets/share.svg";
import sms from "../../assets/sms.svg";
import people from "../../assets/people.svg";
import notification from "../../assets/notification.svg";
import { Fade } from "react-reveal";
import Tiles from "./Tiles";
import Slide from "react-reveal/Slide";

const Xturi = () => {
  return (
    <>
      <div className={styles.mainWrap}>
        <Fade>
          <div className={styles.centerred}>
            <h3 className={styles.covered}>
              We empower <span className={styles.businesses}>businesses</span>{" "}
              with seamless communication.
            </h3>
          </div>
        </Fade>

        <Slide right>
          <div className={styles.midSectionBox}>
            <div className={styles.tilesBox}>
              <div>
                <Tiles
                  Svg={share}
                  Title={"Programmable Message API"}
                  Details={
                    "XMS APIs provide an easy way to send messages directly from your applications to your users."
                  }
                />
                <Tiles
                  Svg={sms}
                  Title={"Bulk SMS"}
                  style={{ marginTop: "4.8vh" }}
                  Details={
                    "Send messages to thousands of numbers at once to publicize your event, product, or service."
                  }
                />
              </div>
              <div>
                <Tiles
                  Svg={people}
                  Title={"Collaborate more with Teams"}
                  Details={
                    "XMS allows you to collaborate more conveniently and intuitively with your teammates"
                  }
                />

                <Tiles
                  Svg={notification}
                  Title={"Instant Notification"}
                  style={{ marginTop: "4.8vh" }}
                  Details={
                    "Send transactional messages, payment alerts/reminders and other sensitive information."
                  }
                />
              </div>
            </div>
          </div>
        </Slide>

        <div className={styles.btnContainer}>
        <Slide up>
        <button className={styles.btn}>
        Get started
        </button>
        </Slide>
        </div>
      </div>
    </>
  );
};

export default Xturi;
