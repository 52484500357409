import React, { useEffect, useState } from "react";
import axios from "../../../../utils/axios";
import UserData from "./UserData";

import "./senderId.css";
import PopUp from "./PopUp";
import { useDispatch } from "react-redux";
import SAlert from "../../../../utils/Alert";
import { Alert, Box, CircularProgress } from "@mui/material";
const SenderId = ({ Name, Status, UseCase }) => {
  const [senderIDs, setSenderIDs] = useState([]);
  const [registerStatus, setRegisterStatus] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>, deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
    if (registerStatus) {
      setAlertStatus({
        alertMessage: `${
          registerStatus === "success"
            ? "Sender ID created"
            : "Registration Failed"
        }`,
        alertType: `${registerStatus === "success" ? "success" : "error"}`,
        openAlert: true,
      });
      setRegisterStatus("");
    }
    axios
      .get("/user/sender_id/view")
      .then((response) => {
        const { data } = response.data;
        setSenderIDs(data);
        setLoading(false);
      })
      .catch((error) => {
        setAlertStatus({
          alertMessage: "Error fetching Sender IDs",
          alertType: "error",
          openAlert: true,
        });
        setLoading(false);
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const [popup, setPopup] = useState(false);
  const openPopUp = () => {
    setPopup(true);
  };

  return (
    <div>
      {loading ? (
        <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
          <CircularProgress sx={{ color: "#C4C4C4" }} />
        </Box>
      ) : (
        <div className="mainContainer">
          <div className="subContainer">
            {/* <div className="dataBox"> */}
            {/* <div className="rowTitles">
          <h3>Sender ID</h3>
          <h3>Status</h3>
          <h3>Use Case</h3>
        </div> */}
            {senderIDs.length === 0 ? (
              <center>
                <Alert
                  sx={{ width: 350, marginBottom: "1rem" }}
                  severity="info"
                >
                  You have no Sender ID
                </Alert>
              </center>
            ) : (
              <div className="row1">
                <div style={{ overflowX: "auto" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sender ID</th>
                        <th>Status</th>
                        <th>Use Case</th>
                      </tr>
                    </thead>
                    {/* {senderIDs.map((SenderID, index) => (
            <UserData
              key={index}
              Name={SenderID.sender_id}
              Status={SenderID.status}
              Usecase={SenderID.useCase}
            />
          ))} */}
                    {/* <tr>
              <td>{Name}</td>
              <td
                id="status"
                style={{
                  backgroundColor:
                    Status === "pending"
                      ? "#F29339"
                      : Status === "approved"
                      ? ""
                      : "#D11A2A",
                }}
              >
                {Status}
              </td>
              <td>{UseCase}</td>
            </tr> */}

                    {senderIDs.map((item, index) => (
                      <tr key={index}>
                        <td>{item.sender_id}</td>
                        <td
                          id="status"
                          style={
                            {
                              // background:
                              //   Status === "pending"
                              //     ? "#F29339"
                              //     : Status === "approved"
                              //     ? ""
                              //     : "#D11A2A",
                            }
                          }
                        >
                          {item.status}
                        </td>
                        <td>{item.use_case}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            )}
            <div className="RegBtns">
              <button className="active" id="btns" onClick={openPopUp}>
                Register Sender ID
              </button>
              {/* <button id="btns" onClick={openPopUp}>
            Register Another Sender ID
          </button> */}
            </div>
            {/* </div> */}
          </div>
          {popup && (
            <PopUp
              registerStatus={registerStatus}
              setRegisterStatus={setRegisterStatus}
              close={() => {
                setPopup(false);
              }}
            />
          )}
        </div>
      )}
      <SAlert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </div>
  );
};

export default SenderId;
