import {
  getTransactionFailed,
  getTransactionStart,
  getTransactionSuccess,
} from "../../../../redux/admin/users/userTransactionsSlice";
import axios from "../../../axios";

// export const getRecentTransactions = () => async (dispatch) =>{
//     dispatch(getTransactionStart())
//     await axios.get("user/trans/recent").then((response) => {
//         const {message} = response.data
//         dispatch(getTransactionSuccess(message))
//     });
// }

export const getUserTransactionByDate =
  (state, start_date, end_date) => async (dispatch) => {
    dispatch(getTransactionStart());
    await axios
      .get(`admin/trans/${state}/${start_date}/${end_date}`)
      .then((response) => {
        const { message } = response.data;

        dispatch(getTransactionSuccess(message));
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(getTransactionFailed(message));
      });
  };
