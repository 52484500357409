import Sidebar from "../Sidebar/Sidebar";
import TopBar from "../TopBar/TopBar";

import { COLORS } from "../../constants/theme";

import styles from "./Layout.module.css";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const { layout } = COLORS;

export default function Layout({ children }) {
  // window.addEventListener("contextmenu", (e) => e.preventDefault());// disable right click
  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = useState(false);
  useEffect(() => {
    setOpenSideBar(false);
  }, [window.location.pathname]);

  const { user } = JSON.parse(localStorage.userDetails);
  useEffect(() => {
    if (
      user === undefined ||
      user?.first_name === null ||
      user?.last_name === null
    ) {
      navigate("settings/edit");
    }
  }, [window.location.pathname]);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar open={openSideBar === false ? "none" : "flex"} />
      <div
        className={styles.body}
        style={{ background: layout, width: "100%", marginLeft: "256px" }}
      >
        <TopBar
          menuList={[1, 2]}
          handeOpen={() => setOpenSideBar((prev) => !prev)}
        />
        <div className={styles.children} style={{ padding: "4rem" }}>
          {children}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
