import React, { useEffect, useState } from "react";
import styles from "./edit.module.css";
import closeIcon from "../../../assets/closeIcon.svg";
import Modal from "../../../../components/modal/Modal";
import { useSelector } from "react-redux";
import Alert from "../../../../utils/Alert";
import ConfirmAdminEditUserRate from "../../../../components/confirmCard/ConfirmAdminEditUserRate";

const EditBox = ({ closeModal, price }) => {
  const [email, setEmail] = useState(price.email);
  const [promotionalRate, setPromotionalRate] = useState(
    price.promotional_rate
  );
  const [transactionalRate, setTransactionalRate] = useState(
    price.transactional_rate
  );
  const [canUpdatePrice, setCanUpdatePrice] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const { updateSuccess } = useSelector((state) => state.customUserRates);

  useEffect(() => {
    promotionalRate !== "" && email !== "" && transactionalRate !== ""
      ? setCanUpdatePrice(true)
      : setCanUpdatePrice(false);
  }, [email, promotionalRate, transactionalRate]);

  useEffect(() => {
    updateSuccess && setShowWarning(false);
  }, [updateSuccess]);

  const updateUserRateAction = () => {
    if (promotionalRate > 0 && transactionalRate > 0) {
      setShowWarning(true);
    } else {
      setErrorMsg(true);
      setShowWarning(false);
    }
  };

  const handleErrorMsg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMsg(false);
  };

  return (
    <>
      {showWarning && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ConfirmAdminEditUserRate
            close={() => setShowWarning(false)}
            email={email}
            promotionalRate={promotionalRate}
            transactionalRate={transactionalRate}
          />
        </Modal>
      )}
      <div className={styles.mainWrap}>
        <span className={styles.header}>
          <big>Edit Rates</big>
          <img
            src={closeIcon}
            alt=""
            style={{ width: "16px", height: "auto", cursor: "pointer" }}
            onClick={() => closeModal(false)}
          />
        </span>

        <div className={styles.fieldBox}>
          <span className={styles.fields}>
            <label>Promotional Rate</label>

            <input
              type="number"
              onChange={(e) => setPromotionalRate(e.target.value)}
              value={promotionalRate}
              required
            />
          </span>
          <span className={styles.fields}>
            <label>Transactional Rate</label>
            <input
              type="number"
              onChange={(e) => setTransactionalRate(e.target.value)}
              value={transactionalRate}
              required
            />
          </span>
        </div>

        <div className={styles.buttonBox}>
          {/* <button
            id={styles.delBtn}
            onClick={deletePrice}
            style={{ opacity: !priceDelete ? 0.5 : 1 }}
          >
            Delete Price
          </button> */}
          <button
            id={styles.upBtn}
            onClick={updateUserRateAction}
            disabled={!canUpdatePrice ? true : false}
          >
            Update Price
          </button>
        </div>

        <Alert
          openAlert={errorMsg}
          Text={"Rates must be greater than 0!"}
          Type={"error"}
          handleClose={handleErrorMsg}
        />
      </div>
    </>
  );
};

export default EditBox;
