import React from "react";
import styles from "./topContents.module.css";

const InfoButtons = ({ icon, numbers, texts }) => {
  return (
    <div className={styles.infoButtonBox}>
      <span className={styles.infoButtonIcon}> {icon} </span>
      <span className={styles.infoContainer}>
        <h3 className={styles.infoButtonNumber}> {numbers} </h3>
        <p className={styles.infoButtonText}> {texts} </p>
      </span>
    </div>
  );
};

export default InfoButtons;
