import React, { useEffect } from "react";
import styles from "./modalCss.module.css";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteAdminScheduledMessages } from "../../../../utils/AdminApiCalls/adminScheduledMessages";

const DeleteModal = ({ closeModdal, messageID }) => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.sendSmsAll.deleteLoading);
  const deleteSuccess = useSelector((state) => state.sendSmsAll.deleteSuccess);

  useEffect(() => {
    deleteSuccess && closeModdal(false)
  }, [deleteSuccess])
  
  const onDelete = () => {
    dispatch(deleteAdminScheduledMessages(messageID));
  }
  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "20px" }}>
        <Warning />
        <h3>Are you sure you want to delete this message?</h3>
        <div className={styles.buttonSection}>
          <button onClick={() => closeModdal(false)}>Cancel</button>
          <button onClick={()=> onDelete()} disabled={loading} style={{opacity: loading ? 0.5 : 1}}>{loading ? "Please wait..." : "Continue"}</button>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
