import React, { useEffect, useState } from "react";
import styles from "./Schedulesms.module.css";
import { Box, CircularProgress, Modal, Alert } from "@mui/material";
import moment from "moment";
import bin1R from "../../../../assets/bin1R.svg";
import sendMsg from "../../../../assets/sendMsg.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "./DeleteModal";
import SendNowModal from "./SendNowModal";
import {
  resetScheduleSuccess,
  resetStatus,
} from "../../../../redux/admin/messageScheduleSlices/scheduledMessagesSlice";
import { getAdminScheduledMessages } from "../../../../utils/AdminApiCalls/adminScheduledMessages";
import Alertt from "../../../../utils/Alert";

function AdminScheduledMessages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const [sendSuccessful, setSendSuccessful] = useState(false);

  const loading = useSelector((state) => state.AdminScheduledMessages.loading);
  const scheduled = useSelector((state) => state.AdminScheduledMessages.Data);
  const deleteSuccess = useSelector(
    (state) => state.AdminScheduledMessages.deleteSuccess
  );
  const sendSuccess = useSelector(
    (state) => state.AdminScheduledMessages.sendSuccess
  );
  const sendSuccessMessage = useSelector(
    (state) => state.AdminScheduledMessages.sendSuccessMessage
  );
  const deleteSuccessMessage = useSelector(
    (state) => state.AdminScheduledMessages.deleteSuccessMessage
  );

  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedID, setSelectedID] = useState("");

  useEffect(() => {
    dispatch(getAdminScheduledMessages());
    dispatch(resetScheduleSuccess());
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      setShowWarning(false);
      dispatch(resetStatus());
      // setTimeout(() => {
      setDeleteSuccessful(true);
      // window.location.reload();
      // }, 500);
    } else if (sendSuccess) {
      setShowWarning(false);
      dispatch(resetStatus());
      // setTimeout(() => {
      setSendSuccessful(true);
      // window.location.reload();
      // }, 500);
    }
  }, [deleteSuccess, sendSuccess]);

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  const handleDelete = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteSuccessful(false);
    dispatch(resetStatus());
  };

  const handleSend = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSendSuccessful(false);
    dispatch(resetStatus());
  };

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return scheduled && scheduled.slice(firstPageIndex, lastPageIndex);
  };

  return (
    <>
      {showWarning && (
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DeleteModal closeModdal={setShowWarning} messageID={selectedID} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SendNowModal
            closeModal={setShowConfirmation}
            messageID={selectedID}
          />
        </Modal>
      )}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : scheduled.length === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No record.
          </Alert>
        </center>
      ) : (
        <>
          <div className={styles.transactionBox}>
            <div className={styles.tableSection}>
              <div style={{ overflowX: "auto" }}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th>Sender ID</th>
                      <th>Message</th>
                      <th>Created Time</th>
                      <th>Scheduled Time</th>
                      <th>Channel</th>
                      <th></th>
                    </tr>
                  </thead>
                  {currentTableData()?.map((data, index) => (
                    <tr key={index}>
                      <td>{data.subject}</td>
                      <td>{data.message?.slice(0, 50)}...</td>
                      <td>
                        {moment(data.created_at).format("YYYY-MM-DD, HH:mm:ss")}
                      </td>
                      <td>
                        {moment(`${data.date} ${data.time}`).format(
                          "YYYY-MM-DD, HH:mm:ss"
                        )}
                      </td>

                      <td>{data.channel}</td>
                      <td id={styles.delSend}>
                        <div
                          id={styles.row1}
                          onClick={() => {
                            setShowWarning(true);
                            handleOpen();
                            setSelectedID(data.id);
                          }}
                        >
                          <img src={bin1R} id={styles.binIcon} />
                          <span style={{ color: "var(--color-red)" }}>
                            Delete
                          </span>
                        </div>
                        <di
                          id={styles.row1}
                          onClick={() => {
                            setShowConfirmation(true);
                            handleOpen();
                            setSelectedID(data.id);
                          }}
                        >
                          <img src={sendMsg} alt="" id={styles.msgIcon} />
                          <span> Send now</span>
                        </di>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <Alertt
            openAlert={deleteSuccessful}
            Text={deleteSuccessMessage}
            Type="success"
            handleClose={handleDelete}
          />
          <Alertt
            openAlert={sendSuccessful}
            Text={sendSuccessMessage}
            Type="success"
            handleClose={handleSend}
          />
        </>
      )}
    </>
  );
}

export default AdminScheduledMessages;
