import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  allNotifications: [],
  singleNotification: {},
  failed: "",
};

const getAllNotifications = createSlice({
  name: "Notifications",
  initialState,
  reducers: {
    getNotificationStart: (state) => {
      state.loading = true;
    },
    getEntireNotifications: (state, { payload }) => {
      state.allNotifications = payload;
      state.loading = false;
      state.failed = "";
    },
    getSingleNotification: (state, { payload }) => {
      state.singleNotification = payload;
      state.loading = false;
      state.failed = "";
    },
    getNotificationFailed: (state, { payload }) => {
      state.singleNotification = {};
      state.allNotifications = [];
      state.loading = false;
      state.failed = payload;
    },
    clearSingleNotification: (state) => {
      state.singleNotification = {};
      state.loading = false;
    },
  },
});

export const {
  getEntireNotifications,
  getNotificationFailed,
  getNotificationStart,
  getSingleNotification,
  clearSingleNotification,
} = getAllNotifications.actions;

export default getAllNotifications.reducer;
