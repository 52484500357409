import {
  profileUpdateFail,
  profileUpdateStart,
  profileUpdateSuccess,
} from "../../redux/profile/profileSlice";
import axios from "../axios";
import { getProfile } from "./getProfile";

export const profileUpdate = (data) => async (dispatch) => {
  dispatch(profileUpdateStart());
  axios
    .post("/user/update-profile", data)
    .then((response) => {
      dispatch(profileUpdateSuccess(response.data));
      dispatch(getProfile());
    })
    .catch((error) => {
      dispatch(profileUpdateFail("Phone number already in use"));
    });
};
