import React from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { registerAdmin } from "../../utils/AdminApiCalls/registerAdmin";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
function AdminConfirmAddUser({ close, email, name, phone, role }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const { adminRegistered } = useSelector((state) => state.addAdmin);

  const data = {
    email,
    name,
    phone,
    role,
  };

  const createAdmin = () => {
    if (adminRegistered) {
      setLoader(true);
    }
    dispatch(registerAdmin(data));
  };

  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "40px" }}>
        <Warning />
        <h3 style={{ textAlign: "center" }}>
          Are you sure you want to Add User
        </h3>
        <div className={styles.buttonSection}>
          <button onClick={() => close(false)}>cancel</button>
          <button onClick={createAdmin}>
            {loader ? (
              <CircularProgress sx={{ color: "#C4C4C4" }} />
            ) : (
              "continue"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminConfirmAddUser;
