import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  depositsFetched: [],
  depositsError: "",
};

const DepositsSlice = createSlice({
  name: "Deposits",
  initialState,
  reducers: {
    depositFetchingStart: (state) => {
      state.loading = true;
    },
    depositFetchingSuccess: (state, { payload }) => {
      state.depositsError = null;
      state.depositsFetched = payload;
      state.loading = false;
    },
    depositFetchingError: (state, { payload }) => {
      state.depositsError = payload;
      state.depositsFetched = null;
      state.loading = false;
    },
  },
});

export const {
  depositFetchingError,
  depositFetchingStart,
  depositFetchingSuccess,
} = DepositsSlice.actions;

export default DepositsSlice.reducer;
