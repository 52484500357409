import { Box, CircularProgress, Modal } from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import Modal from "../../../../../components/modal/Modal";
import Alert from "../../../../../utils/Alert";
import Message from "./Message";
import styles from "./notification.module.css";
const AllNotificationMessages = () => {
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [message, setMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { getNotificationsLoading, getAllNotificationData } = useSelector(
    (state) => state.getAllNotifications
  );

  return (
    <>
      {showMessageBox && (
        <Modal open={open} onClose={handleClose}>
          <Message closeModal={setShowMessageBox} data={message} />
        </Modal>
      )}

      {getNotificationsLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : getAllNotificationData === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No data available at the moment
          </Alert>
        </center>
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.wrapA}>
            {getAllNotificationData.length > 0 &&
              getAllNotificationData.map((data, index) => (
                <div
                  className="hover:cursor-pointer hover:bg-gray-100 w-full"
                  id={styles.boxAWrap}
                  onClick={() => {
                    setShowMessageBox(true);
                    setMessage(data);
                    handleOpen();
                  }}
                  key={index}
                >
                  <div className={styles.boxA} key={index}>
                    <div className="flex flex-col">
                      <span className="text-xs">
                        <b>{data.subject} </b>
                      </span>
                      <span className="text-[#C4C4C4] text-[12px] mt-2 pr-10">
                        {data.message}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[#C4C4C4] text-[10px]">
                        {moment(data.created_at).format("lll")}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AllNotificationMessages;
