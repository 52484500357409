import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sendingLoading: false,
  sent: null,
  failed: null,
  sendingSuccess: false,
  time: "",
  date: "",
  sendScheduledNotificationLoad: false,
  scheduleNotificationSuccess: false,
  scheduledTime: false,
};

const SendSmsAllUsersSlice = createSlice({
  name: "SMS TO ALL USERS",
  initialState,
  reducers: {
    SendingSms: (state) => {
      state.sendingLoading = true;
      state.sent = null;
      state.failed = null;
    },
    SmsSent: (state, { payload }) => {
      state.sent = payload;
      state.sendingLoading = false;
      state.sendingSuccess = true;
      state.scheduledTime = false;
      state.failed = null;
    },
    SmsFailed: (state, { payload }) => {
      state.sent = null;
      state.failed = payload;
      state.sendingLoading = false;
    },

    saveDateInState: (state, { payload }) => {
      state.time = payload.time;
      state.date = payload.date;
    },
    resetDate: (state) => {
      state.time = "";
      state.date = "";
    },
    scheduleSuccess: (state) => {
      state.sendScheduledNotificationLoad = false;
      state.scheduleNotificationSuccess = true;
      state.scheduledTime = false;
    },
    resetScheduleSuccess: (state) => {
      state.scheduleNotificationSuccess = false;
    },
    showScheduledTime: (state) => {
      state.scheduledTime = true;
    },
  },
});

export const {
  SendingSms,
  SmsFailed,
  SmsSent,
  saveDateInState,
  resetDate,
  scheduleSuccess,
  resetScheduleSuccess,
  showScheduledTime,
} = SendSmsAllUsersSlice.actions;

export default SendSmsAllUsersSlice.reducer;
