import axios from "../../../axios";

export const AdminLogout = async () => {
  await axios
    .post("admin/logout")
    .then(() => {
      localStorage.removeItem("userDetails");
    })
    .catch((error) => {
      const { message } = error;
    });
};
