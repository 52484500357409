import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  responseCreated: false,
  getResponse: [],
  loading: false,
  APIsendSuccessMessage: "",
  APIsendSuccess: false,
  APIdeleteSuccess: false,
  APIdeactivateSuccess: false,
  APIdeleteSuccessMessage: "",
  APIdeactivateSuccessMessage: ""
};

export const getAPIslice = createSlice({
  name: "Get-APIs",
  initialState,
  reducers: {
    getAPIstart: (state) => {
      state.loading = true;
    },
    getAPIsuccess: (state, { payload }) => {
      state.responseCreated = true;
      state.getResponse = payload;
      state.loading = false;
    },
    sendAPIsuccess: (state, { payload }) => {
      state.APIsendSuccess = true;
      state.APIsendSuccessMessage = payload;
    },
    deleteAPIsuccess: (state, { payload }) => {
      state.APIdeleteSuccess = true;
      state.APIdeleteSuccessMessage = payload;
    },
    deactivateAPIsuccess: (state, { payload }) => {
      state.APIdeactivateSuccess = true;
      state.APIdeactivateSuccessMessage = payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getAPIsuccess,
  getAPIstart,
  sendAPIsuccess,
  deactivateAPIsuccess,
  deleteAPIsuccess,
} = getAPIslice.actions;

export default getAPIslice.reducer;
