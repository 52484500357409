import axios from "../axios";
import {
  // emailVerificationSuccess,
  registerFail,
  registerStart,
  registerSuccess,
} from "../../redux/auth/registerSlice";

export const register = (data) => async (dispatch) => {
  dispatch(registerStart());
  axios
    .post("/user/register", data)
    .then(function (response) {
      dispatch(registerSuccess(response.data));
    })
    .catch(function (error) {
      dispatch(registerFail(error.message));
    });
};

export async function sendOtp(data) {
  try {
    const req = await axios.post("/auth/send-otp", data);
    return req;
  } catch (error) {
    return error;
  }
}

export async function verifyEmail(data) {
  try {
    const req = await axios.post("/auth/email-verify", data);
    return req;
  } catch (error) {
    return error;
  }
}
