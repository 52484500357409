import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forward: false,
  resend: false,
  message: "",
  numbers: "",
  senderID: ""
};

export const SmsActionsSlice = createSlice({
  name: "Sms action",
  initialState,
  reducers: {
    forwardMessage: (state, { payload }) => {
      state.senderID = payload.senderID;
      state.message = payload.message;
       state.forward = true;
    },
    resendMessage: (state, {payload}) =>{
        state.senderID = payload.senderID;
        state.message = payload.message;
        state.numbers = payload.numbers
        state.resend = true;
    },
    resetStatus: (state) => {
        state.resend = false;
        state.forward = false;
        state.message = "";
        state.numbers = "";
        state.senderID = "";
    }
  },
});

export const { forwardMessage, resendMessage, resetStatus } = SmsActionsSlice.actions;

export default SmsActionsSlice.reducer;
