import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  clientList: [],
  clientError: "",
};

const AllClients = createSlice({
  name: "All Client list",
  initialState,
  reducers: {
    getAllClientStart: (state) => {
      state.loading = true;
    },
    getAllClientSuccess: (state, actions) => {
      state.loading = false;
      state.clientList = actions.payload;
    },
    getAllClientFailed: (state, { payload }) => {
      state.loading = false;
      state.clientList = [];
      state.clientError = payload;
    },
  },
});

export const { getAllClientStart, getAllClientSuccess, getAllClientFailed } =
  AllClients.actions;

export default AllClients.reducer;
