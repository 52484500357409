import React, { useEffect, useState } from "react";
import styles from "./settings.module.css";
import settingsBanner from "../../../assets/settingsBanner.png";
import SettingsNav from "./SettingsNav";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import SearchIcon from "../../../assets/search.svg";
import edit from "../../../assets/edit.svg";
import Modal from "../../../../components/modal/Modal";
import EditBox from "./EditBox";
import { useDispatch, useSelector } from "react-redux";
import { GetUserRates } from "../../../../utils/AdminApiCalls/customUserRate";
import Alert from "../../../../utils/Alert";
import { resetUpdateSuccess } from "../../../../redux/admin/settingsEndpoints/customUserRateSlice";
import { Box, CircularProgress } from "@mui/material";

const UserPrice = () => {
  const dispatch = useDispatch();
  const [editAction, setEditAction] = useState(false);
  const [name, setName] = useState("");
  const [promotional, setPromotional] = useState();
  const [transactional, setTransactional] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [priceToEdit, setPriceToEdit] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const { getUserRateData, updateSuccess, getUserRateLoading } = useSelector(
    (state) => state.customUserRates
  );

  useEffect(() => {
    if (updateSuccess) {
      setOpenSuccess(true);
      setEditAction(false);
      dispatch(resetUpdateSuccess());
    }
    dispatch(GetUserRates());
  }, [updateSuccess]);

  function closeModal(e) {
    if (e.target.id == "modal") {
      setEditAction(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const adminRole = JSON.parse(localStorage.getItem("userDetails")).user.role;

  return (
    <>
      {editAction && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={closeModal}
        >
          <EditBox closeModal={setEditAction} price={priceToEdit} />
        </Modal>
      )}

      <div className="mainWrap">
        <div id={styles.banner}>
          <img src={settingsBanner} alt="banner" />
        </div>
        <div className={styles.mainContentWrap}>
          <div className={styles.mainContents}>
            <SettingsNav />
            {getUserRateLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "300px",
                  paddingTop: "100px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : getUserRateData === 0 ? (
              <center>
                <Alert
                  sx={{ width: 350, marginBottom: "1rem" }}
                  severity="info"
                >
                  No record available at the moment
                </Alert>
              </center>
            ) : (
              <div>
                <div className={styles.searchRow}>
                  <div className={styles.sortSection}>
                    <div className={styles.searchSection}>
                      <div className={styles.searchBox}>
                        <img src={SearchIcon} />
                        <input
                          type="text"
                          className={styles.search}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <button className={styles.sortBtn}>
                      <Filter />
                      Sort by
                    </button>
                  </div>
                </div>
                <div className={styles.tableWrap}>
                  <table className={styles.customers}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Promotional</th>
                        <th>Transactional</th>
                        {adminRole === "superadmin" && <th></th>}
                      </tr>
                    </thead>
                    {getUserRateData.map((item, index) => (
                      <tr key={index}>
                        <td value={name}>{item.name}</td>
                        <td value={promotional}>{item.promotional_rate}</td>
                        <td value={transactional}>{item.transactional_rate}</td>
                        {adminRole === "superadmin" && (
                          <td>
                            {Object.keys(getUserRateData).length > 0 && (
                              <button
                                id={styles.editBtn}
                                onClick={() => {
                                  setEditAction(true);
                                  setPriceToEdit(item);
                                }}
                              >
                                <img
                                  src={edit}
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Alert
        openAlert={openSuccess}
        Text={"User unit rate update successful!"}
        Type={"success"}
        handleClose={handleClose}
      />
    </>
  );
};

export default UserPrice;
