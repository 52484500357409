import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Fundsms.module.css";
import { ReactComponent as Wallet } from "../../../assets/wallet.svg";
import { ReactComponent as Cart } from "../../../assets/cart.svg";
import { ReactComponent as Export } from "../../../assets/export.svg";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import Modal from "../../../components/modal/Modal";
import BuyUnit from "../../../components/buyUnit/BuyUnit";
import TransferCard from "../../../components/transfercard/TransferCard";
import ConfirmCard from "../../../components/confirmCard/ConfirmCard";
import { useDispatch, useSelector } from "react-redux";
import { getWallet } from "../../../utils/apiCalls/getWallet";
import {
  formatFloat,
  formatPriceByCountrySymbol,
} from "../../../utils/formatPrice";
import RecentTransactions from "../dashboard/RecentTransactions";
function Fundsms() {
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showBuyUnitModal, setShowBuyUnitModal] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [unitBalance, setUnitBalance] = useState(0);
  const dispatch = useDispatch();

  const wallet = useSelector((state) => state.wallet.walletData);

  useEffect(() => {
    dispatch(getWallet());
  }, []);
  useEffect(() => {
    setWalletBalance(wallet?.wallet_balance);
    setUnitBalance(wallet?.unit_balance);
  }, [wallet]);

  function closeModal(e) {
    if (e.target.id == "modal") {
      setShowTransferModal(false);
    }
  }

  function closeModall(e) {
    if (e.target.id == "modal") {
      setShowBuyUnitModal(false);
    }
  }

  const navigate = useNavigate();

  return (
    <>
      {showTransferModal && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <TransferCard closeModal={setShowTransferModal} />
        </Modal>
      )}
      {showBuyUnitModal && (
        <Modal
          close={closeModall}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <BuyUnit closeModal={setShowBuyUnitModal} />
        </Modal>
      )}

      <div className={styles.balance}>
        <div className={styles.balanceCard}>
          <p>Wallet Balance</p>
          <h3>{formatPriceByCountrySymbol("Nigeria", walletBalance ?? 0)}</h3>
          <div className={styles.iconWrap}>
            <div className={styles.icons}>
              <button
                className={styles.iconsone}
                onClick={() => navigate("/wallet/addfunds")}
              >
                <Wallet />
                <p>
                  <small>Add funds</small>
                </p>
              </button>
            </div>

            <div className={styles.icons}>
              <button
                className={styles.iconsone}
                style={{ justifyContent: "center" }}
                onClick={() => setShowTransferModal((prev) => !prev)}
              >
                <Export />
                <p>
                  <small id={styles.targetCartTxt}>Transfer money</small>
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fundContainer}>
        {/* <div className={styles.details}>
          <h3>Hi, kakashi</h3>
          <p>Have you been productive today?</p>
        </div> */}
        <RecentTransactions />
      </div>
    </>
  );
}

export default Fundsms;
