import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getNotificationsLoading: false,
  getNotificationSuccess: false,
  getAllNotificationData: [{}],
  getNotificationFailed: false,
  sendScheduledNotificationLoad: false,
  scheduleNotificationSuccess: false,
  time: "",
  date: "",
};

const getAllNotification = createSlice({
  name: "Get All Notifications",
  initialState,
  reducers: {
    getAllNotificationStart: (state) => {
      state.getNotificationsLoading = true;
    },
    getAllNotificationSuccessful: (state, { payload }) => {
      state.getNotificationsLoading = false;
      state.getNotificationSuccess = true;
      state.getAllNotificationData = payload;
      state.getNotificationFailed = false;
    },
    getAllNotificationError: (state) => {
      state.getNotificationsLoading = false;
      state.getNotificationSuccess = false;
      state.getAllNotificationData = {};
      state.getNotificationFailed = true;
    },

    saveDateInState: (state, { payload }) => {
      state.time = payload.time;
      state.date = payload.date;
    },
    resetDate: (state) => {
      state.time = "";
      state.date = "";
    },
    scheduleSuccess: (state) => {
      state.sendScheduledNotificationLoad = false;
      state.scheduleNotificationSuccess = true;
    },
    resetScheduleSuccess: (state) => {
      state.scheduleNotificationSuccess = false;
    },
  },
});

export const {
  getAllNotificationStart,
  getAllNotificationSuccessful,
  getAllNotificationError,
  resetDate,
  saveDateInState,
  scheduleSuccess,
  resetScheduleSuccess,
} = getAllNotification.actions;

export default getAllNotification.reducer;
