import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminConfirmMailAll from "../../../components/confirmCard/AdminConfirmMailAll";
import Modal from "../../../components/modal/Modal";
import {
  sendMailError,
  sendMailSuccess,
} from "../../../redux/admin/messages/sendMailSlice";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Alert from "../../../utils/Alert";

function Broadcast() {
  const dispatch = useDispatch();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [rawMessage, setRawMessage] = useState([]);

  const { sendMailLoading, mailSuccess, mailSentSuccessfully, sendingFailed } =
    useSelector((state) => state.sendMail);

  const handleReactEditorInput = (state) => {
    setMessage(state);
  };

  useEffect(() => {
    subject !== "" && message !== "" ? setCanSubmit(true) : setCanSubmit(false);
  }, [subject, message]);

  useEffect(() => {
    if (message) {
      const newMessage = { ...convertToRaw(message.getCurrentContent()) };
      setRawMessage(newMessage.blocks.map((text) => text.text));
    }
  }, [message]);

  // useEffect(() => {
  //   sendMailLoading && sendMailSuccess
  //     ? dispatch(sendMailSuccess())
  //     : dispatch(sendMailError());
  // }, [sendMailLoading]);

  useEffect(() => {
    if (mailSentSuccessfully) {
      setOpenSuccess(true);
      setSubject("");
      setMessage("");
    } else if (sendingFailed) {
      setOpenFailed(true);
      setSubject("");
      setMessage("");
    }
  }, [sendMailLoading]);

  useEffect(() => {
    if (mailSentSuccessfully) {
      setShowWarning(false);
    }
  }, [mailSentSuccessfully]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const submit = (e) => {
    e.preventDefault();
    setShowWarning(true);
  };

  return (
    <>
      {showWarning && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <AdminConfirmMailAll
            close={() => setShowWarning(false)}
            subject={subject}
            message={message}
            rawMessage={rawMessage}
          />
        </Modal>
      )}

      <div>
        <big
          style={{
            fontWeight: "600",
            fontSize: "var(--fs-500)",
          }}
        >
          Broadcast Mails to Users
        </big>
        <div className="bg-white w-full rounded p-[31px]">
          <h3 className="font-medium">Send mail to all users</h3>
          <form class="w-full" onSubmit={(e) => submit(e)}>
            <div class="flex flex-wrap -mx-3 mb-6"></div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                  for="grid-password"
                >
                  Subject
                </label>
                <input
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-email"
                  type="text"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  required
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block tracking-wide font-light text-gray-700 text-xs  mb-4 text-[18px]"
                  for="grid-password"
                >
                  Message
                </label>
                {/* <textarea
                  rows="10"
                  class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  required
                ></textarea> */}
                <Editor
                  className="h-full"
                  editorStyle={{
                    border: "1px solid",
                    height: "150px",
                    padding: 10,
                  }}
                  editorState={message}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleReactEditorInput}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  required
                />
              </div>
              <div class="flex justify-end px-3 mt-10 w-full">
                <button
                  class=" w-full lg:w-[180px] bg-[#4B1A7C] shadow focus:shadow-outline rounded-md focus:outline-none text-white font-bold py-2 px-6 rounded"
                  type="submit"
                  style={{
                    cursor: "pointer",
                    opacity: !canSubmit ? 0.5 : 1,
                  }}
                  disabled={!canSubmit ? true : false}
                >
                  SEND
                </button>
              </div>
            </div>
          </form>
          <Alert
            openAlert={openSuccess}
            Text={mailSuccess}
            Type={"success"}
            handleClose={handleClose}
          />
          <Alert
            openAlert={openFailed}
            Text={sendingFailed}
            Type={"success"}
            handleClose={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default Broadcast;
