import React from "react";
import styles from "./dark.module.css";
import ButtonRows from "./ButtonRows";
import illustrationPhone from "../../assets/illustrationPhone.png";

const Dark = () => {
  const Btns = [
    {
      Txt: "Text Your Whole List at Once",
    },
    {
      Txt: "One Time Codes",
    },
    {
      Txt: "Important Updates & Notifications",
    },
    {
      Txt: "Send Promtional Messages",
    },
    {
      Txt: "Smart Scheduling",
    },
  ];
  const Btns2 = [
    {
      Txt: "Localize Sender IDs",
    },

    {
      Txt: "Automated Opt-out",
    },
    {
      Txt: "Two-Way Texting",
    },
    {
      Txt: "Survey Questions",
    },
  ];

  return (
    <>
      <div className={styles.darkWrap}>
        <div className={styles.btnRows}>
          <h3 className={styles.header}>Some features of XMS</h3>
          <div className={styles.mobileView}>
            <div className={styles.list}>
              {Btns.map((btn, index) => (
                <ButtonRows key={index} Txt={btn.Txt} />
              ))}
            </div>
            <div className={styles.list}>
              {Btns2.map((btn, index) => (
                <ButtonRows key={index} Txt={btn.Txt} />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.rightImg}>
          <img src={illustrationPhone} alt="phoneimg" id={styles.image} />
        </div>
      </div>
    </>
  );
};

export default Dark;
