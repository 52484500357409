import React from "react";
import styles from "./PlanCard.module.css";
import phantom from "../../assets/phantom.svg";
import { ReactComponent as TickIcon } from "../../assets/tick.svg";
import Button from "../Button/Button";
function PlanCard({plan}) {
  return (
    <div className={styles.planCard}>
      <div className={styles.title}>
        <img src={phantom} />
        <p>{plan?.package}</p>
      </div>
      <p className={styles.message}>What You’ll Get</p>
      <div className={styles.itemBox}>
        <TickIcon />
        <p>500 Units</p>
      </div>
      <div className={styles.itemBox}>
        <TickIcon />
        <p>Access to send sms.</p>
      </div>
      <hr style={{width:'100%', borderColor:"#C4C4C4"}}/>
      <p className={styles.price}>N{plan.unit}<span>/bundle</span></p>
      <Button name="CONTINUE" style={{marginTop:67,fontWeight:600 , filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'}}/>
    </div>
  );
}

export default PlanCard;
