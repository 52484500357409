import React from 'react'
import { COLORS, FONTSIZE } from "../../../constants/theme";
import { ReactComponent as GroupIcon } from "../../../assets/group.svg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../../assets/search.svg";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import bin from "../../../assets/bin.svg";
import movefile from "../../../assets/movefiles.svg";

import styles from "./Users.module.css";
const { white, darkBlue, grey, primary, black } = COLORS;
const { px20, px14, px18, px36 } = FONTSIZE;


const Users = () => {
  const tableData = [
    {
      name: "John",
      email:  "Hokage@gmail.com",
      roles: "2 roles",
      lastLogin: "few seconds ago",
      status: "Enabled",
    },
    {
      name: "Kakashi",
      email:  "Hokage@gmail.com",
      roles: "1 roles",
      lastLogin: "Never logged in",
      status: "Disabled",
    },
    {
      name: "John",
      email:  "Hokage@gmail.com",
      roles: "2 roles",
      lastLogin: "2 months ago",
      status: "Disabled",
    },
    {
      name: "Kakashi",
      email:  "Hokage@gmail.com",
      roles: "3 roles",
      lastLogin: "Few seconds ago",
      status: "Enabled",
    },
    {
      name: "John",
      email:  "Hokage@gmail.com",
      roles: "2 roles",
      lastLogin: "Never logged in",
      status: "Enabled",
    },
    {
      name: "Kakashi",
      email:  "Hokage@gmail.com",
      roles: "2 roles",
      lastLogin: "5 minutes ago",
      status: "Enabled",
    },
    {
      name: "John",
      email:  "Hokage@gmail.com",
      roles: "2 roles",
      lastLogin: "2 months ago",
      status: "Enabled",
    },
    {
      name: "Kakashi",
      email:  "Hokage@gmail.com",
      roles: "3 roles",
      lastLogin: "2 months ago",
      status: "Enabled",
    },
   
  ]


  const navigate = useNavigate()
  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={styles.tableDetails}>
          <GroupIcon />
          <div className={styles.tableActions}>
            <button
              className={styles.importBtn}
              onClick={() => navigate("/people/add-user")}
            >
              ADD NEW USER
            </button>
          </div>
        </div>
        <div className={styles.searchSection}>
          <div className={styles.searchBox}>
            <img src={SearchIcon} />
            <input
              type="text"
              className={styles.search}
              placeholder="Search by first name, last name, number"
            />
          </div>
        </div>
        <div className={styles.sortSection}>
          <div>
            <p> Showing 1 - 8 of 1000</p>
            {/* <div className={styles.actions}>
              <input type="checkbox" />
              <img src={movefile} />
              <img src={bin} />
            </div> */}
          </div>
          <button className={styles.sortBtn}>
            <Filter />
            Sort by
          </button>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table className={styles.customers}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Roles</th>
                <th>Last login</th>
                <th>Status</th>
              </tr>
            </thead>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.roles}</td>
                <td>{item.lastLogin}</td>
                <td>{item.status}</td>
                <td>
                  {/* {new Intl.NumberFormat().format(item.xcoinwalletBalance)} */}
                </td>
                {/* <td>{new Intl.NumberFormat().format(item.xmsWalletBalance)}</td> */}
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default Users