import {
  transferUnitFailed,
  transferUnitStart,
  transferUnitSucessful,
} from "../../redux/wallet/transferUnitSlice";
import axios from "../axios";

export const TransferUnit = (data) => async (dispatch) => {
  dispatch(transferUnitStart());
  axios
    .post("/user/unit/transfer", data)
    .then((response) => {
      const { data } = response.data;
      dispatch(transferUnitSucessful(data));
      //   setSuccess(true);
      //   setLoading(false);
      //   setSuccessMessage(message);
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 2000);
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(transferUnitFailed(message));
      //   setError(true);
      //   setLoading(false);
      //   setErrorMessage(message);
    });
};
