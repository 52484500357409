import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  failed: false,
  userDetails: {},
  balanceDetails: {},
  customPrice: {},
  messageHistory: {
    data: [],
    info: {},
  },
  messageStats: {},
  recentPayment: [],
  recentTransactions: [],
};

const SingleUserInfoSlice = createSlice({
  name: "Single User dashboard info",
  initialState,
  reducers: {
    FetchingData: (state) => {
      state.loading = true;
    },
    UserDetailsData: (state, { payload }) => {
      state.userDetails = payload;
    },
    BalanceDetailsData: (state, { payload }) => {
      state.balanceDetails = payload;
    },
    CustomPriceData: (state, { payload }) => {
      state.customPrice = payload;
    },
    MessageHistoryData: (state, { payload }) => {
      state.messageHistory = payload;
    },
    MessageDeliveredData: (state, { payload }) => {
      state.messageStats = payload;
    },
    StopFetchingData: (state) => {
      state.loading = false;
    },
    FailedData: (state, { payload }) => {
      state.failed = payload;
    },
    RecentPayment: (state, { payload }) => {
      state.recentPayment = payload;
    },
    RecentTransactions: (state, { payload }) => {
      state.recentTransactions = payload;
    },
  },
});

export const {
  FetchingData,
  StopFetchingData,
  UserDetailsData,
  BalanceDetailsData,
  CustomPriceData,
  MessageHistoryData,
  MessageDeliveredData,
  FailedData,
  RecentPayment,
  RecentTransactions,
} = SingleUserInfoSlice.actions;

export default SingleUserInfoSlice.reducer;
