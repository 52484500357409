import React, { useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { TransferUnit } from "../../utils/apiCalls/transferUnit";

function ConfirmTransferUnit({ close, message, unit, email }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { transferUnitSuccess } = useSelector(
    (state) => state.TransferUnit
  );

  const action = () => {
    const data = {
      amount: unit,
      email: email,
    };

    if (transferUnitSuccess) {
      setLoading(false);
    }
    dispatch(TransferUnit(data));
    setLoading(true)
  };
  return (
    <>
      <div className={styles.confirmContainer}>
        <Warning />
        <h3>{message ?? "Are you sure you want to send the message?"}</h3>
        <div className={styles.buttonSection}>
          <button onClick={close}>cancel</button>
          <button onClick={() => action()}>
            {loading ? "Please Wait..." : "confirm"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmTransferUnit;
