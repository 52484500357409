import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { sendSms } from "../../utils/apiCalls/sendSms";
import Alert from "../../utils/Alert";
import {
  resetStatus,
  sendSmsStart,
  sendSuccess,
} from "../../redux/sms/messageSummarySlice";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "../../utils/axios";
import { deletePhoneGroupSuccess } from "../../redux/contacts/phoneGroupSlice";
import { getPhoneGroup } from "../../utils/apiCalls/phoneGroup";
import api from "../../assets/api.svg";

import { sendAPIsuccess } from "../../redux/api/getAPIslice";

function ConfirmCardSendAPI({ closeModdall, api_name }) {
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const action = () => {
    setLoading(true);
    axios
      .get(`/user/api-key/fetch/${api_name}`)
      .then((response) => {
        const { message } = response.data;
        dispatch(sendAPIsuccess(message))
        setLoading(false);
        closeModdall(false);
      })
      .catch((error) => {
   
      });
  
  };

  return (
    <>
      <div className={styles.confirmContainer} style={{ paddingTop: "30px" }}>
        {/* <Warning /> */}
        <div>
          <img src={api} alt="" style={{ width: "100px", height: "auto" }} />
        </div>
        <h3>{`We will send the API key to your email address`}</h3>
        <div className={styles.buttonSection}>
          <button onClick={() => closeModdall(false)}>No, thanks.</button>
          <button onClick={() => action()} disabled={loading}>
            {loading ? "Please wait.." : "Okay."}
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmCardSendAPI;
