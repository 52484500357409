import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/modal/Modal";
import SelectAccount from "../../../components/selectaccount/SelectAccount";
import Summary from "../../../components/summary/Summary";
import styles from "./Schedulesms.module.css";
import { ReactComponent as ArrowDown } from "../../../assets/arrowdown.svg";
import { Alert, Box, CircularProgress } from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import moment from "moment";
import bin1R from "../../../assets/bin1R.svg";
import sendMsg from "../../../assets/sendMsg.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getScheduledSms } from "../../../utils/apiCalls/scheduledSms";
import DeleteModal from "./DeleteModal";
import SendNowModal from "./SendNowModal";
import {
  resetMessageSummary,
  resetScheduleSuccess,
} from "../../../redux/sms/messageSummarySlice";
import Alertt from "../../../utils/Alert";
import { resetStatus } from "../../../redux/sms/scheduledSmsSlice";
function ScheduledSms() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedID, setSelectedID] = useState("");

  useEffect(() => {
    dispatch(getScheduledSms());
    dispatch(resetScheduleSuccess());
    dispatch(resetMessageSummary());
  }, []);

  const loading = useSelector((state) => state.scheduledSms.loading);
  const scheduled = useSelector((state) => state.scheduledSms.Data);
  const deleteSuccess = useSelector(
    (state) => state.scheduledSms.deleteSuccess
  );
  const sendSuccess = useSelector((state) => state.scheduledSms.sendSuccess);
  const sendSuccessMessage = useSelector(
    (state) => state.scheduledSms.sendSuccessMessage
  );
  const deleteSuccessMessage = useSelector(
    (state) => state.scheduledSms.deleteSuccessMessage
  );

  // useEffect(() => {
  //  deleteSuccess && dispatch(resetStatus())
  // }, [deleteSuccess, sendSuccess])

  const handleDelete = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetStatus());
  };

  const handleSend = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetStatus());
  };

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return scheduled && scheduled.slice(firstPageIndex, lastPageIndex);
  };

  useEffect(() => {
    currentTableData();
  }, [currentPage]);

  const [showWarning, setShowWarning] = useState(false);
  function closeModdal(e) {
    if (e.target.id == "modal") {
      setShowWarning(false);
    }
  }
  const [showConfirmation, setShowConfirmation] = useState(false);
  function closeModal(e) {
    if (e.target.id == "modal") {
      setShowConfirmation(false);
    }
  }

  return (
    <>
      {showWarning && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}
          close={closeModdal}
        >
          <DeleteModal closeModdal={setShowWarning} messageID={selectedID} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}
          close={closeModal}
        >
          <SendNowModal
            closeModal={setShowConfirmation}
            messageID={selectedID}
            scheduled={scheduled}
          />
        </Modal>
      )}
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : scheduled?.length === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            No record.
          </Alert>
        </center>
      ) : (
        <>
          <div className={styles.transactionBox}>
            <div className={styles.tableSection}>
              <div style={{ overflowX: "auto" }}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th>Sender ID</th>
                      <th>Message</th>
                      <th>Recipients</th>
                      <th>Scheduled Time</th>
                      <th>Created Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  {currentTableData()?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.sender_id}</td>
                      <td>{item.message?.slice(0, 50)}...</td>
                      <td>{item.total_reciever}</td>
                      <td>
                        {moment(
                          `${item.schedule_date} ${item.schedule_time}`
                        ).format("YYYY-MM-DD, HH:mm:ss")}
                      </td>
                      <td>
                        {moment(item.created_at).format("YYYY-MM-DD, HH:mm:ss")}
                      </td>
                      <td id={styles.delSend}>
                        <div
                          id={styles.row1}
                          onClick={() => {
                            setShowWarning(true);
                            setSelectedID(item.reference);
                          }}
                        >
                          <img src={bin1R} id={styles.binIcon} />
                          <span style={{ color: "var(--color-red)" }}>
                            Delete
                          </span>
                        </div>
                        <di
                          id={styles.row1}
                          onClick={() => {
                            setShowConfirmation(true);
                            setSelectedID(item.reference);
                          }}
                        >
                          <img src={sendMsg} alt="" id={styles.msgIcon} />
                          <span> Send now</span>
                        </di>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <Alertt
            openAlert={deleteSuccess}
            Text={deleteSuccessMessage}
            Type="success"
            handleClose={handleDelete}
          />
          <Alertt
            openAlert={sendSuccess}
            Text={sendSuccessMessage}
            Type="success"
            handleClose={handleSend}
          />
        </>
      )}
    </>
  );
}

export default ScheduledSms;
