import React, { useState, useEffect } from "react";
import ProjectCard from "../../../components/ProjectCard/ProjectCard";
import styles from "./Dashboard.module.css";
import { ReactComponent as FailedMessageIcon } from "../../../assets/failedmessageicon.svg";
import { ReactComponent as ChatIcon } from "../../../assets/chaticon.svg";
import { ReactComponent as RecipientIcon } from "../../../assets/recipienticon.svg";
import { ReactComponent as PagesIcon } from "../../../assets/pagesicon.svg";
import QuickLinkCard from "../../../components/QuickLinkCard/QuickLinkCard";
import SMSIcon from "../../../assets/smsicon.svg";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import ReportIcon from "../../../assets/reporticon.svg";
import ReChargeIcon from "../../../assets/rechargeicon.svg";
import ContactIcon from "../../../assets/contacticon.svg";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/modal/Modal";
import UploadBox from "../../../components/UploadBox/UploadBox";
import { Link, useNavigate } from "react-router-dom";
import { getTimeOfDay } from "../../../utils/getTimeOfDay";
import { getAnalytics, getWallet } from "../../../utils/apiCalls/getWallet";
import { useDispatch, useSelector } from "react-redux";
import ViewAcctModal from "./ViewAcctModal";
import {
  formatFloat,
  formatPriceByCountrySymbol,
} from "../../../utils/formatPrice";
import { getRecentTransactions } from "../../../utils/apiCalls/transactions";
import RecentTransactions from "./RecentTransactions";
import KindlyView from "./KindlyView";
import Alert from "../../../utils/Alert";
const quickLinkData = [
  {
    icon: <img src={SMSIcon} alt="sms" />,
    title: "Send an SMS",
    url: "/instant-sms",
  },
  {
    icon: <img src={ReportIcon} />,
    title: "View reports",
    url: "/history",
  },
  {
    icon: <img src={ReChargeIcon} />,
    title: "Recharge",
    url: "/wallet/addfunds",
  },
  {
    icon: <img src={ContactIcon} />,
    title: "Phone Group",
    url: "/phonegroups",
  },
];
function Dashboard() {
  const dispatch = useDispatch();
  const [upload, setUpload] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [unitBalance, setUnitBalance] = useState(0);
  const wallet = useSelector((state) => state.wallet.walletData);
  const analytics = useSelector((state) => state.wallet.dashboardAnalytics);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(getAnalytics());
    dispatch(getRecentTransactions());
  }, []);

  const data = [
    {
      icon: <ChatIcon style={{ width: "55px", height: "auto" }} />,
      title: "Messages sent",
      stat: analytics?.message_sent_count,
    },
    {
      icon: <RecipientIcon style={{ width: "55px", height: "auto" }} />,
      title: "Total recipients",
      stat: analytics?.total_reciepient_count,
    },
    {
      icon: <PagesIcon style={{ width: "55px", height: "auto" }} />,
      title: "Total pages",
      stat: analytics?.total_pages,
    },
  ];
  useEffect(() => {
    dispatch(getWallet());
  }, []);

  useEffect(() => {
    setWalletBalance(wallet?.wallet_balance);
    setUnitBalance(wallet?.unit_balance);
  }, [wallet]);

  function closeModal(e) {
    if (e.target.id == "modal") {
      setUpload(false);
    }
  }

  function closeModall(e) {
    if (e.target.id == "modal") {
      setViewAcctModal(false);
    }
  }
  function closeModaall(e) {
    if (e.target.id == "modal") {
      setKindlyView(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const [viewacctModal, setViewAcctModal] = useState(false);
  const [kindlyView, setKindlyView] = useState(true);

  return (
    <>
      {upload && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={closeModal}
        >
          <UploadBox
            closeModal={setUpload}
            setOpenAlert={setOpenAlert}
            setMessage={setMessage}
            handleClose={handleClose}
          />
        </Modal>
      )}

      {viewacctModal && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={closeModall}
        >
          <ViewAcctModal closeModal={setViewAcctModal} />
        </Modal>
      )}
      <div className={styles.kindly}>
        {kindlyView && (
          <Modal
            customStyle={{ backdropFilter: "blur(20px)" }}
            close={closeModaall}
          >
            <KindlyView closeModal={setKindlyView} />
          </Modal>
        )}
      </div>

      <div className={styles.dashboardContainer}>
        <div className={styles.userDetails}>
          <div className={styles.name}>
            <h3 className={styles.title}>
              {getTimeOfDay()},{" "}
              {JSON.parse(localStorage.userDetails)?.user?.first_name}&nbsp;
              {JSON.parse(localStorage.userDetails)?.user?.last_name}. 👋
            </h3>
            <p className={styles.desktop}>
              Here is what’s happening with your projects:
            </p>
          </div>
          <div className={styles.wallet}>
            <div className={styles.xwallet}>
              <p>Wallet</p>
              <h3 style={{ color: walletBalance < 1 && "#FF0000" }}>
                {formatPriceByCountrySymbol("Nigeria", walletBalance ?? 0)}
              </h3>
            </div>
          </div>
          <p className={styles.mobile}>
            Here is what’s happening with your projects:
          </p>
        </div>
        <div className={styles.userProjectDetails}>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <ProjectCard item={item} />
            </React.Fragment>
          ))}
        </div>
        <div className={styles.uploadContainer}>
          <div className={styles.uploadBox}>
            <p>Did you make a bank transfer?</p>
            <button onClick={() => setUpload(true)} className={styles.btn}>
              FILL PAYMENT NOTIFICATION
            </button>
            <span>
              <small onClick={() => setViewAcctModal(true)}>
                View our Bank accounts
              </small>
            </span>
          </div>
        </div>
        <div className={styles.quickLinks}>
          <h3 className={styles.title}>Quick links</h3>
          <div className={styles.userProjectDetails}>
            {quickLinkData.map((item, index) => (
              <React.Fragment key={index}>
                <Link to={item.url}>
                  <QuickLinkCard item={item} />
                </Link>
              </React.Fragment>
            ))}
          </div>
        </div>
        <RecentTransactions />
      </div>
      <Alert
        openAlert={openAlert}
        Text={message}
        Type={"success"}
        handleClose={handleClose}
      />
    </>
  );
}

export default Dashboard;
