import React from "react";
import styles from "../footer/footer.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import facebookPurple from "../../assets/facebookPurple.svg";
import twitterPurple from "../../assets/twitterPurple.svg";
import insta from "../../assets/insta.svg";
import xturiLogo from "../../assets/xturiLogo.svg";
import Shake from "react-reveal/Shake";

const BottomFooter = () => {
  const navigate = useNavigate();
  const navLinkStyles = ({ isActive }) => {
    return {
      fontSize: isActive ? "14px" : "14px",
      fontWeight: isActive ? "700" : "700",
      color: isActive ? "#0a142f" : "#404040",
    };
  };

  return (
    <main className={styles.mainWrap}>
      <img src={xturiLogo} alt="" />
      <div className={styles.miniWrap}>
        <div className={styles.noteBox}>
          <span id={styles.bottomNote}>
            XMS is a 100% opt-in service. We do not provide lists of phone
            numbers nor do we access our clients' contact lists.
          </span>
        </div>
        <div className={styles.bottomNav}>
          <div className={styles.navItems}>
            <NavLink style={navLinkStyles} to="/privacy" id={styles.links}>
              <h3 onClick={() => navigate("/privacy")}>Privacy Policy</h3>
            </NavLink>
            <NavLink style={navLinkStyles} to="/privacy" id={styles.links}>
              <h3 onClick={() => navigate("/privacy")}>Terms and Conditions</h3>
            </NavLink>
            <NavLink
              to="https://exturi.gitbook.io/api"
              target="_blank"
              style={navLinkStyles}
              id={styles.links}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://exturi.gitbook.io/api", "_blank");
              }}
            >
              <h3>API Documentation</h3>
            </NavLink>
            <NavLink style={navLinkStyles} id={styles.links} to="contact">
              <h3>Contact Us</h3>
            </NavLink>
          </div>
          <div id={styles.copyRight}>Powered by EXTURI</div>
          <div id={styles.copyRight}>© 2022 XMS. All rights reserved</div>
        </div>
        <div className={styles.socialsContainer}>
          <h3 id={styles.socials}>Follow us</h3>
          <div className={styles.bottomIcons}>
            <span
              onClick={() =>
                window.location.replace(
                  "https://www.facebook.com/XMSng-100444429420447"
                )
              }
            >
              <img src={twitterPurple} alt="" />
            </span>
            <span>
              <img src={facebookPurple} alt="" />
            </span>
            <span
              onClick={() =>
                window.location.replace(
                  "https://www.linkedin.com/company/83052686/"
                )
              }
            >
              <img src={insta} alt="" />
            </span>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BottomFooter;
