import { formatPriceByCountrySymbol } from "../../../../../utils/formatPrice";
import styles from "../styles/index.module.css";
import RevenueIcon from "../../../../assets/revenueIcon.png";
import Messages from "../../../../assets/messages.png";
import User from "../../../../assets/User.png";
import { useSelector } from "react-redux";

const TopCard = ({ dashboardData }) => {
  const { message, revenue, user } = useSelector(
    (state) => state.dashboardData.analysisData
  );

  return (
    <div className=" flex flex-col lg:flex-row mt-[2.6rem] gap-12">
      <div
        className={`${styles.card1} w-[100%] lg:width-[25%] h-40 bg-white flex justify-between rounded-xl p-7 items-center`}
      >
        <div>
          <h3 className="text-[#747474] font-bold uppercase text-white">
            revenue
          </h3>
          <h3 className="text-[#FFFFFF] font-bold uppercase text-2xl ">
            {formatPriceByCountrySymbol(
              "Nigeria",
              dashboardData?.revenue?.approved
            )}
          </h3>
          <div
            className={`w-fit h-[1.25rem] ${
              revenue?.percentage_dif < 0
                ? "bg-[#FF6B80] text-white"
                : "bg-[#CDF7E5] text-green-700"
            } rounded-lg mt[7px] flex items-center justify-center`}
          >
            <p className="text-green-700  text-[0.625rem] px-2">
              {revenue?.percentage_dif &&
                `${revenue?.percentage_dif > 0 ? "+" : "-"}${
                  revenue?.percentage_dif
                }%`}
            </p>
          </div>
        </div>
        <div>
          <img src={RevenueIcon} className="h-8 w-10" />
        </div>
      </div>
      <div
        className={`${styles.card2} w-[100%] lg:width-[25%] h-40 bg-white flex rounded-xl p-7 flex justify-between items-center`}
      >
        <div>
          <h3 className="text-[#747474] font-bold uppercase text-white">
            users
          </h3>
          <h3 className="text-[#FFFFFF] font-bold uppercase text-2xl ">
            {formatPriceByCountrySymbol("", dashboardData?.user?.total)}
          </h3>
          <div
            className={`w-fit h-[1.25rem] ${
              user?.percentage_dif < 0
                ? "bg-[#FF6B80] text-white"
                : "bg-[#CDF7E5] text-green-700"
            } rounded-lg mt[7px] flex items-center justify-center`}
            style={{ padding: "3px 5px" }}
          >
            <p className="text-[0.625rem] ">
              {user?.percentage_dif &&
                `${user?.percentage_dif > 0 ? "+" : ""}${
                  user?.percentage_dif
                }%`}
            </p>
          </div>
        </div>
        <div>
          <img src={User} className="h-8 w-10" />
        </div>
      </div>
      <div
        className={`${styles.card3} w-[100%] lg:width-[25%] h-40 bg-white rounded-xl p-7 flex justify-between items-center`}
      >
        <div>
          <h3 className="text-[#747474] font-bold uppercase text-white">
            message
          </h3>
          <h3 className="text-[#FFFFFF] font-bold uppercase text-2xl ">
            {formatPriceByCountrySymbol("", dashboardData?.message?.total)}
          </h3>
          <div
            className={`w-fit h-[1.25rem] ${
              message?.percentage_dif < 0
                ? "bg-[#FF6B80] text-white"
                : "bg-[#CDF7E5] text-green-700"
            } rounded-lg mt[7px] flex items-center justify-center`}
          >
            <p className="text-[0.625rem] px-2">
              {message?.percentage_dif &&
                `${message?.percentage_dif > 0 ? "+" : ""}${
                  message?.percentage_dif
                }%`}
            </p>
          </div>
        </div>
        <div>
          <img src={Messages} className="h-8 w-10" />
        </div>
      </div>
    </div>
  );
};

export default TopCard;
