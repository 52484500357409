import {
  getMessageStatisticSuccess,
  getMessageStatisticsLoading,
  getMessageStatisticsFailed,
} from "../../../redux/admin/messages/messageStatistics";
import axios from "../../axios";

export const fetchMessageStatistics =
  (startDate, endDate, perpager) => async (dispatch) => {
    try {
      dispatch(getMessageStatisticsLoading());
      const response = await axios.get(
        `/admin/user/message-network?startDate=${startDate}&endDate=${endDate}&perpager=${perpager}`
      );
      const data = response.data.data;
      if (data) {
        dispatch(getMessageStatisticSuccess(data));
      } else {
        dispatch(getMessageStatisticsFailed("No data found"));
      }
    } catch (error) {
      dispatch(getMessageStatisticsFailed(error));
    }
  };
