import React, { useState } from "react";
import styles from "./navbar.module.css";
import logo from "../../assets/logo.svg";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import Scroll from "react-scroll";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const navigate = useNavigate();
  const ScrollLink = Scroll.ScrollLink;

  const navLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? "400" : "400",
      color: isActive ? "#4b1a7c" : "var(--color-white)",
    };
  };

  return (
    <>
      <div className={styles.head}>
        <span>
          <img src={logo} alt="logoImg" id={styles.logo} />
        </span>

        <div className={styles.navRight}>
          <div className={styles.navbar}>
            <NavLink style={navLinkStyles} to="/" id={styles.links}>
              <h3>Home</h3>
            </NavLink>
            <NavLink
              to="/"
              onClick={() => {
                const anchor = document.querySelector("#pricing");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
              style={navLinkStyles}
              id={styles.links}
            >
              <h3>Pricing</h3>
            </NavLink>

          
            <NavLink style={navLinkStyles} id={styles.links} to="/contact">
              <h3>Contact Us</h3>
            </NavLink>
       
          </div>

          <div className={styles.btnBox}>
            <button id={styles.log} onClick={() => navigate("/login")}>
              Log In
            </button>
            
          </div>
        </div>
        <div className={styles.navbarXms}>
          {toggleMenu ? (
            <RiCloseLine
              color="#fff"
              size={46}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#fff"
              size={46}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className={`${styles.navbarLinks}, ${styles.scaleUpCenter}`}>
              <div className={styles.navbarMenuLinks}>
                <div className={styles.navbar}>
                  <NavLink style={navLinkStyles} to="/" id={styles.links}>
                    <h3>Home</h3>
                  </NavLink>
                  <NavLink
                    to="/"
                    onClick={() => {
                      const anchor = document.querySelector("#pricing");
                      anchor.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }}
                    style={navLinkStyles}
                    id={styles.links}
                  >
                    <h3>Pricing</h3>
                  </NavLink>
                  {/* <NavLink style={navLinkStyles} id={styles.links} to="/faq">
                    <h3>FAQ</h3>
                  </NavLink> */}
                  <NavLink
                    style={navLinkStyles}
                    id={styles.links}
                    to="/contact"
                  >
                    <h3>Contact</h3>
                  </NavLink>
                  <NavLink style={navLinkStyles} id={styles.links} to="/login">
                    <h3>Login</h3>
                  </NavLink>
                  <NavLink style={navLinkStyles} id={styles.links} to="/signup">
                    <h3>Sign up</h3>
                  </NavLink>
                  {/* <NavLink style={navLinkStyles} id={styles.links} to="/">
              <h3>About Us</h3>
            </NavLink> */}
                </div>
                <div className={styles.btnBox}>
                  <button id={styles.log} onClick={() => navigate("/login")}>
                    Log In
                  </button>
                  <button id={styles.sign} onClick={() => navigate("/signup")}>
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
