import React from "react";
import styles from "./Users.module.css";
import toggle from "./toggle.css";
import Primaryfill from "../../../assets/Primaryfill.svg";
import BarBox from "./BarBox";
const RolesBox = () => {
  const Bars = [
    {
      Title: "Manage Roles",
      Details:
        " Allow member create new roles, assign roles, edit roles ordelete roles lower than their highest roles.",
      Value: "manage_roles",
    },
    {
      Title: "Account Manager ",
      Details:
        "Create users, teams, revoke access, does everything related to users and roles.",
      Value: "manage_account",
    },
    {
      Title: "Finance Manager",
      Details:
        "Has access to financial data and manages payments, communication costs, track account balance and top up.",
      Value: "manage_finance",
    },
    {
      Title: "Communication Manager",
      Details: " Creates engagement scenarios and sends Broadcast messages.",
      Value: "manage_comm",
    },
  ];

  return (
    <div>
      <div className={styles.roles}>
        <div className={styles.addRolesBar}>
          <span>
            <img src={Primaryfill} alt="fill" />
          </span>
          <h5>Add roles</h5>
        </div>

        <div className={styles.manageRoles}>
          {Bars.map((Bar, index) => (
            <BarBox key={index} Title={Bar.Title} Details={Bar.Details} Value={Bar.Value} />
          ))}
        <button id={styles.saveBtn}>Save</button>
        </div>

      </div>
    </div>
  );
};

export default RolesBox;
