import React from "react";
import UnstyledSelectSimple from "../BasicSelectDropdownList/CustomizedSelects";
import styles from "./UploadBox.module.css";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";
import moment from "moment";
import { StaticDatePicker } from "@mui/lab";
import Modal from "../modal/Modal";
import { useState } from "react";
import { useEffect } from "react";

const FormComponent = ({ Title, Holder, Icon, State, Required }) => {
  const [value, setValue] = useState(dayjs());
  const [openCalendar, setOpenCalendar] = useState(false);

  useEffect(() => {
    State(dayjs(value).format("YYYY-MM-DD"));
    setOpenCalendar(false);
  }, [value]);

  return (
    <>
      <div className={styles.FormContainer}>
        <span id={styles.formFields}>
          <small>{Title}</small>
          {Title !== "Date and Estimated Time of Payment (MM-DD-YYYY)" ? (
            <input
              className="border-2 border-gray-700"
              type="text"
              placeholder={Holder}
              onChange={(e) => State(e.target.value)}
              required={Required}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "53px",
                border: " 1px solid var(--color-gray3)",
                padding: "16px 63px",
                /* margin-top: 15px; */
                borderRadius: "5px",
                fontSize: "var(--fs-300)",
                textAlign: "left",
              }}
              className="border-2 border-gray-700"
              onClick={() => setOpenCalendar(true)}
            >
              {value ? dayjs(value).format("MM-DD-YYYY") : "MM-DD-YYYY"}
            </div>
          )}
          <img id={styles.svgImg} src={Icon} alt="" />
        </span>
      </div>
      {openCalendar && (
        <Modal>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={value}
              disableFuture
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {/* <DatePicker
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  State(newValue);
                }}
                className="w-full border"
                // renderInput={(params) => (
                //   <TextField
                //     size="small"
                //     id="outlined-size-small"
                //     fullWidth
                //     // sx={{ display: "flex", alignItems: "center" }}
                //     {...params}
                //     helperText={null}
                //   />
                // )}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "2px",
                    }}
                  >
                    <div
                      className="border-2 border-gray-700 flex items-center w-full text-gray-500"
                      dateAdapter={AdapterDayjs}
                    >
                      {InputProps?.endAdornment}
                      <input
                        ref={inputRef}
                        {...inputProps}
                        className="ml-2"
                        onClick={() => setOpenCalendar(true)}
                      />
                    </div>
                  </Box>
                )}
              /> */}
          </LocalizationProvider>
        </Modal>
      )}
    </>
  );
};

export default FormComponent;
