import { FONTSIZE, COLORS } from "../../constants/theme"

const { px14 } = FONTSIZE
const { white } = COLORS

export default function LogoFooter() {
    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src='/images/logo2.svg' width='218px' height='51px' alt='exturi' />
        </div>
    )
}