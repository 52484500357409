import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardMessages = () => {
  const data = useSelector(
    (state) =>
      (state.messageStatistics && state.messageStatistics.data) || { data: [] }
  );

  const navigate = useNavigate();

  return (
    <div className="w-full rounded-xl shadow-md">
      <div className="mt-8 overflow-x-auto">
        <div className="inline-block min-w-full overflow-hidden bg-white px-11">
          <div className="pb-6 border-b mt-2">
            <p className="text-[#747474] text-2xl font-bold">Messages Sent</p>
          </div>
          {data && data.data && data.data.length > 0 ? (
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Total Messages
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Airtel
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    Glo
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    9mobile
                  </th>
                  <th className="px-5 py-5 text-xl text-left font-semibold text-gray-700  tracking-wider">
                    MTN
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.data.map((item, index) => {
                  return (
                    <tr
                      className={index % 2 === 0 && "bg-[#E5E5E5]"}
                      key={index}
                    >
                      <td className={`px-5 py-5 text-sm`}>
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item.name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.total_messages}
                        </p>
                      </td>
                      <td className="px-5 py-5 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.Airtel}
                        </p>
                      </td>
                      <td className="px-5 py-5 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.Glo}
                        </p>
                      </td>
                      <td className="px-5 py-5 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item["9mobile"]}
                        </p>
                      </td>
                      <td className="px-5 py-5 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.MTN}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="h-52">
              <p className="mt-16 text-center text-xl font-gray-600">
                No Users found
              </p>
            </div>
          )}
          {data && data.data && data.data.length > 0 && (
            <div className="flex justify-center mt-11">
              <button
                onClick={() => navigate("deposits")}
                className="flex justify-center items-center w-[10rem] text-white py-2 mb-7 rounded-md bg-[#4B1A7C] rounded-xl"
              >
                View all
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardMessages;
