import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./auth/loginSlice";
import changePasswordReducer from "./auth/changePasswordSlice";
import profileReducer from "./profile/profileSlice";
import getProfileReducer from "./profile/getProfileSlice";
import getWalletReducer from "./wallet/getWalletSlice";
import registerSlice from "./auth/registerSlice";
import OTPslice from "./sms/sendOTPslice";
import messageSummarySlice from "./sms/messageSummarySlice";
import createTeamSlice from "./people/createTeamSlice";
import forgotPasswordSlice from "./auth/forgotPasswordSlice";
import transactionsSlice from "./wallet/transactionsSlice";
import phoneGroupSlice from "./contacts/phoneGroupSlice";
import senderIDslice from "./sms/senderIDSlice";
import rateSlice from "./wallet/rateSlice";
import historySlice from "./sms/historySlice";
import SmsActionsSlice from "./sms/SmsActionsSlice";
import createAPIslice from "./api/createAPIslice";
import getAPIslice from "./api/getAPIslice";
import scheduledSmsSlice from "./sms/scheduledSmsSlice";
import calculateSlice from "./calculateRate/calculateRate";
import signInSlice from "./admin/auth/signInSlice";
import AllClients from "./admin/clients/getAllClientSlice";
import addAdminSlice from "./admin/auth/addAdminSlice";
import DashboardSlice from "./admin/dashboard/dashboardSlice";
import SendSmsSlice from "./admin/Send/sendSmsSlice";
import SendEmailSlice from "./admin/Send/sendEmail";
import DepositsSlice from "./admin/deposits/depositsSlice";
import RecentTransactionSlice from "./admin/Recent/getRecentTransactions";
import RecentAddedUserSlice from "./admin/Recent/getRecentAddedUser";
import sendMailSlice from "./admin/messages/sendMailSlice";
import getSingleUserAdminSlice from "./admin/users/getSingleUserAdminSlice";
import priceSlice from "./admin/settingsEndpoints/priceSlice";

import SuspendUserSlice from "./admin/users/suspendUserSlice";
import SingleUserInfoSlice from "./admin/users/SingleUserInfoSlice";
import userTransactionsSlice from "./admin/users/userTransactionsSlice";

import sendSmsAllUsersSlice from "./admin/Send/sendSmsAllUsersSlice";
import customUserRateSlice from "./admin/settingsEndpoints/customUserRateSlice";
import sendInAppNotificationsSlice from "./admin/Send/sendInAppNotificationsSlice";
import getAllNotificationSlice from "./admin/notifications/getAllNotificationSlice";
import adminTopUpSlice from "./admin/users/adminTopUpSlice";
import userMessageHistorySlice from "./admin/users/userMessageHistorySlice";
import userPaymentSlice from "./admin/users/userPaymentSlice";
import userRateSlice from "./admin/users/userRateSlice";
import getAllNotifications from "./clients/notifications/getAllNotifications";
import getAuditLogSlice from "./admin/records/auditLogSlice";
import transferUnitSlice from "./wallet/transferUnitSlice";
import scheduledMessagesSlice from "./admin/messageScheduleSlices/scheduledMessagesSlice";
import inAppNotificationScheduleSlice from "./admin/messageScheduleSlices/inAppNotificationScheduleSlice";
import SmsNotificationScheduleSlice from "./admin/messageScheduleSlices/SmsNotificationScheduleSlice";
import statisticsSlice from "./getStats/statisticsSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerSlice,
    profile: profileReducer,
    getprofile: getProfileReducer,
    wallet: getWalletReducer,
    changePassword: changePasswordReducer,
    sendOTP: OTPslice,
    summary: messageSummarySlice,
    createTeam: createTeamSlice,
    forgotPassword: forgotPasswordSlice,
    transactions: transactionsSlice,
    phoneGroup: phoneGroupSlice,
    senderIDs: senderIDslice,
    userRates: rateSlice,
    history: historySlice,
    smsActions: SmsActionsSlice,
    APIs: createAPIslice,
    getAPIs: getAPIslice,
    scheduledSms: scheduledSmsSlice,
    calculateRate: calculateSlice,
    adminSignIn: signInSlice,
    clients: AllClients,
    addAdmin: addAdminSlice,
    dashboardData: DashboardSlice,
    sendSms: SendSmsSlice,
    deposits: DepositsSlice,
    recentTransactions: RecentTransactionSlice,
    recentAddedUser: RecentAddedUserSlice,
    sendEmailMessage: SendEmailSlice,
    sendMail: sendMailSlice,
    getSingleAdmin: getSingleUserAdminSlice,
    adminRates: priceSlice,
    sendSmsAll: sendSmsAllUsersSlice,
    inAppNotifications: sendInAppNotificationsSlice,
    customUserRates: customUserRateSlice,
    SuspendUser: SuspendUserSlice,
    singleUserInfo: SingleUserInfoSlice,
    userTransactions: userTransactionsSlice,
    userMessageHistory: userMessageHistorySlice,
    getAllNotifications: getAllNotificationSlice,
    userPayments: userPaymentSlice,
    topUpWallet: adminTopUpSlice,
    updateUserRate: userRateSlice,
    clientNotifications: getAllNotifications,
    getAuditLog: getAuditLogSlice,
    TransferUnit: transferUnitSlice,
    AdminScheduledMessages: scheduledMessagesSlice,
    inAppNotificationScheduler: inAppNotificationScheduleSlice,
    smsScheduleFile: SmsNotificationScheduleSlice,
    statistics: statisticsSlice,
  },
});
