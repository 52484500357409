import EmailIcon from "@mui/icons-material/Email";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CallIcon from "@mui/icons-material/Call";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../../../../../components/Button/Button";
import Close from "../../../../assets/closeCircle.svg";
import moment from "moment";
import ConfirmationBox from "../../../../../components/confirmationBox/ConfirmationBox";
import { useDispatch, useSelector } from "react-redux";
import { SuspendUser } from "../../../../../utils/apiCalls/admin/user/SuspendUser";
import { getAllClients } from "../../../../../utils/apiCalls/admin/getAllClients";
import Alert from "../../../../../utils/Alert";
import { suspendReset } from "../../../../../redux/admin/users/suspendUserSlice";

const SingleUser = ({
  name,
  email,
  phone,
  status,
  setSendMessage,
  setSingleUser,
  createdAt,
  updatedAt,
  setSendEmail,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const suspendStatus = useSelector((state) => state.SuspendUser);

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  useEffect(() => {
    if (confirmation) {
      dispatch(SuspendUser(email, status === "suspended" ? "suspended" : ""));
    }
  }, [confirmation]);

  const Timeout = (x) => {
    setTimeout(() => {
      dispatch(suspendReset());
      setOpenDialogBox(false);
      setConfirmation(false);
      x === "success" && dispatch(getAllClients());
    }, 2000);
  };

  useEffect(() => {
    if (suspendStatus.status === "failed") {
      setAlertStatus({
        alertMessage: suspendStatus.message,
        alertType: "error",
        openAlert: true,
      });
      Timeout(suspendStatus.status);
    } else if (suspendStatus.status === "success") {
      setAlertStatus({
        alertMessage: suspendStatus.message,
        alertType: "success",
        openAlert: true,
      });
      Timeout(suspendStatus.status);
    }
  }, [suspendStatus.status]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  return (
    <div className="w-1/4 hidden lg:flex bg-white rounded-xl px-3">
      <div className=" w-full">
        <div className="flex justify-end mt-5">
          <img
            src={Close}
            alt="close the side panel"
            onClick={() => setSingleUser("")}
            className="cursor-pointer"
          />
        </div>
        <p className="text-[#030229] font-semibold my-7 text-center">{name}</p>
        <p className="text-[#030229] py-5 border-b text-opacity-70 flex items-center">
          <EmailIcon fontSize="small" className="mr-2" />
          {email}
        </p>
        <p className="text-[#030229] py-5 border-b text-opacity-70 flex items-center">
          <CallIcon fontSize="small" className="mr-2" />
          {phone}
        </p>
        <p className="text-[#030229] py-5 border-b text-opacity-70 flex items-center">
          <DateRangeIcon fontSize="small" className="mr-2" />
          {createdAt}
        </p>
        <p className="text-[#030229] py-5 border-b text-opacity-70 flex items-center">
          <HourglassTopIcon fontSize="small" className="mr-2" />
          {moment(updatedAt, "YYYY-MM-DD").fromNow()}
        </p>
        <div>
          <p className="font-bold mb-3 mt-7">Actions</p>
          <p
            className="text-blue-800 mb-1 text-sm font-semibold cursor-pointer"
            onClick={() =>
              navigate("/admin/user-dashboard", { state: { email } })
            }
          >
            View Dashboard
          </p>
          <p
            className="text-blue-800 mb-1 text-sm font-semibold cursor-pointer"
            onClick={() => setSendMessage(true)}
          >
            Send SMS
          </p>
          <p
            className="text-blue-800 mb-1 text-sm font-semibold cursor-pointer"
            onClick={() => setSendEmail(true)}
          >
            Send Email
          </p>
          <p
            className="text-blue-800 mb-1 text-sm font-semibold cursor-pointer"
            onClick={() => setOpenDialogBox(true)}
          >
            {status === "suspended" ? "Un-suspend User" : "Suspend User"}
          </p>
        </div>
      </div>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          setSingleUSer={setSingleUser}
          confirmation={confirmation}
        />
      )}
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </div>
  );
};

export default SingleUser;
