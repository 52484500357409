import React, { useEffect, useState } from "react";
import styles from "./Contacts.module.css";
import { ReactComponent as Calculator } from "../../../assets/calculator.svg";
import { ReactComponent as GroupIcon } from "../../../assets/group.svg";
import SearchIcon from "../../../assets/search.svg";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";
import bin1R from "../../../assets/bin1R.svg";
import movefile from "../../../assets/movefiles.svg";
import closeCircle from "../../../assets/closeCircle.svg";
import ArrowDown from "../../../assets/chevrondown.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/modal/Modal";
import ImportModal from "./ImportModal";
import Alertt from "../../../utils/Alert";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  getPhoneGroup,
  savePhoneGroup,
} from "../../../utils/apiCalls/phoneGroup";
import edit from "../../../assets/edit.svg";
// import bin from "../../../assets/bin.svg"
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import { formatPriceByCountrySymbol } from "../../../utils/formatPrice";
import ConfirmCardUser from "../../../components/confirmCard/ConfirmCardUser";
import Edit from "./Edit";
function Contacts() {
  const [groupName, setGroupName] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [saveDetails, setSaveDetails] = useState(false);
  const [add, setAdd] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState("");
  const [groupToEdit, setGroupToEdit] = useState("");
  const [openEdit, setOpenEdit] = useState(false);

  const dispatch = useDispatch();
  const saveSuccess = useSelector((state) => state.phoneGroup.saveSuccess);
  const phoneGroups = useSelector((state) => state.phoneGroup.getSuccessData);
  const loading = useSelector((state) => state.phoneGroup.loading);
  const saveLoading = useSelector((state) => state.phoneGroup.saveLoading);
  const errorMessage = useSelector((state) => state.phoneGroup.saveErrorData);
  const saveError = useSelector((state) => state.phoneGroup.saveError);
  const deleteSuccess = useSelector(
    (state) => state.phoneGroup.deletePhoneGsuccess
  );
  const editSuccess = useSelector(
    (state) => state.phoneGroup.editPhoneGsuccess
  );

  const saveSuccessMessage = useSelector(
    (state) => state.phoneGroup.saveSuccessData
  );

  const deleteSuccessMessage = useSelector(
    (state) => state.phoneGroup.deletePhoneGsuccessMessage
  );
  const editSuccessMessage = useSelector(
    (state) => state.phoneGroup.editPhoneGsuccessMessage
  );

  useEffect(() => {
    saveSuccess && setAdd(false);
    saveSuccess && setImportModal(false);
    saveSuccess && setOpenSuccess(true);
    deleteSuccess && setOpenDelete(true);
    editSuccess && setOpenEdit(true);
  }, [saveSuccess, deleteSuccess, editSuccess]);

  useEffect(() => {
    dispatch(getPhoneGroup());
  }, []);
  function closeModal(e) {
    if (e.target.id == "modal") {
      setAdd(false);
    }
  }
  function closeModall(e) {
    if (e.target.id == "modal") {
      setImportModal(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  const handleDelete = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDelete(false);
  };
  const handleEdit = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenEdit(false);
  };

  const save = () => {
    if (groupName === "" || phoneNumbers === "") {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
      //do this on the backend =====================
      const trimNumber = phoneNumbers.split(" ").join(""); //remove white spaces
      const numbersArray = trimNumber.split(",");
      //exclude a phone number that is not up to 11 characters
      dispatch(
        savePhoneGroup({
          group_name: groupName,
          number: numbersArray,
        })
      );
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
    }
  };

  const [showWarning, setShowWarning] = useState(false);
  function closeModdal(e) {
    if (e.target.id == "modal") {
      setShowWarning(false);
    }
  }

  const [editGroup, setEditGroup] = useState(false);
  function EditcloseModal(e) {
    if (e.target.id == "modal") {
      setEditGroup(false);
    }
  }

  return (
    <>
      {showWarning && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}
          close={closeModdal}
        >
          <ConfirmCardUser closeModdal={setShowWarning} name={groupToDelete} />
        </Modal>
      )}
      {importModal && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={closeModall}
        >
          <ImportModal closeModal={setImportModal} />
        </Modal>
      )}
      {editGroup && (
        <Modal
          customStyle={{ backdropFilter: "blur(20px)" }}
          close={EditcloseModal}
        >
          <Edit EditcloseModal={setEditGroup} groupToEdit={groupToEdit} />
        </Modal>
      )}

      {add && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <div className={styles.addBox} style={{ maxWidth: "800px" }}>
            <div id={styles.imgBox} style={{ marginBottom: 10 }}>
              <img
                src={closeCircle}
                alt=""
                onClick={() => {
                  setAdd(false);
                  setGroupName("");
                  setPhoneNumbers("");
                }}
              />
            </div>
            {
              <p
                style={{
                  textAlign: "center",
                  marginBottom: 10,
                  fontSize: "small",
                  color: "red",
                }}
              >
                {errorMessage}
              </p>
            }
            <div className={styles.addDetails}>
              <span className={styles.titles}>
                <h3>Group Name :</h3>
                <h3>Phone Numbers :</h3>
              </span>
              <span className={styles.inputs}>
                <h3 id={styles.hiddenTxt}>Group Name :</h3>
                <input
                  type="text"
                  id={styles.nameBox}
                  value={groupName}
                  style={{ borderColor: saveError && "red" }}
                  onChange={(e) => setGroupName(e.target.value)}
                  placeholder="Phone Group Name"
                />
                <h3 id={styles.hiddenTxt}>Phone Numbers :</h3>
                <textarea
                  type="text"
                  id={styles.phoneBox}
                  value={phoneNumbers}
                  onChange={(e) => setPhoneNumbers(e.target.value)}
                  placeholder="When manually entering number seperate by using comma using country code format. 
              (e.g 2348012343556, 23480123456781)"
                />
              </span>
            </div>
            <div className={styles.btnBox}>
              <button
                disabled={saveLoading}
                className={styles.addBtn}
                style={{ opacity: saveLoading ? 0.5 : 1 }}
                onClick={() => save()}
              >
                {saveLoading ? "Saving..." : "Save Phone Group"}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {saveDetails && (
        <Modal
          close={closeModal}
          customStyle={{ backdropFilter: "blur(20px)" }}
        >
          <div className={styles.addBox}>
            <h3>Save number</h3>
            <p>Made a mistake, now is the time to make an edit!</p>
            <form className={styles.form} id="save">
              <div className={styles.row}>
                <div className={styles.inputBox}>
                  <input type="text" placeholder="First name" />
                </div>
                <div className={styles.inputBox}>
                  <input type="text" placeholder="Last name" />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.inputBox}>
                  <input type="text" placeholder="Phone number" />
                </div>
                <div className={styles.inputBox}>
                  <input type="text" placeholder="Email address" />
                </div>
              </div>
            </form>
            <button className={styles.addBtn}>SAVE</button>
          </div>
        </Modal>
      )}
      <div className={styles.transactionBox}>
        {/* <div className={styles.tableDetails} style={{display:"flex",justifyContent:"space-between",flexDirection:"row",marginBottom:"15px"}}>
          <GroupIcon />
          <div className={styles.tableActions}>
            <button
              className={styles.addNumbersBtn}
              onClick={() => setImportModal(true)}
            >
              IMPORT NUMBERS
            </button>
            <button className={styles.importBtn} onClick={() => setAdd(true)}>
              CREATE GROUP
            </button>
          </div>
        </div> */}
        <div className={styles.tableSection}>
          <div
            className={styles.tableDetails}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <GroupIcon />
            <div className={styles.tableActions}>
              <button
                className={styles.addNumbersBtn}
                onClick={() => setImportModal(true)}
              >
                IMPORT NUMBERS
                {/* Import Numbers */}
              </button>
              <button className={styles.importBtn} onClick={() => setAdd(true)}>
                CREATE GROUP
                {/* Create Group */}
              </button>
            </div>
          </div>
          {loading ? (
            <Box sx={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
              <CircularProgress sx={{ color: "#C4C4C4" }} />
            </Box>
          ) : phoneGroups?.length === 0 ? (
            <center>
              <Alert
                sx={{ width: 350, marginTop: "5rem", marginBottom: "7rem" }}
                severity="info"
              >
                You do have not added any phone group
              </Alert>
            </center>
          ) : (
            <>
              <div style={{ overflowX: "auto" }}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Group Name</th>
                      <th>Recipients</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {phoneGroups?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{item?.group_name} </td>
                      <td>{item?.total_numbers}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: "25px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={edit}
                            alt=""
                            style={{ width: "15px", height: "auto" }}
                            onClick={() => {
                              setEditGroup(true);
                              setGroupToEdit(item.group_name);
                            }}
                          />

                          <img
                            src={bin1R}
                            style={{ width: "15px", height: "auto" }}
                            alt=""
                            onClick={() => {
                              setShowWarning(true);
                              setGroupToDelete(item.group_name);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </>
          )}
        </div>
      </div>
      <Alertt
        handleClose={handleClose}
        openAlert={openAlert}
        Text="Error: All fields are required"
        Type="error"
      />
      <Alertt
        handleClose={handleSuccess}
        openAlert={openSuccess}
        Text={saveSuccessMessage}
        Type="success"
      />
      <Alertt
        handleClose={handleDelete}
        openAlert={openDelete}
        Text={deleteSuccessMessage}
        Type="success"
      />
      <Alertt
        handleClose={handleEdit}
        openAlert={openEdit}
        Text={editSuccessMessage}
        Type="success"
      />
    </>
  );
}

export default Contacts;
