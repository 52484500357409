import React from "react";
import { Routes, Route } from "react-router-dom";
import Signup from "./clientDashboard/pages/signup/signup";
import Signin from "./clientDashboard/pages/signin/signin";
import ForgotPassword from "./clientDashboard/pages/forgotPassword/forgotPassword";
import ResetPassword from "./clientDashboard/pages/resetPassword/resetPassword";
import VerifyPhone from "./clientDashboard/pages/verifyPhone/verifyPhone";
import Profile from "./clientDashboard/pages/settings/profile/profile";
import Layout from "./components/Layout/Layout";
import AdminLayout from "./adminDashboard/components/layout/Layout";
import EditProfile from "./clientDashboard/pages/settings/profile/editProfile";
import ChangePassword from "./clientDashboard/pages/settings/changePassword/changePassword";
import Dashboard from "./clientDashboard/pages/dashboard/Dashboard";
import Sendsms from "./clientDashboard/pages/sendsms/Sendsms";
import ScheduledSms from "./clientDashboard/pages/schedulesms/ScheduledSms";
import Fundsms from "./clientDashboard/pages/fundsms/Fundsms";
import Addfunds from "./clientDashboard/pages/addfunds/Addfunds";
import Plans from "./clientDashboard/pages/plans/Plans";
import Api from "./clientDashboard/pages/settings/api/Api";
import Transactions from "./clientDashboard/pages/transactions/Transactions";
import Contacts from "./clientDashboard/pages/contacts/Contacts";
import Groups from "./clientDashboard/pages/contacts/Groups";
import Resources from "./clientDashboard/pages/resources/Resources";
import History from "./clientDashboard/pages/history/History";
import Admin from "./adminDashboard/pages/admin/Dashboard/Admin";
import {
  AdminPrivateRoute,
  PrivateRoute,
} from "./components/privateRoute/PrivateRoute";
import Inbox from "./adminDashboard/pages/admin/Inbox";
import Broadcast from "./adminDashboard/pages/admin/Broadcast";
import Deposit from "./adminDashboard/pages/admin/deposit/Deposit";
import AddUser from "./adminDashboard/pages/admin/AddUser";
import UserList from "./adminDashboard/pages/admin/userDashboard/ClientList";
import SenderId from "./clientDashboard/pages/settings/senderIDs/SenderId";
import Users from "./clientDashboard/pages/people/Users";
import Teams from "./clientDashboard/pages/people/Teams";
import AddTeam from "./clientDashboard/pages/people/AddTeam";
import CreateUser from "./clientDashboard/pages/people/AddUser";
import AddNewUserPage from "./clientDashboard/pages/people/AddNewUserPage";
import UserDashboard from "./adminDashboard/pages/admin/userDashboard/UserDashboard.jsx";
import ViewTeams from "./adminDashboard/pages/admin/userDetails/ViewTeams";
import VerifyPayment from "./adminDashboard/pages/admin/userDetails/VerifyPayment";
import DeliveryReport from "./clientDashboard/pages/history/DeliveryReport";
import Settings from "./adminDashboard/pages/admin/settings/Settings";
import BasePrice from "./adminDashboard/pages/admin/settings/BasePrice";
import UserPrice from "./adminDashboard/pages/admin/settings/UserPrice";

import "./index.css";
import CreatedApi from "./clientDashboard/pages/settings/api/CreatedApi";
import LandingPage from "./LandingPage/LandingPage";
import FAQ from "./LandingPage/faq/FAQ";
import Contact from "./LandingPage/contact us/Contact";
import IntegrationGuide from "./clientDashboard/pages/settings/api/IntegrationGuide";
import { BrowserRouter } from "react-router-dom";
import Privacy from "./LandingPage/privacy/Privacy";
import ApiDocumentation from "./LandingPage/documentation/ApiDocumentation";
import SignIn from "./adminDashboard/pages/admin/signIn/SignIn";
import AdminForgotPassword from "./adminDashboard/pages/admin/adminForgotPassword/AdminForgotPassword";
import AdminResetPwd from "./adminDashboard/pages/admin/adminResetPwd/AdminResetPwd";
import AdminSetPassword from "./adminDashboard/pages/admin/signIn/AdminSetPassword";
import UserTransactions from "./adminDashboard/pages/admin/userDashboard/transaction/userTransactions";
import SmsNotification from "./adminDashboard/pages/admin/settings/notifications/SmsNotification";
import GetNotifications from "./adminDashboard/pages/admin/settings/notifications/GetNotifications";
import MessageHistory from "./adminDashboard/pages/admin/userDashboard/message/MessageHistory";
import UserPayments from "./adminDashboard/pages/admin/userDashboard/payment/userPayments";
import AuditLogs from "./adminDashboard/pages/admin/records/AuditLogs";
import AdminScheduledSms from "./adminDashboard/pages/admin/scheduledMessages/AdminScheduledMessages";
import AdminSenderID from "./adminDashboard/pages/admin/senderID/adminSenderId";
import SingleUserSenderIds from "./adminDashboard/pages/admin/userDashboard/components/userSenderIds";
// import SmsHistory from "./clientDashboard/pages/history/SmsHistory";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/office" element={<SignIn />} />
        <Route path="/adminSetPassword" element={<AdminSetPassword />} />
        <Route path="/AdminForgotPassword" element={<AdminForgotPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/adminResetPwd" element={<AdminResetPwd />} />
        <Route path="landingPage" element={<LandingPage />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="documentation" element={<ApiDocumentation />} />
        <Route
          path="/"
          element={
            // <PrivateRoute>
              <Layout />
            // </PrivateRoute>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="instant-sms" element={<Sendsms />} />
          <Route path="scheduled-sms" element={<ScheduledSms />} />
          <Route path="wallet" element={<Fundsms />} />
          <Route path="wallet/addfunds" element={<Addfunds />} />
          <Route path="fund-sms/plans" element={<Plans />} />
          <Route path="verify-phone" element={<VerifyPhone />} />
          <Route path="settings/profile" element={<Profile />} />
          <Route path="settings/edit" element={<EditProfile />} />
          <Route path="settings/change-password" element={<ChangePassword />} />
          <Route path="settings/api" element={<Api />} />
          <Route
            path="settings/integrationGuide"
            element={<IntegrationGuide />}
          />
          <Route path="settings/sender-ids" element={<SenderId />} />
          <Route path="people/users" element={<Users />} />
          <Route path="people/teams" element={<Teams />} />
          <Route path="people/add-team" element={<AddTeam />} />
          <Route path="people/add-user" element={<CreateUser />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="phonegroups" element={<Contacts />} />
          <Route path="contacts/groups" element={<Groups />} />
          <Route path="resources" element={<Resources />} />
          <Route path="history" element={<History />} />
          <Route path="delivery-report/:ref" element={<DeliveryReport />} />
          <Route path="people/add-new-user-page" element={<AddNewUserPage />} />
          <Route path="settings/api/CreatedApi" element={<CreatedApi />} />
          <Route
            path="settings/IntegrationGuide"
            element={<IntegrationGuide />}
          />
          {/* <Route path="history/smsHistory" element={<SmsHistory />} /> */}
        </Route>
        <Route
          path="/admin/"
          element={
            <AdminPrivateRoute>
              <AdminLayout />
           </AdminPrivateRoute>
          }
        >
          <Route path="" element={<Admin />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="settings" element={<Settings />} />
          <Route path="base-price" element={<BasePrice />} />
          <Route path="user-price" element={<UserPrice />} />
          <Route path="mailNotification" element={<Broadcast />} />
          <Route path="smsNotification" element={<SmsNotification />} />
          <Route path="getNotification" element={<GetNotifications />} />
          <Route path="adminScheduledSms" element={<AdminScheduledSms />} />
          <Route path="deposits" element={<Deposit />} />
          <Route path="adminList" element={<AddUser />} />
          <Route path="client-list" element={<UserList />} />
          <Route path="auditLogs" element={<AuditLogs />} />
          <Route path="user-dashboard" element={<UserDashboard />} />
          <Route path="user-transactions" element={<UserTransactions />} />
          <Route path="/admin/message-history" element={<MessageHistory />} />
          <Route path="user-payments" element={<UserPayments />} />
          {/* <Route path="message-history" element={<MessageHistory />} /> */}
          <Route path="view-teams" element={<ViewTeams />} />
          <Route path="verify-payments" element={<VerifyPayment />} />
          <Route path="adminsenderIDs" element={<AdminSenderID />} />
          <Route
            path="user-dashboard/singleusersenderid"
            element={<SingleUserSenderIds />}
          />
        </Route>

        <Route path="*" element={<LandingPage />} />
      </Routes>
    </>
  );
};

export default App;
