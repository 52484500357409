import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getProfileLoad: false,
  getProfileError: null,
  getProfileData: null,
};

export const getProfileSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    getProfileStart: (state) => {
      state.getProfileLoad = true;
    },
    getProfileSuccess: (state, { payload }) => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          ...JSON.parse(localStorage.userDetails),
          user: {
            ...JSON.parse(localStorage.userDetails).user,
            first_name: payload.data.first_name,
            last_name: payload.data.last_name,
            sender_id: payload.data.sender_id,
          },
        })
      );
      state.getProfileLoad = false;
      state.getProfileData = payload;
      state.getProfileError = null;
    },
    getProfileFail: (state, { payload }) => {
      state.getProfileLoad = false;
      state.getProfileData = null;
      state.getProfileError = "invalid credentials";
    },
    cleargetProfileErrorMessage: (state) => {
      state.getProfileError = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getProfileStart,
  getProfileSuccess,
  getProfileFail,
  cleargetProfileErrorMessage,
} = getProfileSlice.actions;

export default getProfileSlice.reducer;
