import React, { useEffect, useState } from "react";
import ConfirmCard from "../confirmCard/ConfirmCard";
import Modal from "../modal/Modal";
import closeCircle from "../../assets/closeCircle.svg";
import styles from "./TransferCard.module.css";
import { getWallet } from "../../utils/apiCalls/getWallet";
import { useDispatch, useSelector } from "react-redux";
import { formatPriceByCountrySymbol } from "../../utils/formatPrice";
import ConfirmTransferUnit from "../confirmCard/ConfirmTransferUnit";
import { COLORS } from "../../constants/theme";
import Alert from "../../utils/Alert";
function TransferCard({ closeModal }) {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [currentAmt, setCurrentAmt] = useState();
  const [showAlert, setShowAlert] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { transferUnitSuccess, transferUnitError, transferError } = useSelector(
    (state) => state.TransferUnit
  );

  
  const wallet = useSelector((state) => state.wallet.walletData);

  useEffect(() => {
    if (transferUnitSuccess) {
      setOpenSuccess(true);
      setTimeout(() => {
        setShowWarning(false);
        window.location.reload();
      }, 1000);
    } else if (transferUnitError) {
      setErrorMessage(true);
      setTimeout(() => {
        setShowWarning(false);
        window.location.reload();
      }, 1000);
    }
  }, [transferUnitSuccess, transferUnitError]);

  useEffect(() => {
    dispatch(getWallet());
  }, []);

  useEffect(() => {
    setWalletBalance(wallet?.wallet_balance);
  }, [wallet]);

  const handleChange = (e) => {
    e.preventDefault();
    if (+wallet > +walletBalance) {
      setShowAlert("Error: Insufficient balance");
    } else if (wallet < 1) {
      setShowAlert("Error: Amount cannot be less than 1");
    } else if (email === "") {
      setShowAlert("Error: Email is compulsory");
    } else {
      setShowWarning(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setErrorMessage(false);
  };

  const handleError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage(false);
  };

  return (
    <>
      {showWarning === true && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ConfirmTransferUnit
            message={`You are about to send ${wallet} money to ${email}`}
            close={() => setShowWarning(false)}
            wallet={walletBalance}
            email={email}
          />
        </Modal>
      )}
      <div className={styles.transferCardContainer}>
        <div className={styles.bttns}>
          <img
            src={closeCircle}
            alt=""
            id={styles.closeBtnn}
            onClick={() => closeModal(false)}
          />
        </div>
        <h3>Transfer Money</h3>
        <p style={{ color: COLORS.grey, fontSize: 12 }}>
          Send money to Teams, XMS users and loved ones.
        </p>
        <Alert
          severity="info"
          sx={{ fontSize: 12, marginTop: "0.5rem", marginBottom: "0.5rem" }}
        >
          Money sent to Non-XMS users will be reversed after 30 days if they do
          not register
        </Alert>
        {showAlert !== "" ? (
          <p style={{ color: "red", fontSize: 12 }}>{showAlert}</p>
        ) : (
          ""
        )}
        <form onSubmit={(e) => handleChange(e)}>
          <div className={styles.available}>
            <span id={styles.available}>
              Available: {formatPriceByCountrySymbol("", walletBalance ?? 0)} |
            </span>
            <span
              id={styles.available}
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => setCurrentAmt(walletBalance)}
            >
              Use all
            </span>
          </div>
          <div className={styles.details}>
            <div className={styles.row}>
              <p>Amount</p>
              <input
                type="number"
                min={0}
                // max={unitBalance}
                // step={0.01}
                value={currentAmt}
                onChange={(e) => setCurrentAmt(e.target.value)}
              />
            </div>
            <div className={styles.row}>
              <p>Email</p>
              <input type="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div
              className={styles.row}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <button>SEND</button>
            </div>
          </div>
        </form>
        <Alert
          openAlert={openSuccess}
          Text={"xUnits transferred successfully!"}
          Type={"success"}
          handleClose={handleClose}
        />
        <Alert
          openAlert={errorMessage}
          Text={transferError}
          Type={"error"}
          handleClose={handleError}
        />
      </div>
    </>
  );
}

export default TransferCard;
