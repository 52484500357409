import Logo from "../Logo/Logo";
import LogoFooter from "../Logo/LogoFooter";

import { COLORS, FONTSIZE } from "../../constants/theme";
import { Link, useNavigate } from "react-router-dom";
import home from "../../assets/homeicon.svg";
import sms from "../../assets/sendsmsicon.svg";
import fund from "../../assets/fundicon.svg";
import contact from "../../assets/sidebarcontacticon.svg";
import transaction from "../../assets/transactionicon.svg";
import history from "../../assets/historyicon.svg";
import styles from "./Sidebar.module.css";
import people from "../../assets/people.svg";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import ConfirmationBox from "../confirmationBox/ConfirmationBox";

const { sidebar, sidebarText } = COLORS;
const { px14 } = FONTSIZE;

export const listOne = [
  {
    title: "Home",
    icon: home,
    url: "dashboard",
  },
  {
    title: "Messaging",
    icon: sms,
    url: "instant-sms",
  },
  {
    title: "My Wallet",
    icon: fund,
    url: "wallet",
  },
  {
    title: "Phone Groups",
    icon: contact,
    url: "phonegroups",
  },
  {
    title: "Transactions",
    icon: transaction,
    url: "transactions",
  },
  // {
  //   title: "Users & Teams",
  //   icon: people,
  //   url: "people/users",
  // },
];

const listTwo = [
  // {
  //   title: "Resources",
  //   icon: "/images/resources.svg",
  //   url: "resources",
  // },
  {
    title: "Settings",
    icon: "/images/settings.svg",
    url: "settings/profile",
  },
];

export default function Sidebar({ open }) {
  const navigate = useNavigate();

  const [confirmation, setConfirmation] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const clearStorage = async () => {
    // file deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
    await axios.post("/user/logout").then(() => {
      localStorage.removeItem("userDetails");
      setTimeout(() => {
        navigate("/login");
      }, 500);
    });
  };

  const logOut = () => {
    setOpenDialogBox(true);
  };

  useEffect(() => {
    if (confirmation) {
      clearStorage();
      // setOpenDialogBox(false);
      // setConfirmation(false);
    }
  }, [confirmation]);

  return (
    <div
      className={styles.container}
      style={{
        width: "256px",
        backgroundColor: sidebar,
        padding: "2rem 0",
        height: "100vh",
        display: window.innerWidth < 992 ? open : "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "fixed",
        overflowY: "scroll",
      }}
    >
      <div>
        <Logo />
        <div>
          <div
            style={{
              color: sidebarText,
              listStyleType: "none",
              margin: 0,
              padding: "0 2rem",
              fontSize: "px14",
              marginTop: "3.438rem",
              borderBottom: "1px solid rgba(143, 143, 143, 0.54)",
            }}
          >
            {listOne.map((item, i) => (
              <List
                key={i}
                title={item.title}
                url={item.url}
                icon={item.icon}
              />
            ))}
          </div>
          <div
            style={{
              color: sidebarText,
              listStyleType: "none",
              margin: 0,
              padding: "0 2rem",
              fontSize: "px14",
              marginTop: "2rem",
            }}
          >
            {listTwo.map((item, i) => (
              <List
                key={i}
                title={item.title}
                icon={item.icon}
                url={item.url}
              />
            ))}

            <div
              // to={``}
              style={{
                padding: ".5rem",
                marginBottom: "1.5rem",
                alignItems: "center",
                display: "flex",
                color: sidebarText,
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={logOut}
            >
              <i
                className="ri-logout-box-line text-4xl"
                id={styles.sideIcons}
              ></i>
              <span
                // style={{ marginLeft: "1rem", fontSize: px14 }}
                id={styles.sideTitles}
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={"Are you sure you want to Log out?"}
        />
      )}
      {/* <LogoFooter /> */}
    </div>
  );
}

const List = ({ title, icon, url }) => {
  return (
    <Link
      to={`/${url}`}
      style={{
        padding: ".5rem",
        marginBottom: "1.5rem",
        alignItems: "center",
        display: "flex",
        color: sidebarText,
        textDecoration: "none",
      }}
    >
      <img src={icon} alt={title} id={styles.sideIcons} />
      <span id={styles.sideTitles}>{title}</span>
      {/* <span style={{ marginLeft: "1rem", fontSize: px14 }} id={styles.sideTitles}>{title}</span> */}
    </Link>
  );
};
