import {
  getDashboardAnalytics,
  getWalletStart,
  getWalletSuccess,
} from "../../redux/wallet/getWalletSlice";
import axios from "../axios";

export const getWallet = (data) => async (dispatch) => {
  dispatch(getWalletStart());
  axios
    .get("/user/get-balance")
    .then(function (response) {

    dispatch(getWalletSuccess(response?.data?.data));
    })
    .catch(function (error) {
 
    //   dispatch(getWalletFail("cannot get data at the moment"));
    });
};

export const getAnalytics = () => async (dispatch) => {
  axios
    .get("/user/dashboard")
    .then((response) => {
      const { data } = response.data;
      dispatch(getDashboardAnalytics(data[0]));
    })
    .catch((error) => {});
}
