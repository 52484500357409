import * as React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import "./basicSelect.css";
import exchange from "../../assets/exchange.svg";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    background: "url(../../assets/exchange.svg)",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "14px 26px 14px 2px",
    marginLeft: -9,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
    ].join(","),
    "&:focus": {
      borderRadius: 5,
      borderColor: "#fff",
      boxShadow:
        " 0px 5px 8px 0px rgba(92, 92, 92, 0.2), 0px 7px 10px 0px rgba(92, 92, 92, 0.2)",
    },
  },
}));

export default function CustomizedSelects() {
  const [bank, setBank] = React.useState("");
  const [channel, setChannel] = React.useState("");

  const handlePayment = (event) => {
    setChannel(event.target.name);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "-10px",
        marginTop: "10px",
      }}
    >
      {/* <FormControl sx={{ m: 1, width: "99.2%" }} variant="standard">
        <h3 id="label"> Which of our banks did you use?</h3>

        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={bank}
          name="bank"
          onChange={(e)=>setBank(e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem value="">
          </MenuItem>
          <MenuItem value={"GTB"}>Guaranty Trust Bank</MenuItem>
        </Select>
      </FormControl> */}


      <FormControl sx={{ m: 1, width: "99.2%" }} variant="standard">
        <h3 id="label"> What payment channel did you use?</h3>
        <Select
          labelId="demo-customized-select-label2"
          id="demo-customized-select2"
          route={channel}
          name="channel"
          onChange={(e)=>setChannel(e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem route="1" placeholder="which bank">
            {/* <em>None</em> */}
          </MenuItem>
          <MenuItem value="ATM Transfer">ATM Transfer</MenuItem>
          <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
          <MenuItem value="Cash Deposit">Cash Deposit</MenuItem>
          <MenuItem value="USSD">USSD</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
          
        </Select>
      </FormControl>
    </div>
  );
}
