import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";
import { useState } from "react";
import axios from "../../../../../utils/axios";
import Alert from "../../../../../utils/Alert";

const Route = ({
  setAction,
  setActionResponse,
  action,
  loading,
  setActionLoading,
  senderType,
  email,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  const [senderTypes, setSenderTypes] = useState(senderType);

  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const allSenderTypes = ["transactional", "promotional"];

  const handleSenderTypeChange = (type) => {
    if (senderTypes.includes(type)) {
      setSenderTypes(senderTypes.filter((t) => t !== type));
      updateSenderType(type, false);
    } else {
      setSenderTypes([...senderTypes, type]);
      updateSenderType(type, true);
    }
  };

  const updateSenderType = (type, enable) => {
    const route = enable ? "enable-route" : "disable-route";

    const requestData = {
      user_email: email,
      route: type,
    };

    axios
      .post(`/admin/user/${route}`, requestData)
      .then((response) => {
        setAlertStatus({
          alertMessage: response.data.message,
          alertType: "success",
          openAlert: true,
        });
      })
      .catch((error) => {
        setAlertStatus({
          alertMessage: error.response.data.error,
          alertType: "error",
          openAlert: true,
        });
      });
  };

  return (
    <div>
      <Modal
        open={action}
        onClose={() => setAction(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="w-[477px]">
          <div className="bg-white w-full rounded-xl">
            <div className="flex justify-between items-center py-3 border-b-2 px-[31px]">
              <h3 className="font-semibold text-gray-700">Enable SMS Route</h3>
              <button
                className="px-3 py-1 bg-red-700 text-white rounded"
                onClick={() => setAction(false)}
              >
                Close
              </button>
            </div>
            <div className="flex flex-row justify-center items-center my-5 p-10">
              <FormControl component="fieldset">
                <FormGroup row>
                  {allSenderTypes.map((type) => (
                    <FormControlLabel
                      key={type}
                      sx={{ display: "flex" }}
                      control={
                        <Checkbox
                          checked={senderTypes.includes(type)}
                          onChange={() => handleSenderTypeChange(type)}
                          name={type}
                        />
                      }
                      label={type}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </Modal>
      {alertStatus.openAlert && (
        <Alert
          openAlert={alertStatus.openAlert}
          Text={alertStatus.alertMessage}
          Type={alertStatus.alertType}
          handleClose={() =>
            setAlertStatus({
              ...alertStatus,
              openAlert: false,
            })
          }
        />
      )}
    </div>
  );
};

export default Route;
