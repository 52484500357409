import {
  getAuditLogStart,
  getAuditLogSuccessful,
} from "../../redux/admin/records/auditLogSlice";
import axios from "../axios";

export const GetAuditLogEndpoint = () => async (dispatch) => {
  dispatch(getAuditLogStart());
  await axios.get("/admin/audit/admins").then((response) => {
    const { data } = response.data;
    dispatch(getAuditLogSuccessful(data));
  });
};
