import React from 'react'
import styles from "./userDetails.module.css"
import { useNavigate } from "react-router-dom";
import { ReactComponent as Filter } from "../../../assets/filtericon.svg";

const ViewTeams = () => {
  const tableData = [
    {
      name: "John",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "few seconds ago",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
    {
      name: "Kakashi",
      users: "2 users",
      email: "Hokage@gmail.com",
      activity: "Never logged in",
      desc: "Financial team",
    },
  ];


  return (
    <div className={styles.mainWrap}>
      <div className={styles.transactionBox}>
        <div
          className={styles.tableSection}
          style={{ border: "none", justifyContent: "space-between" }}
        >
          <div className={styles.sortSection}>
            <div className={styles.tableActions}>
            <h3>Teams</h3>
              <button
                className={styles.importBtn}
             
                // onClick={() => navigate("/people/add-user")}
              >
                ADD USER
              </button>
            </div>
            {/* <div></div> */}
            <button className={styles.sortBtn}>
              <Filter />
              Sort by
            </button>
          </div>
          <div style={{ overflowX: "auto" }}>
            <table className={styles.customers}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Users</th>
                  <th>Email</th>
                  <th>Activity</th>
                  <th>Description</th>
                </tr>
              </thead>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.users}</td>
                  <td>{item.email}</td>
                  <td>{item.activity}</td>
                  <td>{item.desc}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTeams