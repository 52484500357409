import React from "react";
import BottomFooter from "../components/footer/BottomFooter";
import Navbar2 from "../components/nav/Navbar2";
import styles from "./privacy.module.css";
import InformationSecurity from "../assets/InformationSecurity.png";

const Privacy = () => {
  const question = [];

  return (
    <>
      <div className={styles.faqWrap}>
        <Navbar2 />
        <div className={styles.topBox}>
          <div id={styles.sideTexts}>
            <h1>Privacy Policy</h1>
            <p>
              Learn more about how XMS and uses data and your rights as
              a XMS user.
            </p>
          </div>
          <div className={styles.topImg}>
            <img src={InformationSecurity} alt="" />
          </div>
        </div>
        <div className={styles.contents}>
          <span>
            <h1>Terms of Use and Privacy Notice</h1>
            <ol>
              <small>
                1. User Data <br /> When you provide us with a User’s data (i.e.
                personal or billing data), you must:
              </small>
              <li> (1) provide accurate, current and complete information; </li>
              <li>(2) keep your login information and password(s) secure;</li>
              <li>(3) ensure the accuracy of your data;</li>
              <li>
                (4) accept all risks of and responsibility for any unauthorized
                access to your data and any to other information that you
                provide to us.
              </li>
            </ol>
          </span>
          <span>
            <ol>
              <small>2. Service Fees and Taxes</small>
              <p>
                You are responsible (financially and otherwise) for all activity
                on your account, including all fees incurred as a result of your
                activity. Charges and any other fees for the use of this website
                and/or our services are described on the website, in service
                agreements or any in any combination thereof. Such charges and
                fees may change at any time. If you keep using the service after
                the change, you accept the new charges and fees. Any change to
                charges and other fees will be applicable to the billing period
                following the change. You are responsible for all applicable
                taxes related to the fees (which may differ depending on your
                geographical location/residence).
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>3. Trademarks, Ownership, and Copyright</small>
              <p>
                All the content on the website (including all files) is the
                property of EXTURI INTEGRATED SOLUTIONS LIMITED and its
                affiliates. Content provided by the User is the property of the
                User. Any use of any portion of the Content without the prior
                written permission of its owner is strictly prohibited. All
                rights to the website and non-User content are expressly
                reserved. User Content is the user’s responsibility. EXTURI
                INTEGRATED SOLUTIONS LIMITED has no responsibility or liability
                for User’s Content, or for any losses or damages your User
                Content may cause. We have the absolute discretion, but no
                obligation, to remove, or change any User Content, at any time
                and for any reason.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>
                4. Limited License of the User Content to EXTURI INTEGRATED
                SOLUTIONS LIMITED.
              </small>
              <p>
                EXTURI INTEGRATED SOLUTIONS LIMITED requires the right to access
                and use the User Content to the extent necessary to operate the
                website and the services. By uploading/entering User Content to
                the website/platform, the User grants EXTURI INTEGRATED
                SOLUTIONS LIMITED and its subsidiaries and affiliates a
                non-exclusive, royalty-free, transferable right to use and
                modify works from such User Content on the website. EXTURI
                INTEGRATED SOLUTIONS LIMITED is not required to pay or
                compensate User for such access or use.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>5. Privacy Policy</small>
              <p>
                EXTURI INTEGRATED SOLUTIONS LIMITED collects personal
                information when Users place an order or otherwise voluntarily
                enter such information into our system. Our server automatically
                records information when Users visit our website, including the
                URL, IP address, browser type and language, and the date and
                time of each User request. We will not disclose personal
                information to third parties. We may use personal information to
                provide the services and products you’ve requested, including
                services that display customized content and or email
                advertising. We may also use personal information for auditing,
                research and analysis to operate and improve our technologies
                and services. EXTURI INTEGRATED SOLUTIONS LIMITED processes all
                personal information on its own servers which are located in the
                Federal Republic of Nigeria.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>6. Warranty</small>
              <p>
                The Site, the Content, and the Services are provided to you on
                an “as is” basis without warranties of any kind, either express
                or implied. We expressly disclaim all other warranties, express
                or implied, including without limitation, implied warranties of
                merchantability, fitness for a particular purpose, title, and
                non-infringement. We do not represent or warrant that content is
                complete, accurate, reliable, current or error-free, and
                expressly disclaim any warranty or representation as to the
                accuracy or proprietary character of the Site, the Services, the
                Content or any portion thereof.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>7. Limitation of Liability</small>
              <p>
                Users waive and shall not assert any claims or allegations of
                any nature whatsoever against EXTURI INTEGRATED SOLUTIONS
                LIMITED, its directors, officers, employees, agents, affiliates,
                subsidiaries, their sponsors, contractors, advertisers, vendors
                or other partners, any of their successors or assigns, or any of
                their respective officers, directors, agents or employees,
                arising out of or in any way relating to your use of the
                website, including, without limitation, any claims or
                allegations relating to the alleged infringement of proprietary
                rights and alleged inaccuracy of Content. We shall not be liable
                for any damages, including but not limited to loss of use, loss
                of profits or loss of data, arising out of or in any way
                connected with the use of the website or services.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>7.1 Message Privacy</small>
              <p>
                The Customer acknowledges that message content is transmitted
                unencrypted and that eavesdropping of phone communications by
                third parties is possible.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>7.2 Delivery</small>
              <p>
                The Customer acknowledges that EXTURI INTEGRATED SOLUTIONS
                LIMITED delivers messages via major telecommunications companies
                and mobile network providers and can therefore only influence
                the delivery of the messages within the technical constraints
                imposed by the above mentioned providers. Messages submitted via
                our website will be transferred to the addressed recipients
                provided that the recipient’s phone is switched on and
                located in an area covered by their subscribed network provider.
                The customer acknowledges that, depending on the recipient’s
                provider service, it may not be possible to transmit the message
                to the recipient successfully, particularly if the provider does
                not support the chosen delivery method at all.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>7.3 Large Volume Transmission</small>
              <p>
                The Customer acknowledges that transmission of large numbers of
                messages may be subject to restrictions and delays on the part
                of the network providers affected.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>7.4 Disruptions and Damages</small>
              <p>
                If the Customer causes damages or disruption of the website or
                our delivery system with malicious intent, he/she shall be
                liable for all direct and indirect consequences and associated
                costs, including full legal costs.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>7.5 Message Content</small>
              <p>
                The User understands that 100% of all messages sent must have
                directly opted in to receive such messages unless exempt from
                these requirements. The User assumes full responsibility for the
                content of the messages transmitted by it or by a third party on
                its request. The User agrees to abide by all laws and
                regulations applicable to the content and intent of the messages
                transmitted by her. The User assumes liability for all
                consequences and costs arising out of offences against such
                regulations. The User must ensure that its message delivery does
                not cause disturbance or harassment of a recipient or other
                third party. The customer agrees to refrain from sending
                messages containing offensive, violent, pornographic, or
                discriminatory, or otherwise illegal content. The User is not
                permitted to send messages to recipients who have made clear
                that they do not wish to receive these messages or
                advertisements (i.e. they are on a Do Not Call List or have
                |opted out”). The sender of such unsolicited messages (the User)
                bears full legal and financial responsibility for such actions.
                EXTURI INTEGRATED SOLUTIONS LIMITED shall not be liable for any
                damages arising out of or in any way connected with the use of
                the website by such Users. The User understands that 100% of all
                messages sent must have directly opted in to receive such
                messages unless exempt from these requirements.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>8. Applicable Law</small>
              <p>
                The User agrees that all disputes, claims or other matters
                arising from its use of the Site will be governed by the laws of
                the Federal Republic of Nigeria. The User also agrees that all
                Claims will be heard and resolved in a court located in the
                Federal Republic of Nigeria.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>9. Termination/Modification of License</small>
              <p>
                EXTURI INTEGRATED SOLUTIONS LIMITED reserves the right, without
                notice and in its sole discretion, without any liability to
                User, to terminate or limit the User’s license and/or access to
                use the website or any services provided by us.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>10. Inactive Accounts; Termination of Agreement</small>
              <p>
                If your subscription payment has been cancelled, rejected, not
                cleared or not received on time, we may deactivate and/or delete
                your account without any notice. All your data will be lost. You
                and EXTURI INTEGRATED SOLUTIONS LIMITED may terminate these
                Terms and User’s usage of the Site and Services at any time.
                Purchased message credits and other fees are not refundable or
                transferable and do not bear interest. In case of
                account/program cancellation by the User, unused credits will
                expire immediately.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>11. Email Subscription and Automatic Opt-In</small>
              <p>
                When you click on the "Verify Email" button, you are
        automatically opted in to receive emails and notifications. This
        automatic opt-in process ensures that you are well-informed and have
        agreed to receive communications from us as part of your decision to
        register. You also have the option to manage your notification
        preferences within your dashboard and can easily unsubscribe from
        further correspondence at any time using the provided unsubscribe link
        in emails.
        Our aim is to create a seamless and user-friendly experience that empowers users to stay informed while respecting their preferences and privacy.
        </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>12. Complaints</small>
              <p>
                All complaints relating to the services provided to the customer
                must be directed to Exturi/XMS by calling the customer care line
                displayed on our website or fill out the contact form on the
                “contact us” page of our website.
              </p>
            </ol>
          </span>
          <span>
            <ol>
              <small>13. Contact Information</small>
              <p>
                If you have any questions regarding these Terms or the website,
                please contact us:
              </p>
            </ol>
          </span>
        </div>
        <div>
          <BottomFooter />
        </div>
      </div>
    </>
  );
};

export default Privacy;
