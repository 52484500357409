import {
  getUserRateStart,
  getUserRateSuccessful,
  UserRateUpdateStart,
  UserRateUpdateSuccessful,
  UserRateUpdateError,
} from "../../redux/admin/settingsEndpoints/customUserRateSlice.js";
import axios from "../axios";

export const GetUserRates = () => async (dispatch) => {
  dispatch(getUserRateStart());
  await axios
    .get("/admin/user/custom-price")
    .then((response) => {
      const { data } = response.data;
      dispatch(getUserRateSuccessful(data));
    })
    .catch((error) => {});
};

export const UpdateUserRate = (userRate) => async (dispatch) => {
  dispatch(UserRateUpdateStart());
  await axios
    .post("/admin/unit/update-user-rate", userRate)
    .then(() => {
      dispatch(UserRateUpdateSuccessful());
    })
    .catch((error) => {
      const { data } = error.response;
      // dispatch(UserRateUpdateError(data));
    });
};
