import React, { useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import { ReactComponent as Calculator } from "../../../assets/calculator.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import ArrowDown from "../../../assets/chevrondown.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadTransactions,
  getTransactionByDate,
  getTransactionByMedium,
  getTransactionByType,
  getTransactionPerpage,
} from "../../../utils/apiCalls/transactions";
import moment from "moment";
import {
  formatFloat,
  formatPriceByCountrySymbol,
} from "../../../utils/formatPrice";
import Paginationn from "../../../components/Pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import MaterialUIPickers from "../../../components/dateAndTime/MaterialUIPickers";
import FilterDropdown from "../../../components/filter/FilterDropdown";
import DownloadDropdown from "../../../components/DownloadDropdown/DownloadDropdown";
import PerpageDropdown from "../../../components/RowDropdown/PerpageDropdown";
import { CSVLink } from "react-csv";

function Transactions() {
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const loading = useSelector((state) => state.transactions.loading);
  const transactions = useSelector(
    (state) => state.transactions.getTransactionData
  );
  const [startDay, setStartDay] = useState(
    moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [stopDay, setStopDay] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  );

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const apply = () => {
    setStartDay(moment(date[0].startDate).format("YYYY-MM-DD"));
    setStopDay(moment(date[0].endDate).format("YYYY-MM-DD"));
    setShowCalendar(false);
  };
  const handlePerpageChange = (value) => {
    setSelectedRow(value);
    dispatch(getTransactionPerpage(value));
  };
  const handleDownloadOptionChange = async (option) => {
    setSelectedDownloadOption(option);
    dispatch(
      downloadTransactions({
        exportType: option,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getTransactionByDate({
        start_date: startDay,
        end_date: stopDay,
        currentPage,
      })
    );
  }, [dispatch, startDay, stopDay, currentPage]);

  const getData = () => {
    let dd = [];
    transactions?.data?.map((data) => {
      dd.push({
        Description: data.tag,
        Amount: data.type === "debit" ? `-${data.amount}` : data.amount,
        "Wallet Balance": data?.wallet_balance,
        Medium: data.medium === "APP" ? "web" : data.medium,
        Date: moment(data.created_at).format("YYYY-MM-DD, h:mm:ss a"),
      });
    });
    return dd;
  };

  const filterByType = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];
  const filterByMedium = [
    { label: "WEB", value: "WEB" },
    { label: "API", value: "API" },
  ];
  const DownloadOptions = [
    { label: "CSV", value: "csv" },
    { label: "EXCEL", value: "excel" },
  ];

  const [selectedDownloadOption, setSelectedDownloadOption] = useState("csv");
  const [selectedRow, setSelectedRow] = useState("");
  const [filterMedium, setFilterMedium] = useState("Medium");
  const [filterType, setFilterType] = useState("Type");

  const handleFilterMediumChange = (value) => {
    setFilterMedium(value);
    dispatch(getTransactionByMedium({ medium: value }));
  };

  const handleFilterTypeChange = (value) => {
    setFilterType(value);
    dispatch(getTransactionByType({ type: value }));
  };

  return (
    <div className={styles.transactionBox}>
      <div className={styles.tableSection}>
        <div className={styles.tableDetails}>
          <div className="flex items-center">
            <div>
              <Calculator />
            </div>
            <div>
              {/* <CSVLink
                data={getData()}
                filename={`XMS_message_history_report [${startDay} to ${stopDay}].csv`}
                style={{ textDecoration: "none" }}
              > */}
              <button>
                <DownloadDropdown
                  items={DownloadOptions}
                  selectedFilter={selectedDownloadOption}
                  onFilterChange={handleDownloadOptionChange}
                />
              </button>
              {/* </CSVLink> */}
            </div>
          </div>
          <div className={styles.middleFilters}>
            <FilterDropdown
              items={filterByMedium}
              selectedFilter={filterMedium}
              onFilterChange={handleFilterMediumChange}
            />
            <FilterDropdown
              items={filterByType}
              selectedFilter={filterType}
              onFilterChange={handleFilterTypeChange}
            />
          </div>
          <div className={styles.tableActions}>
            <button
              className={styles.carlendarBtn}
              onClick={() => setShowCalendar(true)}
            >
              <div>
                <CalendarIcon />
                <p>
                  <div className={styles.desktopTimePicker}>
                    {moment(startDay).format("DD-MM-YYYY")} to{" "}
                    {moment(stopDay).format("DD-MM-YYYY")}
                  </div>
                </p>
              </div>
              <img src={ArrowDown} alt="arrowDown" />
            </button>
            <div className={styles.mobileTimePicker}>
              <MaterialUIPickers />
            </div>
            {showCalendar && (
              <Modal>
                <div className={styles.calendar}>
                  <DateRangePicker
                    showDateDisplay={false}
                    color="#A382C5"
                    rangeColors={"#4B1A7C"}
                    ranges={date}
                    onChange={(item) => setDate([item.selection])}
                  />
                  <div className={styles.buttonSection}>
                    <button onClick={() => setShowCalendar(false)}>
                      Cancel
                    </button>
                    <button onClick={() => apply()}>Apply</button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
        {loading ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress sx={{ color: "#C4C4C4" }} />
          </Box>
        ) : transactions?.data?.data?.length === 0 ? (
          <center>
            <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
              You have not made any transaction
            </Alert>
          </center>
        ) : (
          <>
            {" "}
            <div style={{ overflowX: "auto" }}>
              <table className={styles.customers}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Description</th>
                    <th style={{ textAlign: "center" }}>Amount</th>
                    <th style={{ textAlign: "center" }}>Wallet balance</th>
                    <th style={{ textAlign: "center" }}>Type</th>
                    <th style={{ textAlign: "center" }}>Medium</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.data?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{item.tag}</td>
                      <td
                        style={{
                          textAlign: "center",
                          color: item.type === "debit" ? "red" : "green",
                        }}
                      >
                        {item.type === "debit"
                          ? `${formatFloat(item.amount)}`
                          : formatPriceByCountrySymbol(
                              item.medium === "FUND TRANSFER" ? "Nigeria" : "",
                              item.amount
                            )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {formatPriceByCountrySymbol(
                          "Nigeria",
                          item.wallet_balance
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.type === "debit"
                          ? "Debit"
                          : item.type === "credit" && "Credit"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.medium === "APP" ? "web" : item.medium}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {moment(item.created_at).format(
                          "YYYY-MM-DD, h:mm:ss a"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-row justify-between px-5">
              <div className="flex flex-row gap-1 items-center">
                <PerpageDropdown
                  selectedFilter={selectedRow}
                  onFilterChange={handlePerpageChange}
                  data={transactions}
                />
              </div>
              <div style={{ marginLeft: "-130px" }}>
                {transactions?.data?.length > 0 && (
                  <Paginationn
                    currentPage={transactions?.current_page}
                    totalCount={transactions?.total}
                    pageSize={transactions?.per_page}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
              <div></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Transactions;
