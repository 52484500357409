import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserRate } from "../../utils/AdminApiCalls/customUserRate";
import { CircularProgress } from "@mui/material";

const ConfirmAdminEditUserRate = ({
  close,
  email,
  promotionalRate,
  transactionalRate,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();

  const { updateSuccess } = useSelector((state) => state.customUserRates);
  const continueUserRateUpdate = () => {
    if (!updateSuccess) {
      setLoader(true);
      dispatch(UpdateUserRate(userRate));
    }
  };

  const userRate = {
    email: email,
    pr_rate: promotionalRate,
    tr_rate: transactionalRate,
  };
  return (
    <div className={styles.confirmContainer}>
      <Warning />
      <h3>Are you sure you want to Update User Rate</h3>
      <div className={styles.buttonSection}>
        <button onClick={close}>cancel</button>
        <button onClick={continueUserRateUpdate}>
          {loader ? <CircularProgress sx={{ color: "#C4C4C4" }} /> : "continue"}
        </button>
      </div>
    </div>
  );
};

export default ConfirmAdminEditUserRate;
