export const getPages = (length) => {
  let page = 0;
  if (length > 0 && length <= 160) {
    page = 1;
  } else if (length > 160 && length <= 320) {
    page = 2;
  } else if (length > 320 && length <= 480) {
    page = 3;
  } else if (length > 480 && length <= 640) {
    page = 4;
  } else if (length > 640 && length <= 800) {
    page = 5;
  } else if (length > 800 && length <= 960) {
    page = 6;
  } else if (length > 960 && length <= 1120) {
    page = 7;
  } else if (length > 1120 && length <= 1280) {
    page = 8;
  } else if (length > 1280 && length <= 1440) {
    page = 9;
  } else if (length > 1440 && length <= 1600) {
    page = 10;
  }
  
  return page;
};
