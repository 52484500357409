import React from "react";
import styles from "./Users.module.css";
import Primaryfill from "../../../assets/Primaryfill.svg";
import RolesBox from "./RolesBox";

const AddUser = () => {
  return (
    <>
      <div className={styles.mainBox}>
        <h3>Enter user details</h3>
        <div className={styles.userInfo}>
          <div className={styles.firstField}>
            <span>
              <h4>Name</h4>
              <input
                id={styles.userName}
                type="text"
                placeholder="Enter name"
              />
            </span>
            <span>
              <h4>Email address</h4>
              <input
                id={styles.userEmail}
                type="text"
                placeholder="Enter email address"
              />
            </span>
          </div>

          <span className={styles.numField}>
            <h4>Phone number</h4>
            <input
              id={styles.userNo}
              type="text"
              placeholder="Enter phone number"
            />
          </span>
        </div>

        <RolesBox/>

       
      </div>
    </>
  );
};

export default AddUser;
