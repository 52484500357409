import React, { useEffect, useState } from "react";
import styles from "./ConfirmCard.module.css";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { SendInAppNotifications } from "../../utils/AdminApiCalls/sendInAppNotification";
import { setScheduledNotifications } from "../../utils/AdminApiCalls/scheduleMailandNotifications";
import Alert from "../../utils/Alert";
// import { scheduleSuccess } from "../../redux/admin/Send/sendSmsAllUsersSlice";
import { CircularProgress } from "@mui/material";
import {
  notificationScheduledSuccess,
  resetScheduleSuccess,
  setScheduledNotification,
} from "../../redux/admin/messageScheduleSlices/inAppNotificationScheduleSlice";

const InAppMsgConfirmationCard = ({ rawMessage, subject, close, message }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const date = useSelector((state) => state.getAllNotifications.date);
  const time = useSelector((state) => state.getAllNotifications.time);
  const { loading } = useSelector((state) => state.inAppNotificationScheduler);

  const sendNotification = () => {
    if (date !== "") {
      const details = {
        subject,
        message: rawMessage.join(message),
        date,
        time,
      };
      dispatch(setScheduledNotifications(details));
    } else if (date == "") {
      const data = {
        message: rawMessage.join(message),
        subject,
      };
      dispatch(SendInAppNotifications(data));
      dispatch(setScheduledNotification());
      dispatch(notificationScheduledSuccess());
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (loading) {
      setLoader(true);
    }
    dispatch(resetScheduleSuccess());
  }, [loading]);

  return (
    <>
      <div className={styles.confirmContainer}>
        <Warning />
        <h3>
          Are you sure you want to {date !== "" ? "schedule" : "send"}{" "}
          notification
        </h3>
        <div className={styles.buttonSection}>
          <button onClick={close}>cancel</button>
          <button onClick={sendNotification}>
            {" "}
            {loader ? (
              <CircularProgress sx={{ color: "#C4C4C4" }} />
            ) : (
              "continue"
            )}
          </button>
        </div>
      </div>
      <Alert
        openAlert={openSuccess}
        Text={"Message scheduled successfully!"}
        Type={"success"}
        handleClose={handleClose}
      />
    </>
  );
};

export default InAppMsgConfirmationCard;
