import {
    TopUpFailed,
    TopUpLoading,
    TopUpWallet,
} from "../../../../redux/admin/users/adminTopUpSlice";
import axios from "../../../axios";

export const ProcessAdminTopUp = (data) => async(dispatch) => {
    dispatch(TopUpLoading());
    await axios
        .post(`admin/payment/fund/user`, data)
        .then((response) => {
            const { message } = response.data;
            dispatch(TopUpWallet(message));
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch(TopUpFailed(message));
        });
};