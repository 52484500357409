import React from "react";
import styles from "./Modal.module.css";

function Modal({ children, close,}) {
  return (
    <div
      className={`${styles.modalContainer}`}
      // style={customStyle}
      onClick={close}
      id="modal"
    >
      {children}
    </div>
  );
}

export default Modal;
