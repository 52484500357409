import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getSingleUserLoading: false,
  getSingleUserSuccess: false,
  getSingleUserFailed: false,
  getSingleUserData: null,
};

const getSingleUser = createSlice({
  name: "Single User",
  initialState,
  reducers: {
    getSingleUserStart: (state) => {
      state.getSingleUserLoading = true;
    },
    getSingleUserSuccessful: (state, payload) => {
      state.getSingleUserLoading = false;
      state.getSingleUserData = payload;
      state.getSingleUserSuccess = true;
      state.getSingleUserFailed = false;
    },
    getSingleUserError: (state, payload) => {
      state.getSingleUserLoading = false;
      state.getSingleUserData = null;
      state.getSingleUserSuccess = false;
      state.getSingleUserFailed = payload;
    },
  },
});

export const {
  getSingleUserStart,
  getSingleUserSuccessful,
  getSingleUserError,
} = getSingleUser.actions;

export default getSingleUser.reducer;
