import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  status: "",
  message: "",
};

const SuspendUserSlice = createSlice({
  name: "Suspend User Slice",
  initialState,
  reducers: {
    suspendStart: (state) => {
      state.loading = true;
      state.message = "";
    },
    suspendSuccessful: (state, { payload }) => {
      state.status = "success";
      state.loading = false;
      state.message = payload;
    },
    suspendFailed: (state, { payload }) => {
      state.status = "failed";
      state.loading = false;
      state.message = payload;
    },
    suspendReset: (state) => {
      state.status = "";
      state.message = "";
    },
  },
});

export const { suspendFailed, suspendStart, suspendSuccessful, suspendReset } =
  SuspendUserSlice.actions;

export default SuspendUserSlice.reducer;
