import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentTransactions } from "../../../utils/apiCalls/transactions";
import styles from "./Dashboard.module.css";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import moment from "moment";
import {
  formatFloat,
  formatPriceByCountrySymbol,
} from "../../../utils/formatPrice";
import { useNavigate } from "react-router-dom";

const RecentTransactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getRecentTransactions());
  }, []);
  const loading = useSelector((state) => state.transactions.loading);

  const recentTransactions = useSelector(
    (state) => state.transactions.getTransactionData
  );
  return (
    <div className={styles.tableSection}>
      <div className={styles.tableDetails}>
        <h3 style={{ alignItems: "center" }}>Recent Transactions</h3>
        {/* <button className={styles.sortBtn}>
              <Filter />
              Sort by
            </button> */}
      </div>
      {loading ? (
        <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
          <CircularProgress sx={{ color: "#C4C4C4" }} />
        </Box>
      ) : recentTransactions?.length === 0 ? (
        <center>
          <Alert sx={{ width: 350, marginBottom: "1rem" }} severity="info">
            You have not made any transaction
          </Alert>
        </center>
      ) : (
        <>
          <div style={{ overflowX: "auto" }}>
            <table className={styles.customers}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Description</th>
                  <th style={{ textAlign: "center" }}>Amount</th>
                  <th style={{ textAlign: "center" }}>Wallet balance</th>
                  <th style={{ textAlign: "center" }}>Medium</th>
                  <th style={{ textAlign: "center" }}>Date</th>
                </tr>
              </thead>
              <tbody>
                {recentTransactions &&
                  recentTransactions?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{item.tag}</td>
                      <td
                        style={{
                          textAlign: "center",
                          color: item.type === "debit" ? "red" : "green",
                        }}
                      >
                        {item.type === "debit"
                          ? `${formatFloat(item.amount)}`
                          : formatPriceByCountrySymbol(
                              item.medium === "FUND TRANSFER" ? "Nigeria" : "",
                              item.amount
                            )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {formatPriceByCountrySymbol(
                          "Nigeria",
                          item.wallet_balance
                        )}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        {item.medium === "APP" ? "web" : item.medium}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {moment(item.created_at).format(
                          "YYYY-MM-DD, h:mm:ss a"
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.viewButtonBox}>
            <button
              className={styles.viewBtn}
              onClick={() => navigate("/transactions")}
            >
              VIEW ALL
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default RecentTransactions;
