import {
  deleteScheduledMessagesStart,
  deleteScheduledMessagesSuccess,
  getScheduledMessagesFail,
  getScheduledMessagesStart,
  getScheduledMessagesSuccess,
  scheduleSuccess,
  sendScheduledMessagesStart,
  sendScheduledMessagesSuccess,
} from "../../redux/admin/messageScheduleSlices/scheduledMessagesSlice";
import axios from "../axios";

export const getAdminScheduledMessages = () => async (dispatch) => {
  dispatch(getScheduledMessagesStart());
  axios
    .get("/admin/notification/schedule/view")
    .then((response) => {
      const { data } = response.data;
      dispatch(getScheduledMessagesSuccess(data));
    })
    .catch((error) => {
      const { data } = error.response.data;
      dispatch(getScheduledMessagesFail(data));
    });
};

export const deleteAdminScheduledMessages = (id) => async (dispatch) => {
  dispatch(deleteScheduledMessagesStart());
  axios.post(`/admin/notification/schedule/cancel/${id}`).then((response) => {
    const { message } = response.data;
    dispatch(deleteScheduledMessagesSuccess(message));
    dispatch(getAdminScheduledMessages());
  });
};

export const sendAdminScheduledMessages = (id) => async (dispatch) => {
  dispatch(sendScheduledMessagesStart());
  axios.post(`/admin/notification/schedule/send/${id}`).then((response) => {
    const { message } = response.data;
    dispatch(sendScheduledMessagesSuccess(message));
    dispatch(getAdminScheduledMessages());
    dispatch(scheduleSuccess());
  });
};
