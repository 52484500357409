import {
  getAllDashboardInfoFailure,
  getAllDashboardInfoStart,
  getAllDashboardInfoSuccess,
} from "../../../redux/admin/dashboard/dashboardSlice";
import axios from "../../axios";

export const fetchAnalysis = (data) => async (dispatch) => {
  dispatch(getAllDashboardInfoStart());

 
  axios
    .post("/admin/dash/analysis", data)
    .then(function (response) {
    
      const { data } = response.data;
 
      dispatch(getAllDashboardInfoSuccess(data));
    })
    .catch(function (error) {
      dispatch(getAllDashboardInfoFailure(error));
    
    });
};
