import { CircularProgress, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationBox from "../../../../../components/confirmationBox/ConfirmationBox";
import { TopUpReset } from "../../../../../redux/admin/users/adminTopUpSlice";
import Alert from "../../../../../utils/Alert";
import { ProcessAdminTopUp } from "../../../../../utils/apiCalls/admin/user/processAdminTopUp";
import Close from "../../../../assets/closeCircle.svg";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const AdminTopUp = ({ openTopUp, setOpenTopUp, user }) => {
  const dispatch = useDispatch();

  const { loading, topUpStatus, failed } = useSelector(
    (state) => state.topUpWallet
  );

  const [amount, setAmount] = useState(50);
  const [acct_type, setAcctType] = useState("wallet");
  const [purpose, setPurpose] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    alertMessage: "",
    alertType: "",
    openAlert: false,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (confirmation) {
      dispatch(ProcessAdminTopUp({ acct_type, user, amount, reason: purpose }));
    }
  }, [confirmation]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus({ ...alertStatus, openAlert: false });
  };

  const Timeout = () => {
    setTimeout(() => {
      setOpenDialogBox(false);
      setConfirmation(false);
      dispatch(TopUpReset());
      setOpenTopUp(false);
    }, 2000);
  };

  useEffect(() => {
    if (topUpStatus !== "" || failed !== "") {
      setAlertStatus({
        alertMessage: topUpStatus || failed,
        alertType: topUpStatus !== "" ? "success" : "error",
        openAlert: true,
      });
      Timeout();
    }
  }, [loading]);

  // useEffect(() => {
  //   if (!loading && topUpStatus !== "") {
  //     setOpenTopUp(false);
  //     setAlertStatus({
  //       alertMessage: topUpStatus,
  //       alertType: "success",
  //       openAlert: true,
  //     });
  //     dispatch(TopUpReset());
  //   } else if (!loading && failed !== "") {
  //     setOpenTopUp(false);
  //     setAlertStatus({
  //       alertMessage: failed,
  //       alertType: "error",
  //       openAlert: true,
  //     });
  //     dispatch(TopUpReset());
  //   }
  // }, [loading]);

  return (
    <div>
      <Modal
        open={openTopUp}
        onClose={() => setOpenTopUp(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="w-[577px]">
          <div className="bg-white w-full rounded-xl">
            <div className="flex justify-between items-center py-3 border-b-2 px-[31px]">
              <h3 className="font-semibold text-gray-700">
                Add funds to user account{" "}
              </h3>
              <img
                src={Close}
                alt="close the side panel"
                onClick={() => setOpenTopUp(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="w-full px-[31px] bg-[#f5f5f5]">
              <div className="pb-6">
                <div className="w-full pt-7 mb-14">
                  <label
                    className="block tracking-wide font-bold text-gray-700 my-4"
                    htmlFor="grid-password"
                  >
                    Amount
                  </label>
                  <input
                    className="w-[295px] p-1"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />

                  <Select
                    sx={{
                      width: 100,
                      height: 40,
                      marginRight: 15,
                      border: "1px solid darkgrey",
                      color: "#fff",
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    <MenuItem value={"testing"}>Test</MenuItem>
                    <MenuItem value={"live"}>Live</MenuItem>
                  </Select>
                </div>

                <div>
                  <p className="font-bold mb-3">Add funds to</p>
                  <div
                    className={`${
                      acct_type === "wallet" && "border-2 border-[#4B1A7C]"
                    } mb-3 bg-white px-7 py-4 cursor-pointer`}
                    onClick={() => setAcctType("wallet")}
                  >
                    <p
                      className={`${
                        acct_type === "wallet" && "text-[#4B1A7C]"
                      } font-semibold`}
                    >
                      Pay to wallet balance
                    </p>
                    <p className="font-semibold text-gray-400">
                      This method ensures money will be added directed to the
                      user’s wallet.
                    </p>
                  </div>
                  {/* <div
                    className={`${
                      acct_type === "unit" && "border-2 border-[#4B1A7C]"
                    } bg-white px-7 py-4 cursor-pointer`}
                    onClick={() => setAcctType("unit")}
                  >
                    <p
                      className={`${
                        acct_type === "unit" && "text-[#4B1A7C]"
                      } font-semibold`}
                    >
                      Pay to xUnit balance
                    </p>
                    <p className="font-semibold text-gray-400">
                      This method ensures money will be added directed to the
                      user’s xUnit balance.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full py-3 border-t-2 px-[31px]">
              <button className="bg-white shadow-lg rounded-md font-bold mr-5 px-6 py-1">
                Cancel
              </button>
              <button
                className={`bg-[#4B1A7C] shadow-lg focus:shadow-outline rounded-md focus:outline-none text-white font-bold py-1 px-6
                    `}
                type="submit"
                onClick={() => setOpenDialogBox(true)}
              >
                {loading ? <CircularProgress size={20} /> : "Next"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {openDialogBox && (
        <ConfirmationBox
          openDialogBox={openDialogBox}
          setConfirmation={setConfirmation}
          setOpenDialogBox={setOpenDialogBox}
          confirmation={confirmation}
          text={"Are you sure you want to top up?"}
        />
      )}
      <Alert
        openAlert={alertStatus.openAlert}
        Text={alertStatus.alertMessage}
        Type={alertStatus.alertType}
        handleClose={handleClose}
      />
    </div>
  );
};

export default AdminTopUp;
