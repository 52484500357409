import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  registeringAdminError: null,
  registeringAdminData: null,
  adminRegistered: false,
  getAdminLoading: false,
  getAllAdminData: [],
  // getSingleAdminData:{},
};

export const addAdminSlice = createSlice({
  name: "registerAdmin",
  initialState,
  reducers: {
    registeringAdminStart: (state) => {
      state.loading = true;
    },
    registeringAdminSuccess: (state) => {
      state.loading = false;
      state.registeringAdminError = null;
      state.adminRegistered = true;
    },
    registeringAdminFail: (state, { payload }) => {
      state.loading = false;
      state.registeringAdminData = null;
      state.registeringAdminError = payload;
    },
    getAdminStart: (state) => {
      state.getAdminLoading = true;
    },
    getAdminSuccess: (state, { payload }) => {
      state.getAllAdminData = payload;
      state.getAdminLoading = false;
    },
    clearRegisteringAdminErrorMessage: (state) => {
      state.registeringAdminError = null;
    },
  },
});

export const {
  registeringAdminStart,
  registeringAdminFail,
  registeringAdminSuccess,
  getAdminStart,
  getAdminSuccess,
  clearRegisteringAdminErrorMessage,
} = addAdminSlice.actions;

export default addAdminSlice.reducer;
