import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Summary.module.css";
import closeWhite from "../../assets/closeWhite.svg";
import userProfile from "../../assets/profileTick.svg";
import CardBox from "./CardBox";
import { getPages } from "../../utils/getPages";
import {
  saveSummary,
  toggleShowSummary,
  toggleWarning,
} from "../../redux/sms/messageSummarySlice";
import Modal from "../modal/Modal";
import ConfirmCard from "../confirmCard/ConfirmCard";
import moment from "moment";
import { useEffect } from "react";
// import { getUnitValue } from "../../utils/apiCalls/sendSms";
import axios from "../../utils/axios";
import bill from "../../assets/bill.svg";
import note from "../../assets/note.svg";
import people from "../../assets/people2.svg";
import directboxSend from "../../assets/directboxSend.svg";
import { formatPriceByCountrySymbol } from "../../utils/formatPrice";

function Summary({ close, proceed, costBreakdown, msgSummary }) {
  const dispatch = useDispatch();
  const { sender_id, destPhone, msgContent, date, time } = msgSummary;

  const { networkCostRate, totalCost, totalReciever, totalPages } =
    costBreakdown;
  const [loading, setLoading] = useState(true);
  const [unit, setUnit] = useState({});

  const dateTime =
    date === undefined ? new Date(Date.now()) : date + " " + time;

  const pages = getPages(msgContent?.length);
  const showWarning = useSelector((state) => state.summary.showWarning);
  function handleChange() {
    dispatch(toggleWarning());
  }

  useEffect(() => {
    dispatch(saveSummary(msgSummary));
  }, [msgSummary]);

  const cards = [
    {
      Title: "Cost",
      icon: bill,
      Value: formatPriceByCountrySymbol(
        "Nigeria",
        costBreakdown.totalCost ?? 0
      ),
    },
    {
      Title: "Recipients",
      icon: people,
      Value: costBreakdown.totalreceiver,
    },
    {
      Title: "Pages",
      icon: note,
      Value: costBreakdown.totalPages,
    },
  ];
  return (
    <>
      {showWarning === true && (
        <Modal customStyle={{ backdropFilter: "blur(20px)", zIndex: 9 }}>
          <ConfirmCard close={() => dispatch(toggleWarning())} type="sms" />
        </Modal>
      )}

      <div className={styles.mainBox}>
        <div className={styles.notificationBar}>
          <span id={styles.imgBox}>
            <img src={closeWhite} alt="img" />
          </span>
        </div>
        <span id={styles.subHeading}>
          <h2>Sms summary</h2>
        </span>
        <div className={styles.childContainer}>
          {cards.map((cards, index) => (
            <CardBox
              Title={cards.Title}
              icon={cards.icon}
              Value={cards.Value}
            />
          ))}
        </div>

        <div>
          <h3 className={styles.sectionHeader}>Cost Breakdown</h3>
          <div className="flex justify-center">
            <table className={styles.customers}>
              <thead>
                <tr>
                  {networkCostRate.map((networkCostRate) => (
                    <th key={networkCostRate.network}>
                      {networkCostRate.network}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {networkCostRate.map((networkCostRate) => (
                    <td key={networkCostRate.network}>
                      Cost:{" "}
                      <span className={styles.detailValues}>
                        {formatPriceByCountrySymbol(
                          "Nigeria",
                          networkCostRate.total_cost ?? 0
                        )}
                      </span>
                      <br />
                      Recipients:{" "}
                      <span className={styles.detailValues}>
                        {networkCostRate.total_recipients}
                      </span>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.message}>
          <div className={styles.designBox}>
            <span id={styles.dateTime}>
              <h4>{moment(dateTime).format("LLLL")}</h4>
            </span>

            <span className={styles.msgBox}>
              <p id={styles.msgDescription}>{msgSummary.msgContent}</p>
            </span>
          </div>
        </div>
        <div className={styles.senderId}>
          <span>
            <img src={userProfile} alt="" />
          </span>
          <span id={styles.name}>
            <h3>Sender ID</h3>
          </span>
          <span id={styles.signature}>
            <h3>{msgSummary.sender_id}</h3>
          </span>
          <span className={styles.btnBox}>
            <button id={styles.btnCancel} onClick={() => close()}>
              CANCEL
            </button>
            <button
              id={styles.btnContd}
              onClick={() => {
                proceed();
              }}
            >
              CONTINUE
            </button>
          </span>
        </div>
      </div>
    </>
  );
}

export default Summary;
