import React, { useState } from "react";
import styles from "./UserDashboard.module.css";
import backIcon from "../../../assets/backIcon.svg";
import editIcn from "../../../assets/editIcn.svg";
import smallTriangle from "./../../../assets/smallTriangle.svg";
import dustBin from "./../../../assets/dustBin.svg";
import { getTimeFromTimestamp } from "./ClientList";
import { useNavigate } from "react-router-dom";
import { formatPriceByCountrySymbol } from "../../../../utils/formatPrice";
import EditPrice from "./components/EditPrice";
import Route from "./components/Route";

const Headings = ({
  userDetails,
  balanceDetails,
  setSendEmail,
  setSendMessage,
  setOpenDialogBox,
  setAlertStatus,
}) => {
  const navigate = useNavigate();

  const [openPriceBox, setOpenPriceBox] = useState(false);
  const [updatedBalanceDetails, setUpdatedBalanceDetails] =
    useState(balanceDetails);
  const [action, setAction] = useState(false);
  const [actionResponse, setActionResponse] = useState("");
  // const [openDialogBox, setOpenDialogBox] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [senderAction, setSenderAction] = useState({});
  const [sender_type, setSenderType] = useState("");
  // const [alertStatus, setAlertStatus] = useState({
  //   alertMessage: "",
  //   alertType: "",
  //   openAlert: false,
  // });

  const handlePriceUpdate = (newBalanceDetails) => {
    setUpdatedBalanceDetails(newBalanceDetails);
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex justify-center items-center">
          <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <img src={backIcon} alt="" />
          </div>
          <div className={`${styles.userInfo} pl-5`}>
            <big>
              {userDetails.user.first_name + " " + userDetails.user.last_name}
            </big>
            <h6>Mobile: {userDetails.user.phone}</h6>
            <h6>{userDetails.user.email}</h6>
            <h6>
              Registered on: {getTimeFromTimestamp(userDetails.user.created_at)}
            </h6>
          </div>
        </div>
        <div className={styles.actionBtnns}>
          <small onClick={() => setSendMessage(true)}>Send SMS</small>
          <small onClick={() => setSendEmail(true)}>Send Email</small>
          <small onClick={() => setOpenDialogBox(true)}>
            {userDetails.status === "suspended"
              ? "Un-suspend User"
              : "Suspend User"}
          </small>
          <small onClick={() => setAction(true)}>Set route</small>
        </div>
        <div className="flex justify-between items-center gap-4">
          <div className={`${styles.unitPrice}`}>
            <div className="flex">
              <h3 className="mr-2">Unit Price</h3>
              <div onClick={() => setOpenPriceBox(true)}>
                <img src={editIcn} alt="" />
              </div>
            </div>
            <span id={styles.editUnit}>
              <div className="flex items-center">
                <p className="mr-2">Pr: </p>
                <h5>{balanceDetails.transactional_unit_price}</h5>
              </div>
              <div className="flex items-center">
                <p className="mr-2">Tr: </p>
                <h5>{balanceDetails.promotional_unit_price}</h5>
              </div>
            </span>
          </div>
          <div className={`${styles.totalMsgs} pl-5`}>
            {/* <h3>Total Messages</h3>
            <h5>
              {formatPriceByCountrySymbol("", +balanceDetails.message_sent)}
            </h5> */}
            <button
              className="px-4 py-1 text-white rounded-lg my-5 font-bold"
              style={{ backgroundColor: "rgb(59 130 246)" }}
              onClick={() =>
                navigate("singleusersenderid", {
                  state: { email: userDetails.email },
                })
              }
            >
              View Sender IDs
            </button>
          </div>
        </div>
      </div>

      {action && (
        <Route
          setActionResponse={setActionResponse}
          setAction={setAction}
          action={action}
          loading={actionLoading}
          setActionLoading={setActionLoading}
          setSenderType={setSenderType}
          setAlertStatus={setAlertStatus}
          senderType={sender_type}
          email={userDetails.email}
        />
      )}

      {openPriceBox && (
        <EditPrice
          trRate={balanceDetails.transactional_rate}
          prRate={balanceDetails.promotional_rate}
          email={userDetails.email}
          openPriceBox={openPriceBox}
          setOpenPriceBox={setOpenPriceBox}
          setAlertStatus={setAlertStatus}
          onPriceUpdate={handlePriceUpdate}
        />
      )}
    </>
  );
};

export default Headings;
