import {
  getHistoryStart,
  getReportSuccess,
  getReportFail,
  getHistorySuccess,
  getSmsSummaryStart,
  getSmsSummarySuccess,
  getSmsSummaryFail,
} from "../../redux/sms/historySlice";
import axios from "../axios";

export const getDeliveryReport =
  (ref, perpage, page, network, status, recipient) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      .get(
        // `/user/sms/report-details/${ref}?perpage=${perpage}&page=${page}&status=${status}&recipient=${recipient}&network=${network}`
        `/user/sms/report-details/${ref}`
      )
      .then((response) => {
        const { data } = response.data;

        dispatch(getReportSuccess(data));
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(getReportFail(message));
      });
  };
export const getDeliveryReportByNetwork =
  (ref, network) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      .get(
        `/user/sms/report-details/${ref}?&network=${network}`
        // `/user/sms/report-details/${ref}`
      )
      .then((response) => {
        const { data } = response.data;

        dispatch(getReportSuccess(data));
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(getReportFail(message));
      });
  };
export const getDeliveryReportPerpage = (ref, perpage) => async (dispatch) => {
  dispatch(getHistoryStart());
  await axios
    .get(
      `/user/sms/report-details/${ref}?&perpage=${perpage}`
      // `/user/sms/report-details/${ref}`
    )
    .then((response) => {
      const { data } = response.data;

      dispatch(getReportSuccess(data));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(getReportFail(message));
    });
};

export const deliveryReportDownload = (ref, exportType) => async (dispatch) => {
  dispatch(getHistoryStart());
  await axios
    .get(
      `/user/sms/report-details/${ref}?&export-type=${exportType}`
      // `/user/sms/report-details/${ref}`
    )
    .then((response) => {
      const { data } = response.data;

      dispatch(getReportSuccess(data));
    })
    .catch((error) => {
      const { message } = error.response.data;
      dispatch(getReportFail(message));
    });
};

export const getMessageHistory =
  (startDate, stopDate, currentPage) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      // .get(`/user/sms/history/${startDate}/${stopDate}`)
      .get(
        `/user/sms/report-summary?startDate=${startDate}&stopDate=${stopDate}&page=${currentPage}`
      )
      .then((response) => {
        const { data } = response.data;
        dispatch(getHistorySuccess(data));
      })
      .catch((error) => {});
  };
export const getMessageSummaryPerpage =
  (startDate, stopDate, perpage) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      // .get(`/user/sms/history/${startDate}/${stopDate}`)
      .get(
        `/user/sms/report-summary?startDate=${startDate}&stopDate=${stopDate}&perpage=${perpage}`
      )
      .then((response) => {
        const { data } = response.data;
        dispatch(getHistorySuccess(data));
      })
      .catch((error) => {});
  };
export const getMessageSummaryBySenderId =
  (startDate, stopDate, senderId) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      // .get(`/user/sms/history/${startDate}/${stopDate}`)
      .get(
        `/user/sms/report-summary?startDate=${startDate}&stopDate=${stopDate}&senderId=${senderId}`
      )
      .then((response) => {
        const { data } = response.data;
        dispatch(getHistorySuccess(data));
      })
      .catch((error) => {});
  };
export const messageHistoryDownload =
  (startDate, stopDate, exportType) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      .get(`/user/sms/report-summary`, {
        params: {
          startDate: startDate,
          stopDate: stopDate,
          "export-type": exportType,
        },
      })
      .then((response) => {
        const { data } = response.data;
        dispatch(getHistorySuccess(data));
      })
      .catch((error) => {});
  };

export const messageSummaryByRoute =
  (startDate, stopDate, route) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      .get(
        `/user/sms/report-summary?startDate=${startDate}&stopDate=${stopDate}&route=${route}`
      )
      .then((response) => {
        const { data } = response.data;
        dispatch(getHistorySuccess(data));
      })
      .catch((error) => {});
  };
export const messageSummaryByMedium =
  (startDate, stopDate, medium) => async (dispatch) => {
    dispatch(getHistoryStart());
    await axios
      .get(
        `/user/sms/report-summary?startDate=${startDate}&stopDate=${stopDate}&medium=${medium}`
      )
      .then((response) => {
        const { data } = response.data;
        dispatch(getHistorySuccess(data));
      })
      .catch((error) => {});
  };

export const getSmsReportSummary =
  (startDate, stopDate) => async (dispatch) => {
    dispatch(getSmsSummaryStart());

    try {
      const response = await axios.get(
        `/user/sms/report-summary?startDate=${startDate}&stopDate=${stopDate}`
      );
      const { data } = response.data;
      dispatch(getSmsSummarySuccess(data));
    } catch (error) {
      dispatch(getSmsSummaryFail(error.message));
    }
  };
