import React from "react";
import styles from "./userDetails.module.css";
import closeCircle from "../../../assets/closeCircle.svg";

const VerifyPayment = (props) => {
  return (
    <div>
      <div id={styles.mainBg}>
        <div id={styles.modalll}>
          <div className={styles.flex1}>
            <h3>Verify Deposit</h3>
            <img
              src={closeCircle}
              alt="close"
              id={styles.closeBtnn}
              onClick={props.close}
            />
          </div>
          <div className={styles.modalContents}>
            <div className={styles.subHeadings}>
              <h4>Name</h4>
              <h4>Amount Deposited</h4>
              <h4>Payment Method</h4>
              <span id={styles.pops}>
                <small>IMAGE</small>
                <span id={styles.bottomLabel}>Click to view POP</span>
              </span>
            </div>
            <div className={styles.subValues}>
              <h5>Kakashi Hatake</h5>
              <h5>200,000.00</h5>
              <h5>Local Transfer</h5>
            </div>
          </div>
          <div className={styles.actionBtns}>
            <button id={styles.declineBtn}>Decline</button>
            <button id={styles.verifyBtn}>Verify</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPayment;
